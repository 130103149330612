import React from 'react';
import { makeStyles, } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import NumberFormat from 'react-number-format';
import IconButton from '@material-ui/core/IconButton';
import { isMobile } from 'react-device-detect';

import './CustomTextField.css';

import InputAdornment from '@material-ui/core/InputAdornment';

const useStylesInput = makeStyles((theme) => ({
  root: {


    backgroundColor: 'white',
    borderRadius: 12,

    '&:hover': {
      // backgroundColor: 'orange',
      border: 'none',
    },
    '&:focused': {
      // backgroundColor: 'orange',
      border: 'none',
      borderColor:'transparent'
    },

  },

  input: {
    '&:hover': {
      // backgroundColor: 'orange',
      border: 'none',
    },
    // width: 'auto',


    fontFamily: [
      'Arial',
    ].join(','),


  }


}));


export default function CustomTextField(props) {
  const classes = useStylesInput();
  return <TextField
    id="outlined-basic" label="Outlined" variant="outlined"

    InputLabelProps={{ style: {} }}
    // FormHelperTextProps={{ className: "textfield__helperText" }}

    InputProps={{
      classes, disableUnderline: true,
      inputComponent: props.customtype === "number" ? props.int ? IntFormatCustom : props.decimal === 4 ?NumberFormatCustomFourDecimals :NumberFormatCustom : undefined,
      endAdornment:
        <InputAdornment position="end" style={{ height: '100%', justifyContent: 'flex-end', margin: 0 }}>

          {props.rightbuttonlabel === "" ?
            null
            :

            <IconButton position="end"
              style={{ color: '#251CFF', fontSize: 12, fontWeight: 'bold' }}
              onClick={props.onrightbuttonclick}
              disabled={props.onrightbuttondisable}
            >
              {props.rightbuttonlabel}
            </IconButton>

          }

          {props.showcancellbutton ?

            <CancelIcon style={{ color: '#251CFF' }} onClick={props.clear} />
            :
            null
          }
        </InputAdornment>


    }

    }
    {...props}
    autoCapitalize="off"
    autoComplete="off"
    autoCorrect="off"
  >

  </TextField>
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      allowNegative={false}
      decimalScale={8}
    />
  );
}

function NumberFormatCustomFourDecimals(props) {
  const { inputRef, onChange, ...other } = props;
  return (
      <NumberFormat
          {...other}
          getInputRef={inputRef}
          onValueChange={(values) => {
            onChange({
              target: {
                name: props.name,
                value: values.value,
              },
            });
          }}
          allowNegative={false}
          decimalScale={4}
      />
  );
}

function IntFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      allowNegative={false}
      decimalScale={0}
    />
  );
}


