import { stakePoolService} from '../services/stakePoolService';
import {alertActions} from "./alertActions";
import {
    GET_ALL_STAKE_POOL,
    GET_ALL_STAKE_POOL_SUCCEED,
    GET_ALL_STAKE_POOL_FAILED,
    GET_MY_STAKE_POOL,
    GET_MY_STAKE_POOL_SUCCEED,
    GET_MY_STAKE_POOL_FAILED,
    GET_STAKE_TRANSACTIONS,
    GET_STAKE_TRANSACTIONS_FAILED,
    GET_STAKE_COLLECT_TRANSACTIONS,
    GET_STAKE_COLLECT_TRANSACTIONS_FAILED,
    GET_MY_STAKE_TRANSACTIONS_SUCCEED,
    HARVEST_STAKE_REWARD,
    HARVEST_STAKE_REWARD_SUCCEED,
    HARVEST_STAKE_REWARD_FAILED,
    STAKE,
    STAKE_SUCCEED,
    STAKE_FAILED,
    CLEAR_STAKE_STATE,
    UNSTAKE,
    UNSTAKE_SUCCEED,
    UNSTAKE_FAILED,
    CLEAR_UNSTAKE_STATE,
    STAKE_SIGNING,
    STAKE_SIGNING_CANCELLED,
    CLEAR_HARVEST_STAKE_STATE,
    STAKE_LOCK,
    STAKE_LOCK_SUCCEED,
    STAKE_LOCK_FAILED,
    CLEAR_STAKE_LOCK_STATE,
    GET_MY_STAKE_LOCKER,
    GET_MY_STAKE_LOCKER_SUCCEED,
    GET_MY_STAKE_LOCKER_FAILED,
    GET_STAKE_UNLOCKABLE,
    GET_STAKE_UNLOCKABLE_SUCCEED,
    GET_STAKE_UNLOCKABLE_FAILED,
    CLEAR_STAKE_UNLOCKABLE,
    STAKE_UNLOCK,
    STAKE_UNLOCK_SUCCEED,
    STAKE_UNLOCK_FAILED,
    CLEAR_STAKE_UNLOCK_STATE,
    UNLOCK_ALL_STAKE,
    UNLOCK_ALL_STAKE_SUCCEED,
    UNLOCK_ALL_STAKE_FAILED,
    CLEAR_UNLOCK_ALL_STAKE_STATE,
} from "../constants";
import {poolService} from "../services/poolService";
import {swapService} from "../services/swapService";

export const stakePoolActions = {
    getAllStakePool,
    getMyStakePool,
    getMyStakeTransactions,
    harvestStakeReward,
    clearHarvestState,
    stake,
    clearStakeState,
    unStake,
    clearUnStakeState,
    stakePoolSigning,
    stakePoolSigningCancelled,
    stakeLock,
    clearStakeLockState,
    getMyStakeLocker,
    getStakeUnlockable,
    clearStakeUnlockable,
    stakeUnlock,
    clearStakeUnlockState,
    unlockAllStake,
    clearUnlockAllStakeState
}

function getAllStakePool(payload) {
    return dispatch =>{
        dispatch(request());
        stakePoolService.getAllStakePool(payload)
            .then(
                res => {
                    let finalList = []
                    let tokenReward = ''
                    let currentTokenReward = ''
                    for (let i = 0; i < res.data.length; i ++) {
                        currentTokenReward = res.data[i].tokenReward
                        if (currentTokenReward !== tokenReward) {
                            tokenReward = currentTokenReward
                            if (!finalList.find(item => currentTokenReward in item)) {
                                let tokenRewardMap = {}
                                tokenRewardMap["poolsInfo"] = {'tokenReward':currentTokenReward,'tokens':[],'maxApy':0,'dailyReward':0,'pools':[],'isEnd':true}
                                finalList.push(tokenRewardMap)
                            }
                        }
                        let index = finalList.findIndex(item => currentTokenReward === item.poolsInfo.tokenReward)
                        finalList[index]["poolsInfo"]['tokens'].push(res.data[i].token)
                        finalList[index]["poolsInfo"]['dailyReward'] += res.data[i].reward
                        finalList[index]["poolsInfo"]['pools'].push(res.data[i])
                        finalList[index]["poolsInfo"]['isEnd'] = finalList[index]["poolsInfo"]['isEnd'] && res.data[i].isEnd
                        if (finalList[index]["poolsInfo"]['maxApy'] < res.data[i].apy){
                            finalList[index]["poolsInfo"]['maxApy'] = res.data[i].apy
                        }
                    }
                    dispatch(success(finalList,res.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: GET_ALL_STAKE_POOL } }
    function success(res,data) { return { type: GET_ALL_STAKE_POOL_SUCCEED, res,data } }
    function failure(error) { return { type: GET_ALL_STAKE_POOL_FAILED, error } }
}

function getMyStakePool(payload) {
    return dispatch =>{
        dispatch(request());
        stakePoolService.getMyStakePool(payload)
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: GET_MY_STAKE_POOL } }
    function success(res) { return { type: GET_MY_STAKE_POOL_SUCCEED, res } }
    function failure(error) { return { type: GET_MY_STAKE_POOL_FAILED, error } }
}

function getMyStakeTransactions(payload) {
    let transList = []
    return dispatch =>{
        dispatch(stakeTransactionRequest());
        stakePoolService.getStakeTransactions(payload)
            .then(
                res1 => {
                    dispatch(collectRequest());
                    stakePoolService.getStakeCollectTransactions(payload)
                        .then(
                            res3 => {
                                transList = transList.concat(res1.data)
                                transList = transList.concat(res3.data)
                                transList.sort(function(a,b){
                                    // Turn your strings into dates, and then subtract them
                                    // to get a value that is either negative, positive, or zero.
                                    if (new Date(b.createdAt) - new Date(a.createdAt) === 0){
                                        return b.type - a.type
                                    }
                                    return new Date(b.createdAt) - new Date(a.createdAt);
                                });
                                dispatch(success(transList));
                            },
                            error => {
                                dispatch(collectFailure(error.toString()));
                                dispatch(alertActions.error(error.toString()));
                            }
                        );
                },
                error => {
                    dispatch(stakeTransactionFailure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function stakeTransactionRequest() { return { type: GET_STAKE_TRANSACTIONS } }
    function stakeTransactionFailure(error) { return { type: GET_STAKE_TRANSACTIONS_FAILED, error } }
    function collectRequest() { return { type: GET_STAKE_COLLECT_TRANSACTIONS } }
    function collectFailure(error) { return { type: GET_STAKE_COLLECT_TRANSACTIONS_FAILED, error } }
    function success(res) { return { type: GET_MY_STAKE_TRANSACTIONS_SUCCEED, res } }
}

function harvestStakeReward(payload) {
    return dispatch => {
        dispatch(request());
        stakePoolService.harvestStakeReward(payload)
            .then(
                res => {
                    if (res) {
                        dispatch(success('harvest succeed'));
                    }
                },
                error => {
                    let toast = ''
                    if (error.toString() === "TypeError: Failed to fetch") {
                        toast = "networkError"
                    }else if (error.status===503 || error.status===409){
                        toast = "serviceError"
                    }else{
                        toast = "stakePool-collectReward-failed"
                    }
                    dispatch(failure(toast));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: HARVEST_STAKE_REWARD } }
    function success(msg) { return { type: HARVEST_STAKE_REWARD_SUCCEED, msg } }
    function failure(error) { return { type: HARVEST_STAKE_REWARD_FAILED, error } }
}

function clearHarvestState() {
    return dispatch => {
        dispatch(request());
    };
    function request() { return { type: CLEAR_HARVEST_STAKE_STATE } }
}

function stake(payload,token) {
    return dispatch => {
        dispatch(request());
        stakePoolService.stake(payload, token)
            .then(
                res => {
                    dispatch(success("stake succeed"));
                },
                error => {
                    let toast = ''
                    if (error.toString() === "TypeError: Failed to fetch"){
                        toast = "networkError"
                    }else if(error.status === 503|| error.status===409){
                        toast = "serviceError"
                    }else if(error.data ==="TooFrequentError"){
                        toast = "tooFrequent"
                    }else {
                        toast = 'stakePool-stake-failed'
                    }
                    dispatch(failure(toast));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(address) { return { type: STAKE, address } }
    function success(msg) { return { type: STAKE_SUCCEED, msg } }
    function failure(error) { return { type: STAKE_FAILED, error } }
}

function clearStakeState(){
    return dispatch => {
        dispatch(request());
    };
    function request(address) { return { type: CLEAR_STAKE_STATE, address } }
}

function unStake(payload,token) {
    return dispatch => {
        dispatch(request());
        stakePoolService.unStake(payload, token)
            .then(
                res => {
                    dispatch(success("stake succeed"));
                },
                error => {
                    let toast = ''
                    if (error.toString() === "TypeError: Failed to fetch"){
                        toast = "networkError"
                    }else if(error.status === 503|| error.status===409){
                        toast = "serviceError"
                    }else if(error.data ==="TooFrequentError"){
                        toast = "tooFrequent"
                    }else {
                        toast = 'stakePool-unStake-failed'
                    }
                    dispatch(failure(toast));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(address) { return { type: UNSTAKE, address } }
    function success(msg) { return { type: UNSTAKE_SUCCEED, msg } }
    function failure(error) { return { type: UNSTAKE_FAILED, error } }
}

function clearUnStakeState(){
    return dispatch => {
        dispatch(request());
    };
    function request(address) { return { type: CLEAR_UNSTAKE_STATE, address } }
}

function stakePoolSigning() {
    return dispatch => {
        dispatch(stakePoolSigning())
    }
    function stakePoolSigning() {return { type: STAKE_SIGNING }}
}

function stakePoolSigningCancelled() {
    return dispatch => {
        dispatch(stakePoolSigningCancelled())
    }
    function stakePoolSigningCancelled() {return { type: STAKE_SIGNING_CANCELLED }}
}

function stakeLock(payload) {
    return dispatch => {
        dispatch(request());
        stakePoolService.stakeLock(payload)
            .then(
                res => {
                    dispatch(success("stake lock succeed"));
                },
                error => {
                    let toast = ''
                    if (error.toString() === "TypeError: Failed to fetch"){
                        toast = "networkError"
                    }else if(error.status ===503|| error.status===409) {
                        toast = "serviceError"
                    }else if(error.message ==="TooFrequentError") {
                        toast = "tooFrequent"
                    }else if(error.message === "token locker not available for this pool."){
                        toast = "stakePool-lock-failed-lockerUnavailable"
                    }else{
                        toast = "stakePool-lock-failed"
                    }
                    dispatch(failure(toast));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(address) { return { type: STAKE_LOCK, address } }
    function success(msg) { return { type: STAKE_LOCK_SUCCEED, msg } }
    function failure(error) { return { type: STAKE_LOCK_FAILED, error } }
}

function clearStakeLockState() {
    return dispatch => {
        dispatch(request());
    };
    function request() { return { type: CLEAR_STAKE_LOCK_STATE } }
}

function getMyStakeLocker(payload) {
    return dispatch =>{
        dispatch(request());
        stakePoolService.getMyStakeLocker(payload)
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: GET_MY_STAKE_LOCKER } }
    function success(res) { return { type: GET_MY_STAKE_LOCKER_SUCCEED, res } }
    function failure(error) { return { type: GET_MY_STAKE_LOCKER_FAILED, error } }
}

function getStakeUnlockable(payload) {
    return dispatch =>{
        dispatch(request());
        stakePoolService.getStakeUnlockable(payload)
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: GET_STAKE_UNLOCKABLE } }
    function success(res) { return { type: GET_STAKE_UNLOCKABLE_SUCCEED, res } }
    function failure(error) { return { type: GET_STAKE_UNLOCKABLE_FAILED, error } }
}

function clearStakeUnlockable() {
    return dispatch => {
        dispatch(request());
    };
    function request() { return { type: CLEAR_STAKE_UNLOCKABLE } }
}

function stakeUnlock(payload) {
    return dispatch => {
        dispatch(request());
        stakePoolService.stakeUnlock(payload)
            .then(
                res => {
                    dispatch(success("stake unlock succeed"));
                },
                error => {
                    let toast = ''
                    if (error.toString() === "TypeError: Failed to fetch"){
                        toast = "networkError"
                    }else if(error.status ===503|| error.status===409) {
                        toast = "serviceError"
                    }else if(error.message ==="TooFrequentError") {
                        toast = "tooFrequent"
                    }else if(error.message ==="Locker not released yet.") {
                        toast = "stakePool-unlock-notReleaseYet"
                    }else{
                        toast = "stakePool-unlock-failed"
                    }
                    dispatch(failure(toast));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(address) { return { type: STAKE_UNLOCK, address } }
    function success(msg) { return { type: STAKE_UNLOCK_SUCCEED, msg } }
    function failure(error) { return { type: STAKE_UNLOCK_FAILED, error } }
}

function clearStakeUnlockState() {
    return dispatch => {
        dispatch(request());
    };
    function request() { return { type: CLEAR_STAKE_UNLOCK_STATE } }
}

function unlockAllStake(payload) {
    return dispatch => {
        dispatch(request());
        stakePoolService.unlockAllStake(payload)
            .then(
                res => {
                    dispatch(success("unlockAllStake succeed"));
                },
                error => {
                    let toast = ''
                    if (error.toString() === "TypeError: Failed to fetch"){
                        toast = "networkError"
                    }else if(error.status ===503|| error.status===409) {
                        toast = "serviceError"
                    }else if(error.data ==="TooFrequentError") {
                        toast = "tooFrequent"
                    }else{
                        toast = "stakePool-unlockAll-failed"
                    }
                    dispatch(failure(toast));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(address) { return { type: UNLOCK_ALL_STAKE, address } }
    function success(msg) { return { type: UNLOCK_ALL_STAKE_SUCCEED, msg } }
    function failure(error) { return { type: UNLOCK_ALL_STAKE_FAILED, error } }
}

function clearUnlockAllStakeState() {
    return dispatch => {
        dispatch(request());
    };
    function request() { return { type: CLEAR_UNLOCK_ALL_STAKE_STATE } }
}