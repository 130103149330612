import React from 'react';
import { makeStyles, } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import NumberFormat from 'react-number-format';
import IconButton from '@material-ui/core/IconButton';
import { isMobile } from 'react-device-detect';
import NewCustomCoinSelectorModal from './NewCustomCoinSelectorModal'


import './NewCustomTextField.css';

import InputAdornment from '@material-ui/core/InputAdornment';

const useStylesInput = makeStyles((theme) => ({
  root: {



    // borderRadius: 12,

    fontWeight: '500',
    color: '#251CFF',

    fontFamily: 'Syncopate',
    width: '100%',
    fontSize: isMobile ? 20 : 28,

  },

  input: {
    '&::placeholder': {
      color: '#a7a7a7',
      fontSize: isMobile ? 20 : 28,
      fontFamily: 'Syncopate',
      opacity: 1,

    },

  }


}));

export default function NewCustomTextField(props, t) {
  const classes = useStylesInput();
  const [open, setOpen] = React.useState(false);
  const [unlockWarningOpen, setUnlockWarningOpen] = React.useState(false);
  // const handleOnFocused = () => {
  //   console.log("!!!@@")
  //   handleOpen()
  // };
  // const handleOnBlur = () => {
  //   console.log("xxxxx")
  //   handleOpen()
  // };
  const handleClose = () => {
    setOpen(false);
  };

  const handleUnlockWarningClose = () => {
    setUnlockWarningOpen(false);

  };
  const handleClickOnField = () => {
    if (!props.isAddressInput && !props.staticInput) {


      if (props.disabled) {
        handleNotLogin()
      } else if (props.coin === "") {
        setOpen(true)
      }
    }
  };
  const handleNotLogin = () => {

    setUnlockWarningOpen(true);
  };

  const handleCoinChange = (option) => {

    const resultCoin = { label: option.value ? option.value : option, value: option.value ? option.value : option }

    handleClose()
    if (option) {
      props.handleChange(resultCoin, props.name)
    } else {

      props.handleChange(t('noCapital'))
    }

  };

  return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}><TextField
    // id="outlined-basic" label="Outlined" variant="outlined"
    id="filled-full-width"
    // InputLabelProps={{style:{} }}
    disabled={props.staticInput}
    FormHelperTextProps={{ className: "textfield__helperText" }}
    placeholder={props.placeholder}
    style={{ width: '100%', opacity: props.staticInput ? 0.5 : 1 }}
    // onFocus={handleOnFocused}
    // onBlur={handleOnBlur}
    InputProps={{
      onClick: () => handleClickOnField(),

      classes,
      disableUnderline: true,
      inputComponent: props.customtype === "number" ? props.int ? IntFormatCustom : NumberFormatCustom : undefined,
      // startAdornment:
      //   <InputAdornment position="start">
      //   </InputAdornment>,

      endAdornment:
        <InputAdornment position="end" style={{ height: '100%', justifyContent: 'flex-end', margin: 0 }}>

          {props.rightbuttonlabel === "" ?
            null
            :

            <IconButton position="end"
              style={{ color: '#251CFF', fontSize: 12, fontWeight: 'bold' }}
              onClick={props.onrightbuttonclick}
              disabled={props.onrightbuttondisable}
            >
              {props.rightbuttonlabel}
            </IconButton>

          }

          {props.showcancellbutton ?

            <CancelIcon style={{ color: '#251CFF', marginLeft: 12 }} onClick={props.clear} />
            :
            null
          }
        </InputAdornment>


    }

    }
    {...props}
    autoCapitalize="off"
    autoComplete="off"
    autoCorrect="off"
  >

  </TextField>

    <NewCustomCoinSelectorModal
      unlockWarningOpen={unlockWarningOpen}
      handleUnlockWarningClose={handleUnlockWarningClose}
      handleClose={handleClose}

      handleCoinChange={handleCoinChange}
      tokenIcons={props.tokenIcons}
      coins={props.coins}
      open={open}
    >

    </NewCustomCoinSelectorModal>
  </div >
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      allowNegative={false}
      decimalScale={8}
    />
  );
}

function IntFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      allowNegative={false}
      decimalScale={0}
    />
  );
}


