import React, {useEffect, useState} from 'react';
import {Backdrop, Fade, List, makeStyles, Modal, Typography} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import './DApp.css'
import {roundingDown} from "../../utils/RoundingDown";
import validNode from "../../images/ido/validNode.png";
import nodeLong from "../../images/ido/nodeBgLong.png";
import {convertTimeString} from "../../utils/Common";
import crossIcon from "../../images/crossGrey.png";
import CustomTextField from "../CommonElements/CustomTextField";
import warningIcon from "../../images/warningicon.png";

const Web3 = require("web3");
let tempHeight = null;

function Node({ setOpenSliderModal, handleCloseSliderModal, setSignUpPayload, width, t, sliderInfo,  button2Disabled, registered, loggedIn, address, chainId, network, code, button2, sendBackHeight, sendBackAddr, sendBackChainId, sendBackNetworkId, button1, sendBackButton1, sendBackButton1Disabled, height, navBarHeight }) {
    const useStyles = makeStyles((theme) => ({
        root: {
            ...theme.typography.button,
            backgroundColor: "#010746",
            padding: theme.spacing(1),
            flexGrow: 1,
            height: '100%',
            textAlign: 'center',
            justifyContent: 'center',
        },
        btn: {
            backgroundColor: '#83ACF4',
            color: '#010846',
            borderColor: 'transparent',
            borderRadius: 25,
            height: 45,
            fontWeight: 600,
            minWidth: 100,
            border: 'none',
            '&:hover': {
                // backgroundColor: 'orange',
                border: 'none',
            },
        },
        btn_disabled: {
            backgroundColor: '#8BBDFF',
            color: '#010846',
            borderColor: 'transparent',
            borderRadius: 25,
            height: 45,
            fontWeight: 600,
            opacity: 0.2
        },
        registerBtn: {
            backgroundColor: '#271CFF',
            color: 'white',
            borderColor: 'transparent',
            borderRadius: 25,
            height: 45,
            fontWeight: 600,
            minWidth: 100
        },
        paper: {
            position: 'absolute',
            width: 400,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        defaultFont: {
            fontWeight: '400', color: '#363636', fontFamily: 'Avenir', textTransform: 'none'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },

    }));
    const classes = useStyles();

    const dispatch = useDispatch()
    const history = useHistory()
    const { inviteInfo, getInviteInfoLoading, loading, invitees, rankBonusTransactions, rankDetails } = useSelector(state => state.invite)
    const barRef = React.useRef(null);

    const [nodeInfo, setNodeInfo] = useState([
        {name: 'team', value: 25},
        {name: 'teamContribution', value: 52323},
        {name: 'groupContribution', value: 22323},
        {name: 'communityContribution', value: 32323},
        {name: 'communityLeader', value: '0xf7a1d59F8A57866f0C13b94feB5FD93d50b5276A'},
        {name: 'nodeReward', value: 100000},
    ])

    const [openBonusModal, setOpenBonusModal] = useState(false)

    const [headerHeight, setHeaderHeight] = useState(0)

    const [collectAmount, setCollectAmount] = useState('')

    const [collectAmountMsg, setCollectAmountMsg] = useState('')

    const [collectStatus, setCollectStatus] = useState(0)

    const handleOpenBonusModal = () => {
        setOpenBonusModal(true)
    }

    const handleCloseBonusModal = () => {
        setOpenBonusModal(false)
    }

    const handleCollectAmountChange = (e) => {
        setCollectAmount(e.target.value)
    }

    const renderInfoElement = (item, index) => {
        return (
                <div key={item.name} className={'info-wrap'}>
                    <div className="info-container">
                        <p className={'info-title'}>
                            {t(item.name)}
                        </p>
                        <p  style={{ fontWeight: 500, wordWrap: 'break-word', width: 144, marginTop: 7, cursor: 'unset', fontSize: item.name === 'communityLeader' ? 11 : 20, color: item.name === 'communityLeader' ? 'black' : '#363636', fontFamily: item.name === 'communityLeader' ? "PingFang SC" : 'Syncopate' }}>
                            {
                                roundingDown(item.value, 4)
                            }
                        </p>
                    </div>
                </div>
        )
    }

    const renderMobileInfoElement = (item) => {
        let needCheck = (inviteInfo.level < 2 && item.name === 'indirectinvitee' ) || ( inviteInfo.level < 3 && item.name === 'thirdLevelInvitee')

        return (
            <div key={item.name} className="info-wrap" style={{ backgroundColor: '#F5F5F5' }}>
                <div className="info-container">
                    {

                    }
                    <Typography style={{ width: 144, fontSize: 14, color: '#8B96A7', marginBottom: 7 }}>
                        {t(item.name)}
                    </Typography>
                    <Typography  style={{ fontWeight: 500, wordWrap: 'break-word', width: 144, marginTop: 7, cursor: needCheck ? 'pointer' : 'unset', fontSize: item.name === 'communityLeader' ? 11 : 20, color: item.name === 'communityLeader' ? 'black' : '#363636', fontFamily: item.name === 'communityLeader' ? "PingFang SC" : 'Syncopate' }}>
                        {
                            roundingDown(item.value, 4)
                        }
                    </Typography>
                </div>
            </div>
        )
    }

    const renderHeader = () => {
        return (
            <div ref={barRef} style={{ background: `linear-gradient(310.63deg, #FFC400 0%, #FF0067 100%)`, backgroundSize: 'cover', position: 'absolute', top: 0, zIndex: -1, paddingTop: '3%', width: '100%' }}>
                <div style={{margin:"100px 30px 23px 30px"}}>
                    <div style={{display: 'flex', justifyContent:"space-between", alignItems: "center"}}>
                        <div>
                            <Typography style={{fontFamily:"PingFang SC", fontSize:40, fontWeight:"bold", color: 'white'}}>{t('nodeTitle')}</Typography>
                        </div>
                    </div>
                    <Typography className={classes.font} style={{marginTop:25, fontSize:14, fontWeight:400, color: 'white'}}>{t('nodeContent')}</Typography>
                </div>
            </div>
        )
    }

    const renderNodeInfoDetail = () => {
        return (
            <div className='node-info-header-wrapper'>
                <div className="node-info-header">
                    <div className="node-info-subHeader">
                        <img src={validNode} className='node-info-img' />
                        {
                            width < 960 ?
                                <span className='node-title' >{t('lv1Node')}</span>
                                 :
                                <div style={{ display: 'flex', flexDirection: 'column'}}>
                                    <span className='node-subTitle' >{t('currentLevel')}</span>
                                    <span className='node-title' >{t('lv1Node')}</span>
                                </div>
                        }
                        <div style={{ backgroundColor: '#91D96D', width: 58, height: 20, padding: '2px 0 2px 0', borderRadius: 4, display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                            <Typography style={{ color: 'white', fontWeight: 600, fontSize: 14 }}>
                                {t('valid')}
                            </Typography>
                        </div>
                    </div>
                    <div className="node-info-subHeader">
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%'}}>
                            <span className='collected-info'>{t('collectedVSReleased')}</span>
                            <span className='collected-amount'>0</span>
                        </div>
                        {
                            width < 960 ?
                                <button className="collect-btn" onClick={handleOpenBonusModal} >
                                    <span className='btn-text'>
                                        {t('collectReward')}
                                    </span>
                                </button> : null
                        }
                    </div>
                    {
                        width > 960 ?
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 40 }}>
                                <button className="collect-btn" onClick={handleOpenBonusModal}  >
                                    <span className='btn-text'>
                                        {t('collectReward')}
                                    </span>
                                </button>
                            </div>
                             : null
                    }
                </div>
            </div>
        )
    }

    const renderMobile = () => {
        return (
            <>
                {renderHeader()}
                <div className="ido-mobile-bg-wrapper" style={{ marginTop: headerHeight }}>
                    {renderNodeInfoDetail()}
                    <div className="node-info">
                        <div className="info-body">
                            {
                                nodeInfo.map(item => renderMobileInfoElement(item))
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const renderPC = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundImage: `url(${nodeLong})`,
                        backgroundSize: 'cover',
                        justifyContent: 'space-between',
                        width: '42%',
                        height: 467,
                    }}>
                        <div style={{ marginTop: '30%', width: '91%', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <Typography style={{ fontSize: 32, fontFamily: 'Syncopate', color: 'white' }}>{t('nodeTitle')}</Typography>
                            <Typography className={classes.defaultFont} style={{ fontSize: 14, fontWeight: 'bold', color: 'white', textAlign: 'left' }}>
                                {t('nodeContent')}
                            </Typography>
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "#f9f9f9",
                        width: '56%',
                        height:  467,
                        justifyContent: 'space-around',
                        marginBottom: 30

                    }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }} >
                                {
                                    renderNodeInfoDetail()
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="invite-info">
                    <div className="info-body">
                        {
                            nodeInfo.map((item, index) => renderInfoElement(item, index))
                        }
                    </div>
                </div>
            </div>
        )
    }

    const renderTable = () => {
        return (
            <div className="invite-detail">
                <div className="detail-body" >
                    <p className="detail-title">{t('releaseRecord')}</p>
                    <p className="detail-content">{t('releaseNote')}</p>
                    <div className="invitee-list-wrap">
                        <div className="invitee-list-header">
                            <p className="header-text">
                                {t('releaseAmount')}
                            </p>
                            <p className="header-text">
                                {t('releaseTime')}
                            </p>
                        </div>
                        <List className="invitee-list">
                            {
                                invitees.length > 0 ?
                                    invitees.map(item => (
                                        <div key={item.username} className="invitee-list-item">
                                            <p className="list-item-text">
                                                {item.username}
                                            </p>
                                            <p className="list-item-text">
                                                {convertTimeString(item.createdAt)}
                                            </p>
                                        </div>
                                    )) : <p className="no-invitee">{t('noBonus')}</p>
                            }
                        </List>
                    </div>
                </div>
            </div>
        )
    }

    const collectBonus = () => {
        console.log('clicked!')
    }

    const max = () => {
        setCollectAmount('100')
    }

    const bonusModalBody = (
        <div className="buy-modal-body" style={{ background: 'white', height: 492, width: 396 }}>
            <div className="modal-header" >
                <Typography className={classes.defaultFont} style={{ color: '#1C2237', fontSize: 28, fontWeight: 600 }}>{t('collectBonus')}</Typography>
                <img src={crossIcon} style={{ width: 18 }} onClick={handleCloseBonusModal} />
            </div>
            <Typography className={classes.defaultFont} style={{ fontSize: 14, width: '85%', color: '#8786A7', marginTop: 30 }}>{
                `${t('collectedReleased')}: 0/2000 DBO`
            }</Typography>
            <Typography className={classes.defaultFont} style={{ fontSize: 14, width: '85%', color: '#8786A7', marginBottom: 20 }}>{
                `${t('availableBonus')}: 2000 DBO`
            }</Typography>

            <CustomTextField
                placeholder={t("bonusPlaceHolder")}
                label={''}
                style={{ width: '85%', textTransform: 'none' }}
                onChange={handleCollectAmountChange}
                value={collectAmount}
                helperText={collectAmountMsg}
                error={collectAmountMsg !== ''}
                customtype="text"
                rightbuttonlabel={t('max')}
                onrightbuttonclick={max}
            />

            <div className="info-modal-content">
                <img src={warningIcon} style={{ height: 22, width: 23, paddingRight: 5 }} />
                <Typography style={{ fontSize: 14, fontWeight: 'slim', color: '#8786A7', textAlign: 'left' }}>
                    {t('bonusNote')}
                </Typography>
            </div>

            <div className="modal-btn-wrapper">
                <button style={{ opacity: collectStatus !== 1 ? 0.5 : 1, background: '#271CFF', textTransform: 'none' }} className="buy-btn" onClick={collectBonus} disabled={collectStatus !== 1}>
                    <Typography style={{ fontSize: 20, fontWeight: 'bold', color: 'white' }}>
                        {t('collectBonus')}
                    </Typography>
                </button>
            </div>
        </div>
    )

    useEffect(() => {
        console.log('at Node !: ', headerHeight)
    }, [headerHeight])

    useEffect(() => {
        if (barRef.current && tempHeight === null) {
            tempHeight = barRef.current.getBoundingClientRect().height;
            setHeaderHeight(tempHeight)
        }

        return () => {
        }
    }, [])

    useEffect(() => {
        console.log('rankDetails: ', rankDetails)
        console.log('rankBonusTransactions: ', rankBonusTransactions)
    }, [rankDetails, rankBonusTransactions])

    return (
        <div className="node-body">
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: width <= 960 ? "100%" : width > 1600 ? '51%' : '61%', marginTop: width < 960 ? 'unset': 50 }}>
                {width < 960 ?
                    renderMobile() : renderPC()
                }
                {renderTable()}
            </div>
            <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                aria-labelledby="server-modal-title"
                aria-describedby="server-modal-description"
                className="buy-modal"
                open={openBonusModal}
                onClose={handleCloseBonusModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openBonusModal}>
                    {bonusModalBody}
                </Fade>
            </Modal>
        </div>
    )
}

export default withTranslation()(Node);
