import {
    GET_SWAP_QUOTE,
    GET_SWAP_QUOTE_SUCCEED,
    GET_SWAP_QUOTE_FAILED,
    SWAP,
    SWAP_SUCCEED,
    SWAP_FAILED,
    CLEAR_SWAP_STATE,
    GET_SWAP_TRANSACTIONS,
    GET_SWAP_TRANSACTIONS_SUCCEED,
    GET_SWAP_TRANSACTIONS_FAILED,
    SWAP_SIGNING,
    SWAP_SIGNING_CANCELLED,
    GET_SWAP_PRICE,
    GET_SWAP_PRICE_SUCCEED,
    GET_SWAP_PRICE_FAILED,
    CLEAR_SWAP_QUOTE,
    GET_MAX_PRICE_IMPACT,
    GET_MAX_PRICE_IMPACT_FAILED,
    GET_MAX_PRICE_IMPACT_SUCCEED
} from '../constants';
import {roundingDown} from "../../utils/RoundingDown";

export function swap (state = {
    swapQuote: {
        "token": "",
        "amount": 0,
        "priceImpact": 0,
        "swapFee": 0,
        "pools": []
    },
    swapTransactions:[],
    swapPrice:'0',
    maxPriceImpact:0
}, action) {
    switch (action.type) {
        case GET_SWAP_QUOTE:
            return{
                ...state,
                swapQuoteLoading: true
            }
        case GET_SWAP_QUOTE_SUCCEED:
            return{
                ...state,
                swapQuote: action.res.data,
                swapQuoteLoading: false
            }
        case GET_SWAP_QUOTE_FAILED:
            return{
                ...state,
                swapQuote: {
                    "token": "",
                    "amount": 0,
                    "priceImpact": 0,
                    "swapFee": 0,
                    "pools": []
                },
                message: action.error,
                swapQuoteLoading: false
            }
        case SWAP:
            return{
                ...state,
                swapLoading: true,
                swapSucceed: false,
                swapFailed: false,
                swapSigning: false,
            }
        case SWAP_SUCCEED:
            return{
                ...state,
                swapMessage: action.msg,
                swapLoading: false,
                swapSucceed: true,
            }
        case SWAP_FAILED:
            return{
                ...state,
                swapMessage: action.error,
                swapLoading: false,
                swapFailed: true,
            }
        case CLEAR_SWAP_STATE:
            return{
                ...state,
                swapFailed: false,
                swapSucceed: false,
            }
        case GET_SWAP_TRANSACTIONS:
            return{
                ...state,
                getSwapTransLoading: true,
            }
        case GET_SWAP_TRANSACTIONS_SUCCEED:
            return{
                ...state,
                swapTransactions: action.res,
                getSwapTransLoading: false,
            }
        case GET_SWAP_TRANSACTIONS_FAILED:
            return{
                ...state,
                message: action.error,
                getSwapTransLoading: false,
            }
        case SWAP_SIGNING:
            return{
                ...state,
                swapSigning: true,
            }
        case SWAP_SIGNING_CANCELLED:
            return{
                ...state,
                swapSigning: false,
            }

        case GET_SWAP_PRICE:
            return{
                ...state,
                getSwapPriceLoading:true,
            }
        case GET_SWAP_PRICE_SUCCEED:
            return{
                ...state,
                swapPrice:roundingDown(action.res.data.amount,8),
                getSwapPriceLoading:true,
            }
        case GET_SWAP_PRICE_FAILED:
            return{
                ...state,
                swapPrice:'0',
                getSwapPriceLoading:true,
            }

        case CLEAR_SWAP_QUOTE:
            return{
                ...state,
                swapQuote: {
                    "token": "",
                    "amount": 0,
                    "priceImpact": 0,
                    "swapFee": 0,
                    "pools": []
                }
            }

        case GET_MAX_PRICE_IMPACT:
            return{
                ...state,
                getPriceImpactLoading:true,
                getPriceImpactSucceed:false,
                getPriceImpactFailed:false
            }
        case GET_MAX_PRICE_IMPACT_SUCCEED:
            return{
                ...state,
                getPriceImpactLoading:false,
                getPriceImpactSucceed:true,
                maxPriceImpact:action.res.data
            }
        case GET_MAX_PRICE_IMPACT_FAILED:
            return{
                ...state,
                getPriceImpactLoading:false,
                getPriceImpactFailed:true
            }
        default:
            return state;
    }

    return state;
};
