import React, { useEffect, useRef, useState } from 'react';
import './Navbar.css';
import { withTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, Button, Fade, Grid, ListItem, Modal, Typography } from '@material-ui/core';
import i18n from '../../i18n';
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../redux/actions";
import { onClickConnect, onClickInstall } from "../../utils/Sign";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { getChainName } from "../../utils/Common";
import { isMobile } from 'react-device-detect';

import navIcon from "../../images/navigationBar/navLogo.png";
import connectedWallet from "../../images/navigationBar/connectedWallet.png";
import moreIcon from "../../images/navigationBar/moreIcon.png";
import connectWallet from "../../images/navigationBar/connectWallet.png";
import cnIcon from "../../images/navigationBar/ChinaIcon.png";
import enIcon from "../../images/navigationBar/USAIcon.png";

import wpCN from '../../images/whitePaperCN.pdf'
import wpEN from '../../images/whitePaperEN.pdf'

const loggedInIcon = 'https://cdn.swapallapp.com/l2/images/loggedInIcon.png'
// const chartIcon = 'https://cdn.swapallapp.com/l2/images/chartIcon.png'
// const layer3NavLogo = 'https://cdn.swapallapp.com/l2/images/Layer3NavLogo.png'
// const logo = 'https://cdn.swapallapp.com/l2/images/swapAllIconWithL.png'
// const connectIcon = 'https://cdn.swapallapp.com/l2/images/connectIcon.png'
// const registIcon = 'https://cdn.swapallapp.com/l2/images/registIcon.png'
// const moreDetailIcon = 'https://cdn.swapallapp.com/l2/images/moreDetailIcon.png'
// const moreIcon = 'https://cdn.swapallapp.com/l2/images/moreIcon.png'

let tempHeight = null;


function Navbar({ width, t, sendBackHeight, sendBackAddr, sendBackChainId, sendBackNetworkId, button1, sendBackButton1, sendBackButton1Disabled }) {
    const useStyles = makeStyles((theme) => ({
        bar: {
            background: 'yellow'
        },
        navbarDisplayFlex: {
            display: `flex`,
            justifyContent: `space-between`,
            backgroundColor: 'blue',
            width: '100%'
        },
        navDisplayFlex: {
            display: `flex`,
            justifyContent: `space-between`,
        },
        linkText: {
            textDecoration: `none`,
            textTransform: `uppercase`,
            color: `white`,
            alignSelf: 'center'
        },
        defaultFont: {
            fontWeight: '400', color: '#363636', fontFamily: 'Avenir', textTransform: 'none'
        },
        unselect: {
            textDecoration: `none`, display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 12, marginRight: 12, paddingLeft: 12, paddingRight: 12, fontWeight: '400', color: '#363636', fontFamily: 'Avenir', width: 80, height: 40

        },

        selected: {
            textDecoration: `none`,

            alignSelf: 'center',

            borderRadius: 8,
            backgroundColor: '#F8F8F8',
            color: '#251CFF',
            fontWeight: 'bold',



        },
        logo: {
            width: width > 1000 ? 200 : width * 0.2,
        },
        langBtn: {
            height: 30,
            alignSelf: 'center',
            background: 'transparent',
            color: 'white'
        },
        addrBtn: {
            height: 30,
            alignSelf: 'center',
            background: '#2435AC',
            color: 'white',
            fontSize: width > 350 ? 15 : '0.5rem'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: 'white',
            border: 'transparent',
            // boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            borderRadius: 20,
            textAlign: 'center'
        },
    }));
    const classes = useStyles();
    const [open, setOpen] = useState(false)
    const [openMore, setOpenMore] = React.useState(false);
    const [chainId, setChainId] = useState(0)
    const [network, setNetwork] = useState('')
    const [addr, setAddr] = useState('')
    const [startWatch, setStartWatch] = useState(false)
    const { loggedIn, registered, message, loading, authMsg } = useSelector(state => state.auth)
    const prevNavMessageRef = useRef();
    const history = useHistory();
    const location = useLocation()
    const dispatch = useDispatch();
    const [openMsgModal, setOpenMsgModal] = useState(false)
    const [blueHeader, setBlueHeader] = useState(false)
    const handleOpenMsgModal = () => {
        setOpenMsgModal(true);
    };

    const handleCloseMsgModal = () => {
        setOpenMsgModal(false);
        dispatch(authActions.checkUser(addr))
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const changeLanguage = (e) => {
        let newLang = i18n.language === 'en' ? 'cn' : 'en'
        i18n.changeLanguage(newLang);
        localStorage.setItem('lng', newLang)
    }


    const navLinks = [
        // { title: loggedIn ? t('wallet') : t('home'), path: loggedIn ? `/wallet` : `/` },
        // { title: t('l2Pools'), path: `/` },
        // { title: t('swap'), path: `/swap` },
        { title: t('IDO'), path: '/ido' },
        { title: t('IDOInvite'), path: '/ido/invite' },
        { title: t('dapp'), path: '/dapp' },
        { title: t('wallet'), path: `/wallet` }

        // { title: t('vote'), path: '/vote' },
        // { title: "Layer 3", path: '/layer3' },
    ]

    const barRef = React.useRef(null);
    const prevAuthMsgRef = useRef();

    const switchAccount = async () => {
        await window.ethereum.request({
            method: 'wallet_requestPermissions',
            params: [{
                eth_accounts: {},
            }]
        }).then(res => {
            handleClose()
        })
    };


    function handleNewChain(chainId) {
        setChainId(chainId)
        sendBackChainId(chainId)
    }

    function handleNewNetwork(networkId) {
        setNetwork(networkId)
        sendBackNetworkId(networkId)
    }

    function handleNewAccounts(addr) {
        let address = addr === undefined || addr.length < 1 ? '' : addr[0]
        setAddr(address)
        sendBackAddr(address)
    }

    const initialize = async () => {
        if (window.ethereum) {
            try {
                await window.ethereum.enable();
            } catch (err) {
                console.error('Error on init when getting accounts', err)
            }
            try {
                const newAccounts = await window.ethereum.request({
                    method: 'eth_accounts',
                })
                const chainId = await window.ethereum.request({
                    method: 'eth_chainId',
                })

                const networkId = await window.ethereum.request({
                    method: 'net_version',
                })
                handleNewChain(chainId)
                handleNewNetwork(networkId)
                handleNewAccounts(newAccounts)
            } catch (err) {
                console.log('check network failed: ', err)
            }
            window.ethereum.autoRefreshOnNetworkChange = false
            window.ethereum.on('chainChanged', handleNewChain)
            window.ethereum.on('networkChanged', handleNewNetwork)
            window.ethereum.on('accountsChanged', handleNewAccounts)
            setStartWatch(true)
        } else {
            console.log('oops!')
        }
    }

    useEffect(() => {
        if (startWatch) {
            dispatch(authActions.logOut())
        }
    }, [chainId, network, addr])



    useEffect(() => {
        initialize()
        return () => {
        }
    }, [])

    useEffect(() => {
        if (barRef.current && tempHeight === null) {
            tempHeight = barRef.current.getBoundingClientRect().height;
            sendBackHeight(tempHeight)
        }
        return () => {
        }
    }, [])

    useEffect(() => {
        /**
         * imToken DApp browser
         */
        if (!!window.imToken) {
            setAddr(window.ethereum.selectedAddress)
            sendBackAddr(window.ethereum.selectedAddress)
            setChainId(window.ethereum.chainId)
            sendBackChainId(window.ethereum.chainId)
            setNetwork(window.ethereum.networkVersion)
            sendBackNetworkId(window.ethereum.networkVersion)
        }
        return () => {
        }

    }, window.imToken)

    useEffect(() => {
        if (prevNavMessageRef.current === '' && (message === 'Wrong network id.')) {
            handleOpenMsgModal()
        }

        prevNavMessageRef.current = message;
        return () => {
        }

    }, [message, width])

    useEffect(() => {
        if (prevAuthMsgRef.current === '' && authMsg === 'wrongNetwork') {
            handleOpenMsgModal()
        }
        prevAuthMsgRef.current = authMsg;
        return () => {
        }
    }, [authMsg])

    const anchorRef = React.useRef(null);
    const handleToggle = () => {
        setOpenMore((prevOpen) => !prevOpen);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenMore(false);
        }
    }

    const handleCloseMore = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpenMore(false);
    };


    useEffect(() => {
        setBlueHeader(history.location.pathname === '/ido' || history.location.pathname === '/ido/')
    }, [history, location])

    return (
        <div style={{ zIndex: location.pathname.includes('/dapp') ? 111 : 'unset', }} ref={barRef}>
            <div style={{ flexDirection: 'column', justifyContent: 'space-between', height: width > 960 ? 100 : 70, alignItems: 'center' }}>
                <div style={{ background: location.pathname.includes('/dapp') ? 'transparent' : blueHeader ? '#F1F9FF' : 'unset', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', height: width > 960 ? 100 : 72 }}>
                    <div style={{ display: 'flex' }}>
                        {width > 960 ?
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>

                                <Button onClick={() => { history.replace('/') }} aria-label="home" style={{ backgroundColor: "#251CFF", borderRadius: 0, height: 100, alignItems: 'center', justifyContent: 'center' }}>
                                    <img alt='' style={{ width: 72, marginLeft: 24, marginRight: 24 }} src={navIcon} />
                                </Button>
                            </div>
                            :
                            null
                        }
                        <div style={{ display: 'flex', flexDirection: 'row', height: '50%', alignSelf: 'center', marginLeft: "4%" }}>
                            {
                                width > 960 ?
                                    <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {navLinks.map(({ title, path }) => (
                                            // <NavLink to={path} key={title} className={classes.unselect}
                                            //          InputProps={{ disableUnderline: true }}
                                            //          activeClassName={classes.selected}
                                            //          isActive={(match, location) => {
                                            //              if (location.pathname === path) {
                                            //                  return true
                                            //              } else if (location.pathname.includes('') && path === '/') {
                                            //                  return true
                                            //              } else if (location.pathname.includes('swap') && path === '/swap') {
                                            //                  return true
                                            //              } else {
                                            //                  return false
                                            //              }
                                            //          }}
                                            // >
                                            //
                                            //     {title}
                                            //     {/* <ListItem button style={{ height: '100%' }}>
                                            //         {title === "Layer 3" ?
                                            //             <img style={{ width: 16, height: 16, marginRight: 6 }}
                                            //                 src={layer3NavLogo} />
                                            //             :
                                            //             null
                                            //         }
                                            //         <Typography style={{  fontSize: 14, color: "white", fontWeight: 'bold', textTransform: 'none', }}>
                                            //             {title}
                                            //         </Typography>
                                            //     </ListItem> */}
                                            //
                                            // </NavLink>
                                            <NavLink to={path} key={title} className={classes.unselect}
                                                InputProps={{ disableUnderline: true }}
                                                activeClassName={classes.selected}
                                                isActive={(match, location) => {
                                                    if (location.pathname === path) {
                                                        return true
                                                    } else if (location.pathname.includes('wallet') && path === '/wallet') {
                                                        return true
                                                    } else if (location.pathname.includes('swap') && path === '/swap') {
                                                        return true
                                                    } else {
                                                        return false
                                                    }
                                                }}
                                            >

                                                {title}
                                            </NavLink>
                                        ))}








                                        {/* {
                                        width > 900 ?
                                            <Button style={{ marginLeft: 8 }}
                                                ref={anchorRef}
                                                aria-controls={openMore ? 'menu-list-grow' : undefined}
                                                aria-haspopup="true"
                                                onClick={changeLanguage}
                                            >

                                                <Typography className={classes.defaultFont} >
                                                    {t('lang')}
                                                </Typography>

                                            </Button>
                                            :

                                            null
                                    } */}



                                        {width > 900 ?
                                            <Button style={{}}
                                                ref={anchorRef}
                                                aria-controls={openMore ? 'menu-list-grow' : undefined}
                                                aria-haspopup="true"
                                                onClick={handleToggle}
                                            >
                                                <Typography className={classes.defaultFont} >
                                                    {t("more")}
                                                </Typography>
                                            </Button>
                                            :
                                            null
                                        }


                                    </div>
                                    :
                                    <Button style={{ backgroundColor: 'transparent', height: '100%', borderRadius: 50, marginLeft: width < 900 ? 0 : 16 }}
                                        ref={anchorRef}
                                        aria-controls={openMore ? 'menu-list-grow' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleToggle}
                                    >

                                        <img alt='' style={{ height: 32, width: 32 }} src={moreIcon} />
                                    </Button>
                            }

                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: width > 900 ? 16 : 24, width: 340 }}>

                        {
                            width > 960 ?

                                // <Button style={{}}
                                //     ref={anchorRef}
                                //     aria-controls={openMore ? 'menu-list-grow' : undefined}
                                //     aria-haspopup="true"
                                //     onClick={handleToggle}
                                // >

                                //     <img style={{ width: 46 }}
                                //         src={moreIcon} />
                                // </Button>

                                <Button style={{ marginRight: 24 }}

                                    onClick={changeLanguage}
                                >

                                    <img alt='' src={i18n.language === 'en' ? cnIcon : enIcon} style={{ height: 18 }}></img>
                                    <Typography className={classes.defaultFont} style={{ fontSize: 16, marginLeft: 4 }}>
                                        {t('lang')}
                                    </Typography>

                                </Button>

                                :
                                null
                        }

                        <Button style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'row', marginRight: isMobile ? -8 : 24, borderRadius: 12 }}
                            onClick={!window.ethereum ? () => onClickInstall(sendBackButton1, sendBackButton1Disabled) : () => onClickConnect(network, chainId, addr, dispatch, handleOpen, registered, loggedIn, loading)}
                        >

                            <img alt='' style={{ width: width > 960 ? 46 : 32, marginLeft: 0, marginRight: isMobile ? 0 : 8 }} src={registered ? connectedWallet : connectWallet} />



                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center', marginRight: isMobile ? 0 : 16 }}>



                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    {
                                        network !== '' ?
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-end", alignItems: 'center' }}>
                                                <Typography style={{ display: 'flex', fontSize: 12, color: '#8786A7', fontWeight: 'bold', justifyContent: 'baseline' }}>
                                                    {getChainName(network, chainId, t)}

                                                </Typography>


                                            </div>
                                            :
                                            null

                                    }
                                    <Typography style={{ fontSize: 12, color: '#251CFF', fontWeight: '600', marginTop: -3 }}>
                                        {registered ?
                                            isMobile || width < 500 ? '' :
                                                `${addr.slice(0, 8)}...${addr.slice(addr.length - 3)}`
                                            :
                                            button1
                                        }
                                    </Typography>
                                </div>

                            </div>
                        </Button>
                        {/* <Button style={{ height: 38, borderRadius: 22, backgroundColor: '#1DF0A9' }}
                            onClick={!window.ethereum ? () => onClickInstall(sendBackButton1, sendBackButton1Disabled) : () => onClickConnect(network, chainId, addr, dispatch, handleOpen, registered, loggedIn, loading)}
                            variant="contained">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center' }}>
                                <Avatar variant="square" style={{ height: 20, width: 20 }}
                                    src={
                                        registered ?
                                            connectIcon
                                            :
                                            registIcon
                                    }
                                />
                                {
                                    loggedIn ?
                                        null
                                        :
                                        <div style={{ height: 8, width: 8, backgroundColor: '#FF5151', borderRadius: 4, marginLeft: 8 }} />
                                }
                                <Typography style={{ fontSize: 12, color: '#111111', fontWeight: '600', marginLeft: 8 }}>
                                    {registered ?
                                        isMobile || width < 500 ? '' :
                                            `${addr.slice(0, 8)}...${addr.slice(addr.length - 3)}`
                                        :
                                        button1
                                    }
                                </Typography>
                                {
                                    network !== '' ?
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-end", alignItems: 'center' }}>
                                            <Typography style={{ display: 'flex', fontSize: 16, color: '#000F93', fontWeight: 'bold', justifyContent: 'baseline' }}>
                                                <span style={{ marginLeft: 5, borderRadius: 5, justifyContent: 'center', backgroundColor: 'white', paddingTop: 3, paddingBottom: 3, paddingRight: 5, paddingLeft: 5, fontSize: 12, color: 'black', fontWeight: 'bold', }}>{
                                                    getChainName(network, chainId, t)
                                                }</span>
                                            </Typography>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </Button> */}



                        <div style={{ alignItems: 'center', justifyContent: 'center' }}>

                            <Popper open={openMore} anchorEl={anchorRef.current} role={undefined} transition>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                    >
                                        <Paper style={{ width: 200, borderRadius: 12 }}>
                                            <ClickAwayListener onClickAway={handleCloseMore}>

                                                <MenuList style={{ zIndex: 9999999 }} autoFocusItem={openMore} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                                    {
                                                        width <= 900 ?
                                                            <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#8B96A7', marginLeft: 22, marginTop: 16 }}>{t('navigation')}</Typography> : null
                                                    }

                                                    {
                                                        width <= 900 ?
                                                            navLinks.map(({ title, path }) => (
                                                                <NavLink to={path} key={title} style={{
                                                                    height: '100%', textDecoration: `none`
                                                                }}
                                                                    InputProps={{ disableUnderline: true }}
                                                                    activeClassName={classes.selected}
                                                                    isActive={(match, location) => {
                                                                        if (location.pathname === path) {
                                                                            return true
                                                                        } else if (location.pathname.includes('') && path === '/') {
                                                                            return true
                                                                        } else {
                                                                            return false
                                                                        }
                                                                    }}
                                                                >
                                                                    <ListItem button style={{ height: '100%' }}>
                                                                        <Typography className={classes.defaultFont} style={{ fontSize: 16, fontWeight: 'bold', textTransform: 'none', marginLeft: 8, }}>
                                                                            {title}
                                                                        </Typography>

                                                                    </ListItem>
                                                                </NavLink>
                                                            )) : null
                                                    }
                                                    {
                                                        width <= 900 ?
                                                            <Button onClick={changeLanguage} className={classes.defaultFont} style={{ minWidth: 0, minHeight: 0, fontSize: 16, fontWeight: 'bold', textTransform: 'none', marginLeft: 16, }}>
                                                                {t('lang')}
                                                            </Button>
                                                            :
                                                            null
                                                    }
                                                    {/* <MenuItem onClick={() => {
                                                        i18n.language === 'cn' ?
                                                            window.open('https://swapall.zendesk.com/hc/zh-cn/sections/360013180891-SwapAll-Layer-2-%E9%80%9A%E7%9F%A5', '_blank')
                                                            :
                                                            window.open('https://swapall.zendesk.com/hc/en-ca/sections/360013180891-SwapAll-Layer-2-Announcement', '_blank')
                                                    }}>
                                                        <Typography style={{ fontSize: 16, color: '#010846', fontWeight: 'bold', textTransform: 'none', marginLeft: 16, marginRight: 16 }}>
                                                            {t('announcement')}
                                                        </Typography>
                                                    </MenuItem> */}
                                                    {/* <MenuItem onClick={() => {
                                                        i18n.language === 'cn' ?
                                                            window.open('https://dumboex.zendesk.com/hc/zh-cn', '_blank')
                                                            :
                                                            window.open('https://dumboex.zendesk.com/hc/en-ca', '_blank')
                                                    }}>
                                                        <Typography style={{ fontSize: 16, color: '#010846', fontWeight: 'bold', textTransform: 'none', marginLeft: 16, marginRight: 16 }}>
                                                            {t('announcement')}
                                                        </Typography>
                                                    </MenuItem>
                                                    <MenuItem onClick={() => {
                                                        i18n.language === 'cn' ?
                                                            window.open('https://docs.dumboex.com/v/zhong-wen/', '_blank')
                                                            :
                                                            window.open('https://docs.dumboex.com/', '_blank')
                                                    }}>
                                                        <Typography style={{ fontSize: 16, color: '#010846', fontWeight: 'bold', textTransform: 'none', marginLeft: 16, marginRight: 16 }}>
                                                            {t('doc')}
                                                        </Typography>
                                                    </MenuItem> */}
                                                    {/* <MenuItem onClick={() => {
                                                        i18n.language === 'cn' ?
                                                            window.open('https://docs.swapalltrade.com/v/cn/user-guide', '_blank')
                                                            :
                                                            window.open('https://docs.swapalltrade.com/user-guide', '_blank')
                                                    }}>
                                                        <Typography style={{ fontSize: 16, color: '#010846', fontWeight: 'bold', textTransform: 'none', marginLeft: 16, marginRight: 16 }}>
                                                            {t('guide')}
                                                        </Typography>
                                                    </MenuItem> */}
                                                    {/* <MenuItem onClick={() => { window.open("https://swapall.io/download", '_blank') }}>
                                                        <Typography style={{ fontSize: 16, color: '#010846', fontWeight: 'bold', textTransform: 'none', marginLeft: 16, marginRight: 16 }}>
                                                            {t('downloadAndroid')}
                                                        </Typography>
                                                    </MenuItem> */}
                                                    {/* <MenuItem onClick={() => {
                                                        i18n.language === 'cn' ?
                                                            window.open('https://swapall.io/download', '_blank')
                                                            :
                                                            window.open('https://apps.apple.com/app/swapall/id1532973986?l=en', '_blank')
                                                    }}>
                                                        <Typography style={{ fontSize: 16, color: '#010846', fontWeight: 'bold', textTransform: 'none', marginLeft: 16, marginRight: 16 }}>
                                                            {t('downloadIOS')}
                                                        </Typography>
                                                    </MenuItem> */}
                                                    {/* <MenuItem onClick={() => { window.open(i18n.language === 'cn' ? 'https://t.me/SwapAllChinese' : 'https://t.me/SwapAllEnglish', '_blank') }}>
                                                        <Typography style={{ fontSize: 16, color: '#010846', fontWeight: 'bold', textTransform: 'none', marginLeft: 16, marginRight: 16 }}>
                                                            {t('telegram')}
                                                        </Typography>
                                                    </MenuItem>
                                                    <MenuItem onClick={() => { window.open('https://twitter.com/SwapAll_', '_blank') }}>
                                                        <Typography style={{ fontSize: 16, color: '#010846', fontWeight: 'bold', textTransform: 'none', marginLeft: 16, marginRight: 16 }}>
                                                            {t('twitter')}
                                                        </Typography>
                                                    </MenuItem>
                                                    <MenuItem onClick={() => { window.open('https://medium.com/swapall', '_blank') }}>
                                                        <Typography style={{ fontSize: 16, color: '#010846', fontWeight: 'bold', textTransform: 'none', marginLeft: 16, marginRight: 16 }}>
                                                            {t('medium')}
                                                        </Typography>
                                                    </MenuItem>
                                                    <MenuItem onClick={() => { window.open('https://info.swapalltrade.com', '_blank') }}>
                                                        <Typography style={{ fontSize: 16, color: '#010846', fontWeight: 'bold', textTransform: 'none', marginLeft: 16, marginRight: 16 }}>
                                                            {t('chart')}
                                                        </Typography>
                                                    </MenuItem> */}

                                                    {/* <MenuItem
                                                        onClick={() => {
                                                            i18n.language === 'cn' ?
                                                                window.open('https://docs.dumboex.com/v/zhong-wen/shen-ji/shen-ji-bao-gao', '_blank')
                                                                : window.open('https://docs.dumboex.com/audit/audit-reports', '_blank')
                                                        }}
                                                    >
                                                        <Typography style={{ fontSize: 16, color: '#010846', fontWeight: 'bold', textTransform: 'none', marginLeft: 16, marginRight: 16 }}>
                                                            {t('audit')}
                                                        </Typography>
                                                    </MenuItem>

                                                    <MenuItem onClick={changeLanguage} >

                                                        <Typography style={{ fontSize: 16, color: '#010846', fontWeight: 'bold', textTransform: 'none', marginLeft: 16, marginRight: 16 }}>
                                                            {t('lang')}
                                                        </Typography>


                                                    </MenuItem> */}
                                                    {/* <NavLink to={`/referReward`} key={t('referReward')} style={{
                                                        height: '100%', textDecoration: `none`
                                                    }}
                                                        InputProps={{ disableUnderline: true }}
                                                    >
                                                        <ListItem button style={{ height: '100%' }}>
                                                            <Typography style={{ fontSize: 16, color: '#010846', fontWeight: 'bold', textTransform: 'none', marginLeft: 16, marginRight: 16 }}>
                                                                {t('referReward')}
                                                            </Typography>
                                                        </ListItem>
                                                    </NavLink> */}

                                                    {/* <MenuItem
                                                onClick={changeLanguage}
                                            >
                                                <img style={{ height: 61 * 0.8, width: 53 * 0.8, marginLeft: 24, marginRight: 4, }} src={satoShapLogo} />
                                                <Typography style={{ fontSize: 16, color: '#010846', fontWeight: 'bold', textTransform: 'none', marginLeft: 16, marginRight: 16 }}>
                                                    {t('lang')}
                                                </Typography>
                                            </MenuItem> */}

                                                    {/* <Button className={classes.langBtn} onClick={changeLanguage} variant="contained" >{t('lang')}</Button> */}
                                                    {
                                                        width <= 900 ?
                                                            <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#8B96A7', marginLeft: 22, marginTop: 16 }}>{t('more')}</Typography> : null
                                                    }
                                                    <MenuItem
                                                        onClick={() => {
                                                            i18n.language === 'cn' ?
                                                                window.open('https://dumboex.zendesk.com/hc/zh-cn', '_blank')
                                                                :
                                                                window.open('https://dumboex.zendesk.com/hc/en-ca', '_blank')
                                                        }}
                                                    >
                                                        <Typography className={classes.defaultFont} style={{ fontSize: 16, fontWeight: 'bold', textTransform: 'none', marginLeft: 8, }}>
                                                            {t('announcement')}
                                                        </Typography>
                                                    </MenuItem>

                                                    <MenuItem
                                                        onClick={() => {
                                                            i18n.language === 'cn' ?
                                                                window.open('https://docs.dumboex.com/v/zhong-wen/', '_blank')
                                                                :
                                                                window.open('https://docs.dumboex.com/', '_blank')
                                                        }}
                                                    >
                                                        <Typography className={classes.defaultFont} style={{ fontSize: 16, fontWeight: 'bold', textTransform: 'none', marginLeft: 8, }}>
                                                            {t('doc')}
                                                        </Typography>
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => {
                                                            i18n.language === 'cn' ?
                                                                window.open(wpCN, '_blank')
                                                                : window.open(wpEN, '_blank')
                                                        }}
                                                    >
                                                        <Typography className={classes.defaultFont} style={{ fontSize: 16, fontWeight: 'bold', textTransform: 'none', marginLeft: 8, }}>
                                                            {t('whitePaper')}
                                                        </Typography>
                                                    </MenuItem>

                                                    <MenuItem
                                                        onClick={() => {
                                                            i18n.language === 'cn' ?
                                                                window.open('https://docs.dumboex.com/v/zhong-wen/shen-ji/shen-ji-bao-gao', '_blank')
                                                                : window.open('https://docs.dumboex.com/audit/audit-reports', '_blank')
                                                        }}
                                                    >
                                                        <Typography className={classes.defaultFont} style={{ fontSize: 16, fontWeight: 'bold', textTransform: 'none', marginLeft: 8, }}>
                                                            {t('audit')}
                                                        </Typography>
                                                    </MenuItem>

                                                    <MenuItem
                                                        onClick={() => { window.open('mailto:support@dumboex.com') }}
                                                    >
                                                        <Typography className={classes.defaultFont} style={{ fontSize: 16, fontWeight: 'bold', textTransform: 'none', marginLeft: 8, }}>
                                                            {t('contactUs')}
                                                        </Typography>
                                                    </MenuItem>






                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>

                    </div>
                </div>
            </div>
            <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                aria-labelledby="server-modal-title"
                aria-describedby="server-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div style={{
                        backgroundColor: 'white', borderRadius: 16, width: width < 950 ? "91%" : 480, overflow: 'hidden', outline: 0, display: 'flex', alignItems: 'center', flexDirection: 'column'
                    }}>

                        <img alt='' src={loggedInIcon} variant="square" style={{ height: 66 * 1.2, width: 82 * 1.2, marginTop: 24 }} />

                        <Typography style={{ fontSize: 16, fontWeight: 'bold', marginTop: 8, color: '#8786A7' }}>
                            {loggedIn ? t('metamaskConnected') : t('metamaskLogin')}
                        </Typography>
                        <Typography style={{ fontSize: 12, fontWeight: 'bold', marginTop: -4, color: '#251CFF', marginBottom: 32 }}>
                            {addr}
                        </Typography>


                        <Button style={{ height: 52, width: '100%', backgroundColor: '#251CFF', borderRadius: 0 }}
                            onClick={switchAccount} variant="contained">
                            <Typography style={{ fontSize: 16, fontWeight: 'bold', color: 'white' }}>
                                {t('switch')}
                            </Typography>
                        </Button>
                    </div>
                </Fade>
            </Modal>
            <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                aria-labelledby="server-modal-title"
                aria-describedby="server-modal-description"
                className={classes.modal}
                open={openMsgModal}
                onClose={handleCloseMsgModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}


            >
                <Fade in={openMsgModal}>
                    <div className={classes.paper}>
                        <div className={classes.paper}>
                            <h2 id="transition-modal-title">{t('wrongNetwork')}</h2>
                        </div>
                        <Grid container spacing={2} >
                            <Grid item xs={12} >
                                <p id="transition-modal-description">{t('wrongNetworkContent')}</p>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}

export default withTranslation()(Navbar);
