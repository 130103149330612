import * as Url from "../../config/Url";
import {handleResponse} from "../../utils/HandleResponse";
import {authHeader} from "../../utils/AuthHeader";

export const earnService = {
    getAllEarnPool,
    getMyEarnPool,
    getMyEarnTransactions,
    harvestEarnReward,
    earnStake,
    earnUnStake,
    getEthPrice
};

async function getAllEarnPool(payload) {
    const requestOptions = {
        method: 'GET',
        // headers: authHeader(payload.token)
    };
    return fetch(Url.GET_ALL_EARN_POOL, requestOptions).then(handleResponse);
}

async function getMyEarnPool(payload) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(payload)
    };
    return fetch(Url.GET_MY_EARN_POOL, requestOptions).then(handleResponse);
}

async function getMyEarnTransactions(payload) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(payload)
    };
    return fetch(Url.GET_EARN_TRANSACTIONS, requestOptions).then(handleResponse);
}

async function harvestEarnReward(payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(payload.accessToken),
        body: JSON.stringify(payload)
    };
    return fetch(Url.HARVEST_EARN_REWARD, requestOptions).then(handleResponse);
}

async function earnStake(payload,token) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(token),
        body: JSON.stringify(payload)
    };
    return fetch(Url.EARN_STAKE, requestOptions).then(handleResponse);
}

async function earnUnStake(payload,token) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(token),
        body: JSON.stringify(payload)
    };
    return fetch(Url.EARN_UNSTAKE, requestOptions).then(handleResponse);
}

async function getEthPrice(payload) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(payload),
    };
    return fetch(Url.GET_ETH_PRICE, requestOptions).then(handleResponse);
}