import React from 'react';
import './Footer.css';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import lightIcon from "../../images/swap/lightIcon.png";
import footerLog from "../../images/footerLog.png";
import { NavLink } from "react-router-dom";

import { Button, Typography } from '@material-ui/core';
import i18n from '../../i18n';
import { isMobile } from 'react-device-detect';
import wpCN from '../../images/whitePaperCN.pdf'
import wpEN from '../../images/whitePaperEN.pdf'
// const logo = 'https://cdn.swapallapp.com/l2/images/swapAllLogoH.png'

function Footer({ t, width }) {
    const useStyles = makeStyles({
        footer: {
            // position: 'absolute',
            bottom: 0,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#010846'
        },
        footerText: {
            color: 'white',
            display: 'flex',
            justifyContent: 'center'
        },
        defaultFont: {
            fontWeight: 'bold', color: '#363636', fontFamily: 'Avenir', textTransform: 'none', fontSize: isMobile ? 12 : 16
        },
    });

    const classes = useStyles();

    const changeLanguage = (e) => {
        let newLang = i18n.language === 'en' ? 'cn' : 'en'
        i18n.changeLanguage(newLang);
        localStorage.setItem('lng', newLang)
    }
    return (
        <div style={{ width: '100%', backgroundColor: '#F5F5F5', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>



            {isMobile ?
                <div style={{ padding: '26px 0', width: '98%', backgroundColor: '#F5F5F5', fontFamily: 'Avenir', display: 'flex', alignItems: 'flex-start', flexDirection: 'column', flexWrap: 'wrap' }}>
                    <div style={{ display: 'flex', justifyContent: 'center',flexDirection:'column',alignItems:'flex-start' }}>
                        <img style={{ height: 52 * 0.4, marginLeft: 24, marginBottom: 12 }} src={footerLog} alt="" />
                        <Typography style={{ fontSize: 10, marginLeft: 24, marginRight: 24, fontWeight: '500', fontFamily: 'Avenir', textTransform: 'none', color: '#363636' }}>{t("footerDes")}</Typography>

                    </div>
                    <div style={{ width: "91%", height: 1, backgroundColor: '#DADADA', marginLeft: 16, marginTop: 8 }} />
                    <div style={{ display: 'flex', width: '100%' }}>
                        {/* first colume */}
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 16, marginTop: 24, width: "50%" }}>
                            <Typography className={classes.defaultFont} style={{  marginBottom: 8, color: '#8B96A7', marginLeft: 8 }}>{t("navigation")}</Typography>

                            <NavLink to='/ido' style={{ textDecoration: 'none' }}>
                                <Button className={classes.defaultFont} style={{ minWidth: 0, minHeight: 0 }}>
                                    {t('IDO')}
                                </Button>
                            </NavLink>

                            <NavLink to='/ido/invite' style={{ textDecoration: 'none' }}>
                                <Button className={classes.defaultFont} style={{ minWidth: 0, minHeight: 0 }}>
                                    {t('IDOInvite')}
                                </Button>
                            </NavLink>
                            <NavLink to='/dapp' style={{ textDecoration: 'none' }}>
                                <Button className={classes.defaultFont} style={{ minWidth: 0, minHeight: 0 }}>
                                    {t('dapp')}
                                </Button>
                            </NavLink>
                            <NavLink to='/wallet' style={{ textDecoration: 'none' }}>
                                <Button className={classes.defaultFont} style={{ minWidth: 0, minHeight: 0 }}>
                                    {t('wallet')}
                                </Button>
                            </NavLink>





                            <Button onClick={changeLanguage} className={classes.defaultFont} style={{ minWidth: 0, minHeight: 0 }}>
                                {t('lang')}
                            </Button>


                        </div>


                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 16, marginTop: 24, width: "50%" }}>
                            <Typography className={classes.defaultFont} style={{  marginBottom: 8, color: '#8B96A7', marginLeft: 8 }}>{t('more')}</Typography>


                            <Button onClick={() => {
                                i18n.language === 'cn' ?
                                    window.open('https://dumboex.zendesk.com/hc/zh-cn', '_blank')
                                    :
                                    window.open('https://dumboex.zendesk.com/hc/en-ca', '_blank')
                            }} className={classes.defaultFont} style={{ minWidth: 0, minHeight: 0 }}>
                                {t('announcement')}
                            </Button>

                            <Button
                                onClick={() => {
                                    i18n.language === 'cn' ?
                                        window.open('https://docs.dumboex.com/v/zhong-wen/', '_blank')
                                        :
                                        window.open('https://docs.dumboex.com/', '_blank')
                                }}
                                className={classes.defaultFont} style={{ minWidth: 0, minHeight: 0 }}>

                                {t('doc')}

                            </Button>
                            <Button
                                onClick={() => {
                                    i18n.language === 'cn' ?
                                        window.open('https://docs.dumboex.com/v/zhong-wen/', '_blank')
                                        :
                                        window.open('https://docs.dumboex.com/', '_blank')
                                }}
                                className={classes.defaultFont} style={{ minWidth: 0, minHeight: 0 }}>

                                {t("whitePaper")}

                            </Button>


                            <Button
                                onClick={() => {
                                    i18n.language === 'cn' ?
                                        window.open('https://docs.dumboex.com/v/zhong-wen/shen-ji/shen-ji-bao-gao', '_blank')
                                        :
                                        window.open('https://docs.dumboex.com/audit/audit-reports', '_blank')
                                }}
                                className={classes.defaultFont} style={{ minWidth: 0, minHeight: 0 }}>
                                {t('audit')}
                            </Button>

                            <Button onClick={() => { window.open('mailto:support@dumboex.com') }} className={classes.defaultFont} style={{ minWidth: 0, minHeight: 0 }}>
                                {t('contactUs')}
                            </Button>

                        </div>
                    </div>
                    <div style={{ width: "91%", height: 1, backgroundColor: '#DADADA', marginLeft: 16, marginTop: 8 }} />
                    <div style={{ width: '95%', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', marginTop: 16 }}>

                        <Typography className={classes.defaultFont} style={{ fontSize: 10, fontWeight: 500, color: '#9097A1', marginLeft: 8 }}>© 2022 Yesbit. All rights reserved.</Typography>



                    </div>
                </div >
                :
                <div style={{ padding: '26px 0', width: '98%', backgroundColor: '#F5F5F5', fontFamily: 'Avenir', display: 'flex', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                    <div style={{ padding: '24px 0', width: '95%', display: 'flex', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                            <div style={{ width: '40%' }}>
                                <img alt='' style={{ height: 52 * 0.6 }} src={footerLog} />
                                <Typography style={{ fontSize: 16, fontWeight: '500', fontFamily: 'Avenir', textTransform: 'none', color: '#363636' }}>{t("footerDes")}</Typography>

                            </div>

                            {/* first colume */}
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: "12%", width: 200 }}>
                                <Typography className={classes.defaultFont} style={{ fontSize: 14, marginBottom: 16, color: '#8B96A7', marginLeft: 6 }}>{t("navigation")}</Typography>

                                <NavLink to='/ido' style={{ textDecoration: 'none' }}>
                                    <Button className={classes.defaultFont} style={{ minWidth: 0, minHeight: 0 }}>
                                        {t('IDO')}
                                    </Button>
                                </NavLink>

                                <NavLink to='/ido/invite' style={{ textDecoration: 'none' }}>
                                    <Button className={classes.defaultFont} style={{ minWidth: 0, minHeight: 0 }}>
                                        {t('IDOInvite')}
                                    </Button>
                                </NavLink>
                                <NavLink to='/dapp' style={{ textDecoration: 'none' }}>
                                    <Button className={classes.defaultFont} style={{ minWidth: 0, minHeight: 0 }}>
                                        {t('dapp')}
                                    </Button>
                                </NavLink>

                                <NavLink to='/wallet' style={{ textDecoration: 'none' }}>
                                    <Button className={classes.defaultFont} style={{ minWidth: 0, minHeight: 0 }}>
                                        {t('wallet')}
                                    </Button>
                                </NavLink>



                                <Button onClick={changeLanguage} className={classes.defaultFont} style={{ minWidth: 0, minHeight: 0 }}>
                                    {t('lang')}
                                </Button>

                            </div>

                            {/* second colume */}
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 80, color: '#8B96A7', width: 200 }}>
                                <Typography className={classes.defaultFont} style={{ fontSize: 14, marginBottom: 16, color: '#8B96A7', marginLeft: 6 }}>{t('more')}</Typography>
                                <Button onClick={() => {
                                    i18n.language === 'cn' ?
                                        window.open('https://dumboex.zendesk.com/hc/zh-cn', '_blank')
                                        :
                                        window.open('https://dumboex.zendesk.com/hc/en-ca', '_blank')
                                }} className={classes.defaultFont} style={{ minWidth: 0, minHeight: 0 }}>
                                    {t('announcement')}
                                </Button>
                                <Button
                                    onClick={() => {
                                        i18n.language === 'cn' ?
                                            window.open('https://docs.dumboex.com/v/zhong-wen/', '_blank')
                                            :
                                            window.open('https://docs.dumboex.com/', '_blank')
                                    }}
                                    className={classes.defaultFont} style={{ minWidth: 0, minHeight: 0 }}>

                                    {t('doc')}

                                </Button>
                                <Button
                                    onClick={() => {
                                        i18n.language === 'cn' ?
                                            window.open(wpCN, '_blank')
                                            : window.open(wpEN, '_blank')
                                    }}
                                    className={classes.defaultFont} style={{ minWidth: 0, minHeight: 0 }}>

                                    {t("whitePaper")}

                                </Button>

                                <Button
                                    onClick={() => {
                                        i18n.language === 'cn' ?
                                            window.open('https://docs.dumboex.com/v/zhong-wen/shen-ji/shen-ji-bao-gao', '_blank')
                                            :
                                            window.open('https://docs.dumboex.com/audit/audit-reports', '_blank')
                                    }}
                                    className={classes.defaultFont} style={{ minWidth: 0, minHeight: 0 }}>
                                    {t('audit')}
                                </Button>

                                <Button onClick={() => { window.open('mailto:support@dumboex.com') }} className={classes.defaultFont} style={{ minWidth: 0, minHeight: 0 }}>
                                    {t('contactUs')}
                                </Button>




                            </div>
                        </div>
                        {/* <div style={{ backgroundColor: 'yellow', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <Typography style={{ fontSize: 16, fontWeight: '500', fontFamily: 'Avenir', textTransform: 'none', color: '#363636' }}>Contact Info</Typography>
                            <Button onClick={() => {
                                i18n.language === 'cn' ?
                                    window.open('https://dumboex.zendesk.com/hc/zh-cn', '_blank')
                                    :
                                    window.open('https://dumboex.zendesk.com/hc/en-ca', '_blank')
                            }} style={{ marginRight: 0, borderRadius: 0, height: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginLeft: 16, marginRight: 16 }}>
                                    <Typography style={{ fontSize: 16, fontWeight: '500', fontFamily: 'Avenir', textTransform: 'none', color: '#363636' }}>{t('announcement')}</Typography>

                                </div>
                            </Button>

                            <Button onClick={() => { window.open('mailto:support@dumboex.com') }} style={{ marginRight: 0, borderRadius: 0, height: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginLeft: 16, marginRight: 16 }}>
                                    <Typography style={{ fontSize: 16, fontWeight: '500', fontFamily: 'Avenir', textTransform: 'none', color: '#363636' }}>{t('contactUs')}</Typography>
                                </div>
                            </Button>


                        </div> */}

                    </div>
                    <div style={{ height: 1, backgroundColor: '#E6E6E6', width: "95%" }}></div>

                    <div style={{ padding: isMobile ? '5px 0px 27px 0px' : '24px 0', width: '95%', display: 'flex', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                        <div style={{ marginRight: 24, display: 'flex', alignItems: 'center', }}>

                            <Typography style={{ fontSize: isMobile ? 12 : 14, fontWeight: 500, color: '#9097A1', marginLeft: 8 }}>© 2022 Yesbit. All rights reserved.</Typography>
                        </div>


                    </div>

                </div >
            }
        </div >
    )
}



export default withTranslation()(Footer);
