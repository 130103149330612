import * as Url from '../../config/Url'
import { authHeader } from '../../utils/AuthHeader';
import { handleResponse } from '../../utils/HandleResponse'
import * as Contract from "../../config/Contract.json";
import Web3 from 'web3'
import {getChain, isTest} from "../../utils/Common";

let web3 = new Web3(window.ethereum)

export const walletService = {
    getUserCapital,
    withdraw,
    transfer,
    getAllTokenStatus,
    getL1Capital,
    getTransactionRecords,
    getAmplRewards,
    registerAmplRewards,
    getFee,
    getTransferFee,
    addToken,
    buyIDO,
    getIDOInfo,
    getInvestmentInfo,
    getMigrationInfo,
    migrateIDO
};

async function getUserCapital(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };
    return fetch(Url.GET_USER_CAPITAL, requestOptions).then(handleResponse);
}

async function withdraw(payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(payload.token),
        body: JSON.stringify(payload)
    };
    return fetch(Url.WITHDRAW, requestOptions).then(handleResponse);
}

async function transfer(payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(payload.token),
        body: JSON.stringify(payload.body)
    };
    return fetch(Url.TRANSFER, requestOptions).then(handleResponse);
}

async function getAllTokenStatus(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };
    return fetch(Url.GET_ALL_TOKENS_STATUS, requestOptions).then(handleResponse);
}

async function getL1Capital(address, network, chainId) {
    async function loopingCapital () {
        let suffix = isTest(network, chainId) ? '_test' : ''
        let chain = getChain(network, chainId)
            + suffix
        let balance = []
        let balanceCheckerContract = new web3.eth.Contract(Contract.default[chain].balanceChecker.abi, Contract.default[chain].balanceChecker.address);
        for (const key in Contract.default[chain].coins) {
            if ((key === 'ETH' && chain.includes('ETH')) ||
                (key === 'HT' && chain.includes('HECO')) ||
                (key === 'BNB' && chain.includes('BSC')) ||
                (key === 'HOO' && chain.includes('HSC'))
            ) {
                await web3.eth.getBalance(address, (err, res) => {
                    if (err){
                        console.log('get ' + key + 'failed: ', err)
                    }
                    console.log('get ' + key + ' balance:', res)
                    balance.push({token: key, free: Number(res) / Math.pow(10, Contract.default[chain].coins[key].decimals)})
                })
            } else {
                await balanceCheckerContract.methods.tokenBalance(address, Contract.default[chain].coins[key].address).call({}, (err, res) => {
                    if (err){
                        console.log('get ' + key + 'failed: ', err)
                    }
                    console.log('get ' + key + ' balance:', res)
                    balance.push({token: key, free: Number(res) / Math.pow(10, Contract.default[chain].coins[key].decimals)})
                })
            }
        }
        console.log('balance: ', balance)
        return balance
    }

    return await loopingCapital()
}

async function getTransactionRecords(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };
    return fetch(Url.GET_TRANSACTION_RECORDS, requestOptions).then(handleResponse);
}

async function getAmplRewards(token) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader(token)
    };
    if (token && token.length > 0) {
        return fetch( Url.GET_AMPL_REWARDS, requestOptions).then(handleResponse);
    } else {
        return fetch( Url.GET_AMPL_REWARDS + '/public', requestOptions).then(handleResponse);
    }
}

async function registerAmplRewards(token) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(token),
        body: null
    };
    return fetch(Url.REGISTER_AMPL_REWARDS, requestOptions).then(handleResponse);
}

async function getFee(payload) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(payload.token),
    };
    return fetch(Url.GET_FEE + payload.action + '/' + payload.amount, requestOptions).then(handleResponse);
}

async function getTransferFee(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };
    return fetch(Url.GET_TRANSFER_FEE , requestOptions).then(handleResponse);
}

async function addToken(coin, network, chainId) {
    let suffix = isTest(network, chainId) ? '_test' : ''
    let chain = getChain(network, chainId)  + suffix
    let address = Contract.default[chain].coins[coin].address
    let symbol = coin
    let decimals = Contract.default[chain].coins[coin].decimals
    web3.currentProvider.sendAsync({
        method: 'wallet_watchAsset',
        params: {
            type: 'ERC20',
            options: {
                address,
                symbol,
                decimals
                // image: 'https://foo.io/token-image.svg',
            },
        },
    }, (err, res) => {
        if (err) {
            console.log('add token failed: ', err)
        } else {
            return res
        }
    })
}

async function buyIDO(payload, token) {
    console.log('buy ido payload: ', JSON.stringify(payload))
    const requestOptions = {
        method: 'POST',
        headers: authHeader(token),
        body: JSON.stringify(payload)
    };
    return fetch(Url.BUY_IDO, requestOptions).then(handleResponse);
}

async function getIDOInfo(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };
    return fetch(Url.GET_IDO_INFO, requestOptions).then(handleResponse);
}

async function getInvestmentInfo(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };
    return fetch(Url.GET_IDO_INVESTMENT_INFO, requestOptions).then(handleResponse);
}

async function getMigrationInfo(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };
    return fetch(Url.GET_IDO_MIGRATION_INFO, requestOptions).then(handleResponse);
}

async function migrateIDO(payload, token) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(token),
        body: JSON.stringify(payload)
    };
    return fetch(Url.MIGRATE_IDO, requestOptions).then(handleResponse);
}
