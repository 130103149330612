import React, {useState} from 'react';
import {makeStyles,} from '@material-ui/core/styles';
import './CustomTextField.css';
import {Avatar, Button, Typography} from '@material-ui/core';
import {getIcons} from "../../utils/Common";
import NewCustomCoinSelectorModal from './NewCustomCoinSelectorModal'
import useWindowDimensions from "../../utils/WindowDimensions";
import pickCoinIcon from "../../images/pickCoinIcon.png";
import {isMobile} from 'react-device-detect';

// const closeIcon = 'https://cdn.swapallapp.com/l2/images/cross.png'
const customDropBox1 = 'https://cdn.swapallapp.com/l2/images/customDropBox1.png'

const useStylesInput = makeStyles((theme) => ({
    root: {

        'label + &': {
            marginTop: theme.spacing(3),
        },

        // 'label.Mui-focused': {
        //   color: 'green',
        // },
        '&:hover': {
            backgroundColor: '#271CFF',
        },
        '&$focused': {
            backgroundColor: '#271CFF',
        },
        backgroundColor: '#271CFF',
        borderRadius: 16,
        alignItems: 'center',
        // backgroundColor: 'red',
        overflow: 'hidden'
    },
    modalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        // bgcolor: 'background.paper',
        // height: 400,
        borderRadius: 12,
        // backgroundColor: 'rgba(0, 0, 0, 0.8)'
        backgroundColor: "white"

    },

    modalBoxPhoneSize: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "80%",
        // bgcolor: 'background.paper',

        pt: 2,
        px: 4,
        pb: 3,
        backgroundColor: '#f5f5f5',

        borderRadius: 20,

    },


    selectCoinBut: {
        boxShadow: 'none',
        textTransform: 'none',


        height: isMobile ? 28 : 36,
        width: isMobile ? 100 : 120,
        borderRadius: 18,
        padding: '6px 12px',
        border: 'none',
        lineHeight: 1.5,
        backgroundColor: '#E6EBF0',

        fontFamily: 'Avenir',
        fontWeight: '500',

    },
    list: {
        overflowY: "auto",
        margin: 0,
        padding: 0,
        listStyle: "none",
        height: "100%",

    }

    // input: {
    //     position: 'relative',
    //     // backgroundColor: '#1F32B4',
    //     fontSize: 36,
    //     // width: 'auto',
    //     padding: '10px 12px',
    //     color: '#1DF0A9',

    //     fontFamily: [
    //         'Arial',
    //     ].join(','),

    //     '&:focused': {
    //         color: '#1DF0A9'
    //     }
    // }


}));



export default function NewCustomCoinSelector(props, t) {
    const classes = useStylesInput();
    const inputRef = React.useRef();

    const [open, setOpen] = React.useState(false);
    const [unlockWarningOpen, setUnlockWarningOpen] = React.useState(false);

    const [searchInput, setSearchInput] = useState('')
    const [filteredCoins, setFilteredCoins] = useState([])

    const handleSearch = (e) => {
        let text = e.toLowerCase()
        setSearchInput(e)
        let trucks = props.coins
        let filteredCoins = trucks.filter((item) => {
            return item.value.toLowerCase().match(text)
        })
        if (!text || text == "") {
            setFilteredCoins([])

        } else {
            setFilteredCoins(filteredCoins)
        }
    }

    const handleClose = () => {
        setOpen(false);
        setSearchInput("")
        setFilteredCoins([])
    };

    const handleUnlockWarningClose = () => {
        setUnlockWarningOpen(false);

    };
    const handleOpen = () => {

        setOpen(true);
    };
    const handleNotLogin = () => {

        setUnlockWarningOpen(true);
    };

    const handleCoinChange = (option) => {

        const resultCoin = { label: option.value ? option.value : option, value: option.value ? option.value : option }

        handleClose()
        if (option) {
            props.handleChange(resultCoin, props.name)
        } else {

            props.handleChange(t('noCapital'))
        }

    };

    return <div >


        <Button onClick={props.isloggedIn ? handleOpen : handleNotLogin} className={classes.selectCoinBut} >
            {
                props.coin === "" ?
                    null
                    :
                    <Avatar alt="Coin Icon" style={{ width: isMobile ? 16 : 20, height: isMobile ? 16 : 20 }} src={getIcons(props.coin, props.tokenIcons, true)} />

            }
            <Typography style={{ marginLeft: 4, fontSize: isMobile ? 12 : 16, fontFamily: 'Avenir', color: '#363636', fontWeight: "600" }}>
                {
                    props.coin === "" ?
                        props.plachHolder
                        :
                        props.coin
                }
            </Typography>
            <img style={{ width: isMobile ? 10 : 12, objectFit: 'contain', marginLeft: 8 }} src={pickCoinIcon} />

        </Button>

        <NewCustomCoinSelectorModal
            unlockWarningOpen={unlockWarningOpen}
            handleUnlockWarningClose={handleUnlockWarningClose}
            handleClose={handleClose}

            handleCoinChange={handleCoinChange}
            tokenIcons={props.tokenIcons}
            coins={props.coins}
            open={open}
        />

    </div>



}


