import {withTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {authActions} from "../../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {Typography} from "@material-ui/core";

function ReleaseBar({ width, t}) {
    const dispatch = useDispatch();
    const { releaseDetail } = useSelector(state => state.auth)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)


    useEffect(() => {
        dispatch(authActions.getReleaseDetail())
    }, [])

    useEffect(() => {
        setStartDate(new Date(Date.parse(releaseDetail.startTime)))
        setEndDate(new Date(Date.parse(releaseDetail.endTime)))
    }, [releaseDetail])

    return (
        Object.keys(releaseDetail).length > 0 && releaseDetail.releasing?
        <div style={{width:'100%', backgroundColor:'#75E2F9'}}>
            <Typography style={{padding:'16px 0px', alignItems:'center', textAlign:'center',color:'#010846', fontSize: '12px', fontWeight:'bold', letterSpacing:'0.53px', lineHeight:'14px'}}>
                {t('releaseBar-first')} ({startDate.getHours()}:{startDate.getMinutes() < 10 ? '0'+startDate.getMinutes(): startDate.getMinutes()} - {endDate.getHours()}:{endDate.getMinutes() < 10? '0'+endDate.getMinutes() :endDate.getMinutes()})
                {t('releaseBar-second')}
            </Typography>
        </div>
            :null
    )
}

export default withTranslation()(ReleaseBar);
