import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Avatar, Backdrop, Button, Fade, Modal, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import useWindowDimensions from '../../utils/WindowDimensions'
import './Deposit.css';
import NewCustomTextField from '../CommonElements/NewCustomTextField'
import NewCustomCoinSelector from '../CommonElements/NewCustomCoinSelector'
import { walletActions } from '../../redux/actions/walletActions';
import { useDispatch, useSelector } from 'react-redux';
import { roundingDown } from '../../utils/RoundingDown'
import { countDecimals, formDateString, getChain, getScan, isNumeric } from "../../utils/Common";
import { history } from '../../utils/History';
import { onClickConnect, onClickInstall, unlock } from '../../utils/Sign'
import { isValidAddress } from "ethereumjs-util";
import CustomButton from '../CommonElements/CustomButton';
import depositIcon from "../../images/wallet/depositIcon.png";
import backIcon from "../../images/backIcon.png";
// import lightIcon from "../../images/swap/lightIcon.png";
// import CustomCoinSelector from '../CommonElements/CustomCoinSelector';
import { isMobile } from 'react-device-detect';
import Lottie from 'lottie-react-web'
import animation from "../Animations/127882-gamification-onboarding-animation.json";
import animationSuccess from "../Animations/lf30_editor_g02qsqwx.json";
import animationCancle from "../Animations/98457-transaction-canceled.json";

import metamaskIcon from "../../images/metamaskIcon.png";

// const backArrow = 'https://cdn.swapallapp.com/l2/images/backArrow.png'
// const confirmIcon = 'https://cdn.swapallapp.com/l2/images/confirmIcon.png'

const Web3 = require("web3");

function Deposit({ t, navBarHeight, address, setOpenSliderModal, handleCloseSliderModal, setSignUpPayload, sliderInfo, chainId, network, code,
    sendBackButton1, sendBackButton1Disabled, button1, button2Disabled
}) {
    const { width } = useWindowDimensions();
    const useStyles = makeStyles((theme) => ({
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            border: '1px solid #000',
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
            backgroundColor: '#09135F',
            height: "50%",
            borderRadius: 20,

        },
        selectCoinBut: {
            boxShadow: 'none',
            textTransform: 'none',
            fontSize: 20,
            color: 'white',
            height: 50,
            borderRadius: 16,
            width: '26%',
            fontWeight: 'bold',
            padding: '6px 12px',
            border: '1px solid',
            lineHeight: 1.5,
            backgroundColor: '#0063cc',
            borderColor: '#0063cc',
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:hover': {
                backgroundColor: '#0069d9',
                borderColor: '#0062cc',
                boxShadow: 'none',
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: '#0062cc',
                borderColor: '#005cbf',
            },
            '&:focus': {
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
            },
        },
        root: {
            ...theme.typography.button,
            backgroundColor: '#010846',
            padding: theme.spacing(1),
            flexGrow: 1,
            textAlign: 'center'
        },
        depositBox: {
            width: 600,
            borderRadius: 24,
            backgroundColor: '#101B66',
            justifyContent: 'center',
            display: 'inline-flex',
            marginTop: 100,
            paddingTop: 30,
            paddingBottom: 30
        },
        depositContent: {
            width: 500,
            alignItems: 'flex-start',
        },
        wrapper: {
            color: 'white',
            alignItems: 'flex-start',
            display: 'flex',
            fontSize: 25
        },
        fieldWrapper: {
            justifyContent: 'baseline'
        },
        result: {
            fontSize: 10,
            color: '#000000',
            width: 200
        },
        btn: {
            backgroundColor: '#83ACF4',
            color: '#010846',
            borderColor: 'transparent',
            borderRadius: 36,
            height: 72,
            fontWeight: 600,
            minWidth: "100%",
            textTransform: "none"
        },
        btn_disabled: {
            backgroundColor: '#83ACF4',
            color: '#010846',
            borderColor: 'transparent',
            borderRadius: 25,
            height: 45,
            fontWeight: 600,
            opacity: 0.2,
            textTransform: "none"

        },
        capitalList: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: 'transparent',
        },
        backBtn: {
            float: 'left'
        },
        backArrow: {
            width: 20,
            height: 20,
        },
        contentWrapper: {
            fontSize: 15,
            color: 'white'
        },
        inputField: {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            width: '100%',
            // color: 'red'
        },
        allIn: {
            color: 'white',
            fontSize: 15
        },
        helperText: {
            color: '#ff3434'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // width: width * 0.5,
            // height: height * 0.5
        },
        paper: {
            backgroundColor: 'white',
            border: 'transparent',
            // boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            borderRadius: 20,
            textAlign: 'center'
        },
        defaultFont: {
            fontWeight: '400', color: '#363636', fontFamily: 'Avenir', textTransform: 'none'
        },
    }));
    const classes = useStyles();

    const [depositAmount, setDepositAmount] = useState('')
    // const [searchInput, setSearchInput] = useState('')
    // const [filteredCoins, setFilteredCoins] = useState([])

    const [coin, setCoin] = React.useState('');
    const [capital, setCapital] = React.useState({ free: 0 })
    const [warning, setWarning] = React.useState(' ')
    const [cantDeposit, setCantDeposit] = React.useState(true)
    const [coins, setCoins] = React.useState([])
    // const [open, setOpen] = React.useState(false);


    const { token, loggedIn, registered, loading } = useSelector(state => state.auth)
    const { tokenList, tokenIcons, l1Capital, depositFinished, depositSucceed, depositMsg, depositHash, depositReceipt, walletSigning } = useSelector(state => state.wallet)
    const dispatch = useDispatch();
    // const location = useLocation();
    const inputRef = React.useRef();
    // const prevMessageRef = useRef();

    const [openCallback, setOpenCallback] = useState(false)
    const [time, setTime] = useState('')

    const allIn = () => {
        handleAmountChange(roundingDown(capital.free, 4))
    }

    const handleOpenCallback = () => {
        setOpenCallback(true);
    };

    const handleCloseCallback = () => {
        setOpenCallback(false);
        dispatch(walletActions.clearInfo())
        setCantDeposit(false)
    };

    const handleAmountChange = (amount) => {

        if (parseFloat(amount) >= 0) {
            if (countDecimals(parseFloat(amount)) <= 8) {
                setDepositAmount(amount)
            } else {
                setDepositAmount(roundingDown(parseFloat(amount), 8).toString())
            }
        } else {
            setDepositAmount('')
        }
        if (isNumeric(amount) && amount > 0) {
            if (parseFloat(amount) <= capital.free) {
                setWarning(' ')
                setCantDeposit(false)
            } else {
                setWarning(t('balanceLow'))
                setCantDeposit(true)
            }
        } else {
            if (amount === '') {
                setWarning(' ')
            } else {
                setWarning(t('invalidInput'))
            }
            setCantDeposit(true)
        }

    };

    const handleCoinChange = (option) => {

        setCoin(option.value);
        handleAmountChange('')
    };

    // const handleOpenSelectToken = (option) => {


    // };


    const confirmDeposit = async () => {
        let payload = {
            l1Address: address,
            l2Address: address,
            amount: depositAmount,
            coin: coin,
            network, chainId
        }
        try {
            if (depositFinished) {
                setTime(formDateString(new Date().getTime()))
                handleOpenCallback()
                dispatch(walletActions.deposit(payload))
            }
        } catch (err) {
            console.log('deposit request failed: ', err)
            setCantDeposit(false)
            setDepositAmount('')
        }
    }

    useEffect(() => {
        if (loggedIn) {
            // dispatch(walletActions.getUserCapital(token))
            dispatch(walletActions.getAllTokenStatus(token))
            dispatch(walletActions.getL1Capital(address, network, chainId))
        }
        return () => {
        }
    }, [])

    useEffect(() => {
        if (loggedIn) {
            // dispatch(walletActions.getUserCapital(token))
            dispatch(walletActions.getAllTokenStatus(token))
            dispatch(walletActions.getL1Capital(address, network, chainId))
        }
        return () => {
        }
    }, [loggedIn, address])

    useEffect(() => {
        let _coins = []
        let chain = getChain(network, chainId)
        /**
         * Sorting available coins based on current chain  -- Richard 210408
         */
        for (let i = 0; i < tokenList.length; i++) {
            if (tokenList[i].contractDepositIsOn) {
                let coinChain = tokenList[i].chain
                let token = tokenList[i].token
                if (coinChain === chain) {
                    _coins.push({
                        label: token,
                        value: token
                    })
                }
            }
        }
        setCoins(_coins)
        return () => {
        }

    }, [tokenList, address, loggedIn, network, chainId])


    useEffect(() => {
        let _capital = l1Capital.find(item => item.token === coin)
        if (_capital === undefined) {
            _capital = {
                free: 0,
                token: coin
            }
        }
        setCapital(_capital)
        return () => {
        }

    }, [coin, address, l1Capital])

    useEffect(() => {
        if (depositHash.length > 0 && !depositFinished) {
            setTime(formDateString(new Date().getTime()))
        }
        return () => {
        }

    }, [depositHash, depositFinished, depositMsg])
    // const handleOpen = () => {
    //     setOpen(true);
    // };
    // const handleClose = () => {
    //     setOpen(false);
    //     setSearchInput("")
    //     setFilteredCoins([])
    // };

    // function handleSearch(e) {
    //     let text = e.toLowerCase()
    //     setSearchInput(e)
    //     let trucks = coins

    //     let filteredCoins = trucks.filter((item) => {
    //         return item.value.toLowerCase().match(text)
    //     })
    //     if (!text || text == "") {
    //         setFilteredCoins([])

    //     } else {
    //         setFilteredCoins(filteredCoins)
    //     }

    // }
    // function handleClickSelector(value) {

    // }
    // useEffect(() => {
    //     console.log('confirmed receipt: ', depositConfirmationNumber)
    //     if (depositConfirmationNumber === 5) {
    //         handleOpenCallback()
    //     }
    // },[depositConfirmationNumber, depositReceipt])

    const confirmViewBody = (
        <div style={{
            backgroundColor: 'white', borderRadius: 24, width: width < 950 ? "91%" : 520, overflow: 'hidden', outline: 0, display: 'flex', alignItems: 'center', flexDirection: 'column'
        }}>

            {
                depositFinished ?
                    depositSucceed ?
                        <div style={{ width: width < 950 ? 60 : 100, height: width < 950 ? 60 : 100, marginTop: 24 }}>
                            <Lottie
                                speed={0.8}
                                options={{
                                    animationData: animationSuccess,
                                    loop: false,
                                }}
                            />
                        </div>
                        :
                        <div style={{ width: width < 950 ? 60 : 100, height: width < 950 ? 60 : 100, marginTop: 24 }}>
                            <Lottie
                                speed={0.8}
                                options={{
                                    animationData: animationCancle,
                                    loop: false,
                                }}
                            />


                        </div>

                    :

                    <div style={{ width: width < 950 ? 60 : 100, height: width < 950 ? 60 : 100, marginTop: 24 }}>


                        <Lottie

                            speed={0.5}
                            options={{

                                animationData: animation,
                                loop: true,

                            }}
                        />


                    </div>
            }


            <Typography className={classes.defaultFont} style={{ fontSize: 20, fontWeight: 'bold', marginTop: 8, color: '#363636', textAlign: 'center' }}>


                {depositSucceed ? t('depositSucceed') : depositHash.length > 0 ? t('depositSucceed') : depositMsg !== '' ? t(depositMsg) : t('swap-confirm-title')}
            </Typography>
            <Typography className={classes.defaultFont} style={{ fontSize: 12, fontWeight: 'bold', marginTop: -4, color: '#8A96A7', marginBottom: 24 }}>
                {depositSucceed ? "" : depositHash.length > 0 ? "" : depositMsg !== '' ? t(depositMsg) : t('swap-confirm-content')}
            </Typography>
            {
                walletSigning ?
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', backgroundColor: 'white' }}>

                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '89%', marginTop: 40 }}>
                            <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#8A96A7', fontWeight: 'bold' }}>
                                {t('depositAmount')}
                            </Typography>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {/* <Avatar variant="square" src={getIcons(pairOne, tokenIcons, true)} style={{ height: 20, width: 20, marginRight: 8 }} /> */}
                                <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#363636', fontWeight: 'bold', }} >
                                    {depositAmount}
                                </Typography>


                            </div>
                        </div>


                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '89%', marginTop: 16 }}>
                            <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#8A96A7', fontWeight: 'bold' }}>
                                {t('time')}
                            </Typography>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {/* <Avatar variant="square" src={getIcons(pairTwo, tokenIcons, true)} style={{ height: 20, width: 20, marginRight: 8 }} /> */}
                                <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#363636', fontWeight: 'bold', }} >
                                    {time}
                                </Typography>
                            </div>
                        </div>



                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 16, width: '89%' }}>
                            <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#8A96A7', fontWeight: 'bold' }}>
                                {t('action')}
                            </Typography>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {/* <Avatar variant="square" src={getIcons(pairOne, tokenIcons, true)} style={{ height: 20, width: 20, marginRight: 8 }} /> */}
                                <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#363636', fontWeight: 'bold' }} >
                                    {t('depositAction')}
                                </Typography>


                            </div>
                        </div>

                        {
                            Object.keys(depositReceipt).length > 0 ?
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 16, width: '89%' }}>
                                    <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#363636', fontWeight: 'bold' }}>
                                        {t('confirmedBlock')}
                                    </Typography>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {/* <Avatar variant="square" src={getIcons(pairOne, tokenIcons, true)} style={{ height: 20, width: 20, marginRight: 8 }} /> */}
                                        <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#363636', fontWeight: 'bold' }} >
                                            {depositReceipt.blockNumber}
                                        </Typography>


                                    </div>
                                </div>
                                :
                                null

                        }

                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 16, width: '89%', marginBottom: 40 }}>
                            <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#8A96A7', fontWeight: 'bold' }}>
                                {t('status')}
                            </Typography>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {/* <Avatar variant="square" src={getIcons(pairOne, tokenIcons, true)} style={{ height: 20, width: 20, marginRight: 8 }} /> */}
                                <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#363636', fontWeight: 'bold' }} >
                                    {depositSucceed ? t('succeed') : Object.keys(depositReceipt).length > 0 ? t('submitted') : t('loading')}
                                </Typography>


                            </div>
                        </div>
                    </div>
                    :
                    null
            }


            {
                depositHash.length > 0 ?

                    <Button target="_blank" href={getScan(network, chainId) + depositHash} style={{ width: "100%", height: 64, backgroundColor: '#1DF0A9' }}  >
                        <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#010846', fontWeight: 'bold' }} >
                            {`${t('checkScan')} ${getChain(network, chainId)} Scan`}
                        </Typography>
                    </Button>

                    : null
            }

        </div>
    );

    return (
        <div style={{ display: 'flex', height: "100%", flexDirection: 'row', justifyContent: 'center', marginBottom: 120 }}>
            {
                width < 960 ?
                    //phone UI
                    <div style={{ width: "100%", height: "86vh", display: 'flex', flexDirection: "column", justifyContent: 'space-between' }}>
                        <div style={{}}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: 'space-between',
                                width: "100%",
                            }}>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16, marginTop: 16 }}>
                                    <Typography style={{ fontSize: 24, fontFamily: 'Syncopate', color: '#251CFF', marginLeft: 24 }}>{t('deposit')}</Typography>

                                    <Button style={{ color: "white", backgroundColor: '#251CFF', fontSize: 12, textTransform: 'none', height: 32, marginRight: 24, borderRadius: 8 }} onClick={() => {
                                        history.back()
                                    }} >
                                        {t("back")}
                                    </Button>
                                </div>
                                <Typography className={classes.defaultFont} style={{ fontSize: 12, color: '#8786A7', textAlign: 'left', marginLeft: 24, marginRight: 24 }}>
                                    {t('depositContent')}
                                </Typography>

                                {
                                    width < 960 ?
                                        null
                                        :
                                        <img alt='' src={depositIcon} style={{ width: '50%' }}>
                                        </img>
                                }

                            </div>


                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div style={{}}>
                                    {/* new input component */}
                                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 24 }}>
                                        <div style={{ width: '91%', alignItems: 'flex-start', }}>
                                            <Typography style={{ textAlign: 'left', color: '#AFBDD0', fontFamily: 'Avenir', fontWeight: '600', fontSize: isMobile ? 12 : 14 }}>{t("depositAmount")}</Typography>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '91%', backgroundColor: 'white', marginTop: 8, borderRadius: 16, border: '1px solid #DCDCDC' }} >
                                            <div style={{ marginLeft: isMobile ? 12 : 24, marginTop: isMobile ? 12 : 16, marginBottom: 12 }}>
                                                <NewCustomTextField
                                                    disabled={!loggedIn}
                                                    inputRef={inputRef}
                                                    onChange={(e) => handleAmountChange(e.target.value)}
                                                    value={depositAmount}
                                                    placeholder={"0.00"}
                                                    rightbuttonlabel={capital.free > 0.0001 ? t('all') : null}
                                                    onrightbuttonclick={allIn}
                                                    customtype="number"
                                                    coin={coin}
                                                    tokenIcons={tokenIcons}
                                                    coins={coins}
                                                    handleChange={handleCoinChange}
                                                >

                                                </NewCustomTextField>
                                                <Typography style={{ marginLeft: 8, fontFamily: 'Avenir', fontWeight: '500', color: warning === " " ? "#AFBDD0" : '#FF4646', fontSize: isMobile ? 12 : 16 }}> {warning === " " ? t("enterAmount") : warning}</Typography>
                                            </div>



                                            <div style={{ display: 'flex', flexDirection: 'column', marginRight: isMobile ? 12 : 24 }}>
                                                <NewCustomCoinSelector
                                                    // style={{ width: width < 960 ? "unset" : '42%' }}
                                                    coin={coin}
                                                    isloggedIn={loggedIn}

                                                    tokenIcons={tokenIcons}
                                                    coins={coins}
                                                    plachHolder={t("selectCoin")}
                                                    inputTitle={t("searchCoin")}
                                                    handleChange={handleCoinChange}
                                                />
                                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8, alignItems: 'center', justifyContent: 'center' }}>
                                                    <Typography style={{ textAlign: 'left', color: '#AFBDD0', fontFamily: 'Avenir', fontWeight: '500', fontSize: isMobile ? 12 : 14 }}>{t("availableCapital")} </Typography>
                                                    <Typography style={{ textAlign: 'left', marginLeft: 4, color: '#271CFF', fontFamily: 'Avenir', fontWeight: '500', fontSize: isMobile ? 12 : 14 }}>{capital.free}</Typography>
                                                </div>
                                            </div>


                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                        <div style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "8%" }}>
                            {
                                address.length < 42 || !isValidAddress(address) ?
                                    <CustomButton buttonstyle="connectStyle" style={{ width: '91%' }} onClick={!window.ethereum ? () => onClickInstall(sendBackButton1, sendBackButton1Disabled) : () => onClickConnect(network, chainId, address, dispatch)}>
                                        {button1}
                                    </CustomButton> : null
                            }
                            {
                                address.length === 42 && isValidAddress(address) ?
                                    loggedIn ?
                                        <CustomButton style={{ opacity: cantDeposit ? 0.2 : 1, width: '91%' }} onClick={confirmDeposit} disabled={cantDeposit}>
                                            {t('confirm')}
                                        </CustomButton> :
                                        <CustomButton buttonstyle="unlockStyle" style={{ width: '91%' }} onClick={(!registered || !loggedIn) && !loading ? () => unlock(address, chainId, network, code, Web3, registered, dispatch, setOpenSliderModal, setSignUpPayload) : null} disabled={button2Disabled}>
                                            {t('unlock')}
                                        </CustomButton> : null
                            }
                        </div>





                    </div>
                    :

                    //desk UI
                    <div style={{ width: width < 960 ? "100%" : '71%', display: 'flex', flexDirection: width < 960 ? "column" : 'row', justifyContent: width < 960 ? "unset" : 'space-between', marginTop: "3%" }}>

                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            backgroundColor: "#F1F9FF",
                            justifyContent: 'space-between',
                            width: width < 960 ? "100%" : '38%',
                            height: width < 960 ? "unset" : 520,
                            paddingBottom: 24

                        }}>
                            <Typography style={{ fontSize: 32, fontFamily: 'Syncopate', color: '#251CFF', marginLeft: 24, marginTop: 24 }}>{t('deposit')}</Typography>

                            <Typography className={classes.defaultFont} style={{ fontSize: width < 960 ? 12 : 14, color: '#8FB9E1', textAlign: 'left', marginLeft: 24, marginRight: 24 }}>
                                {t('depositContent')}
                            </Typography>

                            {
                                width < 960 ?
                                    null
                                    :
                                    <img alt='' src={depositIcon} style={{ width: '50%' }}>
                                    </img>
                            }

                        </div>

                        <div style={{ backgroundColor: '#F5F5F5', width: width < 960 ? "100%" : '60%', height: 400 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: width < 960 ? 'center' : 'flex-start', height: "100%", justifyContent: 'space-between' }}>
                                <div style={{ width: width < 960 ? '91%' : '100%' }}>
                                    <Button style={{ backgroundColor: 'white', marginLeft: width < 960 ? 0 : 24, marginTop: 24 }} onClick={() => {
                                        history.back()
                                    }} >
                                        <img alt='' style={{ height: 24 }} src={backIcon} />
                                    </Button>


                                    {/* new input component */}
                                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 24 }}>
                                        <div style={{ width: '91%', alignItems: 'flex-start', }}>
                                            <Typography style={{ textAlign: 'left', color: '#AFBDD0', fontFamily: 'Avenir', fontWeight: '600', fontSize: isMobile ? 12 : 14 }}>{t("depositAmount")}</Typography>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '91%', backgroundColor: 'white', marginTop: 8, borderRadius: 16, border: '1px solid #DCDCDC' }}>
                                            <div style={{ marginLeft: isMobile ? 12 : 24, marginTop: isMobile ? 10 : 16, marginBottom: 12 }}>
                                                <NewCustomTextField
                                                    // label={t('depositAmount')}
                                                    disabled={!loggedIn}
                                                    // style={{ width: width < 960 ? "72%" : '68%' }}
                                                    inputRef={inputRef}
                                                    onChange={(e) => handleAmountChange(e.target.value)}
                                                    value={depositAmount}
                                                    // helperText={warning}
                                                    // error={warning !== ''}
                                                    placeholder={"0.00"}
                                                    rightbuttonlabel={capital.free > 0.0001 ? t('all') : null}
                                                    onrightbuttonclick={allIn}
                                                    customtype="number"
                                                    coin={coin}

                                                    tokenIcons={tokenIcons}
                                                    coins={coins}
                                                    plachHolder={t("selectCoin")}
                                                    inputTitle={t("searchCoin")}
                                                    handleChange={handleCoinChange}
                                                >

                                                </NewCustomTextField>
                                                <Typography style={{ marginLeft: 8, fontFamily: 'Avenir', fontWeight: '500', color: warning === " " ? "#AFBDD0" : '#FF4646', fontSize: isMobile ? 10 : 16 }}> {warning == " " ? t("enterAmount") : warning}</Typography>
                                            </div>



                                            <div style={{ display: 'flex', flexDirection: 'column', marginRight: isMobile ? 12 : 24 }}>
                                                <NewCustomCoinSelector
                                                    // style={{ width: width < 960 ? "unset" : '42%' }}
                                                    coin={coin}
                                                    isloggedIn={loggedIn}

                                                    tokenIcons={tokenIcons}
                                                    coins={coins}
                                                    plachHolder={t("selectCoin")}
                                                    inputTitle={t("searchCoin")}
                                                    handleChange={handleCoinChange}
                                                />
                                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8, alignItems: 'center', justifyContent: 'center' }}>
                                                    <Typography style={{ textAlign: 'left', color: '#AFBDD0', fontFamily: 'Avenir', fontWeight: '500', fontSize: isMobile ? 10 : 14 }}>{t("availableCapital")}</Typography>
                                                    <Typography style={{ textAlign: 'left', marginLeft: 4, color: '#271CFF', fontFamily: 'Avenir', fontWeight: '500', fontSize: isMobile ? 10 : 14 }}>{capital.free}</Typography>
                                                </div>
                                            </div>


                                        </div>

                                    </div>

                                </div>


                                <div style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 24 }}>
                                    {
                                        address.length < 42 || !isValidAddress(address) ?
                                            <CustomButton buttonstyle="connectStyle" style={{ width: '91%' }} onClick={!window.ethereum ? () => onClickInstall(sendBackButton1, sendBackButton1Disabled) : () => onClickConnect(network, chainId, address, dispatch)}>
                                                {button1}
                                            </CustomButton> : null
                                    }
                                    {
                                        address.length === 42 && isValidAddress(address) ?
                                            loggedIn ?
                                                <CustomButton style={{ opacity: cantDeposit ? 0.2 : 1, width: '91%' }} onClick={confirmDeposit} disabled={cantDeposit}>
                                                    {t('confirm')}
                                                </CustomButton> :
                                                <CustomButton buttonstyle="unlockStyle" style={{ width: '91%' }} onClick={(!registered || !loggedIn) && !loading ? () => unlock(address, chainId, network, code, Web3, registered, dispatch, setOpenSliderModal, setSignUpPayload) : null} disabled={button2Disabled}>
                                                    {t('unlock')}
                                                </CustomButton> : null
                                    }
                                </div>



                            </div>

                        </div>

                    </div>
            }

            <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                aria-labelledby="server-modal-title"
                aria-describedby="server-modal-description"
                className={classes.modal}
                open={openCallback}
                // open={true}
                onClose={handleCloseCallback}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                disableEscapeKeyDown={walletSigning}
                disableBackdropClick={walletSigning}
            >
                <Fade
                    in={openCallback}
                // in={true}
                >
                    {confirmViewBody}


                </Fade>
            </Modal>
        </div >
    );
}

export default withTranslation()(Deposit);
