import * as Url from '../../config/Url'
import { authHeader } from '../../utils/AuthHeader';
import { handleResponse } from '../../utils/HandleResponse'

export const stakePoolService = {
    getAllStakePool,
    getMyStakePool,
    getStakeTransactions,
    getStakeCollectTransactions,
    harvestStakeReward,
    stake,
    unStake,
    stakeLock,
    getMyStakeLocker,
    stakeUnlock,
    getStakeUnlockable,
    unlockAllStake
};

async function getAllStakePool(payload) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(payload)
    };
    return fetch(Url.GET_ALL_STAKE_POOL, requestOptions).then(handleResponse);
}

async function getMyStakePool(payload) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(payload)
    };
    return fetch(Url.GET_MY_STAKE_POOL, requestOptions).then(handleResponse);
}

async function getStakeTransactions(payload) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(payload)
    };
    return fetch(Url.GET_STAKE_TRANSACTIONS, requestOptions).then(handleResponse);
}

async function getStakeCollectTransactions(payload) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(payload)
    };
    return fetch(Url.GET_STAKE_COLLECT_TRANSACTIONS, requestOptions).then(handleResponse);
}

async function harvestStakeReward(payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(payload.accessToken),
        body: JSON.stringify(payload)
    };
    return fetch(Url.HARVEST_STAKE_REWARD, requestOptions).then(handleResponse);
}

async function stake(payload,token) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(token),
        body: JSON.stringify(payload)
    };
    return fetch(Url.STAKE, requestOptions).then(handleResponse);
}

async function unStake(payload,token) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(token),
        body: JSON.stringify(payload)
    };
    return fetch(Url.UNSTAKE, requestOptions).then(handleResponse);
}

async function stakeLock(payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(payload.token),
        body: JSON.stringify(payload)
    };
    return fetch(Url.STAKE_LOCK, requestOptions).then(handleResponse);
}

async function getMyStakeLocker(payload) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(payload)
    };
    return fetch(Url.GET_MY_STAKE_LOCKER, requestOptions).then(handleResponse);
}

async function stakeUnlock(payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(payload.token),
        body: JSON.stringify(payload)
    };
    return fetch(Url.STAKE_UNLOCK, requestOptions).then(handleResponse);
}

async function getStakeUnlockable(payload) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(payload)
    };
    return fetch(Url.GET_STAKE_UNLOCKABLE, requestOptions).then(handleResponse);
}

async function unlockAllStake(payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(payload.token),
        body: JSON.stringify(payload)
    };
    return fetch(Url.UNLOCK_ALL_STAKE, requestOptions).then(handleResponse);
}