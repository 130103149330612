import React, {useEffect, useState} from 'react';
import {List, makeStyles, Typography} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import './DApp.css'
import {convertTimeString} from "../../utils/Common";
import no1 from '../../images/ido/no1.png'
import no2 from '../../images/ido/no2.png'
import no3 from '../../images/ido/no3.png'
import nodeLong2 from "../../images/ido/nodeBgLong2.png";
import {inviteActions} from "../../redux/actions/inviteActions";
import backIcon from '../../images/backIcon.png'

const Web3 = require("web3");
let tempHeight = null;

function Rank({ setOpenSliderModal, handleCloseSliderModal, setSignUpPayload, width, t, sliderInfo,  button2Disabled, registered, loggedIn, address, chainId, network, code, button2, sendBackHeight, sendBackAddr, sendBackChainId, sendBackNetworkId, button1, sendBackButton1, sendBackButton1Disabled, height, navBarHeight }) {
    const useStyles = makeStyles((theme) => ({
        root: {
            ...theme.typography.button,
            backgroundColor: "#010746",
            padding: theme.spacing(1),
            flexGrow: 1,
            height: '100%',
            textAlign: 'center',
            justifyContent: 'center',
        },
        btn: {
            backgroundColor: '#83ACF4',
            color: '#010846',
            borderColor: 'transparent',
            borderRadius: 25,
            height: 45,
            fontWeight: 600,
            minWidth: 100,
            border: 'none',
            '&:hover': {
                // backgroundColor: 'orange',
                border: 'none',
            },
        },
        btn_disabled: {
            backgroundColor: '#8BBDFF',
            color: '#010846',
            borderColor: 'transparent',
            borderRadius: 25,
            height: 45,
            fontWeight: 600,
            opacity: 0.2
        },
        registerBtn: {
            backgroundColor: '#271CFF',
            color: 'white',
            borderColor: 'transparent',
            borderRadius: 25,
            height: 45,
            fontWeight: 600,
            minWidth: 100
        },
        paper: {
            position: 'absolute',
            width: 400,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        defaultFont: {
            fontWeight: '400', color: '#363636', fontFamily: 'Avenir', textTransform: 'none'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },

    }));
    const classes = useStyles();

    const dispatch = useDispatch()
    const history = useHistory()
    const { token } = useSelector(state => state.auth)
    const { rankDetails, rankBonusTransactions } = useSelector(state => state.invite)
    const barRef = React.useRef(null);

    const [rankInfo, setRankInfo] = useState([
    ])

    const [headerHeight, setHeaderHeight] = useState(0)

    const renderRank = (item) => {
        return (
            <div key={item.name} className="rank-wrap" >
                <div className="rank-container">
                    <img src={item.icon} style={{ width: 42, height: 42, marginRight: 20}} />
                    <div className='rank-right'>
                        <div className='rank-info' style={{ width: width > 960 ? 0.2 * width : '100%' }}>
                            <span className='rank-amount' style={{ marginRight: 10 }}>
                                {`${t('total')} ${item.amount} mDBO`}
                            </span>
                            <span className='rank-bonus'>
                                {`${t('bonus')} ${item.bonus} DBO`}
                            </span>
                        </div>

                        <span className='rank-address' style={{ width: 0.2 * width }}>
                            {item.address === null ? t('userNotAvailable') : item.address}
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    const renderHeader = () => {
        return (
            <div ref={barRef} style={{ background: `linear-gradient(310.63deg, #08D09F 0%, #FF00FD 100%)`, backgroundSize: 'cover', position: 'absolute', top: 0, zIndex: -1, paddingTop: '3%', width: '100%' }}>
                <div style={{margin:"100px 30px 23px 30px"}}>
                    <div style={{display: 'flex', justifyContent:"space-between", alignItems: "center"}}>
                        <div>
                            <Typography style={{fontFamily:"PingFang SC", fontSize:40, fontWeight:"bold", color: 'white'}}>{t('rankTitle')}</Typography>
                        </div>
                    </div>
                    <Typography className={classes.font} style={{marginTop:16, fontSize:14, fontWeight:400, color: 'white'}}>{t('rankContent')}</Typography>
                </div>
            </div>
        )
    }

    const renderPC = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundImage: `url(${nodeLong2})`,
                        backgroundSize: 'cover',
                        justifyContent: 'space-between',
                        width: '42%',
                        height: 467,
                    }}>
                        <div style={{ marginTop: '20%', width: '91%', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <Typography style={{ fontSize: 40, fontFamily: 'Syncopate', color: 'white' }}>{t('rankTitle')}</Typography>
                            <Typography className={classes.defaultFont} style={{ marginTop: 10, fontSize: 14, fontWeight: 'slim', color: 'white', textAlign: 'left' }}>
                                {t('rankContent')}
                            </Typography>
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "#f9f9f9",
                        width: '56%',
                        height:  467,
                        justifyContent: 'space-around',
                        marginBottom: 30

                    }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 24, width: '100%', height: '100%', justifyContent: width <= 960 ? 'center' : 'flex-start', alignItems: 'flex-start' }}>
                            <div style={{ display: 'flex', width: '100%', alignItems: 'center', flexDirection: 'column', marginBottom: 50 }} >
                                <div style={{ width: '86%', marginBottom: 50 }}>
                                    <img src={backIcon} style={{ width: 16.5, height: 30.5, cursor: 'pointer' }} onClick={() => {
                                        history.goBack()
                                    }}/>
                                </div>
                                <div className="node-info-header-wrapper">
                                    <div className="node-info-header">
                                            <span className='current-rank'>{t('currentRank')}</span>
                                            <span className='rank-time'>{`${t('calculated')}${convertTimeString(rankDetails.updatedAt)}`}</span>
                                        {
                                            rankInfo.length > 1 ?
                                                rankInfo.map((item, index) => renderRank(item, index)) : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderMobile = () => {
        return (
            <>
                {renderHeader()}
                <div className="ido-mobile-bg-wrapper" style={{ zIndex: -2, marginTop: 0.75 * headerHeight }}>
                    <div className="node-info-header-wrapper">
                        <div className="node-info-header">
                            <span className='current-rank'>{t('currentRank')}</span>
                            <span className='rank-time'>{`(${t('calculated')} 2022/12/12 12:00)`}</span>
                        </div>
                    </div>
                    <div className="node-info">
                        <div className="rank-body">
                            {rankInfo.map(item => renderRank(item))}
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const renderTable = () => {
        return (
            <div className="invite-detail">
                <div className="detail-body" >
                    <p className="detail-title">{t('bonusRecord')}</p>
                    <p className="detail-content">{t('rankNote')}</p>
                    <div className="invitee-list-wrap">
                        <div className="invitee-list-header">
                            <p className="header-text">
                                {t('bonusAmount')}
                            </p>
                            <p className="header-text">
                                {t('bonusTime')}
                            </p>
                        </div>
                        <List className="invitee-list">
                            {
                                rankBonusTransactions.length > 0 ?
                                    rankBonusTransactions.reverse().map(item => (
                                        <div key={item.username} className="invitee-list-item">
                                            <p className="list-item-text">
                                                {`+ ${item.receiveAmount} ${item.receiveCurrency}`}
                                            </p>
                                            <p className="list-item-text">
                                                {convertTimeString(item.createdAt)}
                                            </p>
                                        </div>
                                    )) : <p className="no-invitee">{t('noBonus')}</p>
                            }
                        </List>
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (rankDetails.id !== -1) {
            setRankInfo(
                [
                    {address: rankDetails.rank1Username, amount: rankDetails.rank1PurchaseLp , bonus: 3000, icon: no1},
                    {address: rankDetails.rank2Username, amount: rankDetails.rank2PurchaseLp , bonus: 2000, icon: no2},
                    {address: rankDetails.rank3Username, amount: rankDetails.rank3PurchaseLp , bonus: 1000, icon: no3},
                ]
            )
        }
    }, [rankDetails])

    useEffect(() => {
        dispatch(inviteActions.getRankDetails('eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIweDc0YjNhOTkyYzgyMTBiNGRlNDVmNTNmNTg1ZDk3MTMzMjU5MjdjODIiLCJleHAiOjE2NzE1NTY1OTh9.M9HNqJ1srxloq8efmbcQdDPaB5ojUGtp2HXscrYtS-5cryZMSXuKh4pR2BC3khVUybjmyWm00vu6R6JBUs4hSQ'))
        dispatch(inviteActions.getRankBonusTransactions('eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIweDc0YjNhOTkyYzgyMTBiNGRlNDVmNTNmNTg1ZDk3MTMzMjU5MjdjODIiLCJleHAiOjE2NzE1NTY1OTh9.M9HNqJ1srxloq8efmbcQdDPaB5ojUGtp2HXscrYtS-5cryZMSXuKh4pR2BC3khVUybjmyWm00vu6R6JBUs4hSQ'))
        return () => {
        }
    }, [])

    useEffect(() => {
        if (barRef?.current?.clientHeight !== undefined) {
            setHeaderHeight(barRef?.current?.clientHeight)
        }
    }, [barRef?.current?.clientHeight]);

    return (
        <div className="node-body">
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: width <= 960 ? "100%" : width > 1600 ? '51%' : '61%' }}>
                {width > 960 ? renderPC() : renderMobile()}
                {renderTable()}
            </div>
        </div>
    )
}

export default withTranslation()(Rank);
