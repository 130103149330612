import {authHeader} from "../../utils/AuthHeader";
import * as Url from "../../config/Url";
import {handleResponse} from "../../utils/HandleResponse";

export const voteService = {
    getProposals,
    getVoteHistory,
    vote,
    getLayer3Reward
};

async function getProposals() {
    const requestOptions = {
        method: 'GET',
        // headers: authHeader(token),
    };
    return fetch(Url.GET_PROPOSALS + "all", requestOptions).then(handleResponse);
}

async function getVoteHistory(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };
    return fetch(Url.GET_VOTE_HISTORY, requestOptions).then(handleResponse);
}

async function vote(payload,token) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(token),
        body: JSON.stringify(payload)
    };
    return fetch(Url.VOTE, requestOptions).then(handleResponse);
}

async function getLayer3Reward(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };
    return fetch(Url.GET_LAYER3_REWARD, requestOptions).then(handleResponse);
}