import { Avatar, Button, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import warningicon from "../../images/warningicon.png";
import { isMobile } from 'react-device-detect';
import i18n from '../../i18n';
const unlockStyle = makeStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: '#463EFF',
        },

        background: "#251CFF",

        borderRadius: 12,
        border: 0,
        color: 'white',
        height: 64
    },
    label: {
        fontWeight: '500',
        fontFamily: 'Avenir',
        color: 'white',
        fontSize: 20,
        textTransform: 'none'

    }
}));

const connectStyle = makeStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: '#463EFF',
        },
        background: "#251CFF",

        borderRadius: 16,
        border: 0,
        color: 'white',
        height: isMobile ? 56 : 64
    },
    label: {
        fontWeight: '500',
        fontFamily: 'Avenir',
        color: 'white',
        fontSize: 20,
        textTransform: 'none'

    }
}));

const confirmStyle = makeStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: '#463EFF',
        },
        background: "#251CFF",

        borderRadius: 12,
        border: 0,
        color: 'white',
        height: 64
    },
    label: {
        fontWeight: '500',
        fontFamily: 'Avenir',
        color: 'white',
        fontSize: 20,
        textTransform: 'none'

    }
}));

export default function CustomButton(props) {
    var classes = connectStyle()
    var warningText


    if (props.buttonstyle == "connectStyle") {
        // classes = connectStyle()
        warningText = i18n.language === 'cn' ? "请链接您的MetaMask钱包":"Please Connect to Your MetaMask Wallet"

    } else if (props.buttonstyle == "unlockStyle") {
        // classes = unlockStyle()
        warningText = i18n.language === 'cn' ? "请解锁您的MetaMask钱包" : "Please Unlock to Your MetaMask Wallet"

    } else {
        // classes = confirmStyle()
        warningText = "Please Enter Amount"

    }
    return <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {
            props.buttonstyle != "unlockStyle" && props.buttonstyle != "connectStyle" ? null :
                <div style={{ width: '91%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 8 }}>

                    <Avatar style={{ height: 20, width: 20 }} variant="square" src={warningicon}> </Avatar>
                    <Typography style={{ fontFamily: 'Avenir', fontSize: 14, marginLeft: 4, color: '#8786A7' }}>{warningText}</Typography>
                </div>
        }

        <Button
            //   id="filled-full-width"
            //   margin="normal"
            //   InputLabelProps={{ className: "textfield__label" }}
            //   FormHelperTextProps={{className:"textfield__helperText"}}
            //   variant="filled"
            //   InputProps={{ classes, disableUnderline: true, }}
            {...props}
            autoCapitalize="off"
            autoComplete="off"
            autoCorrect="off"

            classes={{
                root: classes.root, // class name, e.g. `classes-nesting-root-x`
                label: classes.label, // class name, e.g. `classes-nesting-label-x`
            }}
        >

        </Button>

    </div>
}
