import { inviteService} from '../services/inviteService';
import {alertActions} from "./alertActions";
import {
    GET_INVITE_INFO,
    GET_INVITE_INFO_SUCCEED,
    GET_INVITE_INFO_FAILED,
    GET_INVITE_CODE,
    GET_INVITE_CODE_SUCCEED,
    GET_INVITE_CODE_FAILED,
    REGISTER_INVITER,
    REGISTER_INVITER_SUCCEED,
    REGISTER_INVITER_FAILED,
    GET_INVITEE,
    GET_INVITEE_SUCCEED,
    GET_INVITEE_FAILED, CLEAR_INVITE_INFO, CLEAR_MODAL_INFO,
    GET_RANK_BONUS_TRANSACTIONS, GET_RANK_BONUS_TRANSACTIONS_FAILED, GET_RANK_BONUS_TRANSACTIONS_SUCCEED,
    GET_RANK_DETAIL, GET_RANK_DETAIL_FAILED, GET_RANK_DETAIL_SUCCEED, GET_RANK_SWITCH, GET_RANK_SWITCH_FAILED, GET_RANK_SWITCH_SUCCEED,
} from "../constants";

export const inviteActions = {
    getInviteInfo,
    getInviteCode,
    registerInviter,
    getInvitee,
    clearInviteInfo,
    clearModalInfo,
    getRankDetails,
    getRankBonusTransactions,
    getRankSwitch
}

function clearInviteInfo() {
    return dispatch => {
        dispatch(clearInviteInfo())
    }
    function clearInviteInfo() { return { type: CLEAR_INVITE_INFO } }
}

function clearModalInfo() {
    return dispatch => {
        dispatch(clearModalInfo())
    }
    function clearModalInfo() { return { type: CLEAR_MODAL_INFO } }
}

function getInviteInfo(token) {
    return dispatch =>{
        dispatch(request());
        inviteService.getInviteInfo(token)
            .then(
                res => {
                    console.log('invite info: ', res)
                    dispatch(success(res));
                },
                error => {
                    console.log('invite info: ', error)
                    dispatch(failure(error.data));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: GET_INVITE_INFO } }
    function success(res) { return { type: GET_INVITE_INFO_SUCCEED, res } }
    function failure(error) { return { type: GET_INVITE_INFO_FAILED, error } }
}

function getInviteCode(token) {
    return dispatch =>{
        dispatch(request());
        inviteService.getInviteCode(token)
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: GET_INVITE_CODE } }
    function success(res) { return { type: GET_INVITE_CODE_SUCCEED, res } }
    function failure(error) { return { type: GET_INVITE_CODE_FAILED, error } }
}

function registerInviter(token, payload) {
    return dispatch =>{
        dispatch(request());
        inviteService.registerInviter(token, payload)
            .then(
                res => {
                    console.log('res: ', res)
                    dispatch(success(res));
                    dispatch(inviteActions.getInviteInfo(token))
                    dispatch(inviteActions.getInviteCode(token))
                },
                error => {
                    let msg = ''
                    console.log('error: ', error)
                    if (error.data === 'IDO referral code does not exist.') {
                        msg = 'inviterNotFound'
                    } else {
                        msg = 'unCaught'
                    }
                    dispatch(failure(msg))
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: REGISTER_INVITER } }
    function success(res) { return { type: REGISTER_INVITER_SUCCEED, res } }
    function failure(error) { return { type: REGISTER_INVITER_FAILED, error } }
}

function getInvitee(token) {
    return dispatch =>{
        dispatch(request());
        inviteService.getInvitee(token)
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: GET_INVITEE } }
    function success(res) { return { type: GET_INVITEE_SUCCEED, res } }
    function failure(error) { return { type: GET_INVITEE_FAILED, error } }
}

function getRankDetails(token) {
    return dispatch =>{
        dispatch(request());
        inviteService.getRankDetails(token)
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: GET_RANK_DETAIL } }
    function success(res) { return { type: GET_RANK_DETAIL_SUCCEED, res } }
    function failure(error) { return { type: GET_RANK_DETAIL_FAILED, error } }
}

function getRankBonusTransactions(token) {
    return dispatch =>{
        dispatch(request());
        inviteService.getRankBonusTransactions(token)
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: GET_RANK_BONUS_TRANSACTIONS } }
    function success(res) { return { type: GET_RANK_BONUS_TRANSACTIONS_SUCCEED, res } }
    function failure(error) { return { type: GET_RANK_BONUS_TRANSACTIONS_FAILED, error } }
}

function getRankSwitch(token) {
    console.log('calling !!!')
    return dispatch =>{
        dispatch(request());
        inviteService.getRankSwitch(token)
            .then(
                res => {
                    console.log('rank switch res: ', res)
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: GET_RANK_SWITCH } }
    function success(res) { return { type: GET_RANK_SWITCH_SUCCEED, res } }
    function failure(error) { return { type: GET_RANK_SWITCH_FAILED, error } }
}
