import React, {useEffect, useRef, useState} from 'react';
import {makeStyles, Typography, LinearProgress, Button} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {isValidAddress} from "ethereumjs-util";
import {onClickConnect, onClickInstall, unlock} from "../../utils/Sign";
import {useDispatch, useSelector} from "react-redux";
import {walletActions} from "../../redux/actions/walletActions";
import {inviteActions} from "../../redux/actions/inviteActions";
import {authActions} from "../../redux/actions";
import DBOIcon from "../../images/ido/DBO.png"
import SAPIcon from "../../images/ido/SAP.png"
import upArrow from "../../images/ido/upArrow.png"
import downArrow from "../../images/ido/downArrow.png"
import tier0Icon from "../../images/ido/tier0.png"
import tier1Icon from "../../images/ido/tier1.png"
import tier2Icon from "../../images/ido/tier2.png"
import tier3Icon from "../../images/ido/tier3.png"
import {useHistory} from "react-router-dom";
import {roundingDown} from "../../utils/RoundingDown";
import {FormatNumber} from "../../utils/Common";
import i18n from "../../i18n";
import ReferralReminderModal from "./components/ReferralReminderModal";
import IDOBuyModal from "./components/IDOBuyModal";
import IDOMigrateModal from "./components/IDOMigrateModal";

const Web3 = require("web3");
let timeout;

function IDOHome({ width, t, button2Disabled, registered, loggedIn, address, chainId, network, code, button2, sendBackHeight, sendBackAddr, sendBackChainId, sendBackNetworkId, button1, sendBackButton1, sendBackButton1Disabled, height, setOpenSliderModal, setSignUpPayload}) {
    const useStyles = makeStyles((theme) => ({
        font: {
            fontFamily: "PingFang SC",
            letterSpacing: 0
        },
        countDownFont:{
            fontFamily:"Technology",
            letterSpacing: 1.11,
            fontSize: 40,
            lineHeight: "39px",
            color: "#251CFF",
            fontWeight: "bold",
        },
        forBarColorPrimary: {
            backgroundColor:"#F5573B",
            borderRadius:5
        },
        forMigrationBarColor:{
            backgroundColor:"#271CFF",
            borderRadius:5
        },
        forReferralBarColor:{
            backgroundColor:"#FAD301",
            borderRadius:5
        },
        btn: {
            backgroundColor: '#83ACF4',
            color: '#010846',
            borderColor: 'transparent',
            borderRadius: 25,
            height: 45,
            fontWeight: 600,
            minWidth: 100
        },
        btn_disabled: {
            backgroundColor: '#83ACF4',
            color: '#010846',
            borderColor: 'transparent',
            borderRadius: 25,
            height: 45,
            fontWeight: 600,
            opacity: 0.2
        },
    }));

    const classes = useStyles();
    const dispatch = useDispatch()
    const history = useHistory()
    const { token, userInfo } = useSelector(state => state.auth)
    const { inviteInfo, getInviteInfoLoading, loading } = useSelector(state => state.invite)
    const { idoInvestmentInfo, idoMigrationInfo, buyIDOFinished, buyIDOSucceed, buyIDOMsg, idoInfo, migrateIDOMsg, migrateIDOSucceed, migrateIDOFinished } = useSelector(state => state.wallet)

    const [idoStart, setIdoStart] = useState(false)
    const [myBought, setMyBought] = useState(false)
    const [myMigration, setMyMigration] = useState(false)
    const [countDown, setCountDown] = useState({
        distance:0,
        days:0,
        hours:0,
        minutes:0,
        seconds:0
    });
    const [referralReminderVisible, setReferralReminderVisible] = useState(false)
    const [buyVisible, setBuyVisible] = useState(false)
    const [migrateVisible, setMigrateVisible] = useState(false)

    useEffect(() => {
        dispatch(walletActions.getIDOInfo(token))
        return () => {
            clearInterval(timeout);
            dispatch(walletActions.clearIDOInfo())
        }
    }, [])

    useEffect(() => {
        if (loggedIn) {
            dispatch(walletActions.getUserCapital(token))
            dispatch(authActions.getUserInfo(token))
            dispatch(inviteActions.getInviteInfo(token))
            dispatch(walletActions.getInvestmentInfo(token))
            dispatch(walletActions.getMigrationInfo(token))
        } else {
            dispatch(walletActions.clearInfo())
            dispatch(inviteActions.clearInviteInfo())
        }
    }, [loggedIn])

    useEffect(() => {
        if (buyIDOFinished && buyIDOMsg !== '') {
            if (buyIDOSucceed) {
                dispatch(walletActions.getUserCapital(token))
                dispatch(walletActions.getIDOInfo(token))
                dispatch(authActions.getUserInfo(token))
                dispatch(walletActions.getInvestmentInfo(token))
            }
        }
        if (migrateIDOFinished && migrateIDOMsg !== '') {
            if (migrateIDOSucceed) {
                dispatch(walletActions.getUserCapital(token))
                dispatch(walletActions.getIDOInfo(token))
                dispatch(authActions.getUserInfo(token))
                dispatch(walletActions.getMigrationInfo(token))
            }
        }
        return () => {
        }
    }, [buyIDOMsg, buyIDOSucceed, buyIDOFinished, migrateIDOMsg, migrateIDOSucceed, migrateIDOFinished])

    useEffect(() => {
        var countDownDate = new Date(idoInfo.endAt).getTime();
        var now = new Date(idoInfo.currentTime).getTime();
        var startDate = new Date(idoInfo.startAt).getTime();
        var distance = countDownDate - now;

        if(distance>0 && now - startDate >=0){
            setIdoStart(true)
            clearInterval(timeout);
            setCountDown(
                {
                    distance,
                    days:Math.floor(distance / (1000 * 60 * 60 * 24)),
                    hours:Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                    minutes:Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
                    seconds:Math.floor((distance % (1000 * 60)) / 1000)
                }
            );
            timeout = setInterval(function() {
                distance -= 1000
                setCountDown(
                    {
                        distance,
                        days:Math.floor(distance / (1000 * 60 * 60 * 24)),
                        hours:Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                        minutes:Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
                        seconds:Math.floor((distance % (1000 * 60)) / 1000)
                    }
                );

                if (distance <= 0) {
                    clearInterval(timeout);
                }
            },1000)
        }
    }, [idoInfo])

    useEffect(() => {
        console.log('setOpenSliderModal: ', setOpenSliderModal)
    }, [])

    const renderConnectMetamask = ()=>{
        return (
            <>
                {
                    address.length < 42 || !isValidAddress(address) ?
                        <Button style={{ borderRadius: 13, height: 58, width: '100%', backgroundColor: '#F5573B' }} onClick={!window.ethereum ? () => onClickInstall(sendBackButton1, sendBackButton1Disabled) : onClickConnect}>
                            <Typography style={{ fontSize: 20, fontWeight: 'bold', color: 'white' }}>
                                {button1}
                            </Typography>
                        </Button>
                        :
                        null
                }

                {
                    address.length === 42 && isValidAddress(address) && !loggedIn ?
                        <Button style={{ borderRadius: 13, height: 58, width: '100%', backgroundColor: '#8BBDFF' }}
                                className={button2Disabled ? classes.btn_disabled : classes.btn} onClick={(!registered || !loggedIn) && !loading ? () => unlock(address, chainId, network, code, Web3, registered, dispatch, setOpenSliderModal, setSignUpPayload) : null} variant="outlined" color="primary" disabled={button2Disabled}>
                            <Typography style={{ fontSize: 20, fontWeight: 'bold', color: 'white' }}>
                                {button2}
                            </Typography>
                        </Button> : null
                }
            </>
        )
    }

    const renderHeader = ()=>{
        return (
            <div style={{marginTop:60}}>
                <Typography
                    style={{
                        fontSize: width < 960 ? 24 :40,
                        fontWeight: 600,
                        color:"#251CFF",
                        textAlign: "center",
                        fontFamily:"Syncopate"
                    }}
                >
                    {t("dboPresale")}
                </Typography>

                {
                    idoStart?
                        <>
                            <Typography
                                className={classes.font}
                                style={{
                                    marginTop:24,
                                    fontSize: 14,
                                    color:"#363636",
                                    textAlign: "center"
                                }}
                            >
                                {t("presaleEnds")}
                            </Typography>

                            <div style={{display:"flex", marginTop:width < 960 ? 10 : 20, justifyContent:"center"}}>
                                <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                                    <Typography className={classes.countDownFont} >
                                        {countDown.days}
                                    </Typography>
                                    <Typography className={classes.font} style={{fontSize:10, color: "#8786A7", fontWeight: 500}} >
                                        {t("day")}
                                    </Typography>
                                </div>

                                <Typography className={classes.countDownFont} style={{marginLeft:10, marginRight:10}} >:</Typography>

                                <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                                    <Typography className={classes.countDownFont} >
                                        {countDown.hours}
                                    </Typography>
                                    <Typography className={classes.font} style={{fontSize:10, color: "#8786A7", fontWeight: 500}} >
                                        {t("hour")}
                                    </Typography>
                                </div>

                                <Typography className={classes.countDownFont} style={{marginLeft:10, marginRight:10}} >:</Typography>

                                <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                                    <Typography className={classes.countDownFont} >
                                        {countDown.minutes}
                                    </Typography>
                                    <Typography className={classes.font} style={{fontSize:10, color: "#8786A7", fontWeight: 500}} >
                                        {t("minute")}
                                    </Typography>
                                </div>

                                <Typography className={classes.countDownFont} style={{marginLeft:10, marginRight:10}} >:</Typography>

                                <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                                    <Typography className={classes.countDownFont} >
                                        {countDown.seconds}
                                    </Typography>
                                    <Typography className={classes.font} style={{fontSize:10, color: "#8786A7", fontWeight: 500}} >
                                        {t("second")}
                                    </Typography>
                                </div>

                            </div>
                        </>
                        :<Typography
                            className={classes.font}
                            style={{
                                marginTop:24,
                                fontSize: 14,
                                color:"#363636",
                                textAlign: "center",
                                whiteSpace: 'pre-line'
                            }}
                        >
                            {t("presaleNotStarted")}
                        </Typography>
                }

            </div>
        )
    }

    const renderIDO = ()=>{
        return(
            <div style={{display: 'flex', width:"100%", justifyContent:"space-between", marginTop:60, marginBottom:40}}>

                <div style={{ height:447, width: 438, backgroundColor: "white"}}>
                    <div style={{margin:"45px 30px"}}>
                        <div style={{display: 'flex', justifyContent:"space-between", alignItems: "center"}}>
                            <div>
                                <Typography style={{fontFamily:"Syncopate", fontSize:24, fontWeight:"bold"}}>DBO</Typography>
                                <Typography className={classes.font} style={{fontSize:14, fontWeight:500, color: "#8786A7"}}>{t("tokenForPresale")}：15,000,000 DBO</Typography>
                            </div>
                            <img src={DBOIcon} style={{width:70, height:70}}/>
                        </div>
                        <Typography className={classes.font} style={{marginTop:30, fontSize:14, fontWeight:600}}>{t("presaleProgress")} {idoInfo.usdtRaised ? roundingDown(idoInfo.usdtRaised/2500000 *100,2) :0}%</Typography>
                        <LinearProgress classes={{barColorPrimary: classes.forBarColorPrimary}} variant="determinate" value={idoInfo.usdtRaised ? idoInfo.usdtRaised/2500000 *100 :0} style={{height:10, marginTop:4, marginBottom:4, borderRadius: 5, backgroundColor: '#F6D1CA'}}/>
                        <div style={{display: 'flex', justifyContent:"space-between"}}>
                            <Typography className={classes.font}  style={{fontSize: 14, color: "#8786A7"}}>{idoInfo.usdtRaised ? FormatNumber(idoInfo.usdtRaised) :0} USDT</Typography>
                            <Typography className={classes.font}  style={{fontSize: 14, color: "#8786A7"}}>2,500,000 USDT</Typography>
                        </div>
                        <div style={{marginTop:30, display: 'flex', justifyContent:"space-between"}}>
                            <Typography className={classes.font}  style={{fontSize: 16, lineHeight: "22px", color: "#8786A7", fontWeight:600}}>{t("price")}</Typography>
                            <Typography className={classes.font}  style={{fontSize: 16, lineHeight: "22px", color: "#8786A7", fontWeight:600}}>1 DBO = 0.1 USDT</Typography>
                        </div>
                        <div style={{marginTop:8, display: 'flex', justifyContent:"space-between"}}>
                            <Typography className={classes.font}  style={{fontSize: 16, lineHeight: "22px", color: "#8786A7", fontWeight:600}}>{t("hardCap")}</Typography>
                            <Typography className={classes.font}  style={{fontSize: 16, lineHeight: "22px", color: "#8786A7", fontWeight:600}}>2,500,000 USDT</Typography>
                        </div>
                        <div style={{marginTop:8, display: 'flex', justifyContent:"space-between"}}>
                            <Typography className={classes.font}  style={{fontSize: 16, lineHeight: "22px", color: "#8786A7", fontWeight:600}}>{t("softCap")}</Typography>
                            <Typography className={classes.font}  style={{fontSize: 16, lineHeight: "22px", color: "#8786A7", fontWeight:600}}>1,750,000 USDT</Typography>
                        </div>
                        <div style={{marginTop:8, display: 'flex', justifyContent:"space-between"}}>
                            <Typography className={classes.font}  style={{fontSize: 16, lineHeight: "22px", color: "#8786A7", fontWeight:600}}>{t("liquidityPercentage")}</Typography>
                            <Typography className={classes.font}  style={{fontSize: 16, lineHeight: "22px", color: "#8786A7", fontWeight:600}}>80%</Typography>
                        </div>
                        <div style={{marginTop:8, display: 'flex', justifyContent:"space-between"}}>
                            <Typography className={classes.font}  style={{fontSize: 16, lineHeight: "22px", color: "#8786A7", fontWeight:600}}>{t("minBuy")}</Typography>
                            <Typography className={classes.font}  style={{fontSize: 16, lineHeight: "22px", color: "#8786A7", fontWeight:600}}>100 USDT</Typography>
                        </div>
                        <div style={{marginTop:8, display: 'flex', justifyContent:"space-between"}}>
                            <Typography className={classes.font}  style={{fontSize: 16, lineHeight: "22px", color: "#8786A7", fontWeight:600}}>{t("maxBuy")}</Typography>
                            <Typography className={classes.font}  style={{fontSize: 16, lineHeight: "22px", color: "#8786A7", fontWeight:600}}>5000 USDT</Typography>
                        </div>
                    </div>
                </div>

                <div style={{height: 447, width: 438, backgroundColor: "white"}}>
                    <div style={{margin:"45px 30px"}}>
                        {isValidAddress(address) && loggedIn ?
                            <div>
                                <Typography className={classes.font} style={{fontSize:14, fontWeight:600, color:"#8786A7"}}>{t("myContribution")}</Typography>
                                <Typography className={classes.font} style={{marginTop:4,fontSize:24, fontWeight:"bold", fontFamily:"Syncopate" }}>
                                    {idoInvestmentInfo.usdtInvestment ? idoInvestmentInfo.usdtInvestment : 0} <span style={{display:"inline", fontWeight:"bold", fontSize:14, fontFamily:"Syncopate"}}>USDT</span>
                                </Typography>
                                <Typography className={classes.font} style={{fontSize:14, fontWeight:600}}>
                                    ${idoInvestmentInfo.usdtInvestment ? idoInvestmentInfo.usdtInvestment : 0}
                                </Typography>

                                <Typography className={classes.font} style={{marginTop:20, fontSize:14, fontWeight:600, color:"#8786A7"}}>{t("myLp")}</Typography>
                                <Typography className={classes.font} style={{marginTop:4,fontSize:24, fontWeight:"bold", fontFamily:"Syncopate" }}>
                                    {idoInvestmentInfo.totalLpReturn ? roundingDown(idoInvestmentInfo.totalLpReturn,4) : 0} <span style={{display:"inline", fontWeight:"bold", fontSize:14, fontFamily:"Syncopate"}}>mDBO</span>
                                </Typography>
                                <Typography className={classes.font} style={{fontSize:14, fontWeight:600}}>
                                    ${idoInvestmentInfo.totalLpReturn && idoInvestmentInfo.dboUsdtLpPrice? roundingDown(idoInvestmentInfo.totalLpReturn * idoInvestmentInfo.dboUsdtLpPrice,2) :0}
                                </Typography>

                                <Typography className={classes.font} style={{marginTop:20, fontSize:14, fontWeight:600, color:"#8786A7"}}>{t("released")} DBO</Typography>
                                <Typography className={classes.font} style={{marginTop:4,fontSize:24, fontWeight:"bold", fontFamily:"Syncopate" }}>
                                    {idoInvestmentInfo.dboReleased ? roundingDown(idoInvestmentInfo.dboReleased,4) : 0} <span style={{display:"inline", fontWeight:"bold", fontSize:14, fontFamily:"Syncopate"}}>DBO</span>
                                </Typography>
                                <Typography className={classes.font} style={{fontSize:14, fontWeight:600}}>
                                    ${idoInvestmentInfo.dboReleased && idoInvestmentInfo.dboPrice ? roundingDown(idoInvestmentInfo.dboReleased * idoInvestmentInfo.dboPrice,2) : 0}
                                </Typography>

                                <Button
                                    className={classes.font} style={{opacity:idoStart && !getInviteInfoLoading?1:0.5, marginTop:20, height:58, borderRadius: 13, backgroundColor: "#271CFF", width:"100%", color:"white", fontSize:18, fontWeight:600, textTransform:"none"}}
                                    disabled={!idoStart || getInviteInfoLoading}
                                    onClick={()=>{
                                        if(inviteInfo.uid < 0){
                                            setReferralReminderVisible(true)
                                        }else{
                                            setBuyVisible(true)
                                            setReferralReminderVisible(false)
                                        }
                                    }}
                                >
                                    {t("buy")}
                                </Button>
                            </div>
                            :
                            <div style={{marginTop:120, marginBottom:i18n.language === 'en' ? 76:176}}>
                                <Typography className={classes.font} style={{fontSize:14, fontWeight:600}}>
                                    {address.length < 42 || !isValidAddress(address) ?
                                        !window.ethereum ?
                                            t("installMeta")
                                            :t("connectMeta")
                                        :t("unlockMeta")
                                    }
                                </Typography>
                                <Typography className={classes.font} style={{marginTop:9, fontSize:14, color:"#8786A7", whiteSpace: 'pre-line'}}>
                                    {address.length < 42 || !isValidAddress(address) ?
                                        !window.ethereum ?
                                            t("installMetaSubtitle")
                                            :t("connectMetaSubtitle")
                                        :t("unlockMetaSubtitle")
                                    }
                                </Typography>
                            </div>

                        }
                        {renderConnectMetamask()}
                    </div>
                </div>

            </div>
        )
    }

    const renderIDOPhone = ()=>{
        return(
            <div style={{display: 'flex', width:"100%", flexDirection:"column", marginTop:60, marginBottom:40, alignItems:"center"}}>
                <div style={{ display:"flex", flexDirection:"column", width:"90%", maxWidth: 396, backgroundColor: "rgb(5, 0, 107,0.04)",borderRadius: 20}}>
                    <div style={{backgroundColor: "white",borderRadius: 20}}>
                        <div style={{margin:"43px 30px 30px"}}>
                            <div style={{display: 'flex', justifyContent:"space-between", alignItems: "center"}}>
                                <div>
                                    <Typography style={{fontFamily:"Syncopate", fontSize:24, fontWeight:"bold"}}>DBO</Typography>
                                    <Typography className={classes.font} style={{fontSize:14, fontWeight:500, color: "#8786A7"}}>{t("tokenForPresale")}：15,000,000 DBO</Typography>
                                </div>
                                <img src={DBOIcon} style={{width:70, height:70}}/>
                            </div>
                            <Typography className={classes.font} style={{marginTop:30, fontSize:14, fontWeight:600}}>{t("presaleProgress")}  {idoInfo.usdtRaised ? roundingDown(idoInfo.usdtRaised/2500000 *100,2) :0}%</Typography>
                            <LinearProgress classes={{barColorPrimary: classes.forBarColorPrimary}} variant="determinate" value={idoInfo.usdtRaised ? idoInfo.usdtRaised/2500000 *100 :0} style={{height:10, marginTop:4, marginBottom:4, borderRadius: 5, backgroundColor: '#F6D1CA'}}/>
                            <div style={{display: 'flex', justifyContent:"space-between"}}>
                                <Typography className={classes.font}  style={{fontSize: 14, color: "#8786A7"}}>{idoInfo.usdtRaised ? FormatNumber(idoInfo.usdtRaised) :0} USDT</Typography>
                                <Typography className={classes.font}  style={{fontSize: 14, color: "#8786A7"}}>2,500,000 USDT</Typography>
                            </div>
                            <div style={{marginTop:30, display: 'flex', justifyContent:"space-between"}}>
                                <Typography className={classes.font}  style={{fontSize: 16, lineHeight: "22px", color: "#8786A7", fontWeight:600}}>{t("price")}</Typography>
                                <Typography className={classes.font}  style={{fontSize: 16, lineHeight: "22px", color: "#8786A7", fontWeight:600}}>1 DBO = 0.1 USDT</Typography>
                            </div>
                            <div style={{marginTop:8, display: 'flex', justifyContent:"space-between"}}>
                                <Typography className={classes.font}  style={{fontSize: 16, lineHeight: "22px", color: "#8786A7", fontWeight:600}}>{t("hardCap")}</Typography>
                                <Typography className={classes.font}  style={{fontSize: 16, lineHeight: "22px", color: "#8786A7", fontWeight:600}}>2,500,000 USDT</Typography>
                            </div>
                            <div style={{marginTop:8, display: 'flex', justifyContent:"space-between"}}>
                                <Typography className={classes.font}  style={{fontSize: 16, lineHeight: "22px", color: "#8786A7", fontWeight:600}}>{t("softCap")}</Typography>
                                <Typography className={classes.font}  style={{fontSize: 16, lineHeight: "22px", color: "#8786A7", fontWeight:600}}>1,750,000 USDT</Typography>
                            </div>
                            <div style={{marginTop:8, display: 'flex', justifyContent:"space-between"}}>
                                <Typography className={classes.font}  style={{fontSize: 16, lineHeight: "22px", color: "#8786A7", fontWeight:600}}>{t("liquidityPercentage")}</Typography>
                                <Typography className={classes.font}  style={{fontSize: 16, lineHeight: "22px", color: "#8786A7", fontWeight:600}}>80%</Typography>
                            </div>
                            <div style={{marginTop:8, display: 'flex', justifyContent:"space-between"}}>
                                <Typography className={classes.font}  style={{fontSize: 16, lineHeight: "22px", color: "#8786A7", fontWeight:600}}>{t("minBuy")}</Typography>
                                <Typography className={classes.font}  style={{fontSize: 16, lineHeight: "22px", color: "#8786A7", fontWeight:600}}>100 USDT</Typography>
                            </div>
                            <div style={{marginTop:8, marginBottom:30, display: 'flex', justifyContent:"space-between"}}>
                                <Typography className={classes.font}  style={{fontSize: 16, lineHeight: "22px", color: "#8786A7", fontWeight:600}}>{t("maxBuy")}</Typography>
                                <Typography className={classes.font}  style={{fontSize: 16, lineHeight: "22px", color: "#8786A7", fontWeight:600}}>5000 USDT</Typography>
                            </div>
                            {renderConnectMetamask()}
                            {isValidAddress(address) && loggedIn ?
                                <Button
                                    className={classes.font}
                                    style={{ opacity:idoStart && !getInviteInfoLoading?1:0.5, height:58, borderRadius: 13, backgroundColor: "#271CFF", width:"100%", color:"white", fontSize:18, fontWeight:600, textTransform:"none"}}
                                    disabled={!idoStart || getInviteInfoLoading}
                                    onClick={()=>{
                                        if(inviteInfo.uid < 0){
                                            setReferralReminderVisible(true)
                                        }else{
                                            setBuyVisible(true)
                                            setReferralReminderVisible(false)
                                        }
                                    }}
                                >
                                    {t("buy")}
                                </Button>
                                :null
                            }
                        </div>
                    </div>
                    {
                        myBought ?
                            <div style={{margin:"30px 30px 20px"}}>
                                <Typography className={classes.font} style={{fontSize:14, fontWeight:600, color:"#8786A7"}}>{t("myContribution")}</Typography>
                                <Typography className={classes.font} style={{marginTop:4,fontSize:24, fontWeight:"bold", fontFamily:"Syncopate" }}>
                                    {idoInvestmentInfo.usdtInvestment ? idoInvestmentInfo.usdtInvestment : 0} <span style={{display:"inline", fontWeight:"bold", fontSize:14, fontFamily:"Syncopate"}}>USDT</span>
                                </Typography>
                                <Typography className={classes.font} style={{fontSize:14, fontWeight:600}}>
                                    ${idoInvestmentInfo.usdtInvestment ? idoInvestmentInfo.usdtInvestment : 0}
                                </Typography>

                                <Typography className={classes.font} style={{marginTop:20, fontSize:14, fontWeight:600, color:"#8786A7"}}>{t("myLp")}</Typography>
                                <Typography className={classes.font} style={{marginTop:4,fontSize:24, fontWeight:"bold", fontFamily:"Syncopate" }}>
                                    {idoInvestmentInfo.totalLpReturn ? roundingDown(idoInvestmentInfo.totalLpReturn,4) : 0} <span style={{display:"inline", fontWeight:"bold", fontSize:14, fontFamily:"Syncopate"}}>mDBO</span>
                                </Typography>
                                <Typography className={classes.font} style={{fontSize:14, fontWeight:600}}>
                                    ${idoInvestmentInfo.totalLpReturn && idoInvestmentInfo.dboUsdtLpPrice? roundingDown(idoInvestmentInfo.totalLpReturn * idoInvestmentInfo.dboUsdtLpPrice,2) :0}
                                </Typography>

                                <Typography className={classes.font} style={{marginTop:20, fontSize:14, fontWeight:600, color:"#8786A7"}}>{t("released")} DBO</Typography>
                                <Typography className={classes.font} style={{marginTop:4,fontSize:24, fontWeight:"bold", fontFamily:"Syncopate" }}>
                                    {idoInvestmentInfo.dboReleased ? roundingDown(idoInvestmentInfo.dboReleased,4) : 0} <span style={{display:"inline", fontWeight:"bold", fontSize:14, fontFamily:"Syncopate"}}>DBO</span>
                                </Typography>
                                <Typography className={classes.font} style={{fontSize:14, fontWeight:600}}>
                                    ${idoInvestmentInfo.dboReleased && idoInvestmentInfo.dboPrice ? roundingDown(idoInvestmentInfo.dboReleased * idoInvestmentInfo.dboPrice,2) : 0}
                                </Typography>
                            </div>
                            :null
                    }

                    <Button
                        className={classes.font}
                        style={{marginTop:10, marginBottom:10, color:"#251CFF", fontSize:16, fontWeight:600, textTransform:"none", opacity:isValidAddress(address) && loggedIn ? 1:0.5}}
                        onClick={()=>{setMyBought(!myBought)}}
                        disabled={!(isValidAddress(address) && loggedIn) }
                    >
                        {t("myPresale")} <img src={myBought ? upArrow : downArrow} style={{marginLeft:6, height:11, width:19}}/>
                    </Button>
                </div>
            </div>
        )
    }

    const renderMigration = ()=>{
        return (
            <>
                <Typography className={classes.font} style={{marginTop:60, color:"#271CFF", fontSize:22, fontWeight:600}}>{t("migration")}</Typography>
                <Typography className={classes.font} style={{marginTop:8, color:"#8786A7", fontSize:14}}>{t("migrationSubtitle")}</Typography>
                <div style={{display: 'flex', width:"100%", justifyContent:"space-between", marginTop:30}}>

                    <div style={{height: 344, width: 438, backgroundColor: "#EFEFFF"}}>
                        <div style={{margin:"45px 30px"}}>
                            <div style={{display: 'flex', justifyContent:"space-between", alignItems: "center"}}>
                                <div>
                                    <Typography style={{fontFamily:"Syncopate", fontSize:24, fontWeight:"bold"}}>SAP</Typography>
                                    <Typography className={classes.font} style={{fontSize:14, fontWeight:500, color: "#8786A7"}}>{t("tokenForMigration")}：6,100,000 DBO</Typography>
                                    <Typography className={classes.font} style={{fontSize:14, fontWeight:500, color: "#8786A7"}}>{t("migrationPrice")}：3 SAP = 1 DBO</Typography>
                                </div>
                                <img src={SAPIcon} style={{width:70, height:70}}/>
                            </div>
                            <Typography className={classes.font} style={{marginTop:30, fontSize:14, fontWeight:600}}>{t("migrationProgress")} {idoInfo.sapMigrated ? roundingDown(idoInfo.sapMigrated / 18300000*100,2) :0}%</Typography>
                            <LinearProgress
                                classes={{barColorPrimary: classes.forMigrationBarColor}}
                                variant="determinate" value={idoInfo.sapMigrated ? idoInfo.sapMigrated / 18300000*100 :0}
                                style={{ height:10, marginTop:4, marginBottom:4, borderRadius: 5, backgroundColor: '#D1D6FF'}}
                            />
                            <div style={{marginBottom:30, display: 'flex', justifyContent:"space-between"}}>
                                <Typography className={classes.font}  style={{fontSize: 14, color: "#8786A7"}}>{idoInfo.sapMigrated ? FormatNumber(roundingDown(idoInfo.sapMigrated,4)) :0} SAP</Typography>
                                <Typography className={classes.font}  style={{fontSize: 14, color: "#8786A7"}}>18,300,000 SAP</Typography>
                            </div>
                            {renderConnectMetamask()}
                            {isValidAddress(address) && loggedIn ?
                                <Button
                                    className={classes.font} style={{opacity:idoStart?1:0.5, height:58, borderRadius: 13, backgroundColor: "#271CFF", width:"100%", color:"white", fontSize:18, fontWeight:600, textTransform:"none"}}
                                    disabled={!idoStart}
                                    onClick={()=>{setMigrateVisible(true)}}
                                >
                                    {t("migrate")}
                                </Button>
                                :null
                            }
                        </div>
                    </div>

                    {
                        isValidAddress(address) && loggedIn ?
                            <div style={{height: 344, width: 438, backgroundColor: "#F5F5F5"}}>
                                <div style={{margin:"35px 30px"}}>
                                    <Typography className={classes.font} style={{fontSize:14, fontWeight:600, color:"#8786A7"}}>{t("migrated")}</Typography>
                                    <Typography className={classes.font} style={{marginTop:8,fontSize:24, fontWeight:"bold", fontFamily:"Syncopate", lineHeight:"24px" }}>
                                        {idoMigrationInfo.sapMigrated ? roundingDown(idoMigrationInfo.sapMigrated,4):0} <span style={{display:"inline", fontWeight:"bold", fontSize:14, fontFamily:"Syncopate"}}>SAP/{idoMigrationInfo.totalDbo?roundingDown(idoMigrationInfo.totalDbo,4):0} DBO</span>
                                    </Typography>
                                    <Typography className={classes.font} style={{fontSize:14, fontWeight:600}}>
                                        ${idoMigrationInfo.totalDbo && idoMigrationInfo.dboPrice?roundingDown(idoMigrationInfo.totalDbo*idoMigrationInfo.dboPrice,2) :0}
                                    </Typography>

                                    <Typography className={classes.font} style={{marginTop:20, fontSize:14, fontWeight:600, color:"#8786A7"}}>{t("vesting")} DBO</Typography>
                                    <Typography className={classes.font} style={{marginTop:8,fontSize:24, fontWeight:"bold", fontFamily:"Syncopate", lineHeight:"24px" }}>
                                        {idoMigrationInfo.dboLocked ? roundingDown(idoMigrationInfo.dboLocked,4):0} <span style={{display:"inline", fontWeight:"bold", fontSize:14, fontFamily:"Syncopate"}}>DBO</span>
                                    </Typography>
                                    <Typography className={classes.font} style={{fontSize:14, fontWeight:600}}>
                                        ${idoMigrationInfo.dboLocked && idoMigrationInfo.dboPrice? roundingDown(idoMigrationInfo.dboLocked*idoMigrationInfo.dboPrice,2):0}
                                    </Typography>

                                    <Typography className={classes.font} style={{marginTop:20, fontSize:14, fontWeight:600, color:"#8786A7"}}>{t("released")} DBO</Typography>
                                    <Typography className={classes.font} style={{marginTop:8,fontSize:24, fontWeight:"bold", fontFamily:"Syncopate", lineHeight:"24px" }}>
                                        {idoMigrationInfo.dboReleased ? roundingDown(idoMigrationInfo.dboReleased,4):0} <span style={{display:"inline", fontWeight:"bold", fontSize:14, fontFamily:"Syncopate"}}>DBO</span>
                                    </Typography>
                                    <Typography className={classes.font} style={{fontSize:14, fontWeight:600}}>
                                        ${idoMigrationInfo.dboReleased && idoMigrationInfo.dboPrice? roundingDown(idoMigrationInfo.dboReleased*idoMigrationInfo.dboPrice,2):0}
                                    </Typography>
                                </div>
                            </div>
                            :null
                    }

                </div>
            </>
        )
    }

    const renderMigrationPhone = ()=>{
        return (
            <div style={{display: 'flex', width:"100%", flexDirection:"column", marginTop:60, alignItems:"center"}}>
                <div style={{ display:"flex", flexDirection:"column", width:"90%", maxWidth: 396}}>
                    <Typography className={classes.font} style={{color:"#271CFF", fontSize:22, fontWeight:600}}>{t("migration")}</Typography>
                    <Typography className={classes.font} style={{marginTop:8, color:"#8786A7", fontSize:14}}>{t("migrationSubtitle")}</Typography>
                </div>
                <div style={{ marginTop:30, display:"flex", flexDirection:"column", width:"90%", maxWidth: 396, backgroundColor: "rgb(5, 0, 107,0.04)",borderRadius: 20}}>
                    <div style={{backgroundColor: "#EFEFFF",borderRadius: 20}}>
                        <div style={{margin:"43px 30px 30px"}}>
                            <div style={{display: 'flex', justifyContent:"space-between", alignItems: "center"}}>
                                <div>
                                    <Typography style={{fontFamily:"Syncopate", fontSize:24, fontWeight:"bold"}}>SAP</Typography>
                                    <Typography className={classes.font} style={{fontSize:14, fontWeight:500, color: "#8786A7"}}>{t("tokenForMigration")}：6,100,000 DBO</Typography>
                                    <Typography className={classes.font} style={{fontSize:14, fontWeight:500, color: "#8786A7"}}>{t("migrationPrice")}：3 SAP = 1 DBO</Typography>
                                </div>
                                <img src={SAPIcon} style={{width:70, height:70}}/>
                            </div>
                            <Typography className={classes.font} style={{marginTop:30, fontSize:14, fontWeight:600}}>{t("migrationProgress")} {idoInfo.sapMigrated ? roundingDown(idoInfo.sapMigrated / 18300000*100,2) :0}%</Typography>
                            <LinearProgress
                                classes={{barColorPrimary: classes.forMigrationBarColor}}
                                variant="determinate" value={idoInfo.sapMigrated ? idoInfo.sapMigrated / 18300000*100 :0}
                                style={{ height:10, marginTop:4, marginBottom:4, borderRadius: 5, backgroundColor: '#D1D6FF'}}
                            />
                            <div style={{marginBottom:30, display: 'flex', justifyContent:"space-between"}}>
                                <Typography className={classes.font}  style={{fontSize: 14, color: "#8786A7"}}>{idoInfo.sapMigrated ? FormatNumber(roundingDown(idoInfo.sapMigrated,4)) :0} SAP</Typography>
                                <Typography className={classes.font}  style={{fontSize: 14, color: "#8786A7"}}>18,300,000 SAP</Typography>
                            </div>
                            {renderConnectMetamask()}
                            {isValidAddress(address) && loggedIn ?
                                <Button
                                    className={classes.font} style={{opacity:idoStart?1:0.5, height:58, borderRadius: 13, backgroundColor: "#271CFF", width:"100%", color:"white", fontSize:18, fontWeight:600, textTransform:"none"}}
                                    disabled={!idoStart}
                                    onClick={()=>{setMigrateVisible(true)}}
                                >
                                    {t("migrate")}
                                </Button>
                                :null
                            }
                        </div>
                    </div>
                    {
                        myMigration ?
                            <div style={{margin:"30px 30px 20px"}}>
                                <Typography className={classes.font} style={{fontSize:14, fontWeight:600, color:"#8786A7"}}>{t("migrated")}</Typography>
                                <Typography className={classes.font} style={{marginTop:8,fontSize:24, fontWeight:"bold", fontFamily:"Syncopate", lineHeight:"24px" }}>
                                    {idoMigrationInfo.sapMigrated ? roundingDown(idoMigrationInfo.sapMigrated,4):0} <span style={{display:"inline", fontWeight:"bold", fontSize:14, fontFamily:"Syncopate"}}>SAP/{idoMigrationInfo.totalDbo?roundingDown(idoMigrationInfo.totalDbo,4):0} DBO</span>
                                </Typography>
                                <Typography className={classes.font} style={{fontSize:14, fontWeight:600}}>
                                    ${idoMigrationInfo.totalDbo && idoMigrationInfo.dboPrice?roundingDown(idoMigrationInfo.totalDbo*idoMigrationInfo.dboPrice,2) :0}
                                </Typography>

                                <Typography className={classes.font} style={{marginTop:20, fontSize:14, fontWeight:600, color:"#8786A7"}}>{t("vesting")} DBO</Typography>
                                <Typography className={classes.font} style={{marginTop:8,fontSize:24, fontWeight:"bold", fontFamily:"Syncopate", lineHeight:"24px" }}>
                                    {idoMigrationInfo.dboLocked ? roundingDown(idoMigrationInfo.dboLocked,4):0} <span style={{display:"inline", fontWeight:"bold", fontSize:14, fontFamily:"Syncopate"}}>DBO</span>
                                </Typography>
                                <Typography className={classes.font} style={{fontSize:14, fontWeight:600}}>
                                    ${idoMigrationInfo.dboLocked && idoMigrationInfo.dboPrice? roundingDown(idoMigrationInfo.dboLocked*idoMigrationInfo.dboPrice,2):0}
                                </Typography>

                                <Typography className={classes.font} style={{marginTop:20, fontSize:14, fontWeight:600, color:"#8786A7"}}>{t("released")} DBO</Typography>
                                <Typography className={classes.font} style={{marginTop:8,fontSize:24, fontWeight:"bold", fontFamily:"Syncopate", lineHeight:"24px" }}>
                                    {idoMigrationInfo.dboReleased ? roundingDown(idoMigrationInfo.dboReleased,4):0} <span style={{display:"inline", fontWeight:"bold", fontSize:14, fontFamily:"Syncopate"}}>DBO</span>
                                </Typography>
                                <Typography className={classes.font} style={{fontSize:14, fontWeight:600}}>
                                    ${idoMigrationInfo.dboReleased && idoMigrationInfo.dboPrice? roundingDown(idoMigrationInfo.dboReleased*idoMigrationInfo.dboPrice,2):0}
                                </Typography>
                            </div>
                            :null
                    }

                    <Button
                        className={classes.font}
                        style={{marginTop:10, marginBottom:10, color:"#251CFF", fontSize:16, fontWeight:600, textTransform:"none", opacity:isValidAddress(address) && loggedIn?1:0.5}}
                        onClick={()=>{setMyMigration(!myMigration)}}
                        disabled={!(isValidAddress(address) && loggedIn) }
                    >
                        {t("myMigration")} <img src={myMigration ? upArrow : downArrow } style={{marginLeft:6, height:11, width:19}}/>
                    </Button>
                </div>
            </div>
        )
    }

    const renderReferral=()=>{
        return (
            <>
                <Typography className={classes.font} style={{marginTop:60, color:"#271CFF", fontSize:22, fontWeight:600}}>{t("referral")}</Typography>
                <Typography className={classes.font} style={{marginTop:8, color:"#8786A7", fontSize:14}}>{t('referralSubtitle')}</Typography>
                <div style={{display: 'flex', width:"100%", justifyContent:"space-between", marginTop:30, marginBottom:60}}>

                    <div style={{height: 369, width: 438, backgroundColor: "#F5F5F5"}}>
                        <div style={{margin:"30px"}}>
                            <div style={{display: 'flex'}}>
                                <img src={tier1Icon} style={{height:47, width:46}}/>
                                <div style={{marginLeft:20}}>
                                    <Typography style={{fontFamily: "Syncopate", fontSize: 16, fontWeight: "bold"}}>
                                        {t('tier1')}
                                        <span className={classes.font} style={{marginLeft:16, display:"inline", fontSize:14, fontWeight:600}}>
                                            100-999 USDT
                                        </span>
                                    </Typography>
                                    <Typography className={classes.font} style={{color:"#8786A7", fontSize:14}}>
                                        {t('tier1Subtitle')}
                                    </Typography>
                                </div>
                            </div>

                            <div style={{display: 'flex', marginTop:30}}>
                                <img src={tier2Icon} style={{height:47, width:46}}/>
                                <div style={{marginLeft:20}}>
                                    <Typography style={{fontFamily: "Syncopate", fontSize: 16, fontWeight: "bold"}}>
                                        {t('tier2')}
                                        <span className={classes.font} style={{marginLeft:16, display:"inline", fontSize:14, fontWeight:600}}>
                                            1000-1999 USDT
                                        </span>
                                    </Typography>
                                    <Typography className={classes.font} style={{color:"#8786A7", fontSize:14}}>
                                        {t('tier2Subtitle')}
                                    </Typography>
                                </div>
                            </div>

                            <div style={{display: 'flex', marginTop:30}}>
                                <img src={tier3Icon} style={{height:47, width:46}}/>
                                <div style={{marginLeft:20}}>
                                    <Typography style={{fontFamily: "Syncopate", fontSize: 16, fontWeight: "bold"}}>
                                        {t('tier3')}
                                        <span className={classes.font} style={{marginLeft:16, display:"inline", fontSize:14, fontWeight:600}}>
                                            2000-5000 USDT
                                        </span>
                                    </Typography>
                                    <Typography className={classes.font} style={{color:"#8786A7", fontSize:14}}>
                                        {t('tier3Subtitle')}
                                    </Typography>
                                </div>
                            </div>

                            <Button
                                className={classes.font}
                                style={{marginTop:i18n.language === 'en'?50:30, height:58, borderRadius: 13, backgroundColor: "#271CFF", width:"100%", color:"white", fontSize:18, fontWeight:600, textTransform:"none"}}
                                onClick={()=>{history.push('/ido/invite')}}
                            >
                                {t('referFriends')}
                            </Button>
                        </div>
                    </div>

                    {
                        isValidAddress(address) && loggedIn ?
                            <div style={{height: 369, width: 438, background: "linear-gradient(225.39deg, #4A3FD5 0%, #251CFF 100%)"}}>
                                <div style={{margin:"70px 30px 50px"}}>
                                    <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                                        <div>
                                            <Typography className={classes.font} style={{fontSize:14, color:"white", fontWeight:600}}>
                                                {userInfo.idoLevel === 1 ?
                                                    t('tier1')
                                                    :userInfo.idoLevel === 2?
                                                        t('tier2')
                                                        :userInfo.idoLevel === 3?
                                                            t('tier3')
                                                    :null
                                                }
                                            </Typography>
                                            <Typography className={classes.font} style={{marginTop:16, color:"white", fontSize:40, fontWeight:600}}>
                                                {userInfo.idoInvestment ? userInfo.idoInvestment :0}
                                            </Typography>
                                        </div>
                                        <img src={userInfo.idoLevel === 3 ? tier3Icon : userInfo.idoLevel ===2 ? tier2Icon : userInfo.idoLevel? tier1Icon: tier0Icon} style={{height:76, width:74}}/>
                                    </div>
                                    <Typography className={classes.font} style={{fontSize:14, color:"white", fontWeight:600}}>
                                        {t('myPresaleContribution')}
                                    </Typography>
                                    <div style={{position:"relative"}}>
                                        <LinearProgress
                                            classes={{barColorPrimary: classes.forReferralBarColor}}
                                            variant="determinate" value={userInfo.idoInvestment ? userInfo.idoInvestment/5000*100 :0}
                                            style={{ height:6, marginTop:10, marginBottom:8, borderRadius: 5}}
                                        />
                                        <div style={{position:"absolute", borderRadius: "50%", backgroundColor: "#251CFF", width:6, height:6, left:"2%", bottom:0, marginLeft:-3}}/>
                                        <div style={{position:"absolute", borderRadius: "50%", backgroundColor: "#251CFF", width:6, height:6, left:"20%", bottom:0, marginLeft:-3}}/>
                                        <div style={{position:"absolute", borderRadius: "50%", backgroundColor: "#251CFF", width:6, height:6, left:"40%", bottom:0, marginLeft:-3}}/>
                                    </div>
                                    <div style={{position:"relative"}}>
                                        <Typography className={classes.font} style={{marginLeft:-2.5, color:"white", opacity: 0.7, fontSize:14}}>100</Typography>
                                        <Typography className={classes.font} style={{position:"absolute", left:"20%", bottom:0, color:"white", marginLeft:-15, opacity: 0.7, fontSize:14}}>1000</Typography>
                                        <Typography className={classes.font} style={{position:"absolute", left:"40%", bottom:0, color:"white", marginLeft:-17, opacity: 0.7, fontSize:14}}>2000</Typography>
                                        <Typography className={classes.font} style={{position:"absolute", right:0, bottom:0, color:"white", opacity: 0.7, fontSize:14}}>5000 USDT</Typography>
                                    </div>
                                    {
                                        userInfo.idoLevel === 3?
                                            null
                                            :<div style={{display:"flex", justifyContent:"space-between", marginTop:67}}>
                                                <Typography className={classes.font} style={{fontSize:14, fontWeight:600, color:"white" }}>
                                                    {t('upgradeBuy')} {userInfo.idoLevel != null?
                                                    userInfo.idoLevel === 1 ?
                                                        1000-userInfo.idoInvestment
                                                        :userInfo.idoLevel === 2?
                                                        2000-userInfo.idoInvestment
                                                        :100
                                                    :0} USDT {t('upgradeTo')} {userInfo.idoLevel ? userInfo.idoLevel === 1 ? t('tier2') :userInfo.idoLevel === 2? t('tier3') :t('tier1') :t('tier1')}
                                                </Typography>
                                                <Typography
                                                    className={classes.font}
                                                    style={{fontSize:14, fontWeight:600, color:"#FAD301", cursor:idoStart && !getInviteInfoLoading ? "pointer":null, opacity: idoStart && !getInviteInfoLoading ? 1 :0.5 }}
                                                    onClick={()=>{
                                                        if(idoStart && !getInviteInfoLoading){
                                                            if(inviteInfo.uid < 0){
                                                                setReferralReminderVisible(true)
                                                            }else{
                                                                setBuyVisible(true)
                                                            }
                                                        }
                                                    }}
                                                >{t('upgradeNow')}</Typography>
                                            </div>
                                    }

                                </div>
                            </div>
                            :null
                    }
                </div>
            </>
        )
    }

    const renderReferralPhone =()=>{
        return (
            <div style={{display: 'flex', width:"100%", flexDirection:"column", marginTop:60, alignItems:"center"}}>
                <div style={{ display:"flex", flexDirection:"column", width:"90%", maxWidth: 396}}>
                    <Typography className={classes.font} style={{color:"#271CFF", fontSize:22, fontWeight:600}}>{t("referral")}</Typography>
                </div>

                <div style={{ display:"flex", flexDirection:"column", width:"90%", maxWidth: 396}}>
                    {isValidAddress(address) && loggedIn ?
                        <div style={{marginTop:30, borderRadius: 20, background: "linear-gradient(225.39deg, #4A3FD5 0%, #251CFF 100%)"}}>
                            <div style={{margin:"30px"}}>
                                <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                                    <div>
                                        <Typography className={classes.font} style={{fontSize:14, color:"white", fontWeight:600}}>
                                            {userInfo.idoLevel === 1 ?
                                                t('tier1')
                                                :userInfo.idoLevel === 2?
                                                    t('tier2')
                                                    :userInfo.idoLevel === 3?
                                                        t('tier3')
                                                        :null
                                            }
                                        </Typography>
                                        <Typography className={classes.font} style={{marginTop:16, color:"white", fontSize:40, fontWeight:600}}>
                                            {userInfo.idoInvestment ? userInfo.idoInvestment :0}
                                        </Typography>
                                    </div>
                                    <img src={userInfo.idoLevel === 3 ? tier3Icon : userInfo.idoLevel ===2 ? tier2Icon : userInfo.idoLevel? tier1Icon:tier0Icon} style={{height:76, width:74}}/>
                                </div>
                                <Typography className={classes.font} style={{fontSize:14, color:"white", fontWeight:600}}>
                                    {t('myPresaleContribution')}
                                </Typography>
                                <div style={{position:"relative"}}>
                                    <LinearProgress
                                        classes={{barColorPrimary: classes.forReferralBarColor}}
                                        variant="determinate" value={userInfo.idoInvestment ? userInfo.idoInvestment/5000 * 100 :0}
                                        style={{ height:6, marginTop:10, marginBottom:8, borderRadius: 5}}
                                    />
                                    <div style={{position:"absolute", borderRadius: "50%", backgroundColor: "#251CFF", width:6, height:6, left:"2%", bottom:0, marginLeft:-3}}/>
                                    <div style={{position:"absolute", borderRadius: "50%", backgroundColor: "#251CFF", width:6, height:6, left:"20%", bottom:0, marginLeft:-3}}/>
                                    <div style={{position:"absolute", borderRadius: "50%", backgroundColor: "#251CFF", width:6, height:6, left:"40%", bottom:0, marginLeft:-3}}/>
                                </div>
                                <div style={{position:"relative"}}>
                                    <Typography className={classes.font} style={{marginLeft:-3, color:"white", opacity: 0.7, fontSize:14}}>100</Typography>
                                    <Typography className={classes.font} style={{position:"absolute", left:"20%", bottom:0, color:"white", marginLeft:-15, opacity: 0.7, fontSize:14}}>1000</Typography>
                                    <Typography className={classes.font} style={{position:"absolute", left:"40%", bottom:0, color:"white", marginLeft:-17, opacity: 0.7, fontSize:14}}>2000</Typography>
                                    <Typography className={classes.font} style={{position:"absolute", right:0, bottom:0, color:"white", opacity: 0.7, fontSize:14}}>5000 USDT</Typography>
                                </div>

                                {
                                    userInfo.idoLevel === 3?
                                        null
                                        :<div style={{display:"flex", justifyContent:"space-between", marginTop:20}}>
                                            <Typography className={classes.font} style={{fontSize:14, fontWeight:600, color:"white" }}>
                                                {t('upgradeBuy')} {userInfo.idoLevel != null ?
                                                userInfo.idoLevel === 1 ?
                                                    1000-userInfo.idoInvestment
                                                    :userInfo.idoLevel === 2?
                                                    2000-userInfo.idoInvestment
                                                    :100
                                                :0} USDT {t('upgradeTo')} {userInfo.idoLevel ? userInfo.idoLevel === 1 ? t('tier2') :userInfo.idoLevel === 2? t('tier3') :t('tier1') :t('tier1')}
                                            </Typography>
                                            <Typography
                                                className={classes.font}
                                                style={{fontSize:14, fontWeight:600, color:"#FAD301", cursor:idoStart && !getInviteInfoLoading ? "pointer":null, opacity: idoStart && !getInviteInfoLoading ?1:0.5 }}
                                                onClick={()=>{
                                                    if(idoStart && !getInviteInfoLoading){
                                                        if(inviteInfo.uid < 0){
                                                            setReferralReminderVisible(true)
                                                        }else{
                                                            setBuyVisible(true)
                                                        }
                                                    }
                                                }}
                                            >
                                                {t('upgradeNow')}
                                            </Typography>
                                        </div>
                                }


                            </div>
                        </div>
                        :null
                    }

                    <Typography className={classes.font} style={{marginTop:30, fontSize:14, color:"#8786A7"}}>
                        {t('referralSubtitle')}
                    </Typography>

                    <div style={{marginTop:30, marginLeft:14}}>
                        <div style={{display: 'flex'}}>
                            <img src={tier1Icon} style={{height:47, width:46}}/>
                            <div style={{marginLeft:20}}>
                                <Typography style={{fontFamily: "Syncopate", fontSize: 16, fontWeight: "bold"}}>
                                    {t('tier1')}
                                    <span className={classes.font} style={{marginLeft:16, display:"inline", fontSize:14, fontWeight:600}}>
                                        100-999 USDT
                                    </span>
                                </Typography>
                                <Typography className={classes.font} style={{color:"#8786A7", fontSize:14}}>
                                    {t('tier1Subtitle')}
                                </Typography>
                            </div>
                        </div>

                        <div style={{display: 'flex', marginTop:30}}>
                            <img src={tier2Icon} style={{height:47, width:46}}/>
                            <div style={{marginLeft:20}}>
                                <Typography style={{fontFamily: "Syncopate", fontSize: 16, fontWeight: "bold"}}>
                                    {t('tier2')}
                                    <span className={classes.font} style={{marginLeft:16, display:"inline", fontSize:14, fontWeight:600}}>
                                        1000-1999 USDT
                                    </span>
                                </Typography>
                                <Typography className={classes.font} style={{color:"#8786A7", fontSize:14}}>
                                    {t('tier2Subtitle')}
                                </Typography>
                            </div>
                        </div>

                        <div style={{display: 'flex', marginTop:30}}>
                            <img src={tier3Icon} style={{height:47, width:46}}/>
                            <div style={{marginLeft:20}}>
                                <Typography style={{fontFamily: "Syncopate", fontSize: 16, fontWeight: "bold"}}>
                                    {t('tier3')}
                                    <span className={classes.font} style={{marginLeft:16, display:"inline", fontSize:14, fontWeight:600}}>
                                        2000-5000 USDT
                                    </span>
                                </Typography>
                                <Typography className={classes.font} style={{color:"#8786A7", fontSize:14}}>
                                    {t('tier3Subtitle')}
                                </Typography>
                            </div>
                        </div>
                    </div>

                    <Button
                        className={classes.font}
                        style={{marginTop:60, marginBottom:60, height:58, borderRadius: 13, backgroundColor: "#271CFF", width:"100%", color:"white", fontSize:18, fontWeight:600, textTransform:"none"}}
                        onClick={()=>{history.push('/ido/invite')}}
                    >
                        {t('referFriends')}
                    </Button>

                </div>
            </div>
        )
    }
    return (
        <div style={{ display: 'flex', flexDirection:"column", justifyContent: 'center', alignItems:"center" }}>
            <div style={{backgroundColor:"#F1F9FF", width:"100%", display: 'flex', justifyContent: 'center'}}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minWidth:width<960 ? null:896, width:width<960 ? "100%":"50%" }}>
                    {renderHeader()}
                    {width < 960 ? renderIDOPhone() :renderIDO()}
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minWidth:width<960 ? null:896, width:width<960 ? "100%":"50%" }}>
                {width < 960 ? renderMigrationPhone() :renderMigration()}
                {width < 960 ? renderReferralPhone() : renderReferral()}
            </div>

            <ReferralReminderModal
                onCancel={() => {
                    setReferralReminderVisible(false);
                }}
                modalVisible={referralReminderVisible}
                t
                width={width}
            />

            <IDOBuyModal
                onCancel={() => {
                    setBuyVisible(false);
                }}
                modalVisible={buyVisible}
                t
                width={width}
                address={address}
                chainId={chainId}
                network={network}
                height={height}
            />

            <IDOMigrateModal
                onCancel={() => {
                    setMigrateVisible(false);
                }}
                modalVisible={migrateVisible}
                t
                width={width}
                address={address}
                chainId={chainId}
                network={network}
                height={height}
            />
        </div>
    )
}

export default withTranslation()(IDOHome);
