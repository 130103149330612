import React, { useEffect, useState } from 'react';
import { makeStyles, } from '@material-ui/core/styles';
import './CustomTextField.css';
import { Avatar, Box, Button, Fade, Grid, IconButton, List, ListItem, Modal, Typography } from '@material-ui/core';
import { getIcons } from "../../utils/Common";
import useWindowDimensions from "../../utils/WindowDimensions";
import closeIcon from "../../images/closeIcon.png";
import { isMobile } from 'react-device-detect';
import { withTranslation } from 'react-i18next';
import scrollDownIcon from "../../images/scrollDownIcon.png";

import metamaskIcon from "../../images/metamaskIcon.png";


const useStylesInput = makeStyles((theme) => ({
    root: {

        'label + &': {
            marginTop: theme.spacing(3),
        },

        // 'label.Mui-focused': {
        //   color: 'green',
        // },
        '&:hover': {
            backgroundColor: '#271CFF',
        },
        '&$focused': {
            backgroundColor: '#271CFF',
        },
        backgroundColor: '#271CFF',
        borderRadius: 16,
        alignItems: 'center',
        // backgroundColor: 'red',
        overflow: 'hidden'
    },
    modalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        // bgcolor: 'background.paper',
        // height: 400,
        borderRadius: 24,
        // backgroundColor: 'rgba(0, 0, 0, 0.8)'
        backgroundColor: "white"

    },

    modalBoxPhoneSize: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "80%",
        // bgcolor: 'background.paper',

        pt: 2,
        px: 4,
        pb: 3,
        backgroundColor: 'white',

        borderRadius: 20,

    },


    selectCoinBut: {
        boxShadow: 'none',
        textTransform: 'none',


        height: isMobile ? 28 : 36,
        width: isMobile ? 80 : 120,
        borderRadius: 18,
        padding: '6px 12px',
        border: 'none',
        lineHeight: 1.5,
        backgroundColor: '#E6EBF0',

        fontFamily: 'Avenir',
        fontWeight: '500',

    },




    // input: {
    //     position: 'relative',
    //     // backgroundColor: '#1F32B4',
    //     fontSize: 36,
    //     // width: 'auto',
    //     padding: '10px 12px',
    //     color: '#1DF0A9',

    //     fontFamily: [
    //         'Arial',
    //     ].join(','),

    //     '&:focused': {
    //         color: '#1DF0A9'
    //     }
    // }


}));



function NewCustomCoinSelectorModal({ t, unlockWarningOpen, handleUnlockWarningClose, open, handleClose, coins, handleCoinChange, tokenIcons }) {
    const classes = useStylesInput();

    const [searchInput, setSearchInput] = useState('')
    const [filteredCoins, setFilteredCoins] = useState([{ value: '', label: '' }])
    const { height, width } = useWindowDimensions();

    const handleSearch = (e) => {
        let text = e.toLowerCase()
        setSearchInput(e)
        let trucks = coins
        let filteredCoins = trucks.filter((item) => {
            return item.value.toLowerCase().match(text)
        })
        if (!text || text === "") {
            setFilteredCoins([])

        } else {
            setFilteredCoins(filteredCoins)
        }
    }

    useEffect(() => {
        setFilteredCoins(coins)
    }, [coins])

    return <div>
        <Modal
            open={unlockWarningOpen}
            onClose={handleUnlockWarningClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Fade in={unlockWarningOpen}>
                <Box className={isMobile ? classes.modalBoxPhoneSize : classes.modalBox} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: 12, marginRight: 20 }}>
                        <Button style={{}} onClick={handleUnlockWarningClose}>
                            <Avatar style={{ width: 32, height: 32 }} src={closeIcon} />
                        </Button>
                    </div>
                    <img alt="" style={{ height: 100, marginTop: 12 }} src={metamaskIcon}></img>
                    <Typography style={{ fontSize: 20, fontFamily: 'Avenir', fontWeight: "bold", color: '#363636', marginTop: 24 }}>{t("unabledtoDeposit")}</Typography>

                    <Typography style={{ fontSize: 14, fontFamily: 'Avenir', fontWeight: "500", color: '#8786A7', marginBottom: 24 }}>{t("unabledtoDepositDetail")}</Typography>


                </Box>
            </Fade>
        </Modal>

        {
            width > 900 ?
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description"
                >
                    <Fade in={open}>
                        <Box className={classes.modalBox} >
                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', marginLeft: 24, marginRight: 24, alignItems: 'center', marginTop: 8 }}>
                                <h2 style={{ fontSize: 24, color: '#363636', fontFamily: 'Avenir' }} id="parent-modal-title">{t("selectCoin")}</h2>


                                <Button style={{ minHeight: 0 }} onClick={handleClose}>
                                    <img alt='Close Icon' style={{ width: 32, height: 32 }} src={closeIcon} />
                                </Button>
                            </div>
                            {/* <CustomTextField
                            label={ inputTitle}
                            // disabled={!loggedIn}
                            style={{ marginLeft: 24, textTransform: 'none', marginTop: 0, height: 80 }}
                            inputRef={inputRef}
                            onChange={(e) => handleSearch(e.target.value)}
                            value={searchInput}
                        >

                        </CustomTextField> */}
                            {
                                coins ?
                                    <List style={{ overflow: 'auto', height: 450 }} >
                                        {coins.map((option) => (
                                            <ListItem key={option.label} style={{}} disablePadding>
                                                {option.value === '' ?
                                                    <Grid item xs={12} >

                                                        <Typography style={{ marginLeft: 16, fontSize: 16, fontWeight: "bold", color: '#363636' }}> {option.label}</Typography>
                                                    </Grid>
                                                    :
                                                    <Button onClick={() => handleCoinChange(option)} style={{ backgroundColor: '#f5f5f5', width: '100%', height: 60, display: 'flex', alignItems: 'left', justifyContent: 'flex-start', borderRadius: 12 }}>
                                                        <Avatar alt="Coin Icon" style={{ width: 32, height: 32 }} src={getIcons(option.label, tokenIcons, true)} />
                                                        <Typography style={{ marginLeft: 16, fontSize: 20, fontWeight: "bold", color: '#363636', fontFamily: 'Avenir' }}> {option.label}</Typography>
                                                    </Button>}
                                            </ListItem>
                                        ))}
                                        {/* {
                                        filteredCoins.length === 0 ?
                                            coins.map((option) => (
                                                <ListItem key={option.label} style={{}} disablePadding>
                                                    {option.value === '' ?
                                                        <Grid item xs={12} >

                                                            <Typography style={{ marginLeft: 16, fontSize: 16, fontWeight: "bold", color: '#363636' }}> {option.label}</Typography>
                                                        </Grid>
                                                        :
                                                        <Button onClick={() => handleCoinChange(option)}>
                                                            <Avatar alt="Coin Icon" style={{ width: 24, height: 24 }} src={getIcons(option.label, tokenIcons, true)} />
                                                            <Typography style={{ marginLeft: 16, fontSize: 16, fontWeight: "bold", color: '#363636', fontFamily: 'Avenir' }}> {option.label}</Typography>
                                                        </Button>}
                                                </ListItem>
                                            ))
                                            :
                                            filteredCoins.map((option) => (
                                                <ListItem key={option.label} disablePadding>
                                                    {option.value === '' ?
                                                        <Grid item xs={12} >
                                                            <Typography style={{ marginLeft: 16, fontSize: 16, fontWeight: "bold", color: '#363636' }}> {option.label}</Typography>
                                                        </Grid>
                                                        :
                                                        <Button onClick={() => handleCoinChange(option.value)}>
                                                            <Avatar alt="Coin Icon" style={{ width: 24, height: 24 }} src={getIcons(option.label, tokenIcons, true)} />
                                                            <Typography style={{ marginLeft: 16, fontSize: 16, fontWeight: "bold", color: '#363636' }}> {option.label}</Typography>
                                                        </Button>}
                                                </ListItem>
                                            ))
                                    } */}

                                    </List>
                                    :
                                    <Typography style={{ marginLeft: 16, fontSize: 16, fontWeight: "bold", color: '#363636' }}>{t('noCapitals')}</Typography>


                            }
                            {
                                coins !== undefined && coins.length > 6 ?
                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginLeft: 16, marginBottom: 24, justifyContent: 'center', marginTop: 8 }}>
                                        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', height: 24, borderRadius: 6 }}>
                                            <img alt='' src={scrollDownIcon} style={{ height: 16, marginLeft: 12 }}></img>
                                            <Typography className={classes.defaultFont} style={{ fontSize: 12, marginRight: 12, fontWeight: "500", color: '#8786A7', marginLeft: 6 }}>{t('popScrollDownForMore')}</Typography>
                                        </div>
                                    </div>
                                    : null
                            }



                        </Box>
                    </Fade>
                </Modal>
                :
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description"
                >
                    <Fade in={open}>
                        <Box className={classes.modalBoxPhoneSize}>
                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', marginLeft: 16, marginRight: 8, alignItems: 'center', height: 24, marginTop: 24, marginBottom: 20 }}>
                                <Typography style={{ fontSize: 16, color: '#363636', fontFamily: 'Avenir' }} id="parent-modal-title">{t("selectCoin")}</Typography>
                                <IconButton onClick={handleClose}>
                                    <Avatar alt="Coin Icon" style={{ width: 24, height: 24 }} src={closeIcon} />
                                </IconButton>
                            </div>
                            {/* <CustomTextField
                            label={ inputTitle}
                            // disabled={!loggedIn}
                            style={{ marginLeft: 12, marginRight: 12, textTransform: 'none', marginTop: 0, height: 80 }}
                            inputRef={inputRef}
                            onChange={(e) => handleSearch(e.target.value)}
                            value={searchInput}
                        >

                        </CustomTextField> */}
                            {
                                coins ?
                                    <List style={{ height: 320, overflow: 'auto' }}>
                                        {
                                            coins.map((option) => (
                                                <ListItem key={option.label} style={{}} disablePadding>
                                                    {option.value === '' ?
                                                        <Grid item xs={12} >

                                                            <Typography style={{ marginLeft: 16, fontSize: 16, fontWeight: "500", color: '#363636', fontFamily: 'Avenir' }}> {option.label}</Typography>
                                                        </Grid>
                                                        :
                                                        <Button onClick={() => handleCoinChange(option)} style={{ backgroundColor: '#f5f5f5', width: '100%', height: 48, display: 'flex', alignItems: 'left', justifyContent: 'flex-start', borderRadius: 12 }}>
                                                            <Avatar alt="Coin Icon" style={{ width: 24, height: 24 }} src={getIcons(option.label, tokenIcons, true)} />
                                                            <Typography style={{ marginLeft: 16, fontSize: 16, fontWeight: "500", color: '#363636', fontFamily: 'Avenir' }}> {option.label}</Typography>

                                                        </Button>}
                                                </ListItem>
                                            ))
                                        }
                                        {/* {
                                        filteredCoins.length === 0 ?
                                            coins.map((option) => (
                                                <ListItem key={option.label} style={{}} disablePadding>
                                                    {option.value === '' ?
                                                        <Grid item xs={12} >

                                                            <Typography style={{ marginLeft: 16, fontSize: 16, fontWeight: "500", color: '#636363', fontFamily: 'Avenir' }}> {option.label}</Typography>
                                                        </Grid>
                                                        :
                                                        <Button onClick={() => handleCoinChange(option)}>
                                                            <Avatar alt="Coin Icon" style={{ width: 24, height: 24 }} src={getIcons(option.label, tokenIcons, true)} />
                                                            <Typography style={{ marginLeft: 16, fontSize: 16, fontWeight: "500", color: '#636363', fontFamily: 'Avenir' }}> {option.label}</Typography>
                                                        </Button>}
                                                </ListItem>
                                            ))
                                            :
                                            filteredCoins.map((option) => (
                                                <ListItem key={option.label} disablePadding>
                                                    {option.value === '' ?
                                                        <Grid item xs={12} >
                                                            <Typography style={{ marginLeft: 16, fontSize: 16, fontWeight: "500", color: '#636363', fontFamily: 'Avenir' }}> {option.label}</Typography>
                                                        </Grid>
                                                        :
                                                        <Button onClick={() => handleCoinChange(option.value)}>
                                                            <Avatar alt="Coin Icon" style={{ width: 24, height: 24 }} src={getIcons(option.label, tokenIcons, true)} />
                                                            <Typography style={{ marginLeft: 16, fontSize: 16, fontWeight: "500", color: '#636363', fontFamily: 'Avenir' }}> {option.label}</Typography>
                                                        </Button>}
                                                </ListItem>
                                            ))
                                    } */}

                                    </List>
                                    :
                                    <Typography style={{ marginLeft: 16, fontSize: 16, fontWeight: "500", color: '#636363', fontFamily: 'Avenir' }}>{t('noCapitals')}</Typography>


                            }

                            {coins !== undefined && coins.length > 5 ?
                                <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginLeft: 16, marginBottom: 24, marginTop: 8 }}>
                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', height: 24, borderRadius: 8 }}>
                                        <img alt='' src={scrollDownIcon} style={{ height: 16, marginLeft: 12 }}></img>
                                        <Typography className={classes.defaultFont} style={{ fontSize: 12, marginRight: 12, fontWeight: "500", color: '#8786A7', marginLeft: 6 }}>{t('popScrollDownForMore')}</Typography>
                                    </div>
                                </div>
                                :
                                null
                            }
                        </Box>
                    </Fade>
                </Modal>
        }

    </div >



}


export default withTranslation()(NewCustomCoinSelectorModal);
