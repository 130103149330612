import { earnService} from '../services/earnService';
import {alertActions} from "./alertActions";
import {
    GET_ALL_EARN_POOL,
    GET_ALL_EARN_POOL_SUCCEED,
    GET_ALL_EARN_POOL_FAILED,
    EARN_SIGNING,
    EARN_SIGNING_CANCELLED,
    EARN_STAKE,
    EARN_STAKE_SUCCEED,
    EARN_STAKE_FAILED,
    CLEAR_EARN_STAKE_STATE,
    GET_MY_EARN_POOL,
    GET_MY_EARN_POOL_SUCCEED,
    GET_MY_EARN_POOL_FAILED,
    GET_EARN_TRANSACTIONS,
    GET_EARN_TRANSACTIONS_FAILED,
    GET_EARN_TRANSACTIONS_SUCCEED,
    HARVEST_EARN_REWARD,
    HARVEST_EARN_REWARD_SUCCEED,
    HARVEST_EARN_REWARD_FAILED,
    CLEAR_HARVEST_EARN_STATE,
    EARN_UNSTAKE,
    EARN_UNSTAKE_SUCCEED,
    EARN_UNSTAKE_FAILED,
    CLEAR_EARN_UNSTAKE_STATE,
    GET_EARN_RATE_QUOTE,
    GET_EARN_RATE_QUOTE_SUCCEED,
    GET_EARN_RATE_QUOTE_FAILED,
    CLEAR_EARN_RATE_QUOTE,
    GET_ETH_PRICE,
    GET_ETH_PRICE_SUCCEED,
    GET_ETH_PRICE_FAILED,
} from "../constants";
import {stakePoolService} from "../services/stakePoolService";
import {swapService} from "../services/swapService";



export const earnActions = {
    getAllEarnPool,
    getMyEarnPool,
    getMyEarnTransactions,
    harvestEarnReward,
    clearHarvestState,
    earnStake,
    clearEarnStakeState,
    earnUnStake,
    clearEarnUnStakeState,
    earnRateQuote,
    clearEarnRateQuote,
    earnPoolSigning,
    earnPoolSigningCancelled,
    getEthPrice
}

function getAllEarnPool() {
    return dispatch =>{
        dispatch(request());
        earnService.getAllEarnPool()
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: GET_ALL_EARN_POOL } }
    function success(res) { return { type: GET_ALL_EARN_POOL_SUCCEED, res } }
    function failure(error) { return { type: GET_ALL_EARN_POOL_FAILED, error } }
}

function getMyEarnPool(payload) {
    return dispatch =>{
        dispatch(request());
        earnService.getMyEarnPool(payload)
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: GET_MY_EARN_POOL } }
    function success(res) { return { type: GET_MY_EARN_POOL_SUCCEED, res } }
    function failure(error) { return { type: GET_MY_EARN_POOL_FAILED, error } }
}

function getMyEarnTransactions(payload) {
    let transList = []
    return dispatch =>{
        dispatch(request());
        earnService.getMyEarnTransactions(payload)
            .then(
                res1 => {
                    transList = transList.concat(res1.data)
                    transList.sort(function(a,b){
                        // Turn your strings into dates, and then subtract them
                        // to get a value that is either negative, positive, or zero.
                        return new Date(b.createdAt) - new Date(a.createdAt);
                    });
                    dispatch(success(transList));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: GET_EARN_TRANSACTIONS } }
    function failure(error) { return { type: GET_EARN_TRANSACTIONS_FAILED, error } }
    function success(res) { return { type: GET_EARN_TRANSACTIONS_SUCCEED, res } }
}

function harvestEarnReward(payload) {
    return dispatch => {
        dispatch(request());
        earnService.harvestEarnReward(payload)
            .then(
                res => {
                    if (res) {
                        dispatch(success('harvest succeed'));
                    }
                },
                error => {
                    let toast = ''
                    if (error.toString() === "TypeError: Failed to fetch") {
                        toast = "networkError"
                    }else if (error.status===503 || error.status===409){
                        toast = "serviceError"
                    }else{
                        toast = "earn-collectReward-failed"
                    }
                    dispatch(failure(toast));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: HARVEST_EARN_REWARD } }
    function success(msg) { return { type: HARVEST_EARN_REWARD_SUCCEED, msg } }
    function failure(error) { return { type: HARVEST_EARN_REWARD_FAILED, error } }
}

function clearHarvestState() {
    return dispatch => {
        dispatch(request());
    };
    function request() { return { type: CLEAR_HARVEST_EARN_STATE } }
}

function earnStake(payload,token) {
    return dispatch => {
        dispatch(request());
        earnService.earnStake(payload, token)
            .then(
                res => {
                    dispatch(success("stake earn succeed"));
                },
                error => {
                    let toast = ''
                    if (error.toString() === "TypeError: Failed to fetch"){
                        toast = "networkError"
                    }else if(error.status === 503|| error.status===409){
                        toast = "serviceError"
                    }else if(error.message ==="TooFrequentError"){
                        toast = "tooFrequent"
                    }else {
                        toast = 'earn-stake-failed'
                    }
                    dispatch(failure(toast));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(address) { return { type: EARN_STAKE, address } }
    function success(msg) { return { type: EARN_STAKE_SUCCEED, msg } }
    function failure(error) { return { type: EARN_STAKE_FAILED, error } }
}

function clearEarnStakeState(){
    return dispatch => {
        dispatch(request());
    };
    function request(address) { return { type: CLEAR_EARN_STAKE_STATE, address } }
}

function earnUnStake(payload,token) {
    return dispatch => {
        dispatch(request());
        earnService.earnUnStake(payload, token)
            .then(
                res => {
                    dispatch(success("unStake earn succeed"));
                },
                error => {
                    let toast = ''
                    if (error.toString() === "TypeError: Failed to fetch"){
                        toast = "networkError"
                    }else if(error.status === 503|| error.status===409){
                        toast = "serviceError"
                    }else if(error.message ==="TooFrequentError"){
                        toast = "tooFrequent"
                    }else {
                        toast = 'earn-unStake-failed'
                    }
                    dispatch(failure(toast));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(address) { return { type: EARN_UNSTAKE, address } }
    function success(msg) { return { type: EARN_UNSTAKE_SUCCEED, msg } }
    function failure(error) { return { type: EARN_UNSTAKE_FAILED, error } }
}

function clearEarnUnStakeState(){
    return dispatch => {
        dispatch(request());
    };
    function request(address) { return { type: CLEAR_EARN_UNSTAKE_STATE, address } }
}

function earnRateQuote(payload,token) {
    return dispatch => {
        dispatch(request());
        swapService.swapQuote(payload,token)
            .then(
                res => {
                    if (res) {
                        dispatch(success(res));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: GET_EARN_RATE_QUOTE } }
    function success(res) { return { type: GET_EARN_RATE_QUOTE_SUCCEED, res } }
    function failure(error) { return { type: GET_EARN_RATE_QUOTE_FAILED, error } }
}

function clearEarnRateQuote() {
    return dispatch => {
        dispatch(request());
    };
    function request() { return { type: CLEAR_EARN_RATE_QUOTE } }
}

function getEthPrice(payload) {
    return dispatch => {
        dispatch(request());
        earnService.getEthPrice(payload)
            .then(
                res => {
                    if (res) {
                        dispatch(success(res));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: GET_ETH_PRICE } }
    function success(res) { return { type: GET_ETH_PRICE_SUCCEED, res } }
    function failure(error) { return { type: GET_ETH_PRICE_FAILED, error } }
}

function earnPoolSigning() {
    return dispatch => {
        dispatch(earnPoolSigning())
    }
    function earnPoolSigning() {return { type: EARN_SIGNING }}
}

function earnPoolSigningCancelled() {
    return dispatch => {
        dispatch(earnPoolSigningCancelled())
    }
    function earnPoolSigningCancelled() {return { type: EARN_SIGNING_CANCELLED }}
}