import { poolService } from '../services/poolService';
import {
    ADD_LIQUIDITY,
    ADD_SUCCEED,
    ADD_FAILED,
    REMOVE_LIQUIDITY,
    REMOVE_SUCCEED,
    REMOVE_FAILED,
    GET_ALL_LIQUIDITY_POOL,
    GET_ALL_LIQUIDITY_POOL_SUCCEED,
    GET_ALL_LIQUIDITY_POOL_FAILED,
    GET_MY_LIQUIDITY_POOL,
    GET_MY_LIQUIDITY_POOL_SUCCEED,
    GET_MY_LIQUIDITY_POOL_FAILED,
    GET_LIQUIDITY_TRANSACTIONS,
    GET_LIQUIDITY_TRANSACTIONS_FAILED,
    GET_LIQUIDITY_COLLECT_TRANSACTIONS,
    GET_LIQUIDITY_COLLECT_TRANSACTIONS_FAILED,
    GET_MY_LIQUIDITY_TRANSACTIONS_SUCCEED,
    GET_ADD_LIQUIDITY_QUOTE,
    GET_ADD_LIQUIDITY_QUOTE_FAILED,
    GET_ADD_LIQUIDITY_QUOTE_SUCCEED,
    CLEAR_ADD_LIQUIDITY_QUOTE,
    GET_REMOVE_LIQUIDITY_QUOTE,
    GET_REMOVE_LIQUIDITY_QUOTE_FAILED,
    GET_REMOVE_LIQUIDITY_QUOTE_SUCCEED,
    CLEAR_REMOVE_LIQUIDITY_QUOTE,
    HARVEST_LIQUIDITY_REWARD,
    HARVEST_LIQUIDITY_REWARD_SUCCEED,
    HARVEST_LIQUIDITY_REWARD_FAILED,
    CLEAR_ADD_STATE, CLEAR_REMOVE_STATE,
    CLEAR_HARVEST_STATE,
    POOL_SIGNING,
    POOL_SIGNING_CANCELLED,
    GET_MY_POOL_APY,
    GET_MY_POOL_APY_SUCCEED,
    GET_MY_POOL_APY_FAILED,
    GET_CURRENT_TIME,
    GET_CURRENT_TIME_SUCCEED,
    GET_CURRENT_TIME_FAILED,
    GET_MY_LOCKER,
    GET_MY_LOCKER_SUCCEED,
    GET_MY_LOCKER_FAILED,
    LOCK_LP,
    LOCK_LP_FAILED,
    LOCK_LP_SUCCEED,
    CLEAR_LOCK_LP_STATE,
    UNLOCK_LP,
    UNLOCK_LP_FAILED,
    UNLOCK_LP_SUCCEED,
    CLEAR_UNLOCK_LP_STATE,
    GET_UNLOCKABLE,
    GET_UNLOCKABLE_FAILED,
    GET_UNLOCKABLE_SUCCEED,
    CLEAR_UNLOCKABLE,
    UNLOCK_ALL_LP,
    UNLOCK_ALL_LP_FAILED,
    UNLOCK_ALL_LP_SUCCEED,
    CLEAR_UNLOCK_ALL_LP_STATE,
    GET_POOL_BANNER,
    GET_POOL_BANNER_SUCCEED,
    GET_POOL_BANNER_FAILED
} from '../constants';
import { history } from '../../utils/History';
import { alertActions } from './alertActions';

export const poolActions = {
    addLiquidity,
    removeLiquidity,
    getAllLiquidityPool,
    getMyLiquidityPool,
    getMyLiquidityTransactions,
    addLiquidityQuote,
    clearAddQuote,
    removeLiquidityQuote,
    clearRemoveQuote,
    harvestLiquidityReward,
    clearAddState,
    clearRemoveState,
    clearHarvestState,
    poolSigning,
    poolSigningCancelled,
    getMyPoolApy,
    getCurrentTime,
    getMyLocker,
    lockLp,
    clearLockLpState,
    unlockLp,
    clearUnlockLpState,
    getUnlockable,
    clearUnlockable,
    unlockAllLp,
    clearUnlockAllLpState,
    getPoolBanner
}
function getAllLiquidityPool() {
    return dispatch => {
        dispatch(request());
        poolService.getAllLiquidityPool()
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: GET_ALL_LIQUIDITY_POOL } }
    function success(res) { return { type: GET_ALL_LIQUIDITY_POOL_SUCCEED, res } }
    function failure(error) { return { type: GET_ALL_LIQUIDITY_POOL_FAILED, error } }
}

function getMyLiquidityPool(payload) {
    return dispatch => {
        dispatch(request());
        poolService.getMyLiquidityPool(payload)
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: GET_MY_LIQUIDITY_POOL } }
    function success(res) { return { type: GET_MY_LIQUIDITY_POOL_SUCCEED, res } }
    function failure(error) { return { type: GET_MY_LIQUIDITY_POOL_FAILED, error } }
}


function getPoolBanner(payload) {
    return dispatch => {
        dispatch(request());
        poolService.getPoolBanner(payload)
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));

                }
            );
    };
    function request() { return { type: GET_POOL_BANNER } }
    function success(res) { return { type: GET_POOL_BANNER_SUCCEED, res } }
    function failure(error) { return { type: GET_POOL_BANNER_FAILED, error } }
}

function getMyLiquidityTransactions(payload) {
    let transList = []
    return dispatch => {
        dispatch(liquidityRequest());
        poolService.getLiquidityTransactions(payload)
            .then(
                res1 => {
                    transList = transList.concat(res1.data)
                    dispatch(collectRequest());
                    poolService.getLiquidityCollectTransactions(payload)
                        .then(
                            res2 => {
                                transList = transList.concat(res2.data)
                                transList.sort(function (a, b) {
                                    // Turn your strings into dates, and then subtract them
                                    // to get a value that is either negative, positive, or zero.
                                    if (new Date(b.createdAt) - new Date(a.createdAt) === 0) {
                                        return b.type - a.type
                                    }
                                    return new Date(b.createdAt) - new Date(a.createdAt);
                                });
                                dispatch(success(transList));
                            },
                            error => {
                                dispatch(collectFailure(error.toString()));
                                dispatch(alertActions.error(error.toString()));
                            }
                        );
                },
                error => {
                    dispatch(liquidityFailure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function liquidityRequest() { return { type: GET_LIQUIDITY_TRANSACTIONS } }
    function liquidityFailure(error) { return { type: GET_LIQUIDITY_TRANSACTIONS_FAILED, error } }
    function collectRequest() { return { type: GET_LIQUIDITY_COLLECT_TRANSACTIONS } }
    function collectFailure(error) { return { type: GET_LIQUIDITY_COLLECT_TRANSACTIONS_FAILED, error } }
    function success(res) { return { type: GET_MY_LIQUIDITY_TRANSACTIONS_SUCCEED, res } }
}

function addLiquidity(payload) {
    return dispatch => {
        dispatch(request());
        poolService.addLiquidity(payload)
            .then(
                res => {
                    dispatch(success("add Liquidity succeed"));
                },
                error => {
                    let toast = ''
                    if (error.toString() === "TypeError: Failed to fetch") {
                        toast = "networkError"
                    } else if (error.status === 503 || error.status === 409) {
                        toast = "serviceError"
                    } else if (error.data === "TooFrequentError") {
                        toast = "tooFrequent"
                    } else if (error.data === "Price changed significantly, add liquidity request abort.") {
                        toast = "pool-addLiquidity-priceChanged"
                    } else if (error.data === "LP token locker not available for this pool.") {
                        toast = "pool-addLiquidity-failed-lockerUnavailable"
                    } else {
                        toast = "pool-addLiquidity-failed"
                    }
                    dispatch(failure(toast));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(address) { return { type: ADD_LIQUIDITY, address } }
    function success(msg) { return { type: ADD_SUCCEED, msg } }
    function failure(error) { return { type: ADD_FAILED, error } }
}

function removeLiquidity(payload) {
    return dispatch => {
        dispatch(request());
        poolService.removeLiquidity(payload)
            .then(
                res => {
                    if (res) {
                        dispatch(success('remove succeed'));
                    }
                },
                error => {
                    let toast = ''
                    if (error.toString() === "TypeError: Failed to fetch") {
                        toast = "networkError"
                    } else if (error.status === 503 || error.status === 409) {
                        toast = "serviceError"
                    } else if (error.data === "TooFrequentError") {
                        toast = "tooFrequent"
                    } else {
                        toast = "pool-removeLiquidity-failed"
                    }
                    dispatch(failure(toast));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: REMOVE_LIQUIDITY } }
    function success(msg) { return { type: REMOVE_SUCCEED, msg } }
    function failure(error) { return { type: REMOVE_FAILED, error } }
}

function addLiquidityQuote(payload) {
    return dispatch => {
        dispatch(request());
        poolService.getAddLiquidityQuote(payload)
            .then(
                res => {
                    if (res) {
                        dispatch(success(res));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: GET_ADD_LIQUIDITY_QUOTE } }
    function success(res) { return { type: GET_ADD_LIQUIDITY_QUOTE_SUCCEED, res } }
    function failure(error) { return { type: GET_ADD_LIQUIDITY_QUOTE_FAILED, error } }
}

function clearAddQuote() {
    return dispatch => {
        dispatch(request());
    };
    function request() { return { type: CLEAR_ADD_LIQUIDITY_QUOTE } }
}

function harvestLiquidityReward(payload) {
    return dispatch => {
        dispatch(request());
        poolService.harvestLiquidityReward(payload)
            .then(
                res => {
                    if (res) {
                        dispatch(success('harvest succeed'));
                    }
                },
                error => {
                    let toast = ''
                    if (error.toString() === "TypeError: Failed to fetch") {
                        toast = "networkError"
                    } else if (error.status === 503 || error.status === 409) {
                        toast = "serviceError"
                    } else {
                        toast = "pool-collectReward-failed"
                    }
                    dispatch(failure(toast));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: HARVEST_LIQUIDITY_REWARD } }
    function success(msg) { return { type: HARVEST_LIQUIDITY_REWARD_SUCCEED, msg } }
    function failure(error) { return { type: HARVEST_LIQUIDITY_REWARD_FAILED, error } }
}

function removeLiquidityQuote(payload) {
    return dispatch => {
        dispatch(request());
        poolService.getRemoveLiquidityQuote(payload)
            .then(
                res => {
                    if (res) {
                        dispatch(success(res));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: GET_REMOVE_LIQUIDITY_QUOTE } }
    function success(res) { return { type: GET_REMOVE_LIQUIDITY_QUOTE_SUCCEED, res } }
    function failure(error) { return { type: GET_REMOVE_LIQUIDITY_QUOTE_FAILED, error } }
}

function clearRemoveQuote() {
    return dispatch => {
        dispatch(request());
    };
    function request() { return { type: CLEAR_REMOVE_LIQUIDITY_QUOTE } }
}

function clearAddState() {
    return dispatch => {
        dispatch(request());
    };
    function request() { return { type: CLEAR_ADD_STATE } }
}

function clearRemoveState() {
    return dispatch => {
        dispatch(request());
    };
    function request() { return { type: CLEAR_REMOVE_STATE } }
}

function clearHarvestState() {
    return dispatch => {
        dispatch(request());
    };
    function request() { return { type: CLEAR_HARVEST_STATE } }
}

function poolSigning() {
    return dispatch => {
        dispatch(poolSigning())
    }
    function poolSigning() { return { type: POOL_SIGNING } }
}

function poolSigningCancelled() {
    return dispatch => {
        dispatch(poolSigningCancelled())
    }
    function poolSigningCancelled() { return { type: POOL_SIGNING_CANCELLED } }
}

function getMyPoolApy(payload) {
    return dispatch => {
        dispatch(request());
        poolService.getMyPoolApy(payload)
            .then(
                res => {
                    if (res) {
                        dispatch(success(res));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: GET_MY_POOL_APY } }
    function success(res) { return { type: GET_MY_POOL_APY_SUCCEED, res } }
    function failure(error) { return { type: GET_MY_POOL_APY_FAILED, error } }
}
function getCurrentTime(payload) {
    return dispatch => {
        dispatch(request());
        poolService.getCurrentTime(payload)
            .then(
                res => {
                    if (res) {
                        dispatch(success(res));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: GET_CURRENT_TIME } }
    function success(res) { return { type: GET_CURRENT_TIME_SUCCEED, res } }
    function failure(error) { return { type: GET_CURRENT_TIME_FAILED, error } }
}

function getMyLocker(payload) {
    return dispatch => {
        dispatch(request());
        poolService.getMyLocker(payload)
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: GET_MY_LOCKER } }
    function success(res) { return { type: GET_MY_LOCKER_SUCCEED, res } }
    function failure(error) { return { type: GET_MY_LOCKER_FAILED, error } }
}

function lockLp(payload) {
    return dispatch => {
        dispatch(request());
        poolService.lockLp(payload)
            .then(
                res => {
                    dispatch(success("lockLp succeed"));
                },
                error => {
                    let toast = ''
                    if (error.toString() === "TypeError: Failed to fetch") {
                        toast = "networkError"
                    } else if (error.status === 503 || error.status === 409) {
                        toast = "serviceError"
                    } else if (error.message === "TooFrequentError") {
                        toast = "tooFrequent"
                    } else if (error.message === "LP token locker not available for this pool.") {
                        toast = "pool-lock-failed-lockerUnavailable"
                    } else {
                        toast = "pool-lock-failed"
                    }
                    dispatch(failure(toast));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(address) { return { type: LOCK_LP, address } }
    function success(msg) { return { type: LOCK_LP_SUCCEED, msg } }
    function failure(error) { return { type: LOCK_LP_FAILED, error } }
}

function clearLockLpState() {
    return dispatch => {
        dispatch(request());
    };
    function request() { return { type: CLEAR_LOCK_LP_STATE } }
}

function unlockLp(payload) {
    return dispatch => {
        dispatch(request());
        poolService.unlockLp(payload)
            .then(
                res => {
                    dispatch(success("unlockLp succeed"));
                },
                error => {
                    let toast = ''
                    if (error.toString() === "TypeError: Failed to fetch") {
                        toast = "networkError"
                    } else if (error.status === 503 || error.status === 409) {
                        toast = "serviceError"
                    } else if (error.message === "TooFrequentError") {
                        toast = "tooFrequent"
                    } else if (error.message === "Locker not released yet.") {
                        toast = "pool-unlock-notReleaseYet"
                    } else {
                        toast = "pool-unlock-failed"
                    }
                    dispatch(failure(toast));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(address) { return { type: UNLOCK_LP, address } }
    function success(msg) { return { type: UNLOCK_LP_SUCCEED, msg } }
    function failure(error) { return { type: UNLOCK_LP_FAILED, error } }
}

function clearUnlockLpState() {
    return dispatch => {
        dispatch(request());
    };
    function request() { return { type: CLEAR_UNLOCK_LP_STATE } }
}

function getUnlockable(payload) {
    return dispatch => {
        dispatch(request());
        poolService.getUnlockable(payload)
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: GET_UNLOCKABLE } }
    function success(res) { return { type: GET_UNLOCKABLE_SUCCEED, res } }
    function failure(error) { return { type: GET_UNLOCKABLE_FAILED, error } }
}

function clearUnlockable() {
    return dispatch => {
        dispatch(request());
    };
    function request() { return { type: CLEAR_UNLOCKABLE } }
}

function unlockAllLp(payload) {
    return dispatch => {
        dispatch(request());
        poolService.unlockAllLp(payload)
            .then(
                res => {
                    dispatch(success("unlockAllLp succeed"));
                },
                error => {
                    let toast = ''
                    if (error.toString() === "TypeError: Failed to fetch") {
                        toast = "networkError"
                    } else if (error.status === 503 || error.status === 409) {
                        toast = "serviceError"
                    } else if (error.data === "TooFrequentError") {
                        toast = "tooFrequent"
                    } else {
                        toast = "pool-unlockAll-failed"
                    }
                    dispatch(failure(toast));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(address) { return { type: UNLOCK_ALL_LP, address } }
    function success(msg) { return { type: UNLOCK_ALL_LP_SUCCEED, msg } }
    function failure(error) { return { type: UNLOCK_ALL_LP_FAILED, error } }
}

function clearUnlockAllLpState() {
    return dispatch => {
        dispatch(request());
    };
    function request() { return { type: CLEAR_UNLOCK_ALL_LP_STATE } }
}