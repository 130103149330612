import {Backdrop, Fade, makeStyles, Modal} from "@material-ui/core";
import React, {useEffect} from "react";
import {withTranslation} from "react-i18next";
import ReactSrcDocIframe from 'react-srcdoc-iframe';
import './SliderModa.css'

function SliderModal({ t, openSliderModal, handleCloseSliderModal, height, width, setSliderInfo }) {
    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor:'rgba(52, 52, 52, 0.6)'
        },
        lottieViewStyling: {
            width: "100%", height: '100%', marginTop: -8
        },
        lottieViewContainer: {
            height: 100, width: 80, borderRadius: 12, backgroundColor: 'rgba(16, 27, 102, 1)', marginBottom: 8, justifyContent: 'center', alignItems: 'center', position: 'absolute'
        },
        lottieViewText: {
            marginTop: -32, fontSize: 12, fontWeight: 'bold', color: '#8FB9E1'
        }
    }));
    const classes = useStyles();

    useEffect(() => {
        const handler = event => {
            if (event.data.token) {
                setSliderInfo(event.data)
                handleCloseSliderModal()
            }
        }

        window.addEventListener("message", handler)
        // clean up
        return () => window.removeEventListener("message", handler, false)
    }, [])


    const html = (`
        <!DOCTYPE html>
        <html>
        <head>
            <meta charset="utf-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=0">
            <!-- 国内使用 -->
            <script type="text/javascript" charset="utf-8" src="//g.alicdn.com/sd/nch5/index.js?t=2015052012"></script>
            <!-- 若您的主要用户来源于海外，请替换使用下面的js资源 -->
            <!-- <script type="text/javascript" charset="utf-8" src="//aeis.alicdn.com/sd/nch5/index.js?t=2015052012"></script> -->
        </head>
        <body style="background-color:#F1F9FF">
            <img alt="Logo.png" style="position: absolute;left: 50%;transform: translateX(-50%);width: 60px; height: 60px;padding-top:24%;"  src="https://dumboex.com/images/logo.png">
            <div id="__nc"style="margin-left:auto;margin-right:auto;width:80%;height:20%;padding-top:55%;" >
                <div id="nc"></div>
            </div>
        
            <script>
            window.parent.postMessage('Hi!', '*');
            var nc_token = ["FFFF0N00000000009830", (new Date()).getTime(), Math.random()].join(':');
            var nc=NoCaptcha.init({
                renderTo: '#nc',
                appkey: 'FFFF0N00000000009830',
                scene: 'nc_register_h5',
                token: nc_token,
                trans: {"key1": "code200"},
                elementID: ["usernameID"],
                is_Opt: 0,
                language: "cn",
                timeout: 10000,
                retryTimes: 5,
                errorTimes: 5,
                inline:false,
                apimap: {
                    // 'analyze': '//a.com/nocaptcha/analyze.jsonp',
                    // 'uab_Url': '//aeu.alicdn.com/js/uac/909.js',
                },
                bannerHidden:false,
                initHidden:false,
                callback: function (data) {
                    var backData ={
                        "token" : nc_token,
                        "sessionId" : data.csessionid,
                        "sign" : data.sig,
                        "scene" : 'nc_register_h5'
                    };
                    if (window.ReactNativeWebView){
                        window.ReactNativeWebView.postMessage(JSON.stringify(backData));
                    }
                    window.parent.postMessage(backData, "*");
                },
                error: function (s) {
                    console.log('error: ', s)
                }
            });
            NoCaptcha.setEnabled(true);
            nc.reset();//请务必确保这里调用一次reset()方法
        
            NoCaptcha.upLang('cn', {
                'LOADING':"加载中...",//加载
                'SLIDER_LABEL': "请向右滑动验证",//等待滑动
                'CHECK_Y':"验证通过",//通过
                'ERROR_TITLE':"非常抱歉，这出错了...",//拦截
                'CHECK_N':"验证未通过", //准备唤醒二次验证
                'OVERLAY_INFORM':"经检测你当前操作环境存在风险，请输入验证码",//二次验证
                'TIPS_TITLE':"验证码错误，请重新输入"//验证码输错时的提示
            });
            </script>
        </body>
        </html>
    `)


    const body = (
        <div style={{
            backgroundColor: '#F1F9FF', borderRadius: 24,  overflow: 'hidden', outline: 0, display: 'flex', alignItems: 'center', flexDirection: 'column'
        }}>
            <ReactSrcDocIframe className="slider-iframe" srcDoc={html} width={width > 1280 ? 500 : width > 640 ? 400 : width * 0.8} height={width > 1280 ? 500 : width > 640 ? 400 : height * 0.4} />
        </div>
    );

    return (
        <Modal
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            aria-labelledby="server-modal-title"
            aria-describedby="server-modal-description"
            className={classes.modal}
            open={openSliderModal}
            onClose={handleCloseSliderModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        >
            <Fade in={openSliderModal}>
                {body}
            </Fade>
        </Modal>
    )
}

export default withTranslation()(SliderModal);
