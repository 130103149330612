import { authActions } from "../redux/actions";
import MetaMaskOnboarding from "@metamask/onboarding";
import Web3 from "web3";
import { toBuffer } from 'ethereumjs-util'
import {walletActions} from "../redux/actions/walletActions";
import {poolActions} from "../redux/actions/poolActions";
import {swapActions} from "../redux/actions/swapActions";
import {stakePoolActions} from "../redux/actions/stakePoolActions";
import {earnActions} from "../redux/actions/earnActions";
import {voteActions} from "../redux/actions/voteActions";
import {formDateString} from "./Common";
import { roundingDown } from './RoundingDown'

const currentUrl = new URL(window.location.href)
const forwarderOrigin = currentUrl.hostname === 'localhost'
    ? 'http://localhost:9010'
    : undefined

export const onBoard = new MetaMaskOnboarding({ forwarderOrigin })

export const unlock = async (address, chainId, network, code, Web3, registered, dispatch, setOpenSliderModal, setSignUpPayload) => {
    try {
        let msg = 'Access DumboEx wallet.\n' +
            '\n' +
            'Only sign this message for a trusted client!'
        dispatch(authActions.authSigning())
        const from = address
        const readable = `0x${Buffer.from(msg, 'utf8').toString('hex')}`
        const buffer = [...toBuffer(readable)]
        // const _msg = Web3.utils.soliditySha3(msg);
        const sign = await window.ethereum.request({
            method: 'personal_sign',
            params: [readable, from],
        })
        let payload = {
            referralCode: code,
            personalSign: {
                data: buffer,
                sig: sign,
                pubKeyAddress: address,
                chainId: Web3.utils.hexToNumber(chainId),
                networkId: Number(network)
            }
        }

        if (registered !== undefined) {
            if (registered) {
                dispatch(authActions.logIn(payload.personalSign))
            } else {
                setOpenSliderModal(true)
                setSignUpPayload(payload)
            }
        } else {
            console.log('not found!')
        }

    } catch (err) {
        console.error('sign error: ', err)
        dispatch(authActions.logOut())
    }
}

export const withdraw = async (address, chainId, network, Web3, registered, dispatch, walletSigning, setTime, handleOpenNote, coin, chain, withdrawAmount, withdrawTo, token) => {
    let msg = 'Withdrawing from DumboEx wallet\n\n' +
        `Amount: ${withdrawAmount} ${coin}\n` +
        `To: ${withdrawTo}\n` +
        `ChainId: ${Web3.utils.hexToNumber(chainId)}`
    try {
        const from = address
        const readable = `0x${Buffer.from(msg, 'utf8').toString('hex')}`
        const buffer = [...toBuffer(readable)]
        dispatch(walletActions.walletSigning())
        if (!walletSigning) {
            setTime(formDateString(new Date().getTime()))
            handleOpenNote()

            const sign = await window.ethereum.request({
                method: 'personal_sign',
                params: [readable, from],
            })
            let payload = {
                personalSign: {
                    data: buffer,
                    sig: sign,
                    pubKeyAddress: address,
                    chainId: Web3.utils.hexToNumber(chainId),
                    networkId: Number(network),
                },
                currency: coin,
                chain: chain,
                amount: withdrawAmount,
                address: withdrawTo,
                token: token
            }
            dispatch(walletActions.withdraw(payload))
        }

    } catch (err) {
        console.error('withdraw request cancelled: ', err)
        dispatch(walletActions.walletSigningCancelled())
    }
}


export const transfer = async (address, chainId, network, Web3, registered, dispatch, walletSigning, setTime, handleOpenNote, coin, amount, receiverUserId, sendByAddress, token) => {
    let msg = 'Transfer from DumboEx wallet\n\n' +
        `Amount: ${amount} ${coin}\n` +
        `To: ${receiverUserId}\n` +
        `ChainId: ${Web3.utils.hexToNumber(chainId)}`
    try {
        const from = address
        const readable = `0x${Buffer.from(msg, 'utf8').toString('hex')}`
        const buffer = [...toBuffer(readable)]
        dispatch(walletActions.walletSigning())
        if (!walletSigning) {
            setTime(formDateString(new Date().getTime()))
            handleOpenNote()

            const sign = await window.ethereum.request({
                method: 'personal_sign',
                params: [readable, from],
            })
            let payload = {

                body: {
                    token: coin,
                    amount: amount,
                    receiverUserId: receiverUserId,
                    sendByAddress: sendByAddress,
                    personalSign: {
                        data: buffer,
                        sig: sign,
                        pubKeyAddress: address,
                        chainId: Web3.utils.hexToNumber(chainId),
                        networkId: Number(network),
                    },
                },

                token: token

            }
            dispatch(walletActions.transfer(payload))
        }

    } catch (err) {
        console.error('transfer request cancelled: ', err)
        dispatch(walletActions.walletSigningCancelled())
    }
}
export const addLiquidity = async (address, chainId, network, Web3, dispatch, poolSigning,isLpLocked, token1, token2, amount1, amount2, setAmount1, setAmount2, token, handleClose, setSnackBarVisible) => {
    let msg = 'Adding Liquidity from DumboEx wallet\n\n' +
        `Adding: ${roundingDown(amount1, 8)} ${token1} \n` +
        `and: ${roundingDown(amount2, 8)} ${token2}\n` +
        `ChainId: ${Web3.utils.hexToNumber(chainId)}`
    try {
        const from = address
        const readable = `0x${Buffer.from(msg, 'utf8').toString('hex')}`
        const buffer = [...toBuffer(readable)]
        dispatch(poolActions.poolSigning())

        if (!poolSigning) {
            const sign = await window.ethereum.request({
                method: 'personal_sign',
                params: [readable, from],
            })
            let payload = {
                personalSign: {
                    data: buffer,
                    sig: sign,
                    pubKeyAddress: address,
                    chainId: Web3.utils.hexToNumber(chainId),
                    networkId: Number(network),
                },
                token1,
                token2,
                amount1,
                amount2,
                token,
                isLpLocked
            }
            dispatch(poolActions.addLiquidity(payload))
            setAmount1("")
            setAmount2("")
            dispatch(poolActions.clearAddQuote())
            handleClose()
        }
    } catch (err) {
        console.error('add liquidity request cancelled: ', err)
        setSnackBarVisible(true)
        dispatch(poolActions.poolSigningCancelled())
    }
}

export const removeLiquidity = async (address, chainId, network, Web3, dispatch, poolSigning, token1, token2, amount, setAmount1, setAmount2, token, handleClose, setSnackBarVisible) => {
    let msg = 'Removing Liquidity from DumboEx wallet\n\n' +
        `Removing: ${roundingDown(amount, 8)} ${token1}-${token2}-LP \n` +
        `ChainId: ${Web3.utils.hexToNumber(chainId)}`
    try {
        const from = address
        const readable = `0x${Buffer.from(msg, 'utf8').toString('hex')}`
        const buffer = [...toBuffer(readable)]
        dispatch(poolActions.poolSigning())

        if (!poolSigning) {
            const sign = await window.ethereum.request({
                method: 'personal_sign',
                params: [readable, from],
            })
            let payload = {
                personalSign: {
                    data: buffer,
                    sig: sign,
                    pubKeyAddress: address,
                    chainId: Web3.utils.hexToNumber(chainId),
                    networkId: Number(network),
                },
                token1,
                token2,
                amount,
                token
            }
            dispatch(poolActions.removeLiquidity(payload))
            setAmount1("")
            setAmount2("")
            dispatch(poolActions.clearRemoveQuote())
            handleClose()
        }
    } catch (err) {
        console.error('remove liquidity request cancelled: ', err)
        setSnackBarVisible(true)
        dispatch(poolActions.poolSigningCancelled())
    }
}

export const swap = async (address, chainId, network, Web3, dispatch, swapSigning, pairOne, pairTwo, amount1, amount2, setAmount1, setAmount2, token, setSnackBarMessage, setSnackBarVisible, t) => {
    let msg = 'Swapping from DumboEx wallet\n\n' +
        `Swap: ${roundingDown(amount1, 8)} ${pairOne} \n` +
        `For: ${roundingDown(amount2, 8)} ${pairTwo}\n` +
        `ChainId: ${Web3.utils.hexToNumber(chainId)}`
    try {
        const from = address
        const readable = `0x${Buffer.from(msg, 'utf8').toString('hex')}`
        const buffer = [...toBuffer(readable)]
        dispatch(swapActions.swapSigning())

        if (!swapSigning) {
            const sign = await window.ethereum.request({
                method: 'personal_sign',
                params: [readable, from],
            })
            let payload = {
                personalSign: {
                    data: buffer,
                    sig: sign,
                    pubKeyAddress: address,
                    chainId: Web3.utils.hexToNumber(chainId),
                    networkId: Number(network),
                },
                from: pairOne,
                to: pairTwo,
                fromAmount: amount1,
                toAmount: amount2
            }
            dispatch(swapActions.swap(payload, token))
            setAmount1("")
            setAmount2("")
        }
    } catch (err) {
        console.error('swap request cancelled: ', err)
        setSnackBarMessage(t('actionCancelled'))
        setSnackBarVisible(true)
        dispatch(swapActions.swapSigningCancelled())
    }
}

export const stake = async (address, chainId, network, Web3, dispatch, stakePoolSigning,pid,coin, amount1,setAmount1, token, handleClose,setSnackBarVisible,isLpLocked) => {
    let msg = 'Staking from DumboEx wallet\n\n' +
        `Stake: ${roundingDown(amount1, 8)} ${coin} \n` +
        `ChainId: ${Web3.utils.hexToNumber(chainId)}`
    try {
        const from = address
        const readable = `0x${Buffer.from(msg, 'utf8').toString('hex')}`
        const buffer = [...toBuffer(readable)]
        dispatch(stakePoolActions.stakePoolSigning())

        if (!stakePoolSigning) {
            const sign = await window.ethereum.request({
                method: 'personal_sign',
                params: [readable, from],
            })
            let payload = {
                personalSign: {
                    data: buffer,
                    sig: sign,
                    pubKeyAddress: address,
                    chainId: Web3.utils.hexToNumber(chainId),
                    networkId: Number(network),
                },
                pid: pid,
                coin: coin,
                amount: amount1,
                isLock:isLpLocked
            }
            dispatch(stakePoolActions.stake(payload, token))
            setAmount1("")
            handleClose()
        }
    } catch (err) {
        console.error('stake request cancelled: ', err)
        setSnackBarVisible(true)
        dispatch(stakePoolActions.stakePoolSigningCancelled())
    }
}

export const unStake = async (address, chainId, network, Web3, dispatch, stakePoolSigning,pid,coin, amount1,setAmount1, token, handleClose,setSnackBarVisible) => {
    let msg = 'UnStaking from DumboEx wallet\n\n' +
        `UnStake: ${roundingDown(amount1, 8)} ${coin} \n` +
        `ChainId: ${Web3.utils.hexToNumber(chainId)}`
    try {
        const from = address
        const readable = `0x${Buffer.from(msg, 'utf8').toString('hex')}`
        const buffer = [...toBuffer(readable)]
        dispatch(stakePoolActions.stakePoolSigning())

        if (!stakePoolSigning) {
            const sign = await window.ethereum.request({
                method: 'personal_sign',
                params: [readable, from],
            })
            let payload = {
                personalSign: {
                    data: buffer,
                    sig: sign,
                    pubKeyAddress: address,
                    chainId: Web3.utils.hexToNumber(chainId),
                    networkId: Number(network),
                },
                pid: pid,
                coin: coin,
                amount: amount1,
            }
            dispatch(stakePoolActions.unStake(payload, token))
            setAmount1("")
            handleClose()
        }
    } catch (err) {
        console.error('unStake request cancelled: ', err)
        setSnackBarVisible(true)
        dispatch(stakePoolActions.stakePoolSigningCancelled())
    }
}

export const earnStake = async (address, chainId, network, Web3, dispatch, earnPoolSigning,pid,coin, amount1,setAmount1, token, handleClose,setSnackBarVisible) => {
    let msg = 'Staking Earn from DumboEx wallet\n\n' +
        `Stake: ${roundingDown(amount1, 8)} ${coin} \n` +
        `ChainId: ${Web3.utils.hexToNumber(chainId)}`
    try {
        const from = address
        const readable = `0x${Buffer.from(msg, 'utf8').toString('hex')}`
        const buffer = [...toBuffer(readable)]
        dispatch(earnActions.earnPoolSigning())

        if (!earnPoolSigning) {
            const sign = await window.ethereum.request({
                method: 'personal_sign',
                params: [readable, from],
            })
            let payload = {
                personalSign: {
                    data: buffer,
                    sig: sign,
                    pubKeyAddress: address,
                    chainId: Web3.utils.hexToNumber(chainId),
                    networkId: Number(network),
                },
                pid: pid,
                amount: amount1,
            }
            dispatch(earnActions.earnStake(payload, token))
            dispatch(earnActions.clearEarnRateQuote())
            setAmount1("")
            handleClose()
        }
    } catch (err) {
        console.error('stake earn request cancelled: ', err)
        setSnackBarVisible(true)
        dispatch(earnActions.earnPoolSigningCancelled())
    }
}

export const earnUnStake = async (address, chainId, network, Web3, dispatch, earnPoolSigning,pid,coin, amount1,setAmount1, token, handleClose,setSnackBarVisible) => {
    let msg = 'UnStaking Earn from DumboEx wallet\n\n' +
        `UnStake: ${roundingDown(amount1, 8)} ${coin} \n` +
        `ChainId: ${Web3.utils.hexToNumber(chainId)}`
    try {
        const from = address
        const readable = `0x${Buffer.from(msg, 'utf8').toString('hex')}`
        const buffer = [...toBuffer(readable)]
        dispatch(earnActions.earnPoolSigning())

        if (!earnPoolSigning) {
            const sign = await window.ethereum.request({
                method: 'personal_sign',
                params: [readable, from],
            })
            let payload = {
                personalSign: {
                    data: buffer,
                    sig: sign,
                    pubKeyAddress: address,
                    chainId: Web3.utils.hexToNumber(chainId),
                    networkId: Number(network),
                },
                pid: pid,
                amount: amount1,
            }
            dispatch(earnActions.earnUnStake(payload, token))
            setAmount1("")
            handleClose()
        }
    } catch (err) {
        console.error('unStake earn request cancelled: ', err)
        setSnackBarVisible(true)
        dispatch(earnActions.earnPoolSigningCancelled())
    }
}

export const lockLp = async (address, chainId, network, Web3, dispatch, poolSigning,pid, amount, setAmount1,token1,token2, token, handleClose, setSnackBarVisible) => {
    let msg = 'Lock from DumboEx wallet\n\n' +
        `Lock: ${roundingDown(amount, 8)} ${token1+'-'+token2+'-LP'} \n` +
        `ChainId: ${Web3.utils.hexToNumber(chainId)}`
    try {
        const from = address
        const readable = `0x${Buffer.from(msg, 'utf8').toString('hex')}`
        const buffer = [...toBuffer(readable)]
        dispatch(poolActions.poolSigning())

        if (!poolSigning) {
            const sign = await window.ethereum.request({
                method: 'personal_sign',
                params: [readable, from],
            })
            let payload = {
                personalSign: {
                    data: buffer,
                    sig: sign,
                    pubKeyAddress: address,
                    chainId: Web3.utils.hexToNumber(chainId),
                    networkId: Number(network),
                },
                amount,
                pid,
                token,
            }
            dispatch(poolActions.lockLp(payload))
            setAmount1("")
            handleClose()
        }
    } catch (err) {
        console.error('lock LP request cancelled: ', err)
        setSnackBarVisible(true)
        dispatch(poolActions.poolSigningCancelled())
    }
}

export const unlockLp = async (address, chainId, network, Web3, dispatch, poolSigning,lockerId, amount, setAmount1,token1,token2, token, handleClose, setSnackBarVisible) => {
    let msg = 'Unlock from DumboEx wallet\n\n' +
        `Unlock: ${roundingDown(amount, 8)} ${token1+'-'+token2+'-LP'} \n` +
        `ChainId: ${Web3.utils.hexToNumber(chainId)}`
    try {
        const from = address
        const readable = `0x${Buffer.from(msg, 'utf8').toString('hex')}`
        const buffer = [...toBuffer(readable)]
        dispatch(poolActions.poolSigning())

        if (!poolSigning) {
            const sign = await window.ethereum.request({
                method: 'personal_sign',
                params: [readable, from],
            })
            let payload = {
                personalSign: {
                    data: buffer,
                    sig: sign,
                    pubKeyAddress: address,
                    chainId: Web3.utils.hexToNumber(chainId),
                    networkId: Number(network),
                },
                amount,
                lockerId,
                token,
            }
            dispatch(poolActions.unlockLp(payload))
            setAmount1("")
            handleClose()
        }
    } catch (err) {
        console.error('unlock LP request cancelled: ', err)
        setSnackBarVisible(true)
        dispatch(poolActions.poolSigningCancelled())
    }
}

export const unlockAllLp = async (address, chainId, network, Web3, dispatch, poolSigning, token,setSnackBarVisible, snackBarMessage,t) => {
    let msg = 'Unlock all liquidity from DumboEx wallet\n\n' +
        `ChainId: ${Web3.utils.hexToNumber(chainId)}`
    try {
        const from = address
        const readable = `0x${Buffer.from(msg, 'utf8').toString('hex')}`
        const buffer = [...toBuffer(readable)]
        dispatch(poolActions.poolSigning())

        if (!poolSigning) {
            const sign = await window.ethereum.request({
                method: 'personal_sign',
                params: [readable, from],
            })
            let payload = {
                data: buffer,
                sig: sign,
                pubKeyAddress: address,
                chainId: Web3.utils.hexToNumber(chainId),
                networkId: Number(network),
                token
            }
            dispatch(poolActions.unlockAllLp(payload))
        }
    } catch (err) {
        console.error('unlock all LP request cancelled: ', err)
        snackBarMessage(t('actionCancelled'))
        setSnackBarVisible(true)
        dispatch(poolActions.poolSigningCancelled())
    }
}

export const stakeLock = async (address, chainId, network, Web3, dispatch, stakePoolSigning,pid, amount, setAmount1,token1, token, handleClose, setSnackBarVisible) => {
    let msg = 'Lock from DumboEx wallet\n\n' +
        `Lock: ${roundingDown(amount, 8)} ${token1} \n` +
        `ChainId: ${Web3.utils.hexToNumber(chainId)}`
    try {
        const from = address
        const readable = `0x${Buffer.from(msg, 'utf8').toString('hex')}`
        const buffer = [...toBuffer(readable)]
        dispatch(stakePoolActions.stakePoolSigning())

        if (!stakePoolSigning) {
            const sign = await window.ethereum.request({
                method: 'personal_sign',
                params: [readable, from],
            })
            let payload = {
                personalSign: {
                    data: buffer,
                    sig: sign,
                    pubKeyAddress: address,
                    chainId: Web3.utils.hexToNumber(chainId),
                    networkId: Number(network),
                },
                amount,
                pid,
                token,
            }
            dispatch(stakePoolActions.stakeLock(payload))
            setAmount1("")
            handleClose()
        }
    } catch (err) {
        console.error('stake lock request cancelled: ', err)
        setSnackBarVisible(true)
        dispatch(stakePoolActions.stakePoolSigningCancelled())
    }
}

export const stakeUnlock = async (address, chainId, network, Web3, dispatch, stakePoolSigning,lockerId, amount, setAmount1,token1, token, handleClose, setSnackBarVisible) => {
    let msg = 'Unlock from DumboEx wallet\n\n' +
        `Unlock: ${roundingDown(amount, 8)} ${token1} \n` +
        `ChainId: ${Web3.utils.hexToNumber(chainId)}`
    try {
        const from = address
        const readable = `0x${Buffer.from(msg, 'utf8').toString('hex')}`
        const buffer = [...toBuffer(readable)]
        dispatch(stakePoolActions.stakePoolSigning())

        if (!stakePoolSigning) {
            const sign = await window.ethereum.request({
                method: 'personal_sign',
                params: [readable, from],
            })
            let payload = {
                personalSign: {
                    data: buffer,
                    sig: sign,
                    pubKeyAddress: address,
                    chainId: Web3.utils.hexToNumber(chainId),
                    networkId: Number(network),
                },
                amount,
                lockerId,
                token,
            }
            dispatch(stakePoolActions.stakeUnlock(payload))
            setAmount1("")
            handleClose()
        }
    } catch (err) {
        console.error('stake unlock request cancelled: ', err)
        setSnackBarVisible(true)
        dispatch(stakePoolActions.stakePoolSigningCancelled())
    }
}

export const unlockAllStake = async (address, chainId, network, Web3, dispatch, poolSigning, token,setSnackBarVisible, snackBarMessage,t) => {
    let msg = 'Unlock all Stake from DumboEx wallet\n\n' +
        `ChainId: ${Web3.utils.hexToNumber(chainId)}`
    try {
        const from = address
        const readable = `0x${Buffer.from(msg, 'utf8').toString('hex')}`
        const buffer = [...toBuffer(readable)]
        dispatch(stakePoolActions.stakePoolSigning())

        if (!poolSigning) {
            const sign = await window.ethereum.request({
                method: 'personal_sign',
                params: [readable, from],
            })
            let payload = {
                data: buffer,
                sig: sign,
                pubKeyAddress: address,
                chainId: Web3.utils.hexToNumber(chainId),
                networkId: Number(network),
                token
            }
            dispatch(stakePoolActions.unlockAllStake(payload))
        }
    } catch (err) {
        console.error('unlock all stake request cancelled: ', err)
        snackBarMessage(t('actionCancelled'))
        setSnackBarVisible(true)
        dispatch(stakePoolActions.stakePoolSigningCancelled())
    }
}

export const vote = async (address, chainId, network, Web3, dispatch, voteSigning,pid, amount1,setAmount1, token, handleClose,setSnackBarVisible,option) => {
    let msg = 'Voting from DumboEx wallet\n\n' +
        `Vote: ${roundingDown(amount1, 8)} \n` +
        `ChainId: ${Web3.utils.hexToNumber(chainId)}`
    try {
        const from = address
        const readable = `0x${Buffer.from(msg, 'utf8').toString('hex')}`
        const buffer = [...toBuffer(readable)]
        dispatch(voteActions.voteSigning())

        if (!voteSigning) {
            const sign = await window.ethereum.request({
                method: 'personal_sign',
                params: [readable, from],
            })
            let payload = {
                personalSign: {
                    data: buffer,
                    sig: sign,
                    pubKeyAddress: address,
                    chainId: Web3.utils.hexToNumber(chainId),
                    networkId: Number(network),
                },
                pid: pid,
                amount: amount1,
                option:option
            }
            dispatch(voteActions.vote(payload, token))
            setAmount1("")
            handleClose()
        }
    } catch (err) {
        console.error('vote request cancelled: ', err)
        setSnackBarVisible(true)
        dispatch(voteActions.voteSigningCancelled())
    }
}

export const buyIDO = async (address, chainId, network, Web3, dispatch, walletSigning, buyAmount, idoStartTime, token, handleCloseBuyModal, setBuyAmount, setSnackBarVisible, setSnackMsg, t) => {
    let msg = 'Purchasing IDO from DumboEx wallet\n\n' +
        `IdoAmount: ${buyAmount} USDT \n` +
        `ChainId: ${Web3.utils.hexToNumber(chainId)}`
    try {
        const from = address
        const readable = `0x${Buffer.from(msg, 'utf8').toString('hex')}`
        const buffer = [...toBuffer(readable)]
        dispatch(walletActions.walletSigning())

        if (!walletSigning) {
            const sign = await window.ethereum.request({
                method: 'personal_sign',
                params: [readable, from],
            })
            let payload = {
                personalSign: {
                    data: buffer,
                    sig: sign,
                    pubKeyAddress: address,
                    chainId: Web3.utils.hexToNumber(chainId),
                    networkId: Number(network),
                },
                usdtAmount: buyAmount,
                isPreIdo:new Date(idoStartTime).getTime() > new Date().getTime(),
            }
            dispatch(walletActions.buyIDO(payload, token))
            setBuyAmount("")
            handleCloseBuyModal()
        }
    } catch (err) {
        console.error('buy ido request cancelled: ', err)
        setSnackMsg(t('actionCancelled'))
        setSnackBarVisible(true)
        dispatch(walletActions.walletSigningCancelled(err))
    }
}

export const migrateIDO = async (address, chainId, network, Web3, dispatch, walletSigning, migrateAmount, token, handleCloseMigrateModal, setMigrateAmount, t) => {
    let msg = 'IDO Migrate from DumboEx wallet\n\n' +
        `migrateAmount: ${migrateAmount} SAP \n` +
        `ChainId: ${Web3.utils.hexToNumber(chainId)}`
    try {
        const from = address
        const readable = `0x${Buffer.from(msg, 'utf8').toString('hex')}`
        const buffer = [...toBuffer(readable)]
        dispatch(walletActions.walletSigning())

        if (!walletSigning) {
            const sign = await window.ethereum.request({
                method: 'personal_sign',
                params: [readable, from],
            })
            let payload = {
                personalSign: {
                    data: buffer,
                    sig: sign,
                    pubKeyAddress: address,
                    chainId: Web3.utils.hexToNumber(chainId),
                    networkId: Number(network),
                },
                sapAmount: migrateAmount
            }
            dispatch(walletActions.migrateIDO(payload, token))
            setMigrateAmount("")
            handleCloseMigrateModal()
        }
    } catch (err) {
        console.error('Ido migrate request cancelled: ', err)
        dispatch(walletActions.migrateSigningCancelled(err))
    }
}

export const isMetaMaskConnected = (address) => {
    return address !== ''
}

export const onClickInstall = (setButton1, setButton1Disabled) => {
    onBoard.startOnboarding()
    setButton1('Onboarding in progress')
    setButton1Disabled(true)
}

export const onClickConnect = async (network, chainId, addr, dispatch, handleOpen, registered, loggedIn, loading) => {
    let _chainId
    let _network
    try {
        if (chainId === 0) {
            _chainId = await window.ethereum.request({
                method: 'eth_chainId',
            })

            _network = await window.ethereum.request({
                method: 'net_version',
            })
        }
        await window.ethereum.request({
            method: 'eth_requestAccounts',
        })
        if (!!window.imToken) {
            if (registered !== undefined && loggedIn !== undefined && loading !== undefined) {
                if (chainId === 0) {
                    unlock(addr, _chainId, _network, Web3, false, dispatch)
                } else {
                    await unlock(addr, chainId, network, Web3, false, dispatch)
                }
            }
        } else {
            if (handleOpen !== undefined) {
                handleOpen()
            }
        }

    } catch (error) {
        console.error(error)
    }
}
