import {withTranslation} from "react-i18next";
import {Backdrop, Fade, IconButton, makeStyles, Modal, Snackbar, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import crossIconGrey from "../../../images/crossGrey.png";
import {roundingDown} from "../../../utils/RoundingDown";
import CustomTextField from "../../CommonElements/CustomTextField";
import plusIconGrey from "../../../images/ido/plusIconGrey.png";
import {migrateIDO} from "../../../utils/Sign";
import {walletActions} from "../../../redux/actions/walletActions";
import {inviteActions} from "../../../redux/actions/inviteActions";
import Note from "../../Note/Note";
import CloseIcon from "@material-ui/icons/Close";

const Web3 = require("web3");

const IDOMigrateModal = (props) => {
    const {
        onCancel: handleClose,
        modalVisible,
        t,
        width,
        address,
        chainId,
        network,
        height
    } = props;

    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow:'scroll',
        },
        defaultFont:{
            fontFamily: "PingFang SC",
        }
    }));
    const classes = useStyles();
    const dispatch = useDispatch()

    const { token, userInfo } = useSelector(state => state.auth)
    const { userCapitals, walletSigning, migrateIDOSucceed, migrateIDOFinished, migrateIDOMsg } = useSelector(state => state.wallet)

    const [sapCapital, setSapCapital] = useState(0)
    const [migrateAmount, setMigrateAmount]=useState("");
    const [buyAmountWarning, setBuyAmountWarning] = useState('')
    const [migrateAmountStatus, setMigrateAmountStatus] = useState(0)
    const [openNote, setOpenNote] = useState(false)
    const [noteType, setNoteType] = useState('')
    const [noteTitle, setNoteTitle] = useState('')
    const [noteContent, setNoteContent] = useState('')

    useEffect(() => {
        dispatch(walletActions.getIDOInfo(token))
        dispatch(walletActions.getUserCapital(token))
    }, [])

    useEffect(() => {

        let sap = userCapitals.find(item => item.token === 'SAP')
        if (sap == undefined) {
            sap = {
                free: 0
            }
        }
        setSapCapital(sap.free)
    }, [userCapitals, token, userInfo])

    useEffect(() => {
        checkMigrateInput()
    }, [migrateAmount])

    useEffect(() => {
        if (migrateIDOFinished && migrateIDOMsg !== '') {
            if (migrateIDOSucceed) {
                handleOpenNote('succeed', t('migrateSucceed'), '', 2000)
            } else {
                handleOpenNote('failed', t('migrateFailed'), t(migrateIDOMsg))
            }
        }
        return () => {
        }
    }, [migrateIDOMsg, migrateIDOSucceed, migrateIDOFinished])

    const handleCloseMigrateModal = () => {
        handleClose();
        setMigrateAmount('')
    };

    const handleMigrateInputChange = (e) => {
        setMigrateAmount(e.target.value)
    }

    const checkMigrateInput = () => {
        if (migrateAmount === '') {
            setBuyAmountWarning('')
            setMigrateAmountStatus(0)
        } else {
            if (parseFloat(migrateAmount) < 1) {
                setBuyAmountWarning(t('lessThanMinMigrate'))
                setMigrateAmountStatus(2)
            } else if (parseFloat(migrateAmount) > sapCapital) {
                setBuyAmountWarning(t('balanceLow'))
                setMigrateAmountStatus(2)
            } else {
                setBuyAmountWarning('')
                setMigrateAmountStatus(1)
            }
        }
    }

    const handleOpenNote = (type, title, content, time) => {
        setNoteType(type)
        setNoteTitle(t(title))
        setNoteContent(content)
        setOpenNote(true);
        if (time) {
            setTimeout(() => {
                handleCloseNote()
            }, time)
        }
    };

    const handleCloseNote = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpenNote(false);
        dispatch(walletActions.clearInfo())
    };

    // useEffect(() => {
    //     if (buyIDOFinished && buyIDOMsg !== '') {
    //         if (buyIDOSucceed) {
    //             handleOpenNote('succeed', t('buySucceed'), '', 2000)
    //         } else {
    //             handleOpenNote('failed', t('buyFailed'), t(buyIDOMsg))
    //         }
    //     }
    //     return () => {
    //     }
    // }, [buyIDOMsg, buyIDOSucceed, buyIDOFinished])

    const IDOMigrateModalBody = (
        <div style={{borderRadius: 20, backgroundColor: "white", width:"90%", maxWidth:396, display: "flex", alignItems: "center", flexDirection: "column"}}>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "85%", marginTop: 24}}>
                <Typography className={classes.defaultFont} style={{ fontSize: 28, fontWeight: 'bold' }}>{t('migrate')}</Typography>
                <img src={crossIconGrey} className="modal-close-icon" onClick={handleCloseMigrateModal} />
            </div>

            <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginTop: 30, marginBottom: 16, width: "85%"}}>
                <div style={{ display: 'flex', flexDirection: 'column',marginBottom:12 }}>
                    <Typography className={classes.defaultFont} style={{ fontSize:14, color: "#8786A7" }}>
                        {t('migrationPrice')}: 3 SAP = 1 DBO
                    </Typography>
                    <Typography className={classes.defaultFont} style={{ fontSize:14, color: "#8786A7" }}>
                        {t('balance')}: {roundingDown(sapCapital, 4)} SAP
                    </Typography>
                </div>
            </div>

            <CustomTextField
                placeholder={t('migrateInputLabel')}
                label={''}
                // disabled={buyBtnDisabled}
                className="buy-modal-input"
                onChange={handleMigrateInputChange}
                value={migrateAmount}
                helperText={buyAmountWarning}
                error={buyAmountWarning !== ''}
                rightbuttonlabel={t('max')}
                onrightbuttonclick={()=>{setMigrateAmount(roundingDown(sapCapital, 4))}}
                customtype="number"
                InputLabelProps={{
                    color: 'white'
                }}
                decimal={4}
            />

            <div className="modal-input-info" style={{ marginTop: 30, marginBottom: 40}}>
                <Typography className={classes.defaultFont} style={{ marginBottom:10, fontSize:14, opacity: 0.7, color: "#8786A7" }}>{t('estReceiving')}</Typography>
                <div style={{border: "0.2px solid #8786A7", borderRadius: 20}}>
                    <div style={{margin:30, display:"flex", flexDirection:"column", alignItems:"center"}}>
                        <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                            <Typography className={classes.defaultFont} style={{fontSize:16, fontWeight:600}}>{t('vesting')}</Typography>
                            <Typography className={classes.defaultFont} style={{fontSize:16, fontWeight:600}}>{migrateAmount ? roundingDown(migrateAmount /3,4) : "-"} DBO</Typography>
                        </div>
                        <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                            <Typography className={classes.defaultFont} style={{fontSize:14, opacity: 0.7, color: "#8786A7"}}>100%</Typography>
                            <Typography className={classes.defaultFont} style={{fontSize:14, opacity: 0.7, color: "#8786A7"}}>${ migrateAmount? roundingDown(migrateAmount/3/10,2):"-"}</Typography>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal-btn-wrapper" style={{paddingBottom:30}}>
                <button style={{ opacity: migrateAmountStatus !== 1 ? 0.5 : 1, backgroundColor:"#271CFF" }} className="buy-btn" onClick={async () => {
                    handleOpenNote('confirm', t('confirmTitle'), t('confirmContent'))
                    handleCloseMigrateModal()
                    await migrateIDO(address, chainId, network, Web3, dispatch, walletSigning, migrateAmount, token, handleCloseMigrateModal, setMigrateAmount, t)
                }} disabled={migrateAmountStatus !== 1}>
                    <Typography style={{ fontSize: 20, fontWeight: 'bold', color: 'white' }}>
                        {t('migrate')}
                    </Typography>
                </button>
            </div>
        </div>
    )
    return (
        <>
            <Modal
                disableEnforceFocus
                disableAutoFocus
                aria-labelledby="server-modal-title"
                aria-describedby="server-modal-description"
                open={modalVisible}
                onClose={handleCloseMigrateModal}
                BackdropComponent={Backdrop}
                className={classes.modal}
                BackdropProps={{
                    className:
                        'modalBackground'
                }}
                closeAfterTransition
            >
                {IDOMigrateModalBody}
            </Modal>

            <Note
                btnAction={handleCloseNote}
                btnText={""}
                width={width}
                height={height}
                openNote={openNote}
                handleCloseNote={handleCloseNote}
                showBtn={false}
                noteType={noteType}
                noteTitle={noteTitle}
                noteContent={noteContent}
            />
        </>
    )
}

export default withTranslation()(IDOMigrateModal);