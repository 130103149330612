import {withTranslation} from "react-i18next";
import {Backdrop, Fade, IconButton, makeStyles, Modal, Snackbar, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {isMobile} from "react-device-detect";
import crossIconWhite from "../../../images/crossWhite.png";
import {roundingDown} from "../../../utils/RoundingDown";
import CustomTextField from "../../CommonElements/CustomTextField";
import {buyIDO} from "../../../utils/Sign";
import {useDispatch, useSelector} from "react-redux";
import {walletActions} from "../../../redux/actions/walletActions";
import Note from "../../Note/Note";
import {inviteActions} from "../../../redux/actions/inviteActions";
import CloseIcon from "@material-ui/icons/Close";
import plusIcon from "../../../images/ido/plusIcon.png"

const Web3 = require("web3");

const IDOBuyModal = (props) => {
    const {
        onCancel: handleClose,
        modalVisible,
        t,
        width,
        address,
        chainId,
        network,
        height

    } = props;
    const { token, userInfo } = useSelector(state => state.auth)
    const { userCapitals, walletSigning, buyIDOFinished, buyIDOSucceed, buyIDOMsg, idoInfo, idoInfoMsg } = useSelector(state => state.wallet)

    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow:'scroll',
        },
        defaultFont:{
            fontFamily: "PingFang SC",
        }
    }));
    const classes = useStyles();
    const dispatch = useDispatch()

    const [usdtCapital, setUsdtCapital] = useState(0)
    const [quota, setQuota] = useState(0)
    const [buyAmount, setBuyAmount] = useState('')
    const [buyAmountWarning, setBuyAmountWarning] = useState('')
    const [buyAmountStatus, setBuyAmountStatus] = useState(0)

    const [noteType, setNoteType] = useState('')
    const [noteTitle, setNoteTitle] = useState('')
    const [noteContent, setNoteContent] = useState('')
    const [openNote, setOpenNote] = useState(false)

    const [snackBarVisible, setSnackBarVisible] = useState(false)
    const [snackMsg, setSnackMsg] = useState('')

    const [idoStartTime, setIdoStartTime] = useState('')

    useEffect(() => {
        dispatch(walletActions.getIDOInfo(token))
        dispatch(walletActions.getUserCapital(token))
    }, [])

    useEffect(() => {

        let usdt = userCapitals.find(item => item.token === 'USDT')
        if (usdt == undefined) {
            usdt = {
                free: 0
            }
        }
        setUsdtCapital(usdt.free)
        if (userInfo !== undefined) {
            setQuota(5000 - userInfo.idoInvestment)
        }
    }, [userCapitals, token, userInfo])

    useEffect(() => {
        if (idoInfoMsg === 'succeed') {
            setIdoStartTime(idoInfo.startAt)
        }
    }, [idoInfo, idoInfoMsg])

    useEffect(() => {
        checkBuyInput()
    }, [buyAmount])

    useEffect(() => {
        if (buyIDOFinished && buyIDOMsg !== '') {
            if (buyIDOSucceed) {
                handleOpenNote('succeed', t('buySucceed'), '', 2000)
            } else {
                handleOpenNote('failed', t('buyFailed'), t(buyIDOMsg))
            }
        }
        return () => {
        }
    }, [buyIDOMsg, buyIDOSucceed, buyIDOFinished])

    const handleBuyInputChange = (e) => {
        setBuyAmount(e.target.value)
    }

    const max = () => {
        let maxString = roundingDown(Math.min(quota, usdtCapital), 0)
        if (maxString.includes('.')) {
            maxString = maxString.slice(0, -1)
        }
        setBuyAmount(maxString)
    }

    const handleOpenNote = (type, title, content, time) => {
        setNoteType(type)
        setNoteTitle(t(title))
        setNoteContent(content)
        setOpenNote(true);
        if (time) {
            setTimeout(() => {
                handleCloseNote()
            }, time)
        }
    };

    const handleCloseNote = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpenNote(false);
        dispatch(inviteActions.clearModalInfo())
        dispatch(walletActions.clearInfo())
    };

    const handleCloseBuyModal = () => {
        handleClose();
        setBuyAmount('')
    };

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBarVisible(false);
    };

    const checkBuyInput = () => {

        if (buyAmount === '') {
            setBuyAmountWarning('')
            setBuyAmountStatus(0)
        } else {
            if (parseFloat(buyAmount) < 100) {
                setBuyAmountWarning(t('lessThanBuyMin'))
                setBuyAmountStatus(2)
            } else if (parseFloat(buyAmount) > quota || parseFloat(buyAmount) > usdtCapital) {
                setBuyAmountStatus(2)
                if (Math.min(quota, usdtCapital) === usdtCapital) {
                    setBuyAmountWarning(t('balanceLow'))
                } else {
                    setBuyAmountWarning(t('exceedQuota'))
                }
            } else {
                setBuyAmountWarning('')
                setBuyAmountStatus(1)
            }
        }
    }

    const IDOBuyModalBody = (
        <div className="buy-modal-body" style={{borderRadius: 20, background: "linear-gradient(222.67deg, #4A3FD5 0%, #251CFF 100%)", maxWidth:396, height:"auto"}}>

            <div className="modal-header" >
                <Typography className={classes.defaultFont} style={{ color: 'white', fontSize: 28, fontWeight: 'bold' }}>{t('buy')}</Typography>
                <img src={crossIconWhite} className="modal-close-icon" onClick={handleCloseBuyModal} />
            </div>
            <div className="modal-input-info" style={{ marginTop: 30, marginBottom: 16}}>
                <div style={{ display: 'flex', flexDirection: 'column',marginBottom:12 }}>
                    <Typography className={classes.defaultFont} style={{ fontSize:14, opacity: 0.7, color: "#FFFFFF" }}>
                        {t('quota')}: {`${userInfo.idoInvestment < 1 ? '0' : userInfo.idoInvestment}/${5000 - userInfo.idoInvestment} USDT`}
                    </Typography>
                    <Typography className={classes.defaultFont} style={{ fontSize:14, opacity: 0.7, color: "#FFFFFF" }}>
                        {t('balance')}: {roundingDown(usdtCapital, 4)} USDT
                    </Typography>
                </div>

            </div>
            <CustomTextField
                placeholder={t('buyInputLabel')}
                label={''}
                // disabled={buyBtnDisabled}
                className="buy-modal-input"
                onChange={handleBuyInputChange}
                value={buyAmount}
                helperText={buyAmountWarning}
                error={buyAmountWarning !== ''}
                rightbuttonlabel={t('max')}
                onrightbuttonclick={max}
                customtype="number"
                InputLabelProps={{
                    color: 'white'
                }}
                int={true}
            />
            <div className="modal-input-info" style={{ marginTop: 30, marginBottom: 40}}>
                <Typography className={classes.defaultFont} style={{ marginBottom:10, fontSize:14, opacity: 0.7, color: "#FFFFFF" }}>{t('estReceiving')}</Typography>
                <div style={{border: "0.2px solid #FFFFFF", borderRadius: 20}}>
                    <div style={{margin:30, display:"flex", flexDirection:"column", alignItems:"center"}}>
                        <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                            <Typography className={classes.defaultFont} style={{fontSize:16, fontWeight:600, color:"white"}}>{t('liquidityPool')}</Typography>
                            <Typography className={classes.defaultFont} style={{fontSize:16, fontWeight:600, color:"white"}}>{buyAmount ? buyAmount * 4 :"-"} mDBO</Typography>
                        </div>
                        <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                            <Typography className={classes.defaultFont} style={{fontSize:14, opacity: 0.7, color: "#FFFFFF"}}>80%</Typography>
                            <Typography className={classes.defaultFont} style={{fontSize:14, opacity: 0.7, color: "#FFFFFF"}}>${buyAmount ? roundingDown(buyAmount * 0.8,2) : "-"}</Typography>
                        </div>
                        <img src={plusIcon} style={{width:15, height:15}}/>
                        <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                            <Typography className={classes.defaultFont} style={{fontSize:16, fontWeight:600, color:"white"}}>{t('currentReleased')}</Typography>
                            <Typography className={classes.defaultFont} style={{fontSize:16, fontWeight:600, color:"white"}}>{buyAmount ? roundingDown(buyAmount * 0.2 *(1 + 0.0054* (idoInfo.bonusMultiplier ? idoInfo.bonusMultiplier : 0)) *10,4) : "-"} DBO</Typography>
                        </div>
                        <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                            <Typography className={classes.defaultFont} style={{fontSize:14, opacity: 0.7, color: "#FFFFFF"}}>- 20%</Typography>
                            <Typography className={classes.defaultFont} style={{fontSize:14, opacity: 0.7, color: "#FFFFFF"}}>${buyAmount ? roundingDown(buyAmount * 0.2 *(1 + 0.0054 * (idoInfo.bonusMultiplier ? idoInfo.bonusMultiplier : 0)),2) : "-"}</Typography>
                        </div>
                        <Typography className={classes.defaultFont} style={{fontSize:14, opacity: 0.7, color: "#FFFFFF", width:"100%"}}>- {t('additionalReward')} 0.54%*{idoInfo.bonusMultiplier ? idoInfo.bonusMultiplier:0}</Typography>
                    </div>
                </div>
            </div>

            <div className="modal-btn-wrapper" style={{paddingBottom:30}}>
                <button style={{ opacity: buyAmountStatus !== 1 ? 0.7 : 1 }} className="buy-btn" onClick={async () => {
                    handleOpenNote('confirm', t('confirmTitle'), t('confirmContent'))
                    handleCloseBuyModal()
                    await buyIDO(address, chainId, network, Web3, dispatch, walletSigning, buyAmount, idoStartTime, token, handleCloseBuyModal, setBuyAmount, setSnackBarVisible, setSnackMsg, t)
                }} disabled={buyAmountStatus !== 1}>
                    <Typography style={{ fontSize: 20, fontWeight: 'bold', color: 'black' }}>
                        {t('buy')}
                    </Typography>
                </button>
            </div>
        </div>
    );

    return (
        <>
            <Modal
                disableEnforceFocus
                disableAutoFocus
                aria-labelledby="server-modal-title"
                aria-describedby="server-modal-description"
                open={modalVisible}
                onClose={handleCloseBuyModal}
                BackdropComponent={Backdrop}
                className={classes.modal}
                BackdropProps={{
                    className:
                        'modalBackground'
                }}
                closeAfterTransition
            >
                {IDOBuyModalBody}
            </Modal>

            <Note
                btnAction={handleCloseNote}
                btnText={""}
                width={width}
                height={height}
                openNote={openNote}
                handleCloseNote={handleCloseNote}
                showBtn={false}
                noteType={noteType}
                noteTitle={noteTitle}
                noteContent={noteContent}
            />

            <Snackbar
                open={snackBarVisible}
                onClose={handleSnackClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={3000}
                TransitionComponent={Fade}
                message={snackMsg}
                key={"cancelSign"}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </>
    )
}

export default withTranslation()(IDOBuyModal);
