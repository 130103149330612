import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Backdrop, Button, Fade, Grid, List, ListItem, Modal, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet'
import './Home.css';
import { withTranslation } from 'react-i18next';
import useWindowDimensions from '../../utils/WindowDimensions'
import lightIcon from "../../images/swap/lightIcon.png";
import HistoryIcon from "../../images/wallet/HistoryIcon.png";
import transferIcon from "../../images/wallet/transferIcon.png";
import withdrawIcon from "../../images/wallet/withdrawIcon.png";
import depositIcon from "../../images/wallet/depositIcon.png";
import { walletActions } from '../../redux/actions/walletActions';
import { useDispatch, useSelector } from 'react-redux';
import { roundingDown } from '../../utils/RoundingDown'
import { getIcons } from "../../utils/Common";
import { authActions } from "../../redux/actions";
import { isMobile } from 'react-device-detect';
import walletLeftBanner from "../../images/wallet/walletLeftBanner.png";
import Lottie from 'lottie-react-web'
import animation from "../Animations/110200-mobile-setting.json";
// const withdrawIcon = 'https://cdn.swapallapp.com/l2/images/withdraw.png'

// const badgetIcon = 'https://cdn.swapallapp.com/l2/images/badgetIcon.png'
// const transferIcon = 'https://cdn.swapallapp.com/l2/images/transferIcon.png'

function Wallet({ t, navBarHeight, address, setOpenSliderModal, handleCloseSliderModal, setSignUpPayload, sliderInfo, network, chainId }) {
    const { height, width } = useWindowDimensions();
    const useStyles = makeStyles((theme) => ({
        root: {
            ...theme.typography.button,
            backgroundColor: '#010846',
            padding: theme.spacing(1),
            flexGrow: 1,
            minHeight: height - navBarHeight,
            textAlign: 'center'
        },
        walletBox: {
            width: 441,
            borderRadius: 24,
            backgroundColor: '#101B66',
            justifyContent: 'center',
            display: 'inline-flex',
            marginTop: 100,
            paddingTop: 30,
            paddingBottom: 30
        },
        walletContent: {
            width: 300,
            alignItems: 'flex-start',
        },
        wrapper: {
            color: '#8786A7',
            alignItems: 'flex-start',
            display: 'flex',

            backgroundColor: 'blue',
        },
        result: {
            fontSize: 10,
            color: '#000000',
            width: 200
        },
        btn: {
            backgroundColor: '#83ACF4',
            color: '#010846',
            borderColor: 'transparent',
            borderRadius: 25,
            height: 45,
            fontWeight: 600,
        },
        btn_disabled: {
            backgroundColor: '#83ACF4',
            color: '#010846',
            borderColor: 'transparent',
            borderRadius: 25,
            height: 45,
            fontWeight: 600,
            opacity: 0.2
        },
        capitalList: {
            width: '100%',
            backgroundColor: 'transparent',
        },
        recordWrapper: {
            backgroundColor: 'transparent',
            color: '#8786A7',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        textLarge: {
            color: '#8786A7',
            fontSize: width < 960 ? 16 : 24,
            fontWeight: '600',
        },

        textMid: {
            color: '#8786A7',
            fontSize: 20,
            fontWeight: '600'
        },

        textSmall: {
            color: '#8786A7',
            fontSize: width < 960 ? 12 : 16,
            fontWeight: '600',
            textTransform: 'none'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        defaultFont: {
            fontWeight: '400', color: '#363636', fontFamily: 'Avenir', textTransform: 'none'
        },
    }));
    const classes = useStyles();

    const { token, loggedIn, userInfo } = useSelector(state => state.auth)
    const { userCapitals, tokenIcons } = useSelector(state => state.wallet)
    const [moneyOut, setMoneyOut] = useState(false)
    const [openNote, setOpenNote] = useState(false)

    const dispatch = useDispatch();

    const handleOpenNote = () => {
        setOpenNote(true);
    };

    const handleCloseNote = () => {
        setOpenNote(false);
    };

    useEffect(() => {
        if (loggedIn) {
            dispatch(walletActions.getUserCapital(token))
            dispatch(walletActions.getAllTokenStatus(token))
            dispatch(authActions.getUserInfo(token))
        }
        return () => {
        }
    }, [])

    useEffect(() => {
        setMoneyOut(!!userInfo.withdraw)
    }, [userInfo])

    const body = (
        <div style={{
            backgroundColor: 'white', borderRadius: 24, width: isMobile ? 350 : 450, overflow: 'hidden', outline: 0, display: 'flex', alignItems: 'center', flexDirection: 'column'
        }}>

            <div style={{ width: width < 950 ? 60 : 140, height: width < 950 ? 60 : 140, marginTop: 24 }}>
                <Lottie
                    speed={0.6}
                    options={{
                        animationData: animation,
                        loop: true,
                    }}
                />
            </div>
            <Typography className={classes.defaultFont} style={{ fontSize: 20, fontWeight: 'bold', marginTop: 8, color: '#363636', textAlign: 'center' }}>
                {t('moneyOutClosedTitle')}
            </Typography>

            <Typography className={classes.defaultFont} style={{ fontSize: 12, fontWeight: 'bold', marginTop: 4, color: '#8A96A7', marginBottom: 40 }}>
                {t('moneyOutClosed')}
            </Typography>
        </div>
    );

    return (
        // loggedIn ?
        <div style={{ display: 'flex', minHeight: 900, flexDirection: 'column', alignItems: 'center' }}>
            <Helmet>
                <title>DumboEx Wallet</title>
                <meta name="description" content="Wallet | DumboEx Wallet" />
            </Helmet>

            <div style={{ width: isMobile ? "100%" : '71%', flexDirection: isMobile ? 'column' : 'row', justifyContent: isMobile ? "unset" : 'space-between', marginTop: isMobile ? "0" : "3%", display: 'flex' }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    backgroundColor: "#F1F9FF",
                    justifyContent: 'space-between',
                    width: isMobile ? "100%" : '38%',
                    height: isMobile ? "unset" : 620,
                    paddingBottom: 24
                }}>
                    <Typography style={{ fontSize: 32, fontFamily: 'Syncopate', color: '#251CFF', marginLeft: 24, marginTop: 24 }}>{t('wallet')}</Typography>

                    <Typography className={classes.defaultFont} style={{ fontSize: isMobile ? 12 : 14, fontWeight: 'bold', color: '#8FB9E1', textAlign: 'left', marginLeft: 24, marginRight: 24 }}>
                        {t('swap-announce')}
                    </Typography>



                    {
                        isMobile ?
                            null
                            :

                            <img src={walletLeftBanner} style={{ width: '96%' }}>
                            </img>
                    }
                </div>

                <div style={{ width: isMobile ? "100%" : '60%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

                    <div style={{ width: '100%' }}>
                        <Button style={{ width: '100%', backgroundColor: '#251CFF', marginBottom: isMobile ? 0 : 24, height: isMobile ? 80 : 120, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', borderRadius: 0 }} >

                            <div style={{ marginLeft: isMobile ? 24 : 32 }}>
                                <Typography className={classes.defaultFont} style={{ color: 'white', fontSize: isMobile ? 12 : 14, textAlign: 'left' }}>已连接钱包</Typography>
                                <div style={{ display: 'flex' }}>

                                    <Typography className={classes.defaultFont} style={{ color: 'white', fontFamily: 'Syncopate', fontSize: isMobile ? 16 : 28 }}>{address.slice(0, 8) + "..." + address.slice(address.length - 3)}</Typography>
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginRight: isMobile ? 24 : 32 }}>
                                {/* <Typography className={classes.defaultFont} style={{ color: 'white', fontSize: isMobile ? 12 : 16, marginRight: 16 }}>{button1}</Typography> */}
                                {/* <img style={{ height: isMobile ? 40 : 64 }} src={unlockEnterIcon}></img> */}

                            </div>
                        </Button>
                    </div>

                    <div style={{
                        display: "flex",
                        flexDirection: "column",


                        backgroundColor: "#f9f9f9",
                        width: isMobile ? '100%' : '100%',
                        // height: isMobile ? "unset" : 644,
                        justifyContent: 'center',
                        alignItems: 'center',


                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            width: '91%',
                            marginTop: isMobile ? 24 : 40,
                            justifyContent: 'space-between',
                        }}>


                            <Link to='/deposit' style={{ width: '30%', height: isMobile ? 100 : 200, backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textDecoration: 'none' }}>
                                <img alt='' style={{ width: "60%" }} src={depositIcon}></img>
                                <Typography className={classes.defaultFont} style={{ fontSize: isMobile ? 12 : 16 }}>{t('deposit')}</Typography>
                            </Link>
                            {
                                moneyOut ?
                                    <Link to='/withdraw' style={{ width: '30%', height: isMobile ? 100 : 200, backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textDecoration: 'none' }}>
                                        <img alt='' style={{ width: "60%" }} src={withdrawIcon}></img>
                                        <Typography className={classes.defaultFont} style={{ fontSize: isMobile ? 12 : 16 }}>{t('withdraw')}</Typography>
                                    </Link>
                                    :
                                    <div onClick={() => handleOpenNote()} style={{ width: '30%', height: isMobile ? 100 : 200, backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textDecoration: 'none' }}>
                                        <img alt='' style={{ width: "60%" }} src={withdrawIcon}></img>
                                        <Typography className={classes.defaultFont} style={{ fontSize: isMobile ? 12 : 16 }}>{t('withdraw')}</Typography>
                                    </div>
                            }
                            {
                                moneyOut ?

                                    <Link to='/transfer' style={{ width: '30%', height: isMobile ? 100 : 200, backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textDecoration: 'none' }}>
                                        <img alt='' style={{ width: "60%" }} src={transferIcon}></img>
                                        <Typography className={classes.defaultFont} style={{ fontSize: isMobile ? 12 : 16 }}>{t('transfer')}</Typography>
                                    </Link>
                                    :
                                    <div onClick={() => handleOpenNote()} style={{ width: '30%', height: isMobile ? 100 : 200, backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textDecoration: 'none' }}>
                                        <img alt='' style={{ width: "60%" }} src={transferIcon}></img>
                                        <Typography className={classes.defaultFont} style={{ fontSize: isMobile ? 12 : 16 }}>{t('transfer')}</Typography>
                                    </div>
                            }

                        </div>

                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            width: '91%',
                            justifyContent: 'space-between',
                            marginTop: 24,
                            marginBottom: isMobile ? 24 : 40,
                        }}>
                            <Link to='/records' style={{ width: '30%', height: isMobile ? 100 : 200, backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textDecoration: 'none' }}>
                                <img style={{ width: "60%" }} src={HistoryIcon}></img>


                                <Typography className={classes.defaultFont} style={{ fontSize: isMobile ? 12 : 16 }}>{t('records')}</Typography>

                            </Link>




                        </div>

                    </div>
                </div>


            </div>




            <div style={{ width: isMobile ? "91%" : '71%', marginTop: 32, marginBottom: isMobile ? 0 : 16 }}>

                <Typography className={classes.defaultFont} style={{ textTransform: 'none', color: '#8B96A7', fontSize: isMobile ? 12 : 20 }} >
                    {t('capitalTitle')}
                </Typography>
            </div>
            <div style={{ width: isMobile ? "100%" : '71%', flexDirection: isMobile ? 'column' : 'row', justifyContent: isMobile ? "center" : 'center', alignItems: 'center', display: 'flex', backgroundColor: isMobile ? "white" : '#F5F5F5', marginBottom: 100 }}>
                <div style={{ width: '98%', marginTop: isMobile ? 16 : 24 }}>


                    {/* <div className='cards__title__wrapper'>
                        <Typography className={classes.textLarge} style={{ textTransform: 'none' }}>
                            {t('walletTitle')}

                        </Typography>


                        <Link to='/records' style={{textDecoration:'none'}}>
                            <Button className={classes.textSmall} color="textSecondary">
                                {t('records')}
                            </Button>
                        </Link>
                    </div> */}
                    {/* <Link to='/deposit' style={{ textDecoration: 'none', width: '98%', height: 80, backgroundColor: 'white', borderRadius: 16, border: '2px solid #DCDCDC', display: 'flex', justifyContent: 'space-between', flexDirection: 'row', overflow: 'hidden', marginTop: 40 }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={depositIcon} style={{ height: 60, width: 60, marginLeft: 16 }}></img>
                            <div style={{ marginLeft: 16 }}>
                                <Typography style={{ fontSize: 18, color: '#8786A7', fontWeight: 'bold' }} >
                                    {t('deposit')}
                                </Typography>

                                <Typography style={{ fontSize: 12, color: '#8FB9E1', fontWeight: 'bold' }} >
                                    {t('depositSub')}
                                </Typography>
                            </div>
                        </div>

                    </Link> */}

                    {/* {
                        !moneyOut ?
                            <Link to='/withdraw' style={{ textDecoration: 'none', width: '98%', height: 80, backgroundColor: 'white', borderRadius: 16, border: '2px solid #DCDCDC', display: 'flex', justifyContent: 'space-between', flexDirection: 'row', overflow: 'hidden', marginTop: 24 }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={withdrawIcon} style={{ height: 60, width: 60, marginLeft: 16 }}></img>
                                    <div style={{ marginLeft: 16 }}>
                                        <Typography style={{ fontSize: 18, color: '#8786A7', fontWeight: 'bold' }} >
                                            {t('withdraw')}
                                        </Typography>

                                        <Typography style={{ fontSize: 12, color: '#8FB9E1', fontWeight: 'bold' }} >
                                            {t('withdrawSub')}
                                        </Typography>
                                    </div>
                                </div>
                            </Link> :
                            <div onClick={() => handleOpenNote()} style={{ textDecoration: 'none', width: '98%', height: 80, backgroundColor: 'white', borderRadius: 16, border: '2px solid #DCDCDC', display: 'flex', justifyContent: 'space-between', flexDirection: 'row', overflow: 'hidden', marginTop: 24 }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={withdrawIcon} style={{ height: 60, width: 60, marginLeft: 16 }}></img>
                                    <div style={{ marginLeft: 16 }}>
                                        <Typography style={{ fontSize: 18, color: '#8786A7', fontWeight: 'bold' }} >
                                            {t('withdraw')}
                                        </Typography>

                                        <Typography style={{ fontSize: 12, color: '#8FB9E1', fontWeight: 'bold' }} >
                                            {t('withdrawSub')}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                    } */}
                    {/* {
                        moneyOut ?
                            <Link to='/transfer' style={{ textDecoration: 'none', width: '98%', height: 80, backgroundColor: 'white', borderRadius: 16, border: '2px solid #DCDCDC', display: 'flex', justifyContent: 'space-between', flexDirection: 'row', overflow: 'hidden', marginTop: 24 }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={transferIcon} style={{ height: 60, width: 60, marginLeft: 16 }}></img>
                                    <div style={{ marginLeft: 16 }}>
                                        <Typography style={{ fontSize: 18, color: '#8786A7', fontWeight: 'bold' }} >
                                            {t('transfer')}
                                        </Typography>

                                        <Typography style={{ fontSize: 12, color: '#8FB9E1', fontWeight: 'bold' }} >
                                            {t('transferSubtitle')}
                                        </Typography>
                                    </div>
                                </div>
                            </Link> :
                            <div onClick={() => handleOpenNote()} style={{ textDecoration: 'none', width: '98%', height: 80, backgroundColor: 'white', borderRadius: 16, border: '2px solid #DCDCDC', display: 'flex', justifyContent: 'space-between', flexDirection: 'row', overflow: 'hidden', marginTop: 24 }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={transferIcon} style={{ height: 60, width: 60, marginLeft: 16 }}></img>
                                    <div style={{ marginLeft: 16 }}>
                                        <Typography style={{ fontSize: 18, color: '#8786A7', fontWeight: 'bold' }} >
                                            {t('transfer')}
                                        </Typography>

                                        <Typography style={{ fontSize: 12, color: '#8FB9E1', fontWeight: 'bold' }} >
                                            {t('transferSubtitle')}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                    } */}
                    {/* <div className='cards__buttons__wrapper'>
                            <Link to='/wallet/deposit' style={{ textDecoration: 'none', alignItems: 'center', justifyContent: 'center' }}>
                                <Button style={{ textTransform: 'none', backgroundColor: '#1DF0A9', fontSize: 16, fontWeight: 'bold', height: 42, borderRadius: 21, padding: 16, color: '#010746' }}>
                                    {t('deposit')}
                                </Button>
                            </Link>
                            <Link to='/wallet/withdraw' style={{ textDecoration: 'none', alignItems: 'center', justifyContent: 'center', marginLeft: 16 }}>
                                <Button style={{ textTransform: 'none', backgroundColor: '#192786', fontSize: 16, fontWeight: 'bold', height: 42, borderRadius: 21, padding: 16, color: '#8FB9E1' }}>
                                    {t('withdraw')}
                                </Button>
                            </Link>
                        </div> */}



                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{}}>
                        {
                            userCapitals.map(item => (
                                <Grid item sm={12} md={6} lg={4} key={item.id} button style={{ display: 'flex', alignItems: 'center',width:'100%'}}>
                                    <div style={{ width: '100%', height: isMobile ? 124 : 160, flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
                                        <div style={{ width: '94%', height: isMobile ? 110 : 140, flexDirection: 'column', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', backgroundColor: isMobile ? "#f5f5f5" : 'white', borderRadius: 16 }}>
                                            <div style={{ display: 'flex', marginLeft: 16, alignItems: 'center' }}>
                                                <div style={{ backgroundColor: isMobile ? "white" : '#f5f5f5', flexDirection: 'column', width: isMobile ? 46 : 60, height: isMobile ? 46 : 60, alignItems: 'center', justifyContent: 'center', display: 'flex', borderRadius: 8 }}>
                                                    <img alt='' style={{ height: isMobile ? 24 : 36, width: isMobile ? 24 : 36 }} variant="square" src={getIcons(item.token, tokenIcons, true)} />
                                                </div>
                                                <div style={{ marginLeft: 12 }}>

                                                    <Typography className={classes.defaultFont} style={{ fontSize: isMobile ? 12 : 14, color: '#8B96A7', marginTop: 6 }}>{t("stakePool-poolRecord-token")} </Typography>
                                                    <Typography className={classes.defaultFont} style={{ fontSize: isMobile ? 16 : 20, opacity: 1, color: '#363636',fontWeight:'bold' }}>{item.token}</Typography>

                                                </div>
                                            </div>


                                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between',alignItems:'center',marginTop:12 }}>

                                                <Typography className={classes.defaultFont} style={{ marginLeft: 24, fontSize: isMobile ? 12 : 14, color: '#8B96A7'}}>{t("balance")} </Typography>
                                                <Typography style={{ fontSize: isMobile ? 20 : 24, color: '#251CFF', fontFamily: 'Syncopate', marginRight: 24 }}>{roundingDown(item.free, 4)} </Typography>

                                            </div>


                                        </div>
                                    </div>
                                </Grid>
                            ))
                        }


                    </Grid>



                    {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ backgroundColor: 'red' }}>
                        <Grid item xs={6} md={3} >
                            <div className={classes.wrapper}>
                                {userCapitals === undefined || userCapitals.length <= 0 ?
                                    <Typography style={{ fontSize: 13 }}>{t('noCapitals')}</Typography> :
                                    <List className={classes.capitalList}>
                                        {
                                            userCapitals.map(item => (
                                                <ListItem style={{ marginBottom: 16, borderRadius: 16, alignItems: 'center', justifyContent: 'center', width: '98%', backgroundColor: '#f5f5f5' }} key={item.id} button>
                                                    <div style={{ width: '96%', height: '84%', flexDirection: 'column', display: 'flex', alignItems: 'flex-start' }}>
                                                        <Typography style={{ fontSize: 14, fontWeight: 'bold', opacity: 0.8, marginLeft: 0, marginTop: 8 }}>{item.token}</Typography>
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 8 }}>

                                                            <Typography style={{ fontSize: 20, fontWeight: 'bold', color: '#251CFF' }}>{roundingDown(item.free, 4)} </Typography>
                                                            <Typography style={{ fontSize: 14, fontWeight: 'bold', opacity: 0.8, marginLeft: 4, color: '#251CFF' }}>{item.token}</Typography>

                                                        </div>
                                                        <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between', marginBottom: 8 }}>

                                                            <div>
                                                                <Typography style={{ fontSize: 8, fontWeight: '600', color: '#8786A7', opacity: 0.4, marginTop: 8 }}>{t('walletID')}</Typography>
                                                                <Typography style={{ fontSize: 12, fontWeight: '600', color: '#8786A7', opacity: 0.8, marginTop: -4 }}>{address.slice(0, 8)}...{address.slice(address.length - 3)}</Typography>
                                                            </div>
                                                            <Avatar style={{ height: 32, width: 32, opacity: 0.8, }} variant="square" src={getIcons(item.token, tokenIcons, true)} />
                                                        </div>

                                                    </div>
                                                </ListItem>
                                            ))
                                        }
                                    </List>
                                }
                            </div>
                        </Grid>
                    </Grid> */}
                </div>
                <Modal
                    disablePortal
                    disableEnforceFocus
                    disableAutoFocus
                    aria-labelledby="server-modal-title"
                    aria-describedby="server-modal-description"
                    className={classes.modal}
                    open={openNote}

                    onClose={handleCloseNote}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade
                        in={openNote}


                    >
                        {body}
                    </Fade>
                </Modal>
            </div>

        </div>
        // : <Redirect to='/' />


    );
}

export default withTranslation()(Wallet);
