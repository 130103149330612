import { combineReducers } from 'redux';
import { wallet } from './wallet';
import { pool } from './pool';
import { swap } from './swap';
import { auth } from './auth'
import { alert } from './alert'
import { stakePool } from './stakePool'
import { refer } from './refer'
import { earn } from './earn'
import { vote } from './vote'
import { invite } from './invite'

const rootReducer = combineReducers({
    auth,
    swap,
    pool,
    wallet,
    alert,
    stakePool,
    refer,
    earn,
    vote,
    invite
});

export default rootReducer;
