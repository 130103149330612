export const LOGIN = 'LOGIN';
export const LOGIN_SUCCEED = 'LOGIN_SUCCEED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';

export const SIGNUP = 'SIGNUP';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';
export const SIGNUP_SUCCEED = 'SIGNUP_SUCCEED';

export const SUCCESS = 'ALERT_SUCCESS';
export const ERROR = 'ALERT_ERROR';
export const CLEAR = 'ALERT_CLEAR';

export const CHECK_EXIST = 'CHECK_EXIST';
export const CHECK_FAILED = 'CHECK_FAILED';
export const CHECK_SUCCEED = 'CHECK_SUCCEED';

export const ADD_LIQUIDITY = 'ADD_LIQUIDITY';
export const ADD_SUCCEED = 'ADD_SUCCEED';
export const ADD_FAILED = 'ADD_FAILED';

export const REMOVE_LIQUIDITY = 'REMOVE_LIQUIDITY';
export const REMOVE_SUCCEED = 'REMOVE_SUCCEED';
export const REMOVE_FAILED = 'REMOVE_FAILED';

export const GET_USER_CAPITAL = 'GET_USER_CAPITAL';
export const GET_USER_CAPITAL_SUCCEED = 'GET_USER_CAPITAL_SUCCEED';
export const GET_USER_CAPITAL_FAILED = 'GET_USER_CAPITAL_FAILED'

export const WITHDRAW = 'WITHDRAW';
export const WITHDRAW_SUCCEED = 'WITHDRAW_SUCCEED';
export const WITHDRAW_FAILED = 'WITHDRAW_FAILED';

export const TRANSFER = 'TRANSFER';
export const TRANSFER_SUCCEED = 'TRANSFER_SUCCEED';
export const TRANSFER_FAILED = 'TRANSFER_FAILED';

export const GET_ALL_TOKEN_STATUS = 'GET_ALL_TOKEN_STATUS';
export const GET_ALL_TOKEN_STATUS_SUCCEED = 'GET_ALL_TOKEN_STATUS_SUCCEED';
export const GET_ALL_TOKEN_STATUS_FAILED = 'GET_ALL_TOKEN_STATUS_FAILED';
export const GET_ALL_TOKEN_ICONS_SUCCEED = 'GET_ALL_TOKEN_ICONS_SUCCEED'

export const DEPOSIT = 'DEPOSIT';
export const DEPOSIT_SUCCEED = 'DEPOSIT_SUCCEED';
export const DEPOSIT_FAILED = 'DEPOSIT_FAILED';

export const DEPOSIT_HASH = 'DEPOSIT_HASH'
export const DEPOSIT_RECEIPT = 'DEPOSIT_RECEIPT'

export const GET_L1_CAPITAL_SUCCEED = 'GET_L1_CAPITAL_SUCCEED';
export const GET_L1_CAPITAL_FAILED = 'GET_L1_CAPITAL_FAILED';

export const GET_TRANSACTION_RECORDS_SUCCEED = 'GET_TRANSACTION_RECORDS_SUCCEED';
export const GET_TRANSACTION_RECORDS_FAILED = 'GET_TRANSACTION_RECORDS_FAILED'

export const GET_AMPL_REWARDS_SUCCEED = 'GET_AMPL_REWARDS_SUCCEED'
export const GET_AMPL_REWARDS_FAILED = 'GET_AMPL_REWARDS_FAILED'

export const REGISTER_AMPL_REWARDS = 'REGISTER_AMPL_REWARDS'
export const REGISTER_AMPL_REWARDS_SUCCEED = 'REGISTER_AMPL_REWARDS_SUCCEED'
export const ALREADY_REGISTERED = 'ALREADY_REGISTERED'
export const NOT_QUALIFIED = 'NOT_QUALIFIED'
export const REGISTER_AMPL_REWARDS_FAILED = 'REGISTER_AMPL_REWARDS_FAILED'

export const GET_TRANSFER_FEE = 'GET_TRANSFER_FEE'
export const GET_TRANSFER_FEE_SUCCESS = 'GET_TRANSFER_FEE_SUCCESS'
export const GET_TRANSFER_FEE_FAILED = 'GET_TRANSFER_FEE_FAILED'

export const GET_WITHDRAW_FEE = 'GET_WITHDRAW_FEE'
export const GET_WITHDRAW_FEE_SUCCESS = 'GET_WITHDRAW_FEE_SUCCESS'
export const GET_WITHDRAW_FEE_FAILED = 'GET_WITHDRAW_FEE_FAILED'

export const AUTH_SIGNING = 'AUTH_SIGNING'
export const WALLET_SIGNING = 'WALLET_SIGNING'
export const WALLET_SIGNING_CANCELLED = 'WALLET_SIGNING_CANCELLED'

export const GET_SWAP_QUOTE = 'GET_SWAP_QUOTE'
export const GET_SWAP_QUOTE_SUCCEED = 'GET_SWAP_QUOTE_SUCCEED'
export const GET_SWAP_QUOTE_FAILED = 'GET_SWAP_QUOTE_FAILED'
export const SWAP = 'SWAP'
export const SWAP_SUCCEED = 'SWAP_SUCCEED'
export const SWAP_FAILED = 'SWAP_FAILED'
export const CLEAR_SWAP_STATE = 'CLEAR_SWAP_STATE'
export const GET_SWAP_TRANSACTIONS = 'GET_SWAP_TRANSACTIONS'
export const GET_SWAP_TRANSACTIONS_SUCCEED = 'GET_SWAP_TRANSACTIONS_SUCCEED'
export const GET_SWAP_TRANSACTIONS_FAILED = 'GET_SWAP_TRANSACTIONS_FAILED'
export const SWAP_SIGNING = 'SWAP_SIGNING'
export const SWAP_SIGNING_CANCELLED = 'SWAP_SIGNING_CANCELLED'
export const GET_SWAP_PRICE = 'GET_SWAP_PRICE'
export const GET_SWAP_PRICE_SUCCEED = 'GET_SWAP_PRICE_SUCCEED'
export const GET_SWAP_PRICE_FAILED = 'GET_SWAP_PRICE_FAILED'
export const CLEAR_SWAP_QUOTE = 'CLEAR_SWAP_QUOTE'

export const GET_ALL_LIQUIDITY_POOL = 'GET_ALL_LIQUIDITY_POOL'
export const GET_ALL_LIQUIDITY_POOL_SUCCEED = 'GET_ALL_LIQUIDITY_POOL_SUCCEED'
export const GET_ALL_LIQUIDITY_POOL_FAILED = 'GET_ALL_LIQUIDITY_POOL_FAILED'

export const GET_MY_LIQUIDITY_POOL = 'GET_MY_LIQUIDITY_POOL'
export const GET_MY_LIQUIDITY_POOL_SUCCEED = 'GET_MY_LIQUIDITY_POOL_SUCCEED'
export const GET_MY_LIQUIDITY_POOL_FAILED = 'GET_MY_LIQUIDITY_POOL_FAILED'

export const GET_POOL_BANNER = 'GET_POOL_BANNER'
export const GET_POOL_BANNER_SUCCEED = 'GET_POOL_BANNER_SUCCEED'
export const GET_POOL_BANNER_FAILED = 'GET_POOL_BANNER_FAILED'

export const GET_LIQUIDITY_TRANSACTIONS = "GET_LIQUIDITY_TRANSACTIONS"
export const GET_LIQUIDITY_TRANSACTIONS_FAILED = "GET_LIQUIDITY_TRANSACTIONS_FAILED"
export const GET_LIQUIDITY_COLLECT_TRANSACTIONS = "GET_LIQUIDITY_COLLECT_TRANSACTIONS"
export const GET_LIQUIDITY_COLLECT_TRANSACTIONS_FAILED = "GET_LIQUIDITY_COLLECT_TRANSACTIONS_FAILED"
export const GET_MY_LIQUIDITY_TRANSACTIONS_SUCCEED = "GET_MY_LIQUIDITY_TRANSACTIONS_SUCCEED"
export const GET_ADD_LIQUIDITY_QUOTE = "GET_ADD_LIQUIDITY_QUOTE"
export const GET_ADD_LIQUIDITY_QUOTE_SUCCEED = "GET_ADD_LIQUIDITY_QUOTE_SUCCEED"
export const GET_ADD_LIQUIDITY_QUOTE_FAILED = "GET_ADD_LIQUIDITY_QUOTE_FAILED"
export const CLEAR_ADD_STATE = "CLEAR_ADD_STATE"
export const CLEAR_ADD_LIQUIDITY_QUOTE = "CLEAR_ADD_LIQUIDITY_QUOTE"
export const GET_REMOVE_LIQUIDITY_QUOTE = "GET_REMOVE_LIQUIDITY_QUOTE"
export const GET_REMOVE_LIQUIDITY_QUOTE_SUCCEED = "GET_REMOVE_LIQUIDITY_QUOTE_SUCCEED"
export const GET_REMOVE_LIQUIDITY_QUOTE_FAILED = "GET_REMOVE_LIQUIDITY_QUOTE_FAILED"
export const CLEAR_REMOVE_STATE = "CLEAR_REMOVE_STATE"
export const CLEAR_REMOVE_LIQUIDITY_QUOTE = "CLEAR_REMOVE_LIQUIDITY_QUOTE"
export const HARVEST_LIQUIDITY_REWARD = "HARVEST_LIQUIDITY_REWARD"
export const HARVEST_LIQUIDITY_REWARD_SUCCEED = "HARVEST_LIQUIDITY_REWARD_SUCCEED"
export const HARVEST_LIQUIDITY_REWARD_FAILED = "HARVEST_LIQUIDITY_REWARD_FAILED"
export const CLEAR_HARVEST_STATE = "CLEAR_HARVEST_STATE"
export const CLEAR_INFO = 'CLEAR_INFO'
export const POOL_SIGNING = 'POOL_SIGNING'
export const POOL_SIGNING_CANCELLED = 'POOL_SIGNING_CANCELLED'
export const GET_MY_POOL_APY = 'GET_MY_POOL_APY'
export const GET_MY_POOL_APY_SUCCEED = 'GET_MY_POOL_APY_SUCCEED'
export const GET_MY_POOL_APY_FAILED = 'GET_MY_POOL_APY_FAILED'
export const GET_CURRENT_TIME = 'GET_CURRENT_TIME'
export const GET_CURRENT_TIME_SUCCEED = 'GET_CURRENT_TIME_SUCCEED'
export const GET_CURRENT_TIME_FAILED = 'GET_CURRENT_TIME_FAILED'
export const GET_MY_LOCKER = 'GET_MY_LOCKER'
export const GET_MY_LOCKER_SUCCEED = 'GET_MY_LOCKER_SUCCEED'
export const GET_MY_LOCKER_FAILED = 'GET_MY_LOCKER_FAILED'
export const LOCK_LP = 'LOCK_LP'
export const LOCK_LP_SUCCEED = 'LOCK_LP_SUCCEED'
export const LOCK_LP_FAILED = 'LOCK_LP_FAILED'
export const CLEAR_LOCK_LP_STATE = 'CLEAR_LOCK_LP_STATE'
export const UNLOCK_LP = 'UNLOCK_LP'
export const UNLOCK_LP_SUCCEED = 'UNLOCK_LP_SUCCEED'
export const UNLOCK_LP_FAILED = 'UNLOCK_LP_FAILED'
export const CLEAR_UNLOCK_LP_STATE = 'CLEAR_UNLOCK_LP_STATE'
export const GET_UNLOCKABLE = 'GET_UNLOCKABLE'
export const GET_UNLOCKABLE_SUCCEED = 'GET_UNLOCKABLE_SUCCEED'
export const GET_UNLOCKABLE_FAILED = 'GET_UNLOCKABLE_FAILED'
export const CLEAR_UNLOCKABLE = 'CLEAR_UNLOCKABLE'
export const UNLOCK_ALL_LP = 'UNLOCK_ALL_LP'
export const UNLOCK_ALL_LP_SUCCEED = 'UNLOCK_ALL_LP_SUCCEED'
export const UNLOCK_ALL_LP_FAILED = 'UNLOCK_ALL_LP_FAILED'
export const CLEAR_UNLOCK_ALL_LP_STATE = 'CLEAR_UNLOCK_ALL_LP_STATE'

export const IN_REBASE = 'IN_REBASE'
export const CLEAR_FEE = 'CLEAR_FEE'

export const GET_ALL_STAKE_POOL = 'GET_ALL_STAKE_POOL'
export const GET_ALL_STAKE_POOL_SUCCEED = 'GET_ALL_STAKE_POOL_SUCCEED'
export const GET_ALL_STAKE_POOL_FAILED = 'GET_ALL_STAKE_POOL_FAILED'
export const GET_MY_STAKE_POOL = 'GET_MY_STAKE_POOL'
export const GET_MY_STAKE_POOL_SUCCEED = 'GET_MY_STAKE_POOL_SUCCEED'
export const GET_MY_STAKE_POOL_FAILED = 'GET_MY_STAKE_POOL_FAILED'
export const GET_STAKE_TRANSACTIONS = "GET_STAKE_TRANSACTIONS"
export const GET_STAKE_TRANSACTIONS_FAILED = "GET_STAKE_TRANSACTIONS_FAILED"
export const GET_STAKE_COLLECT_TRANSACTIONS = "GET_STAKE_COLLECT_TRANSACTIONS"
export const GET_STAKE_COLLECT_TRANSACTIONS_FAILED = "GET_STAKE_COLLECT_TRANSACTIONS_FAILED"
export const GET_MY_STAKE_TRANSACTIONS_SUCCEED = "GET_MY_STAKE_TRANSACTIONS_SUCCEED"
export const HARVEST_STAKE_REWARD = "HARVEST_STAKE_REWARD"
export const HARVEST_STAKE_REWARD_SUCCEED = "HARVEST_STAKE_REWARD_SUCCEED"
export const HARVEST_STAKE_REWARD_FAILED = "HARVEST_STAKE_REWARD_FAILED"
export const CLEAR_HARVEST_STAKE_STATE = "CLEAR_HARVEST_STAKE_STATE"
export const STAKE = 'STAKE'
export const STAKE_SUCCEED = 'STAKE_SUCCEED'
export const STAKE_FAILED = 'STAKE_FAILED'
export const CLEAR_STAKE_STATE = 'CLEAR_STAKE_STATE'
export const UNSTAKE = 'UNSTAKE'
export const UNSTAKE_SUCCEED = 'UNSTAKE_SUCCEED'
export const UNSTAKE_FAILED = 'UNSTAKE_FAILED'
export const CLEAR_UNSTAKE_STATE = 'CLEAR_UNSTAKE_STATE'
export const STAKE_SIGNING = 'STAKE_SIGNING'
export const STAKE_SIGNING_CANCELLED = 'STAKE_SIGNING_CANCELLED'
export const STAKE_LOCK = 'STAKE_LOCK'
export const STAKE_LOCK_SUCCEED = 'STAKE_LOCK_SUCCEED'
export const STAKE_LOCK_FAILED = 'STAKE_LOCK_FAILED'
export const CLEAR_STAKE_LOCK_STATE = 'CLEAR_STAKE_LOCK_STATE'
export const GET_MY_STAKE_LOCKER = 'GET_MY_STAKE_LOCKER'
export const GET_MY_STAKE_LOCKER_SUCCEED = 'GET_MY_STAKE_LOCKER_SUCCEED'
export const GET_MY_STAKE_LOCKER_FAILED = 'GET_MY_STAKE_LOCKER_FAILED'
export const GET_STAKE_UNLOCKABLE = 'GET_STAKE_UNLOCKABLE'
export const GET_STAKE_UNLOCKABLE_SUCCEED = 'GET_STAKE_UNLOCKABLE_SUCCEED'
export const GET_STAKE_UNLOCKABLE_FAILED = 'GET_STAKE_UNLOCKABLE_FAILED'
export const CLEAR_STAKE_UNLOCKABLE = 'CLEAR_STAKE_UNLOCKABLE'
export const STAKE_UNLOCK = 'STAKE_UNLOCK'
export const STAKE_UNLOCK_SUCCEED = 'STAKE_UNLOCK_SUCCEED'
export const STAKE_UNLOCK_FAILED = 'STAKE_UNLOCK_FAILED'
export const CLEAR_STAKE_UNLOCK_STATE = 'CLEAR_STAKE_UNLOCK_STATE'
export const UNLOCK_ALL_STAKE = 'UNLOCK_ALL_STAKE'
export const UNLOCK_ALL_STAKE_SUCCEED = 'UNLOCK_ALL_STAKE_SUCCEED'
export const UNLOCK_ALL_STAKE_FAILED = 'UNLOCK_ALL_STAKE_FAILED'
export const CLEAR_UNLOCK_ALL_STAKE_STATE = 'CLEAR_UNLOCK_ALL_STAKE_STATE'

export const GET_REFERRAL_CODE = 'GET_REFERRAL_CODE'
export const GET_REFERRAL_CODE_SUCCEED = 'GET_REFERRAL_CODE_SUCCEED'
export const GET_REFERRAL_CODE_FAILED = 'GET_REFERRAL_CODE_FAILED'
export const GET_REFERRAL_INFO = 'GET_REFERRAL_INFO'
export const GET_REFERRAL_INFO_SUCCEED = 'GET_REFERRAL_INFO_SUCCEED'
export const GET_REFERRAL_INFO_FAILED = 'GET_REFERRAL_INFO_FAILED'
export const GET_REFERRAL_COMMISION = 'GET_REFERRAL_COMMISION'
export const GET_REFERRAL_COMMISION_SUCCEED = 'GET_REFERRAL_COMMISION_SUCCEED'
export const GET_REFERRAL_COMMISION_FAILED = 'GET_REFERRAL_COMMISION_FAILED'
export const HARVEST_ALL = 'HARVEST_ALL'
export const HARVEST_ALL_SUCCEED = 'HARVEST_ALL_SUCCEED'
export const HARVEST_ALL_FAILED = 'HARVEST_ALL_FAILED'

export const GET_ALL_EARN_POOL = 'GET_ALL_EARN_POOL'
export const GET_ALL_EARN_POOL_SUCCEED = 'GET_ALL_EARN_POOL_SUCCEED'
export const GET_ALL_EARN_POOL_FAILED = 'GET_ALL_EARN_POOL_FAILED'
export const GET_MY_EARN_POOL = 'GET_MY_EARN_POOL'
export const GET_MY_EARN_POOL_SUCCEED = 'GET_MY_EARN_POOL_SUCCEED'
export const GET_MY_EARN_POOL_FAILED = 'GET_MY_EARN_POOL_FAILED'
export const GET_EARN_TRANSACTIONS = "GET_EARN_TRANSACTIONS"
export const GET_EARN_TRANSACTIONS_FAILED = "GET_EARN_TRANSACTIONS_FAILED"
export const GET_EARN_TRANSACTIONS_SUCCEED = "GET_EARN_TRANSACTIONS_SUCCEED"
export const HARVEST_EARN_REWARD = "HARVEST_EARN_REWARD"
export const HARVEST_EARN_REWARD_SUCCEED = "HARVEST_EARN_REWARD_SUCCEED"
export const HARVEST_EARN_REWARD_FAILED = "HARVEST_EARN_REWARD_FAILED"
export const CLEAR_HARVEST_EARN_STATE = "CLEAR_HARVEST_EARN_STATE"
export const EARN_STAKE = 'EARN_STAKE'
export const EARN_STAKE_SUCCEED = 'EARN_STAKE_SUCCEED'
export const EARN_STAKE_FAILED = 'EARN_STAKE_FAILED'
export const CLEAR_EARN_STAKE_STATE = 'CLEAR_EARN_STAKE_STATE'
export const EARN_UNSTAKE = 'EARN_UNSTAKE'
export const EARN_UNSTAKE_SUCCEED = 'EARN_UNSTAKE_SUCCEED'
export const EARN_UNSTAKE_FAILED = 'EARN_UNSTAKE_FAILED'
export const CLEAR_EARN_UNSTAKE_STATE = 'CLEAR_EARN_UNSTAKE_STATE'
export const GET_EARN_RATE_QUOTE = 'GET_EARN_RATE_QUOTE'
export const GET_EARN_RATE_QUOTE_SUCCEED = 'GET_EARN_RATE_QUOTE_SUCCEED'
export const GET_EARN_RATE_QUOTE_FAILED = 'GET_EARN_RATE_QUOTE_FAILED'
export const CLEAR_EARN_RATE_QUOTE = 'CLEAR_EARN_RATE_QUOTE'
export const GET_ETH_PRICE = 'GET_ETH_PRICE'
export const GET_ETH_PRICE_SUCCEED = 'GET_ETH_PRICE_SUCCEED'
export const GET_ETH_PRICE_FAILED = 'GET_ETH_PRICE_FAILED'
export const EARN_SIGNING = 'EARN_SIGNING'
export const EARN_SIGNING_CANCELLED = 'EARN_SIGNING_CANCELLED'
export const GET_USER_INFO = 'GET_USER_INFO'
export const GET_USER_INFO_SUCCEED = 'GET_USER_INFO_SUCCEED'
export const GET_USER_INFO_FAILED = 'GET_USER_INFO_FAILED'

export const GET_RELEASE_DETAIL = 'GET_RELEASE_DETAIL'
export const GET_RELEASE_DETAIL_SUCCEED = 'GET_RELEASE_DETAIL_SUCCEED'
export const GET_RELEASE_DETAIL_FAILED = 'GET_RELEASE_DETAIL_FAILED'

export const GET_PROPOSALS = 'GET_PROPOSALS'
export const GET_PROPOSALS_SUCCEED = 'GET_PROPOSALS_SUCCEED'
export const GET_PROPOSALS_FAILED = 'GET_PROPOSALS_FAILED'
export const GET_VOTE_HISTORY = 'GET_VOTE_HISTORY'
export const GET_VOTE_HISTORY_SUCCEED = 'GET_VOTE_HISTORY_SUCCEED'
export const GET_VOTE_HISTORY_FAILED = 'GET_VOTE_HISTORY_FAILED'
export const VOTE_SIGNING = 'VOTE_SIGNING'
export const VOTE_SIGNING_CANCELLED = 'VOTE_SIGNING_CANCELLED'
export const VOTE = 'VOTE'
export const VOTE_SUCCEED = 'VOTE_SUCCEED'
export const VOTE_FAILED = 'VOTE_FAILED'
export const CLEAR_VOTE_STATE = 'CLEAR_VOTE_STATE'

export const GET_L3_REWARD = 'GET_L3_REWARD'
export const GET_L3_REWARD_SUCCEED = 'GET_L3_REWARD_SUCCEED'
export const GET_L3_REWARD_FAILED = 'GET_L3_REWARD_FAILED'

export const ADD_TOKEN_SUCCEED = 'ADD_TOKEN_SUCCEED'
export const ADD_TOKEN_FAILED = 'ADD_TOKEN_FAILED'

export const GET_MAX_PRICE_IMPACT = 'GET_MAX_PRICE_IMPACT'
export const GET_MAX_PRICE_IMPACT_SUCCEED = 'GET_MAX_PRICE_IMPACT_SUCCEED'
export const GET_MAX_PRICE_IMPACT_FAILED = 'GET_MAX_PRICE_IMPACT_FAILED'

export const GET_INVITE_INFO = 'GET_INVITE_INFO'
export const GET_INVITE_INFO_SUCCEED = 'GET_INVITE_INFO_SUCCEED'
export const GET_INVITE_INFO_FAILED = 'GET_INVITE_INFO_FAILED'

export const GET_INVITE_CODE = 'GET_INVITE_CODE'
export const GET_INVITE_CODE_SUCCEED = 'GET_INVITE_CODE_SUCCEED'
export const GET_INVITE_CODE_FAILED = 'GET_INVITE_CODE_FAILED'

export const REGISTER_INVITER = 'REGISTER_INVITER'
export const REGISTER_INVITER_SUCCEED = 'REGISTER_INVITER_SUCCEED'
export const REGISTER_INVITER_FAILED = 'REGISTER_INVITER_FAILED'

export const GET_INVITEE = 'GET_INVITEE'
export const GET_INVITEE_SUCCEED = 'GET_INVITEE_SUCCEED'
export const GET_INVITEE_FAILED = 'GET_INVITEE_FAILED'

export const BUY_IDO = 'BUY_IDO'
export const BUY_IDO_SUCCEED = 'BUY_IDO_SUCCEED'
export const BUY_IDO_FAILED = 'BUY_IDO_FAILED'

export const GET_IDO_INFO = 'GET_IDO_INFO'
export const GET_IDO_INFO_SUCCEED = 'GET_IDO_INFO_SUCCEED'
export const GET_IDO_INFO_FAILED = 'GET_IDO_INFO_FAILED'

export const CLEAR_MODAL_INFO = 'CLEAR_MODAL_INFO'
export const CLEAR_INVITE_INFO = 'CLEAR_INVITE_INFO'
export const CLEAR_IDO_INFO = 'CLEAR_IDO_INFO'

export const GET_IDO_INVESTMENT_INFO = 'GET_IDO_INVESTMENT_INFO'
export const GET_IDO_INVESTMENT_INFO_SUCCEED = 'GET_IDO_INVESTMENT_INFO_SUCCEED'
export const GET_IDO_INVESTMENT_INFO_FAILED = 'GET_IDO_INVESTMENT_INFO_FAILED'

export const GET_IDO_MIGRATION_INFO = 'GET_IDO_MIGRATION_INFO'
export const GET_IDO_MIGRATION_INFO_SUCCEED = 'GET_IDO_MIGRATION_INFO_SUCCEED'
export const GET_IDO_MIGRATION_INFO_FAILED = 'GET_IDO_MIGRATION_INFO_FAILED'

export const MIGRATE_IDO = 'MIGRATE_IDO'
export const MIGRATE_IDO_SUCCEED = 'MIGRATE_IDO_SUCCEED'
export const MIGRATE_IDO_FAILED = 'MIGRATE_IDO_FAILED'

export const MIGRATE_SIGNING_CANCELLED ='MIGRATE_SIGNING_CANCELLED'

export const GET_RANK_BONUS_TRANSACTIONS = 'GET_RANK_BONUS_TRANSACTIONS'
export const GET_RANK_BONUS_TRANSACTIONS_SUCCEED = 'GET_RANK_BONUS_TRANSACTIONS_SUCCEED'
export const GET_RANK_BONUS_TRANSACTIONS_FAILED = 'GET_RANK_BONUS_TRANSACTIONS_FAILED'

export const GET_RANK_DETAIL = 'GET_RANK_DETAIL'
export const GET_RANK_DETAIL_SUCCEED = 'GET_RANK_DETAIL_SUCCEED'
export const GET_RANK_DETAIL_FAILED = 'GET_RANK_DETAIL_FAILED'

export const GET_RANK_SWITCH = 'GET_RANK_SWITCH'
export const GET_RANK_SWITCH_SUCCEED = 'GET_RANK_SWITCH_SUCCEED'
export const GET_RANK_SWITCH_FAILED = 'GET_RANK_SWITCH_FAILED'

