import { swapService} from '../services/swapService';
import {
    GET_SWAP_QUOTE,
    GET_SWAP_QUOTE_SUCCEED,
    GET_SWAP_QUOTE_FAILED,
    SWAP,
    SWAP_SUCCEED,
    SWAP_FAILED,
    CLEAR_SWAP_STATE,
    GET_SWAP_TRANSACTIONS,
    GET_SWAP_TRANSACTIONS_SUCCEED,
    GET_SWAP_TRANSACTIONS_FAILED,
    SWAP_SIGNING,
    SWAP_SIGNING_CANCELLED,
    GET_SWAP_PRICE,
    GET_SWAP_PRICE_SUCCEED,
    GET_SWAP_PRICE_FAILED,
    CLEAR_SWAP_QUOTE,
    GET_MAX_PRICE_IMPACT,
    GET_MAX_PRICE_IMPACT_SUCCEED,
    GET_MAX_PRICE_IMPACT_FAILED,
} from '../constants';
import { alertActions } from './alertActions';

export const swapActions = {
    swapQuote,
    swap,
    clearSwapState,
    getSwapTransactions,
    swapSigning,
    swapSigningCancelled,
    getSwapPrice,
    clearSwapQuote,
    getMaxPriceImpact
}

function swapQuote(payload,token) {
    return dispatch => {
        dispatch(request());
        swapService.swapQuote(payload,token)
            .then(
                res => {
                    if (res) {
                        dispatch(success(res));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: GET_SWAP_QUOTE } }
    function success(res) { return { type: GET_SWAP_QUOTE_SUCCEED, res } }
    function failure(error) { return { type: GET_SWAP_QUOTE_FAILED, error } }
}

function swap(payload,token) {
    return dispatch => {
        dispatch(request());
        swapService.swap(payload, token)
            .then(
                res => {
                    dispatch(success("swap succeed"));
                },
                error => {
                    let toast = ''
                    if (error.toString() === "TypeError: Failed to fetch"){
                        toast = "networkError"
                    }else if(error.status === 503|| error.status===409) {
                        toast = "serviceError"
                    }else if(error.data ==="TooFrequentError"){
                        toast = "tooFrequent"
                    }else if (error.data && error.data.message === "SlippageToleranceError"){
                        toast = 'swap-failed-slippage'
                    } else {
                        toast = 'swap-failed'
                    }
                    dispatch(failure(toast));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(address) { return { type: SWAP, address } }
    function success(msg) { return { type: SWAP_SUCCEED, msg } }
    function failure(error) { return { type: SWAP_FAILED, error } }
}

function clearSwapState(){
    return dispatch => {
        dispatch(request());
    };
    function request(address) { return { type: CLEAR_SWAP_STATE, address } }
}

function getSwapTransactions(payload) {
    let transList = []
    return dispatch =>{
        dispatch(request());
        swapService.getSwapTransactions(payload)
            .then(
                res => {
                    res.data.sort(function(a,b){
                        // Turn your strings into dates, and then subtract them
                        // to get a value that is either negative, positive, or zero.
                        return new Date(b.createdAt) - new Date(a.createdAt);
                    });
                    let list1 = []
                    let list2 = []
                    res.data.map((item, index) => {
                        if (index % 2 == 0) {
                            list1.push(item)
                        } else if (index % 2 == 1) {
                            list2.push(item)

                        }
                    })
                    for (let i = 0; i < list1.length; i++) {
                        const temp = { fromToken: list1[i].token, toToken: list2[i].token, fromAmount: list1[i].amount, toAmount: list2[i].amount, date: list1[i].createdAt, fee: list1[i].fee }
                        transList.push(temp)
                    }
                    dispatch(success(transList));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request(address) { return { type: GET_SWAP_TRANSACTIONS, address } }
    function success(res) { return { type: GET_SWAP_TRANSACTIONS_SUCCEED, res } }
    function failure(error) { return { type: GET_SWAP_TRANSACTIONS_FAILED, error } }
}

function swapSigning() {
    return dispatch => {
        dispatch(swapSigning())
    }
    function swapSigning() {return { type: SWAP_SIGNING }}
}

function swapSigningCancelled() {
    return dispatch => {
        dispatch(swapSigningCancelled())
    }
    function swapSigningCancelled() {return { type: SWAP_SIGNING_CANCELLED }}
}

function getSwapPrice(payload,token) {
    return dispatch => {
        dispatch(request());
        swapService.swapQuote(payload,token)
            .then(
                res => {
                    if (res) {
                        dispatch(success(res));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: GET_SWAP_PRICE } }
    function success(res) { return { type: GET_SWAP_PRICE_SUCCEED, res } }
    function failure(error) { return { type: GET_SWAP_PRICE_FAILED, error } }
}

function clearSwapQuote() {
    return dispatch => {
        dispatch(request());
    };
    function request(address) { return { type: CLEAR_SWAP_QUOTE, address } }
}

function getMaxPriceImpact(token){
    return dispatch => {
        dispatch(request());
        swapService.getMaxPriceImpact(token)
            .then(
                res => {
                    if (res) {
                        dispatch(success(res));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: GET_MAX_PRICE_IMPACT } }
    function success(res) { return { type: GET_MAX_PRICE_IMPACT_SUCCEED, res } }
    function failure(error) { return { type: GET_MAX_PRICE_IMPACT_FAILED, error } }
}