import {
    GET_ALL_STAKE_POOL,
    GET_ALL_STAKE_POOL_FAILED,
    GET_ALL_STAKE_POOL_SUCCEED,
    GET_STAKE_COLLECT_TRANSACTIONS,
    GET_STAKE_COLLECT_TRANSACTIONS_FAILED,
    GET_STAKE_TRANSACTIONS,
    GET_STAKE_TRANSACTIONS_FAILED,
    GET_MY_STAKE_TRANSACTIONS_SUCCEED,
    GET_MY_STAKE_POOL,
    GET_MY_STAKE_POOL_FAILED,
    GET_MY_STAKE_POOL_SUCCEED,
    HARVEST_STAKE_REWARD,
    HARVEST_STAKE_REWARD_SUCCEED,
    HARVEST_STAKE_REWARD_FAILED,
    STAKE,
    STAKE_SUCCEED,
    STAKE_FAILED,
    CLEAR_STAKE_STATE,
    UNSTAKE,
    UNSTAKE_SUCCEED,
    UNSTAKE_FAILED,
    CLEAR_UNSTAKE_STATE,
    STAKE_SIGNING,
    STAKE_SIGNING_CANCELLED,
    CLEAR_HARVEST_STAKE_STATE,
    STAKE_LOCK,
    STAKE_LOCK_SUCCEED,
    STAKE_LOCK_FAILED,
    CLEAR_STAKE_LOCK_STATE,
    GET_MY_STAKE_LOCKER,
    GET_MY_STAKE_LOCKER_SUCCEED,
    GET_MY_STAKE_LOCKER_FAILED,
    GET_STAKE_UNLOCKABLE,
    GET_STAKE_UNLOCKABLE_SUCCEED,
    GET_STAKE_UNLOCKABLE_FAILED,
    CLEAR_STAKE_UNLOCKABLE,
    STAKE_UNLOCK,
    STAKE_UNLOCK_SUCCEED,
    STAKE_UNLOCK_FAILED,
    CLEAR_STAKE_UNLOCK_STATE,
    UNLOCK_ALL_STAKE,
    UNLOCK_ALL_STAKE_SUCCEED,
    UNLOCK_ALL_STAKE_FAILED,
    CLEAR_UNLOCK_ALL_STAKE_STATE, UNLOCK_ALL_LP, UNLOCK_ALL_LP_SUCCEED, UNLOCK_ALL_LP_FAILED, CLEAR_UNLOCK_ALL_LP_STATE,
} from "../constants";

export function stakePool (state = {
    stakePools:[],
    myStakePools:[],
    myStakeTransactions:[],
    myLocker:[],
    unlockables:[],
    stakePoolsList:[],
    stakePoolSigning:false,
}, action){
    switch (action.type) {
        case GET_ALL_STAKE_POOL:
            return{
                ...state,
                loading: true
            }
        case GET_ALL_STAKE_POOL_SUCCEED:
            return{
                ...state,
                stakePools: action.res,
                stakePoolsList: action.data,
                loading: false
            }
        case GET_ALL_STAKE_POOL_FAILED:
            return{
                ...state,
                message: action.error,
                loading: false
            }


        case GET_MY_STAKE_POOL:
            return{
                ...state,
                loading: true
            }
        case GET_MY_STAKE_POOL_SUCCEED:
            return{
                ...state,
                myStakePools: action.res.data,
                loading: false
            }
        case GET_MY_STAKE_POOL_FAILED:
            return{
                ...state,
                message: action.error,
                loading: false
            }


        case GET_STAKE_TRANSACTIONS:
            return{
                ...state,
                loading: true
            }
        case GET_STAKE_TRANSACTIONS_FAILED:
            return{
                ...state,
                message: action.error,
                loading: false
            }
        case GET_STAKE_COLLECT_TRANSACTIONS:
            return{
                ...state,
                loading: true
            }
        case GET_STAKE_COLLECT_TRANSACTIONS_FAILED:
            return{
                ...state,
                message: action.error,
                loading: false
            }
        case GET_MY_STAKE_TRANSACTIONS_SUCCEED:
            return{
                ...state,
                myStakeTransactions: action.res,
                loading: false
            }


        case HARVEST_STAKE_REWARD:
            return{
                ...state,
                harvestLoading: true,
                harvestSucceed: false,
                harvestFailed: false,
                stakePoolSigning:false,
            }
        case HARVEST_STAKE_REWARD_SUCCEED:
            return{
                ...state,
                harvestMessage: action.msg.message,
                harvestLoading: false,
                harvestSucceed:true,
            }
        case HARVEST_STAKE_REWARD_FAILED:
            return{
                ...state,
                harvestMessage: action.error,
                harvestLoading: false,
                harvestFailed:true,
            }
        case CLEAR_HARVEST_STAKE_STATE:
            return{
                ...state,
                harvestSucceed: false,
                harvestFailed: false,
            }


        case STAKE:
            return{
                ...state,
                stakeLoading: true,
                stakeSucceed: false,
                stakeFailed: false,
                stakePoolSigning: false,
            }
        case STAKE_SUCCEED:
            return{
                ...state,
                stakeMessage: action.msg,
                stakeLoading: false,
                stakeSucceed: true,
            }
        case STAKE_FAILED:
            return{
                ...state,
                stakeMessage: action.error,
                stakeLoading: false,
                stakeFailed: true,
            }
        case CLEAR_STAKE_STATE:
            return{
                ...state,
                stakeFailed: false,
                stakeSucceed: false,
            }


        case UNSTAKE:
            return{
                ...state,
                unStakeLoading: true,
                unStakeSucceed: false,
                unStakeFailed: false,
                stakePoolSigning: false,
            }
        case UNSTAKE_SUCCEED:
            return{
                ...state,
                unStakeMessage: action.msg,
                unStakeLoading: false,
                unStakeSucceed: true,
            }
        case UNSTAKE_FAILED:
            return{
                ...state,
                unStakeMessage: action.error,
                unStakeLoading: false,
                unStakeFailed: true,
            }
        case CLEAR_UNSTAKE_STATE:
            return{
                ...state,
                unStakeFailed: false,
                unStakeSucceed: false,
            }


        case STAKE_SIGNING:
            return{
                ...state,
                stakePoolSigning: true,
            }
        case STAKE_SIGNING_CANCELLED:
            return{
                ...state,
                stakePoolSigning: false,
            }


        case STAKE_LOCK:
            return {
                ...state,
                stakeLockLoading: true,
                stakeLockSucceed: false,
                stakeLockFailed: false,
                stakePoolSigning:false,
            }
        case STAKE_LOCK_SUCCEED:
            return {
                ...state,
                stakeLockMessage: action.msg,
                stakeLockLoading: false,
                stakeLockSucceed: true,
            }
        case STAKE_LOCK_FAILED:
            return {
                ...state,
                stakeLockMessage: action.error,
                stakeLockLoading: false,
                stakeLockFailed: true,
            }
        case CLEAR_STAKE_LOCK_STATE:
            return{
                ...state,
                stakeLockSucceed: false,
                stakeLockFailed: false,
            }

        case GET_MY_STAKE_LOCKER:
            return{
                ...state,
                loading: true
            }
        case GET_MY_STAKE_LOCKER_SUCCEED:
            return{
                ...state,
                myLocker: action.res.data,
                loading: false
            }
        case GET_MY_STAKE_LOCKER_FAILED:
            return{
                ...state,
                message: action.error,
                loading: false
            }



        case GET_STAKE_UNLOCKABLE:
            return {
                ...state,
                loading: true
            }
        case GET_STAKE_UNLOCKABLE_SUCCEED:
            return {
                ...state,
                unlockables: action.res.data,
                loading: false
            }
        case GET_STAKE_UNLOCKABLE_FAILED:
            return {
                ...state,
                unlockables: [],
                message: action.error,
                loading: false
            }
        case CLEAR_STAKE_UNLOCKABLE:
            return{
                ...state,
                unlockables: [],
            }


        case STAKE_UNLOCK:
            return {
                ...state,
                stakeUnlockLoading: true,
                stakeUnlockSucceed: false,
                stakeUnlockFailed: false,
                stakePoolSigning:false,
            }
        case STAKE_UNLOCK_SUCCEED:
            return {
                ...state,
                stakeUnlockMessage: action.msg,
                stakeUnlockLoading: false,
                stakeUnlockSucceed: true,
            }
        case STAKE_UNLOCK_FAILED:
            return {
                ...state,
                stakeUnlockMessage: action.error,
                stakeUnlockLoading: false,
                stakeUnlockFailed: true,
            }
        case CLEAR_STAKE_UNLOCK_STATE:
            return{
                ...state,
                stakeUnlockSucceed: false,
                stakeUnlockFailed: false,
            }


        case UNLOCK_ALL_STAKE:
            return {
                ...state,
                unlockAllStakeLoading: true,
                unlockAllStakeSucceed: false,
                unlockAllStakeFailed: false,
                stakePoolSigning:false,
            }
        case UNLOCK_ALL_STAKE_SUCCEED:
            return {
                ...state,
                unlockAllStakeMessage: action.msg,
                unlockAllStakeLoading: false,
                unlockAllStakeSucceed: true,
            }
        case UNLOCK_ALL_STAKE_FAILED:
            return {
                ...state,
                unlockAllStakeMessage: action.error,
                unlockAllStakeLoading: false,
                unlockAllStakeFailed: true,
            }
        case CLEAR_UNLOCK_ALL_STAKE_STATE:
            return{
                ...state,
                unlockAllStakeSucceed: false,
                unlockAllStakeFailed: false,
            }


        default:
            return state;
    }
    return state;
};
