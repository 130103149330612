import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import './CustomTextField.css';


const useStyles = makeStyles({
    underline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        }
    },
    root:{
        width:'91%',

        alignItems:'center',
        justifyContent:'center',
        marginLeft:"4%"
    }
});

export default function CustomDropBox(props) {
    const classes = useStyles();

    return <TextField
        {...props}
        fullWidth
        select
        InputProps={{ classes }}
        // inputprops={{
        //     className: classes.input,
        // }}
        // id="filled-full-width"
        // inputProps={{
        //     disableUnderline: true
        // }}
        // disableUnderline
        // InputLabelProps={{ className: "dropBox__label" }}
        disabled={props.disabled}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"

    >

    </TextField>


}


