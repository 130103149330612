import { voteService } from '../services/voteService';
import {
    GET_PROPOSALS,
    GET_PROPOSALS_FAILED,
    GET_PROPOSALS_SUCCEED,
    GET_VOTE_HISTORY,
    GET_VOTE_HISTORY_FAILED,
    GET_VOTE_HISTORY_SUCCEED,
    VOTE_SIGNING,
    VOTE_SIGNING_CANCELLED,
    VOTE,
    VOTE_FAILED,
    VOTE_SUCCEED,
    CLEAR_VOTE_STATE,
    GET_L3_REWARD,
    GET_L3_REWARD_SUCCEED,
    GET_L3_REWARD_FAILED

} from '../constants';

import { alertActions } from "./alertActions";

export const voteActions = {
    getProposals,
    getVoteHistory,
    voteSigning,
    voteSigningCancelled,
    vote,
    clearVoteState,
    getLayer3Reward
};

function getProposals() {
    return dispatch => {
        dispatch(request());
        voteService.getProposals()
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: GET_PROPOSALS } }
    function success(res) { return { type: GET_PROPOSALS_SUCCEED, res } }
    function failure(error) { return { type: GET_PROPOSALS_FAILED, error } }
}

function getVoteHistory(token) {
    return dispatch => {
        dispatch(request());
        voteService.getVoteHistory(token)
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: GET_VOTE_HISTORY } }
    function success(res) { return { type: GET_VOTE_HISTORY_SUCCEED, res } }
    function failure(error) { return { type: GET_VOTE_HISTORY_FAILED, error } }
}

function vote(payload, token) {
    return dispatch => {
        dispatch(request());
        voteService.vote(payload, token)
            .then(
                res => {
                    dispatch(success("vote succeed"));
                },
                error => {
                    let toast = ''
                    if (error.toString() === "TypeError: Failed to fetch") {
                        toast = "networkError"
                    } else if (error.status === 503 || error.status === 409) {
                        toast = "serviceError"
                    } else if (error.data === "TooFrequentError") {
                        toast = "tooFrequent"
                    } else {
                        toast = 'vote-vote-failed'
                    }
                    dispatch(failure(toast));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(address) { return { type: VOTE, address } }
    function success(msg) { return { type: VOTE_SUCCEED, msg } }
    function failure(error) { return { type: VOTE_FAILED, error } }
}

function clearVoteState() {
    return dispatch => {
        dispatch(request());
    };
    function request() { return { type: CLEAR_VOTE_STATE } }
}

function voteSigning() {
    return dispatch => {
        dispatch(voteSigning())
    }
    function voteSigning() { return { type: VOTE_SIGNING } }
}

function voteSigningCancelled() {
    return dispatch => {
        dispatch(voteSigningCancelled())
    }
    function voteSigningCancelled() { return { type: VOTE_SIGNING_CANCELLED } }
}

function getLayer3Reward(token) {
 
    return dispatch => {
        dispatch(request());
        voteService.getLayer3Reward(token)
            .then(
                res => {
                    console.log(res)
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: GET_L3_REWARD } }
    function success(res) { return { type: GET_L3_REWARD_SUCCEED, res } }
    function failure(error) { return { type: GET_L3_REWARD_FAILED, error } }
}