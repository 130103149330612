import * as Url from "../../config/Url";
import {handleResponse} from "../../utils/HandleResponse";
import {authHeader} from "../../utils/AuthHeader";

export const inviteService = {
    getInviteInfo,
    getInviteCode,
    registerInviter,
    getInvitee,
    getRankDetails,
    getRankBonusTransactions,
    getRankSwitch
};

async function getInviteInfo(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token)
    };
    return fetch(Url.GET_INVITE_INFO, requestOptions).then(handleResponse);
}

async function getInviteCode(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token)
    };
    return fetch(Url.GET_INVITE_CODE, requestOptions).then(handleResponse);
}

async function registerInviter(token, payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(token)
    };
    return fetch(`${Url.REGISTER_INVITER}${payload.code}`, requestOptions).then(handleResponse);
}

async function getInvitee(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };
    return fetch(Url.GET_INVITEE, requestOptions).then(handleResponse);
}

async function getRankDetails(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };
    return fetch(Url.GET_RANK_DETAILS, requestOptions).then(handleResponse);
}

async function getRankBonusTransactions(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };
    return fetch(Url.GET_RANK_BONUS_TRANSACTIONS, requestOptions).then(handleResponse);
}

async function getRankSwitch(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };
    return fetch(Url.GET_RANK_SWITCH, requestOptions).then(handleResponse);
}
