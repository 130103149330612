import React, { useEffect } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './Home.css';
import { withTranslation } from 'react-i18next';
import { isValidAddress } from 'ethereumjs-util'
import { onClickConnect, onClickInstall, unlock, withdraw } from '../../utils/Sign'
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Helmet } from "react-helmet";
import walletLeftBanner from "../../images/wallet/walletLeftBanner.png";

import HistoryIcon from "../../images/wallet/HistoryIcon.png";
import transferIcon from "../../images/wallet/transferIcon.png";
import withdrawIcon from "../../images/wallet/withdrawIcon.png";
import depositIcon from "../../images/wallet/depositIcon.png";

import unlockEnterIcon from "../../images/unlockEnterIcon.png";

const Web3 = require("web3");

function Home({ t, navBarHeight, address, setOpenSliderModal, handleCloseSliderModal, setSignUpPayload, sliderInfo, network, chainId, code,
    sendBackButton1, sendBackButton1Disabled, button1, button1Disabled,
    sendBackButton2, sendBackButton2Disabled, button2, button2Disabled
}) {

    const useStyles = makeStyles((theme) => ({
        root: {
            ...theme.typography.button,
            backgroundColor: '#010846',
            padding: theme.spacing(1),
            flexGrow: 1,
            textAlign: 'center',
            height: isMobile ? 'auto' : 900
        },
        walletBox: {
            maxWidth: 441,
            borderRadius: 24,
            backgroundColor: '#101B66',
            justifyContent: 'center',
            display: 'inline-flex',
            marginTop: 100,
            paddingTop: 30,
            paddingBottom: 30
        },
        walletContent: {
            width: 300,
            alignItems: 'flex-start',
        },
        wrapper: {
            color: 'white',
            alignItems: 'flex-start',
            display: 'flex'
        },
        textLarge: {
            color: 'white',
            fontSize: 24,
            fontWeight: '600',
        },

        textMid: {
            color: 'white',
            fontSize: 20,
            fontWeight: '600'
        },

        textSmall: {
            color: 'white',
            fontSize: 16,
            fontWeight: '600',
            textTransform: "none"
        },
        result: {
            fontSize: 10,
            color: '#000000',
            width: 200
        },
        btn: {
            backgroundColor: '#83ACF4',
            color: '#010846',
            borderColor: 'transparent',
            borderRadius: 25,
            height: 45,
            fontWeight: 600,
            minWidth: 100
        },
        btn_disabled: {
            backgroundColor: '#83ACF4',
            color: '#010846',
            borderColor: 'transparent',
            borderRadius: 25,
            height: 45,
            fontWeight: 600,
            opacity: 0.2
        },
        defaultFont: {
            fontWeight: '400', color: '#363636', fontFamily: 'Avenir', textTransform: 'none'
        },
    }));
    const classes = useStyles();
    const { registered, loggedIn, loading } = useSelector(state => state.auth)
    const dispatch = useDispatch();

    return (
        <div style={{ display: 'flex', minHeight: 900, flexDirection: 'column', alignItems: 'center' }}>
            <Helmet>
                <title>DumboEX</title>
                <meta name="description" content="Home | DumboEX, a fully decentralized trading protocol" />
            </Helmet>

            <div style={{ width: isMobile ? "100%" : '71%', flexDirection: isMobile ? 'column' : 'row', justifyContent: isMobile ? "unset" : 'space-between', marginTop: isMobile ? "0" : "3%", display: 'flex' }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    backgroundColor: "#F1F9FF",
                    justifyContent: 'space-between',
                    width: isMobile ? "100%" : '38%',
                    height: isMobile ? "unset" : 620,
                    paddingBottom: 24
                }}>
                    <Typography style={{ fontSize: 32, fontFamily: 'Syncopate', color: '#251CFF', marginLeft: 24, marginTop: 24 }}>{t('wallet')}</Typography>

                    <Typography className={classes.defaultFont} style={{ fontSize: isMobile ? 12 : 14, fontWeight: 'bold', color: '#8FB9E1', textAlign: 'left', marginLeft: 24, marginRight: 24 }}>
                        {t('swap-announce')}
                    </Typography>



                    {
                        isMobile ?
                            null
                            :

                            <img src={walletLeftBanner} style={{ width: '96%' }}>
                            </img>
                    }
                </div>


                <div style={{ width: isMobile ? "100%" : '60%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

                    <div style={{ width: '100%' }}>

                        {
                            address.length < 42 || !isValidAddress(address) ?


                                <Button style={{ width: '100%', backgroundColor: '#251CFF', marginBottom: isMobile ? 0 : 24, height: isMobile ? 80 : 120, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', borderRadius: 0 }} onClick={!window.ethereum ? () => onClickInstall(sendBackButton1, sendBackButton1Disabled) : onClickConnect}>
                                    <div style={{ marginLeft: isMobile ? 24 : 32 }}>
                                        <Typography className={classes.defaultFont} style={{ color: 'white', fontSize: isMobile ? 12 : 16, textAlign: 'left' }}>{t("availableCapital")}</Typography>
                                        <div style={{ display: 'flex' }}>
                                            <Typography className={classes.defaultFont} style={{ color: 'white', fontSize: isMobile ? 12 : 16 }}>$</Typography>

                                            <Typography className={classes.defaultFont} style={{ color: 'white', fontFamily: 'Syncopate', fontSize: isMobile ? 24 : 32 }}>0.00</Typography>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', marginRight: isMobile ? 24 : 32 }}>
                                        <Typography className={classes.defaultFont} style={{ color: 'white', fontSize: isMobile ? 12 : 16, marginRight: 16 }}>{button1}</Typography>
                                        <img style={{ height: isMobile ? 40 : 64 }} src={unlockEnterIcon}></img>

                                    </div>

                                </Button>
                                :
                                null
                        }
                        {
                            address.length === 42 && isValidAddress(address) ?

                                <Button style={{ width: '100%', backgroundColor: '#251CFF', marginBottom: isMobile ? 0 : 24, height: isMobile ? 80 : 120, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', borderRadius: 0 }} disabled={button2Disabled} onClick={(!registered || !loggedIn) && !loading ? () => unlock(address, chainId, network, code, Web3, registered, dispatch, setOpenSliderModal, setSignUpPayload) : null} >
                                    <div style={{ marginLeft: isMobile ? 24 : 32 }}>
                                        <Typography className={classes.defaultFont} style={{ color: 'white', fontSize: isMobile ? 12 : 16, textAlign: 'left' }}>{t("availableCapital")}</Typography>
                                        <div style={{ display: 'flex' }}>
                                            <Typography className={classes.defaultFont} style={{ color: 'white', fontSize: isMobile ? 12 : 16 }}>$</Typography>

                                            <Typography className={classes.defaultFont} style={{ color: 'white', fontFamily: 'Syncopate', fontSize: isMobile ? 24 : 32 }}>0.00</Typography>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', marginRight: isMobile ? 24 : 32 }}>
                                        <Typography className={classes.defaultFont} style={{ color: 'white', fontSize: isMobile ? 12 : 16, marginRight: 16 }}>{button2}</Typography>
                                        <img style={{ height: isMobile ? 40 : 64 }} src={unlockEnterIcon}></img>

                                    </div>

                                </Button>
                                : null
                        }
                    </div>

                    <div style={{
                        display: "flex",
                        flexDirection: "column",


                        backgroundColor: "#f9f9f9",
                        width: isMobile ? '100%' : '100%',
                        // height: isMobile ? "unset" : 644,
                        justifyContent: 'center',
                        alignItems: 'center',


                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            width: '91%',
                            marginTop: isMobile ? 24 : 40,
                            justifyContent: 'space-between',
                        }}>


                            <Link to='/deposit' style={{ width: '30%', height: isMobile ? 100 : 200, backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textDecoration: 'none' }}>
                                <img style={{ width: "60%" }} src={depositIcon}></img>
                                <Typography className={classes.defaultFont} style={{ fontSize: isMobile ? 12 : 20 }}>{t('deposit')}</Typography>
                            </Link>

                            <Link to='/withdraw' style={{ width: '30%', height: isMobile ? 100 : 200, backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textDecoration: 'none' }}>
                                <img style={{ width: "60%" }} src={withdrawIcon}></img>
                                <Typography className={classes.defaultFont} style={{ fontSize: isMobile ? 12 : 20 }}>{t('withdraw')}</Typography>
                            </Link>

                            <Link to='/transfer' style={{ width: '30%', height: isMobile ? 100 : 200, backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textDecoration: 'none' }}>
                                <img style={{ width: "60%" }} src={transferIcon}></img>
                                <Typography className={classes.defaultFont} style={{ fontSize: isMobile ? 12 : 20 }}>{t('transfer')}</Typography>
                            </Link>


                        </div>

                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            width: '91%',
                            justifyContent: 'space-between',
                            marginTop: 24,
                            marginBottom: isMobile ? 24 : 40,
                        }}>
                            <Link to='/records' style={{ width: '30%', height: isMobile ? 100 : 200, backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textDecoration: 'none' }}>
                                <img style={{ width: "60%" }} src={HistoryIcon}></img>


                                <Typography className={classes.defaultFont} style={{ fontSize: isMobile ? 12 : 20 }}>{t('records')}</Typography>

                            </Link>




                        </div>

                    </div>
                </div>
                {/* {
                    loggedIn ? <Redirect to='/wallet' /> :
                        <div style={{ overflow: 'hidden' }}>
                            <div className='cards__wrapper'>

                                <div className='cards__title__wrapper'>
                                    <Typography className={classes.textLarge} style={{ textTransform: 'none' }}>
                                        {t('walletTitle')}

                                    </Typography>
                                    <Link to='/wallet/records' style={{ textDecoration: 'none' }}>
                                        <Button className={classes.textSmall} color="textSecondary">
                                            {t('records')}
                                        </Button>
                                    </Link>
                                </div>

                                <Link to='/wallet/deposit' style={{ textDecoration: 'none', width: '98%', height: 80, backgroundColor: '#000745', borderRadius: 16, border: '2px solid #1DF0A9', display: 'flex', justifyContent: 'space-between', flexDirection: 'row', overflow: 'hidden', marginTop: 40 }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={deposit} style={{ height: 60, width: 60, marginLeft: 16 }}></img>
                                        <div style={{ marginLeft: 16 }}>
                                            <Typography style={{ fontSize: 18, color: 'white', fontWeight: 'bold' }} >
                                                {t('deposit')}
                                            </Typography>

                                            <Typography style={{ fontSize: 12, color: '#8FB9E1', fontWeight: 'bold' }} >
                                                {t('depositSub')}
                                            </Typography>
                                        </div>
                                    </div>
                                    <img src={badgetIcon} style={{ height: 70, width: 81 }}></img>

                                </Link>

                                <Link to='/wallet/withdraw' style={{ textDecoration: 'none', width: '98%', height: 80, backgroundColor: '#000745', borderRadius: 16, border: '2px solid #000745', display: 'flex', justifyContent: 'space-between', flexDirection: 'row', overflow: 'hidden', marginTop: 24 }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={withdrawIcon} style={{ height: 60, width: 60, marginLeft: 16 }}></img>
                                        <div style={{ marginLeft: 16 }}>
                                            <Typography style={{ fontSize: 18, color: 'white', fontWeight: 'bold' }} >
                                                {t('withdraw')}
                                            </Typography>

                                            <Typography style={{ fontSize: 12, color: '#8FB9E1', fontWeight: 'bold' }} >
                                                {t('withdrawSub')}
                                            </Typography>
                                        </div>
                                    </div>
                                </Link>
                                <Link to='/wallet/transfer' style={{ textDecoration: 'none', width: '98%', height: 80, backgroundColor: '#000745', borderRadius: 16, border: '2px solid #000745', display: 'flex', justifyContent: 'space-between', flexDirection: 'row', overflow: 'hidden', marginTop: 24 }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={transferIcon} style={{ height: 60, width: 60, marginLeft: 16 }}></img>
                                        <div style={{ marginLeft: 16 }}>
                                            <Typography style={{ fontSize: 18, color: 'white', fontWeight: 'bold' }} >
                                                {t('transfer')}
                                            </Typography>

                                            <Typography style={{ fontSize: 12, color: '#8FB9E1', fontWeight: 'bold' }} >
                                                {t('transferSubtitle')}
                                            </Typography>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div style={{ width: '100%' }}>
                                {
                                    address.length < 42 || !isValidAddress(address) ?
                                        <Button
                                            style={{ height: 52, width: '100%', backgroundColor: '#4477F1', borderRadius: 0 }}
                                            onClick={!window.ethereum ? () => onClickInstall(sendBackButton1, sendBackButton1Disabled) : onClickConnect}>
                                            <Typography style={{ fontSize: 16, fontWeight: 'bold', color: '#010846' }}>
                                                {button1}
                                            </Typography>
                                        </Button>
                                        :
                                        null
                                }
                                {
                                    address.length === 42 && isValidAddress(address) ?
                                        <Button
                                            style={{ height: 52, width: '100%', backgroundColor: '#75E2F9', borderRadius: 0 }}

                                            className={button2Disabled ? classes.btn_disabled : classes.btn} onClick={(!registered || !loggedIn) && !loading ? () => unlock(address, chainId, network, code, Web3, registered, dispatch, setOpenSliderModal, setSignUpPayload) : null} variant="outlined" color="primary" disabled={button2Disabled}>
                                            <Typography style={{ fontSize: 16, fontWeight: 'bold', color: '#010846' }}>

                                                {button2}
                                            </Typography>

                                        </Button> : null
                                }
                            </div>

                        </div>
                } */}
            </div>
        </div>
    );
}

export default withTranslation()(Home)
