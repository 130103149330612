import React, { useEffect, useState } from 'react';
import { Avatar, Backdrop, Button, Fade, Grid, MenuItem, Modal, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import useWindowDimensions from '../../utils/WindowDimensions'
// import CustomTextField from '../CommonElements/CustomTextField'

import { walletActions } from '../../redux/actions/walletActions';
import { authActions } from '../../redux/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import { roundingDown } from '../../utils/RoundingDown'
import { countDecimals, getChain } from "../../utils/Common";
import { history } from '../../utils/History';
import { onClickConnect, onClickInstall, transfer, unlock } from '../../utils/Sign'
import { isValidAddress } from "ethereumjs-util";
import CustomButton from '../CommonElements/CustomButton';
import CustomDropBox from '../CommonElements/CustomDropBox';
import i18n from "../../i18n";
import transferIcon from "../../images/wallet/transferIcon.png";
import backIcon from "../../images/backIcon.png";
// import lightIcon from "../../images/swap/lightIcon.png";
import { isMobile } from 'react-device-detect';
import NewCustomTextField from '../CommonElements/NewCustomTextField'
import NewCustomCoinSelector from '../CommonElements/NewCustomCoinSelector'
import Lottie from 'lottie-react-web'
import animation from "../Animations/127882-gamification-onboarding-animation.json";
import animationSuccess from "../Animations/lf30_editor_g02qsqwx.json";
import animationCancle from "../Animations/98457-transaction-canceled.json";


// import CustomCoinSelector from '../CommonElements/CustomCoinSelector'
// const backArrow = 'https://cdn.swapallapp.com/l2/images/backArrow.png'
// const confirmIcon = 'https://cdn.swapallapp.com/l2/images/confirmIcon.png'
// const AppIcon = 'https://cdn.swapallapp.com/l2/images/AppIcon.png'
// const L2Icon = 'https://cdn.swapallapp.com/l2/images/L2Icon.png'
import metamaskIcon from "../../images/metamaskIcon.png";

const Web3 = require("web3");
// let web3 = new Web3(window.ethereum)

function Transfer({ t, address, setOpenSliderModal, handleCloseSliderModal, setSignUpPayload, sliderInfo, chainId, network, code,
    sendBackButton1, sendBackButton1Disabled, button1, button2Disabled
}) {
    const { width } = useWindowDimensions();
    const useStyles = makeStyles((theme) => ({
        root: {
            ...theme.typography.button,
            backgroundColor: '#010846',
            padding: theme.spacing(1),
            flexGrow: 1,
            textAlign: 'center'
        },
        depositBox: {
            width: 600,
            borderRadius: 24,
            backgroundColor: '#101B66',
            justifyContent: 'center',
            display: 'inline-flex',
            marginTop: 100,
            paddingTop: 30,
            paddingBottom: 30
        },
        depositContent: {
            width: 500,
            alignItems: 'flex-start',
        },
        wrapper: {
            color: 'white',
            alignItems: 'flex-start',
            display: 'flex',
            fontSize: 25
        },
        fieldWrapper: {
            // justifyContent: 'baseline',
            width: '100%',

            margin: 0
        },
        result: {
            fontSize: 10,
            color: '#000000',
            width: 200
        },
        btn: {
            backgroundColor: '#83ACF4',
            color: '#010846',
            borderColor: 'transparent',
            borderRadius: 25,
            height: 45,
            fontWeight: 600,
            minWidth: 100
        },
        btn_disabled: {
            backgroundColor: '#83ACF4',
            color: '#010846',
            borderColor: 'transparent',
            borderRadius: 25,
            height: 45,
            fontWeight: 600,
            opacity: 0.2
        },
        capitalList: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: 'transparent',
        },
        backBtn: {
            float: 'left'
        },
        backArrow: {
            width: 20,
            height: 20
        },
        contentWrapper: {
            fontSize: 15,
            color: 'white'
        },
        inputField: {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            width: '100%',
            color: 'red'
        },
        fillAddress: {
            color: 'white',
            fontSize: 15,
            backgroundColor: '#101B66'
        },
        helperText: {
            color: '#ff3434'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // width: width * 0.5,
            // height: height * 0.5
        },
        paper: {
            backgroundColor: 'white',
            border: 'transparent',
            // boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            borderRadius: 20,
            textAlign: 'center'
        },
        feeContentLeft: {
            color: 'white',
            fontSize: 15,
            textAlign: 'left'
        },
        feeContentRight: {
            color: 'white',
            fontSize: 15,
            textAlign: 'right'
        },
        defaultFont: {
            fontWeight: '400', color: '#363636', fontFamily: 'Avenir', textTransform: 'none'
        },
    }));
    const classes = useStyles();

    const [transferTo, setTransferTo] = useState('')

    const [transferFee, setTransferFee] = useState({ base: "", amount: 0 })
    const [isTransferOpen, setIsTransferOpen] = useState(false)
    const [isAppTransferOpen, setIsAppTransferOpen] = useState(false)
    const [minTransferAmount, setMinTransferAmount] = useState(0)
    const [transferLimit, setTransferLimit] = useState(0)
    const [serviceTokenFree, setServiceTokenFree] = useState(0)
    const [transferAmount, setTransferAmount] = useState('')
    const [addrWarning, setAddrWarning] = React.useState('')
    const [coin, setCoin] = React.useState('');
    const [capital, setCapital] = React.useState({ free: 0 })
    const [warning, setWarning] = React.useState('')
    const [validAddress, setValidAddress] = React.useState(false)
    const [validAmount, setValidAmount] = React.useState(false)
    const [coins, setCoins] = React.useState([])
    const [transferableCoins, setTransferableCoins] = React.useState([])
    const [coinInfo, setCoinInfo] = React.useState({})
    const [reciverType, setReciverType] = React.useState('L2');


    const { token, loggedIn, registered, loading, userInfo } = useSelector(state => state.auth)
    const { tokenList, tokenIcons, userCapitals, transferSucceed, transferFinished, transferMsg, walletSigning, walletMsg, transferFeeObj } = useSelector(state => state.wallet)
    const dispatch = useDispatch();
    const inputRef = React.useRef();

    const [openNote, setOpenNote] = useState(false)
    const [time, setTime] = useState('')
    const [receivingAmount, setReceivingAmount] = useState('--')

    const [moneyOut, setMoneyOut] = useState(true)

    const allIn = () => {
        handleAmountChange(roundingDown(capital.free, 4))
    }


    const handleOpenNote = () => {
        setOpenNote(true);
    };

    const handleCloseNote = () => {
        setOpenNote(false);
        setTransferAmount("")
    };

    const handleAddressChange = (addr) => {
        setTransferTo(addr);

        if (reciverType === "App") {
            //添加App地址规则
            if (addr.length === 8 && parseFloat(addr)) {
                setAddrWarning('')
                setValidAddress(true)
            } else {
                if (addr === '') {
                    setAddrWarning('')
                } else {
                    setAddrWarning(t('invalidAddress'))
                }
                setValidAddress(false)
            }
        } else if (reciverType === "L2") {
            //添加L2规则
            if (addr.length === 42 && isValidAddress(addr)) {
                setAddrWarning('')
                setValidAddress(true)
            } else {
                if (addr === '') {
                    setAddrWarning('')
                } else {
                    setAddrWarning(t('invalidAddress'))
                }
                setValidAddress(false)
            }
        }


    };

    const handleAmountChange = (amount) => {
        if (parseFloat(amount) >= 0) {
            if (countDecimals(parseFloat(amount)) <= 8) {
                setTransferAmount(amount)
            } else {
                setTransferAmount(roundingDown(parseFloat(amount), 8).toString())
            }
        } else {
            setTransferAmount('')
        }

        if (amount === "") {
            setValidAmount(false)
            setWarning('')
            setReceivingAmount('--')
        }
        else if (!parseFloat(amount)) {
            setValidAmount(false)
            setWarning(t('invalidInput'))
            setReceivingAmount('--')
        }

        else {
            if (parseFloat(amount) <= capital.free && parseFloat(amount) > 0) {

                if (parseFloat(amount) > transferLimit) {
                    setValidAmount(false)
                    setWarning(t('transferHigherThanLimit') + " (" + roundingDown(transferLimit, 4) + " " + capital.token + ")")
                    setReceivingAmount('--')
                } else if (parseFloat(amount) < minTransferAmount) {
                    setValidAmount(false)
                    setWarning(t('transferLowerThanLimit') + "(" + minTransferAmount + " " + capital.token + ")")
                    setReceivingAmount('--')
                }

                else {
                    if (coin !== transferFee.base) {
                        if (parseFloat(transferFee.amount) > parseFloat(serviceTokenFree)) {
                            //转账手续费币种余额不足
                            setValidAmount(false)
                            setWarning(t('transferFeeTokenFreeNotEnough1'))
                            setReceivingAmount('--')
                        } else {
                            // dispatch(walletActions.getFee({ token, amount: amount, action: coinInfo.contractWithdrawKey }))
                            setValidAmount(true)
                            setWarning('')
                        }
                    } else {
                        let minTotal = transferFee.amount + minTransferAmount
                        if (parseFloat(amount) < minTotal) {
                            setValidAmount(false)
                            setWarning(t('lowerThanMinWithdrawLimit') + "(" + minTotal + " " + transferFee.base + ")")
                            setReceivingAmount('--')
                        } else {
                            // dispatch(walletActions.getFee({ token, amount: amount, action: coinInfo.contractWithdrawKey }))
                            setValidAmount(true)
                            setWarning('')
                        }
                    }
                }
            } else {
                setValidAmount(false)
                setWarning(t('insufficient_fund'))
                setReceivingAmount('--')
            }
        }


    };

    const handleCoinChange = (option) => {
        if (option.value !== t('noCapital')) {
            setCoin(option.value);
            let coinInfo = coins.find(item => item.label === option.value)
            setCoinInfo(coinInfo)
            setWarning('')
            handleAmountChange('')
            setServiceFeeAndLimit(option.value)
        } else {
            setCoinInfo({})
            setCoin('')
            setWarning('')
            setValidAmount(false)
            dispatch(walletActions.clearFee())
            setMinTransferAmount(0)
            setTransferLimit(0)
            setTransferFee({ base: '', amount: 0 })
            setIsTransferOpen(false)
            setServiceTokenFree(0)
        }


    };
    const setServiceFeeAndLimit = (coin) => {
        let coinInfo = coins.find(item => item.label === coin)
        if (coinInfo === undefined) {
            coinInfo = {
                price: 0,
                dailyTransferQuota: 0
            }
        }
        var feeToken
        let feeObj = transferFeeObj.capitals.find(item => item.token === coin)
        if (feeObj === undefined) {
            setTransferFee({ base: '', amount: 0 })
            setIsTransferOpen(false)
            setIsAppTransferOpen(false)
            setTransferLimit(0)
            setMinTransferAmount(0)
            feeToken = ''
        } else {
            setTransferFee(feeObj.fee)
            setIsTransferOpen(feeObj.canTransfer)
            setIsAppTransferOpen(feeObj.canTransferToApp)
            setTransferLimit(coinInfo.price !== 0 ? Math.min(feeObj.limit, coinInfo.dailyTransferQuota) : feeObj.limit)
            setMinTransferAmount(feeObj.minTransferAmount)
            feeToken = feeObj.feeToken
        }
        transferFeeObj.capitals.map((feeObj) => {
            if (feeObj.token === feeToken) {
                setServiceTokenFree(feeObj.free)
            }
        })

    }

    const handleReciverTypeChange = (event) => {
        setReciverType(event.target.value)
        setCoin('')
        handleAmountChange('')
        setWarning('')
        setAddrWarning('')
        setValidAddress(false)
        handleAddressChange('')

        if (event.target.value === 'L2') {
            setTransferableCoins(coins.filter(item => item.l2TransferIsOn === true))
        } else if (event.target.value === 'App') {
            setTransferableCoins(coins.filter(item => item.transferIsOn === true))
        }
    };



    const confirmTransfer = async () => {

        await transfer(address, chainId, network, Web3,
            registered, dispatch, walletSigning, setTime, handleOpenNote,
            coin, transferAmount, transferTo, reciverType === "App" ? false : true, token)
    }

    const clear = () => {
        setTransferTo('')
        setAddrWarning('')
        setValidAddress(false)
    }

    const handleFinish = (changedAmount) => {
        let coinInfo = coins.find(item => item.label === coin)
        if (changedAmount) {
            if (validAmount) {
                // dispatch(walletActions.getFee({ token, amount: transferAmount, action: coinInfo.contractWithdrawKey }))
            }
            if (validAddress) {
                setAddrWarning('')
            }
        }
    }

    useEffect(() => {
        if (loggedIn) {
            dispatch(walletActions.getTransferFee(token))

            dispatch(walletActions.getUserCapital(token))
            dispatch(walletActions.getAllTokenStatus(token));
            dispatch(authActions.getUserInfo(token))
        }
        return () => {
        }
    }, [])

    useEffect(() => {
        if (loggedIn) {
            dispatch(walletActions.getTransferFee(token))
            dispatch(walletActions.getUserCapital(token))
            dispatch(walletActions.getAllTokenStatus(token))
            dispatch(authActions.getUserInfo(token))
            setValidAddress(true)
            handleAmountChange('')
            handleCoinChange({ value: t('noCapital') })
        }
        return () => {
        }
    }, [loggedIn, address])

    useEffect(() => {
        let _coins = []
        let _capital = userCapitals.find(item => item.token === coin)
        let chain = '_' + getChain(network, chainId)
        let _chain = ''
        if (chain[0] !== undefined) {
            _chain = chain[0].toUpperCase() + chain.substring(1).toLowerCase()
        }
        let token
        if (_capital === undefined) {
            _capital = {
                free: 0,
                token: coin
            }
        }
        setCapital(_capital)



        for (let i = 0; i < userCapitals.length; i++) {
            token = tokenList.find(item => item.token === userCapitals[i].token && item.chain === 'ETH')
            if (token === undefined) {
                token = {
                    token: userCapitals[i].token,
                    contractTransferIsOn: false,
                    contractTransferFeeKey: ''
                }
            }
            let price = token.price
            if (price === undefined) {
                price = 0
            }
            let dailyMoneyOutLimit = userInfo.dailyMoneyOutLimit
            if (dailyMoneyOutLimit === undefined || dailyMoneyOutLimit === null) {
                dailyMoneyOutLimit = 0
            }
            if (price !== 0) {
                dailyMoneyOutLimit = dailyMoneyOutLimit / price
            }

            _coins.push({
                label: userCapitals[i].token,
                value: userCapitals[i].token,
                contractWithdrawKey: userCapitals[i].token + '_' + chain + '_CONTRACT_WITHDRAW_FEE',
                dailyTransferQuota: dailyMoneyOutLimit,
                price: price,
                transferIsOn: token.transferIsOn,
                l2TransferIsOn: token.l2TransferIsOn
            })
        }
        if (_coins.length === 0) {
            _coins.push({
                label: t('noCapital'),
                value: ''
            })
        }
        setCoins(_coins)
        if (reciverType === 'L2') {
            setTransferableCoins(_coins.filter(item => item.l2TransferIsOn === true))
        } else {
            setTransferableCoins(_coins.filter(item => item.transferIsOn === true))
        }

        return () => {
        }

    }, [i18n.language, coin, address, userCapitals, tokenList, chainId, network, userInfo])

    useEffect(() => {
        if (transferFeeObj.capitals !== undefined) {
            setServiceFeeAndLimit(coin)
        }
    }, [transferFeeObj])

    useEffect(() => {
        if (userInfo.withdraw !== undefined) {
            setMoneyOut(!!userInfo.withdraw)
        }
    }, [userInfo])


    const confirmViewBody = (
        <div style={{
            backgroundColor: 'white', borderRadius: 24, width: width < 950 ? "91%" : 520, overflow: 'hidden', outline: 0, display: 'flex', alignItems: 'center', flexDirection: 'column'
        }}>

            {/* <Avatar src={metamaskIcon} variant="square" style={{ width: 64, height: 64, marginTop: 24 }} /> */}
            {
                !walletSigning ?
                    transferSucceed ?
                        <div style={{ width: width < 950 ? 60 : 100, height: width < 950 ? 60 : 100, marginTop: 24 }}>
                            <Lottie
                                speed={0.8}
                                options={{
                                    animationData: animationSuccess,
                                    loop: false,
                                }}
                            />
                        </div>
                        :
                        <div style={{ width: width < 950 ? 60 : 100, height: width < 950 ? 60 : 100, marginTop: 24 }}>
                            <Lottie
                                speed={0.8}
                                options={{
                                    animationData: animationCancle,
                                    loop: false,
                                }}
                            />


                        </div>

                    :

                    <div style={{ width: width < 950 ? 60 : 100, height: width < 950 ? 60 : 100, marginTop: 24 }}>


                        <Lottie

                            speed={0.5}
                            options={{

                                animationData: animation,
                                loop: true,

                            }}
                        />


                    </div>
            }

            <div style={{ width: "90%", display: 'flex', justifyContent: 'center', marginTop: 12 }}>
                {walletSigning ?
                    <div style={{ marginBottom: 24 }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar src={metamaskIcon} variant="square" style={{ width: width < 950 ? 24 : 32, height: width < 950 ? 24 : 32, marginRight: 8 }} />
                            <Typography className={classes.defaultFont} style={{ fontSize: width < 950 ? 16 : 20, fontWeight: 'bold', color: '#363636', textAlign: 'center' }}>
                                {t('confirming')}
                            </Typography>

                        </div>
                        <Typography className={classes.defaultFont} style={{ marginTop: 8, fontSize: 12, fontWeight: 'bold', color: '#8786A7', textAlign: 'center' }}>
                            {t('AlreadyConfirmed')}
                        </Typography>
                    </div>

                    :
                    <div style={{ marginBottom: 24, display: 'flex', flexDirection: 'column' }}>
                        <Typography className={classes.defaultFont} style={{ fontSize: width < 950 ? 16 : 20, fontWeight: 'bold', marginTop: 8, color: '#363636', textAlign: 'center' }}>
                            {transferFinished ?
                                t(transferMsg)
                                :
                                walletMsg === "actionCancelled" ?

                                    <Typography style={{ fontSize: 16, fontWeight: 'bold', marginTop: 8, color: '#363636', marginBottom: 16 }}>
                                        {t("actionCancelled")}
                                    </Typography>
                                    :
                                    null
                            }


                        </Typography>

                        <Typography className={classes.defaultFont} style={{ fontSize: width < 950 ? 16 : 20, fontWeight: 'bold', marginTop: 8, color: '#363636', textAlign: 'center' }}>
                            {walletMsg === "actionCancelled" ?
                                <Typography style={{ fontSize: 16, fontWeight: 'bold', marginTop: 8, color: '#363636', marginBottom: 16 }}>
                                    {t("actionCancelled")}
                                </Typography>
                                :
                                null
                            }
                        </Typography>

                        <Button onClick={handleCloseNote}>
                            <Typography className={classes.defaultFont} style={{ fontSize: 12, fontWeight: 'bold', color: '#8786A7', textAlign: 'center' }}>
                                {t('CloseWindow')}
                            </Typography>
                        </Button>
                    </div>


                }
            </div>
            {/* <Typography className={classes.defaultFont} style={{ fontSize: 20, fontWeight: 'bold', marginTop: 8, marginBottom: 24, color: '#363636', textAlign: 'center' }}>
                {
                    walletSigning ?
                        t('confirming')
                        :
                        transferFinished ?
                            t(transferMsg)
                            :
                            t(walletMsg)
                }

            </Typography> */}
            {/* {walletMsg === "actionCancelled" ?

                <Typography style={{ fontSize: 16, fontWeight: 'bold', marginTop: 8, color: '#363636', marginBottom: 16 }}>
                    {t("actionCancelled")}
                </Typography>
                :
                null
            } */}

            {walletSigning ?
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '89%', marginTop: 40 }}>
                        <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#8A96A7', fontWeight: 'bold' }}>
                            {t('transferAmount')}
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* <Avatar variant="square" src={getIcons(pairOne, tokenIcons, true)} style={{ height: 20, width: 20, marginRight: 8 }} /> */}
                            <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#251CFF', fontWeight: 'bold', }} >
                                {transferAmount} {coin}
                            </Typography>


                        </div>
                    </div>



                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '89%', marginTop: 16 }}>
                        <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#8A96A7', fontWeight: 'bold' }}>
                            {t('receiverAddress')}
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* <Avatar variant="square" src={getIcons(pairTwo, tokenIcons, true)} style={{ height: 20, width: 20, marginRight: 8 }} /> */}
                            <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#363636', fontWeight: 'bold', }} >

                                {width < 950 ? `${transferTo.slice(0, 8)}...${transferTo.slice(transferTo.length - 3)}` : transferTo}
                            </Typography>
                        </div>
                    </div>



                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 16, width: '89%' }}>
                        <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#8A96A7', fontWeight: 'bold' }}>
                            {t('action')}
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* <Avatar variant="square" src={getIcons(pairOne, tokenIcons, true)} style={{ height: 20, width: 20, marginRight: 8 }} /> */}
                            <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#363636', fontWeight: 'bold' }} >
                                {t('transfer')}
                            </Typography>


                        </div>
                    </div>



                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 16, width: '89%', marginBottom: 40 }}>
                        <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#8A96A7', fontWeight: 'bold' }}>
                            {t('status')}
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* <Avatar variant="square" src={getIcons(pairOne, tokenIcons, true)} style={{ height: 20, width: 20, marginRight: 8 }} /> */}
                            <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#363636', fontWeight: 'bold' }} >
                                {walletSigning ? t('loading') : transferSucceed ? t(transferMsg) : t(walletMsg)}
                            </Typography>


                        </div>
                    </div>
                </div>
                :
                null
            }
        </div>
    );
    return (
        <div style={{ display: 'flex', minHeight: 1000, flexDirection: 'row', justifyContent: 'center' }}>
            {
                width < 960 ?

                    //phone UI
                    <div style={{ width: "100%", display: 'flex', flexDirection: width < 960 ? "column" : 'row', justifyContent: width < 960 ? "unset" : 'space-between', marginTop: "0" }}>

                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: 'space-between',
                            width: "100%",
                        }}>

                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16, marginTop: 16 }}>
                                <Typography style={{ fontSize: 24, fontFamily: 'Syncopate', color: '#251CFF', marginLeft: 24 }}>{t('transfer')}</Typography>

                                <Button style={{ color: "white", backgroundColor: '#251CFF', fontSize: 12, textTransform: 'none', height: 32, marginRight: 24, borderRadius: 8 }} onClick={() => {
                                    history.back()
                                }} >
                                    {t("back")}
                                </Button>
                            </div>

                        </div>

                        <Typography className={classes.defaultFont} style={{ fontSize: 12, color: '#8786A7', textAlign: 'left', marginLeft: 24, marginRight: 24 }}>
                            {moneyOut ? t('transferContent') : t('moneyOutClosed')}
                        </Typography>


                        <div style={{ width: '100%', height: 780 }}>

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>



                                {
                                    moneyOut ? <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        {/* <div style={{ alignItems: 'flex-start', }}>
                                    </div> */}
                                        <div style={{ width: '100%', marginTop: 24, marginBottom: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                            <div style={{ width: '91%', alignItems: 'flex-start', marginBottom: 8 }}>
                                                <Typography style={{ textAlign: 'left', color: '#AFBDD0', fontFamily: 'Avenir', fontWeight: '600', fontSize: 12 }}>{t('selectReceiverType')}</Typography>
                                            </div>
                                            <div style={{ display: 'flex', width: '91%', backgroundColor: 'white', marginTop: 8, borderRadius: 16, border: '1px solid #DCDCDC', height: 64, alignItems: 'center', justifyContent: 'center', }}>

                                                <CustomDropBox
                                                    onChange={handleReciverTypeChange}
                                                    value={reciverType}
                                                    disabled={!loggedIn}
                                                >
                                                    <MenuItem key={"L2Wallet"} value={"L2"}>
                                                        <Grid container spacing={1}>
                                                            <>

                                                                <Grid item xs={width <= 420 ? 1 : 2} >
                                                                    <Typography style={{ fontSize: isMobile ? 14 : 20, fontWeight: 'bold', color: '#363636', fontFamily: 'Avenir', }}>
                                                                        {t('l2Receiver')}
                                                                    </Typography>
                                                                </Grid>
                                                            </>

                                                        </Grid>
                                                    </MenuItem>
                                                    <MenuItem key={"swapAllApp"} value={"App"}>
                                                        <Grid container spacing={1}>
                                                            <>

                                                                <Grid item xs={width <= 420 ? 1 : 2} >
                                                                    <Typography style={{ fontSize: isMobile ? 14 : 20, fontWeight: 'bold', color: '#363636', fontFamily: 'Avenir', }}>
                                                                        {t('AppReceiver')}
                                                                    </Typography>
                                                                </Grid>
                                                            </>

                                                        </Grid>
                                                    </MenuItem>
                                                </CustomDropBox>
                                            </div>
                                        </div>


                                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                            <div style={{ width: '91%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Typography style={{ textAlign: 'left', color: '#AFBDD0', fontFamily: 'Avenir', fontWeight: '600', fontSize: isMobile ? 12 : 14 }}>{reciverType === "App" ? t('appUID') : t('l2Address')}</Typography>
                                                {

                                                    loggedIn ?
                                                        null
                                                        :
                                                        <Button
                                                            style={{ textTransform: 'none', minHeight: 0, minWidth: 0 }}
                                                            onClick={(!registered || !loggedIn) && !loading ? () => unlock(address, chainId, network, '', Web3, registered, dispatch, setOpenSliderModal, setSignUpPayload) : null} disabled={button2Disabled}

                                                        >

                                                            <Typography style={{ textAlign: 'left', color: '#251CFF', fontFamily: 'Avenir', fontWeight: '600', fontSize: isMobile ? 12 : 14 }}>Unlock Wallet</Typography>
                                                        </Button>

                                                }


                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '91%', backgroundColor: 'white', marginTop: 8, borderRadius: 16, border: '1px solid #DCDCDC' }}>
                                                <div style={{ marginLeft: 12, marginRight: 12, marginTop: 12, marginBottom: 12, width: '100%' }}>
                                                    <NewCustomTextField
                                                        inputRef={inputRef}
                                                        type="text"
                                                        style={{ width: '100%', textTransform: 'none' }}
                                                        rightbuttonlabel={''}
                                                        error={warning !== ''}
                                                        showcancellbutton={true}
                                                        clear={clear}
                                                        onChange={(e) => handleAddressChange(e.target.value)}
                                                        onBlur={() => handleFinish(false)}
                                                        value={transferTo}
                                                        disabled={!loggedIn}
                                                        placeholder={"Enter Address"}
                                                    >

                                                    </NewCustomTextField>
                                                    <Typography style={{ marginLeft: 8, fontFamily: 'Avenir', fontWeight: '500', color: addrWarning === "" ? "#AFBDD0" : '#FF4646', fontSize: isMobile ? 10 : 16 }}> {addrWarning !== '' ? addrWarning : "please enter receive address"}</Typography>

                                                </div>

                                            </div>
                                        </div>





                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 12, width: '100%' }}>
                                            <div style={{ width: '100%' }}>

                                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 24 }}>
                                                    <div style={{ width: '91%', alignItems: 'flex-start', }}>
                                                        <Typography style={{ textAlign: 'left', color: '#AFBDD0', fontFamily: 'Avenir', fontWeight: '600', fontSize: isMobile ? 12 : 14 }}>{t('transferAmount')}</Typography>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '91%', backgroundColor: 'white', marginTop: 8, borderRadius: 16, border: '1px solid #DCDCDC' }}>
                                                        <div style={{ marginLeft: isMobile ? 12 : 24, marginTop: isMobile ? 10 : 16, marginBottom: 12 }}>
                                                            <NewCustomTextField
                                                                disabled={!loggedIn}
                                                                inputRef={inputRef}
                                                                onChange={(e) => handleAmountChange(e.target.value)}
                                                                value={transferAmount}
                                                                onBlur={() => handleFinish(true)}
                                                                placeholder={"0.00"}
                                                                rightbuttonlabel={t(capital.free > 0.0001 ? 'all' : null)}
                                                                onrightbuttonclick={allIn}
                                                                customtype="number"
                                                                coin={coin}
                                                                tokenIcons={tokenIcons}
                                                                coins={coins}
                                                                plachHolder={t("selectCoin")}
                                                                inputTitle={t("searchCoin")}
                                                                handleChange={handleCoinChange}

                                                            >
                                                            </NewCustomTextField>
                                                            <Typography style={{ marginLeft: 8, fontFamily: 'Avenir', fontWeight: '500', color: warning === "" ? "#AFBDD0" : '#FF4646', fontSize: isMobile ? 10 : 16 }}> {warning === "" ? t("enterAmount") : warning}</Typography>
                                                        </div>



                                                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: isMobile ? 12 : 24, alignItems: 'flex-end' }}>
                                                            <NewCustomCoinSelector
                                                                // style={{ width: width < 960 ? "unset" : '42%' }}
                                                                coin={coin}
                                                                isloggedIn={loggedIn}

                                                                tokenIcons={tokenIcons}
                                                                coins={coins}
                                                                plachHolder={t("selectCoin")}
                                                                inputTitle={t("searchCoin")}
                                                                handleChange={handleCoinChange}
                                                            />
                                                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8, alignItems: 'center', justifyContent: 'center', marginRight: 8 }}>
                                                                <Typography style={{ textAlign: 'left', color: '#AFBDD0', fontFamily: 'Avenir', fontWeight: '500', fontSize: isMobile ? 10 : 14 }}>{t("availableCapital")} </Typography>
                                                                <Typography style={{ textAlign: 'left', marginLeft: 4, color: '#271CFF', fontFamily: 'Avenir', fontWeight: '500', fontSize: isMobile ? 10 : 14 }}>{loggedIn ? roundingDown(capital.free, 4) : '--'}</Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: 32, justifyContent: 'center' }}>
                                            <div style={{ width: '91%', display: 'flex', flexDirection: 'column', borderRadius: 16, border: '1px solid #DCDCDC', alignItems: 'center', justifyContent: 'center' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '91%', alignItems: 'center', marginTop: 24, marginBottom: 24 }}>
                                                    <Typography className={classes.defaultFont} style={{ color: '#8B96A7', fontSize: 16 }}>{t('transferFee')}</Typography>
                                                    <Typography className={classes.defaultFont} style={{ fontSize: 16, color: '#8B96A7' }}> {transferFee.base === "" ? "--" : transferFee.amount + " " + transferFee.base}</Typography>
                                                </div>

                                            </div>

                                        </div>



                                        <div style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "8%" }}>
                                            {
                                                address.length < 42 || !isValidAddress(address) ?
                                                    <CustomButton buttonstyle="connectStyle" style={{ width: '91%' }} onClick={!window.ethereum ? () => onClickInstall(sendBackButton1, sendBackButton1Disabled) : () => onClickConnect(network, chainId, address, dispatch)}>
                                                        {button1}
                                                    </CustomButton> : null
                                            }
                                            {
                                                address.length === 42 && isValidAddress(address) ?
                                                    loggedIn ?
                                                        <CustomButton style={{ opacity: !validAmount || !validAddress || !isTransferOpen ? 0.2 : 1, width: '91%' }} onClick={confirmTransfer} disabled={!validAmount || !validAddress || (!isTransferOpen && reciverType === 'L2') || (!isAppTransferOpen && reciverType === 'App')}>
                                                            {t('confirm')}
                                                        </CustomButton> :
                                                        <CustomButton buttonstyle="unlockStyle" style={{ width: '91%' }} onClick={(!registered || !loggedIn) && !loading ? () => unlock(address, chainId, network, code, Web3, registered, dispatch, setOpenSliderModal, setSignUpPayload) : null} disabled={button2Disabled}>
                                                            {t('unlock')}
                                                        </CustomButton> : null
                                            }
                                        </div>
                                    </Grid> : null
                                }
                            </div>
                        </div>
                    </div>
                    :

                    //desk UI
                    <div style={{ width: width < 960 ? "100%" : '71%', display: 'flex', flexDirection: width < 960 ? "column" : 'row', justifyContent: width < 960 ? "unset" : 'space-between', marginTop: "3%" }}>

                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            backgroundColor: "#F1F9FF",
                            justifyContent: 'space-between',
                            width: width < 960 ? "100%" : '38%',
                            height: 620,
                            paddingBottom: 24

                        }}>

                            <Typography style={{ fontSize: 32, fontFamily: 'Syncopate', color: '#251CFF', marginLeft: 24, marginTop: 24 }}>{t('transfer')}</Typography>


                            <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#8FB9E1', textAlign: 'left', marginLeft: 24, marginRight: 24 }}>
                                {moneyOut ? t('transferContent') : t('moneyOutClosed')}
                            </Typography>

                            {
                                width < 960 ?
                                    null
                                    :
                                    <img alt='' src={transferIcon} style={{ width: '70%' }}>
                                    </img>
                            }

                        </div>


                        <div style={{ backgroundColor: '#F5F5F5', width: '60%', height: 840 }}>

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

                                <Button style={{ backgroundColor: 'white', marginLeft: 24, marginTop: 24 }} onClick={() => {
                                    history.back()
                                }} >
                                    <img alt='' style={{ height: 24 }} src={backIcon} />
                                </Button>

                                {
                                    moneyOut ? <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        {/* <div style={{ alignItems: 'flex-start', }}>
                                        </div> */}
                                        <div style={{ width: '100%', marginTop: 24, marginBottom: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                            <div style={{ width: '91%', alignItems: 'flex-start', marginBottom: 8 }}>
                                                <Typography style={{ textAlign: 'left', color: '#AFBDD0', fontFamily: 'Avenir', fontWeight: '600', fontSize: 12 }}>{t('selectReceiverType')}</Typography>
                                            </div>
                                            <div style={{ display: 'flex', width: '91%', backgroundColor: 'white', marginTop: 8, borderRadius: 16, border: '1px solid #DCDCDC', height: 64, alignItems: 'center', justifyContent: 'center', }}>

                                                <CustomDropBox
                                                    onChange={handleReciverTypeChange}
                                                    value={reciverType}
                                                    disabled={!loggedIn}
                                                >
                                                    <MenuItem key={"L2Wallet"} value={"L2"}>
                                                        <Grid container spacing={1}>
                                                            <>

                                                                <Grid item xs={width <= 420 ? 1 : 2} >
                                                                    <Typography style={{ fontSize: isMobile ? 14 : 20, fontWeight: 'bold', color: '#363636', fontFamily: 'Avenir', }}>
                                                                        {t('l2Receiver')}
                                                                    </Typography>
                                                                </Grid>
                                                            </>

                                                        </Grid>
                                                    </MenuItem>
                                                    <MenuItem key={"swapAllApp"} value={"App"}>
                                                        <Grid container spacing={1}>
                                                            <>

                                                                <Grid item xs={width <= 420 ? 1 : 2} >
                                                                    <Typography style={{ fontSize: isMobile ? 14 : 20, fontWeight: 'bold', color: '#363636', fontFamily: 'Avenir', }}>
                                                                        {t('AppReceiver')}
                                                                    </Typography>
                                                                </Grid>
                                                            </>

                                                        </Grid>
                                                    </MenuItem>
                                                </CustomDropBox>
                                            </div>
                                        </div>


                                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                            <div style={{ width: '91%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Typography style={{ textAlign: 'left', color: '#AFBDD0', fontFamily: 'Avenir', fontWeight: '600', fontSize: isMobile ? 12 : 14 }}>{reciverType === "App" ? t('appUID') : t('l2Address')}</Typography>
                                                {

                                                    loggedIn ?
                                                        null
                                                        :
                                                        <Button
                                                            style={{ textTransform: 'none', minHeight: 0, minWidth: 0 }}
                                                            onClick={(!registered || !loggedIn) && !loading ? () => unlock(address, chainId, network, '', Web3, registered, dispatch, setOpenSliderModal, setSignUpPayload) : null} disabled={button2Disabled}

                                                        >

                                                            <Typography style={{ textAlign: 'left', color: '#251CFF', fontFamily: 'Avenir', fontWeight: '600', fontSize: isMobile ? 12 : 14 }}>Unlock Wallet</Typography>
                                                        </Button>

                                                }


                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '91%', backgroundColor: 'white', marginTop: 8, borderRadius: 16, border: '1px solid #DCDCDC' }}>
                                                <div style={{ marginLeft: isMobile ? 12 : 24, marginRight: isMobile ? 12 : 24, marginTop: 12, marginBottom: 12, width: '100%' }}>
                                                    <NewCustomTextField
                                                        inputRef={inputRef}
                                                        type="text"
                                                        style={{ width: '100%', textTransform: 'none' }}
                                                        rightbuttonlabel={''}
                                                        error={warning !== ''}
                                                        showcancellbutton={true}
                                                        clear={clear}
                                                        onChange={(e) => handleAddressChange(e.target.value)}
                                                        onBlur={() => handleFinish(false)}
                                                        value={transferTo}
                                                        disabled={!loggedIn}
                                                        placeholder={t('fullAddress')}
                                                    >

                                                    </NewCustomTextField>
                                                    <Typography style={{ marginLeft: 0, fontFamily: 'Avenir', fontWeight: '500', color: addrWarning === "" ? "#AFBDD0" : '#FF4646', fontSize: isMobile ? 10 : 16 }}> {addrWarning !== '' ? addrWarning : t('pleaseEnterReceiver')}</Typography>

                                                </div>

                                            </div>
                                        </div>





                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 12, width: '100%' }}>
                                            <div style={{ width: '100%' }}>

                                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 24 }}>
                                                    <div style={{ width: '91%', alignItems: 'flex-start', }}>
                                                        <Typography style={{ textAlign: 'left', color: '#AFBDD0', fontFamily: 'Avenir', fontWeight: '600', fontSize: isMobile ? 12 : 14 }}>{t('transferAmount')}</Typography>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '91%', backgroundColor: 'white', marginTop: 8, borderRadius: 16, border: '1px solid #DCDCDC' }}>
                                                        <div style={{ marginLeft: isMobile ? 12 : 24, marginTop: isMobile ? 10 : 16, marginBottom: 12 }}>
                                                            <NewCustomTextField
                                                                disabled={!loggedIn}
                                                                inputRef={inputRef}
                                                                onChange={(e) => handleAmountChange(e.target.value)}
                                                                value={transferAmount}
                                                                onBlur={() => handleFinish(true)}
                                                                placeholder={"0.00"}
                                                                rightbuttonlabel={t(capital.free > 0.0001 ? 'all' : null)}
                                                                onrightbuttonclick={allIn}
                                                                customtype="number"
                                                                coin={coin}
                                                                tokenIcons={tokenIcons}
                                                                coins={coins}
                                                                plachHolder={t("selectCoin")}
                                                                inputTitle={t("searchCoin")}
                                                                handleChange={handleCoinChange}

                                                            >
                                                            </NewCustomTextField>
                                                            <Typography style={{ marginLeft: 0, fontFamily: 'Avenir', fontWeight: '500', color: warning === "" ? "#AFBDD0" : '#FF4646', fontSize: isMobile ? 10 : 16 }}> {warning === "" ? t("enterAmount") : warning}</Typography>
                                                        </div>



                                                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: isMobile ? 12 : 24, alignItems: 'flex-end' }}>
                                                            <NewCustomCoinSelector
                                                                // style={{ width: width < 960 ? "unset" : '42%' }}
                                                                coin={coin}
                                                                isloggedIn={loggedIn}

                                                                tokenIcons={tokenIcons}
                                                                coins={coins}
                                                                plachHolder={t("selectCoin")}
                                                                inputTitle={t("searchCoin")}
                                                                handleChange={handleCoinChange}
                                                            />
                                                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8, alignItems: 'center', justifyContent: 'center', marginRight: 8 }}>
                                                                <Typography style={{ textAlign: 'left', color: '#AFBDD0', fontFamily: 'Avenir', fontWeight: '500', fontSize: isMobile ? 10 : 14 }}>{t("availableCapital")} </Typography>
                                                                <Typography style={{ textAlign: 'left', marginLeft: 4, color: '#271CFF', fontFamily: 'Avenir', fontWeight: '500', fontSize: isMobile ? 10 : 14 }}>{loggedIn ? roundingDown(capital.free, 4) : '--'}</Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: 32, justifyContent: 'center' }}>
                                            <div style={{ width: '91%', display: 'flex', flexDirection: 'column', borderRadius: 16, border: '1px solid #DCDCDC', alignItems: 'center', justifyContent: 'center' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '91%', alignItems: 'center', marginTop: 24, marginBottom: 24 }}>
                                                    <Typography className={classes.defaultFont} style={{ color: '#8B96A7', fontSize: 16 }}>{t('transferFee')}</Typography>
                                                    <Typography className={classes.defaultFont} style={{ fontSize: 16, color: '#8B96A7' }}> {transferFee.base === "" ? "--" : transferFee.amount + " " + transferFee.base}</Typography>
                                                </div>

                                            </div>

                                        </div>



                                        <div style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "8%" }}>
                                            {
                                                address.length < 42 || !isValidAddress(address) ?
                                                    <CustomButton buttonstyle="connectStyle" style={{ width: '91%' }} onClick={!window.ethereum ? () => onClickInstall(sendBackButton1, sendBackButton1Disabled) : () => onClickConnect(network, chainId, address, dispatch)}>
                                                        {button1}
                                                    </CustomButton> : null
                                            }
                                            {
                                                address.length === 42 && isValidAddress(address) ?
                                                    loggedIn ?
                                                        <CustomButton style={{ opacity: !validAmount || !validAddress || !isTransferOpen ? 0.2 : 1, width: '91%' }} onClick={confirmTransfer} disabled={!validAmount || !validAddress || (!isTransferOpen && reciverType === 'L2') || (!isAppTransferOpen && reciverType === 'App')}>
                                                            {t('confirm')}
                                                        </CustomButton> :
                                                        <CustomButton buttonstyle="unlockStyle" style={{ width: '91%' }} onClick={(!registered || !loggedIn) && !loading ? () => unlock(address, chainId, network, code, Web3, registered, dispatch, setOpenSliderModal, setSignUpPayload) : null} disabled={button2Disabled}>
                                                            {t('unlock')}
                                                        </CustomButton> : null
                                            }
                                        </div>
                                    </Grid> : null
                                }
                            </div>
                        </div>
                    </div>
            }

            <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                aria-labelledby="server-modal-title"
                aria-describedby="server-modal-description"
                className={classes.modal}
                open={openNote}
                // open={true}
                onClose={handleCloseNote}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                disableEscapeKeyDown={!transferFinished}
                disableBackdropClick={!transferFinished}
            >
                <Fade
                    in={openNote}
                // in={true}
                >

                    {confirmViewBody}
                </Fade>
            </Modal>
        </div>
    );
}

export default withTranslation()(Transfer);
