import {
    GET_USER_CAPITAL,
    GET_USER_CAPITAL_FAILED,
    GET_USER_CAPITAL_SUCCEED,
    WITHDRAW,
    WITHDRAW_FAILED,
    WITHDRAW_SUCCEED,
    TRANSFER,
    TRANSFER_FAILED,
    TRANSFER_SUCCEED,
    DEPOSIT,
    DEPOSIT_FAILED,
    DEPOSIT_SUCCEED,
    DEPOSIT_HASH,
    DEPOSIT_RECEIPT,
    GET_ALL_TOKEN_STATUS,
    GET_ALL_TOKEN_STATUS_SUCCEED,
    GET_ALL_TOKEN_STATUS_FAILED,
    GET_ALL_TOKEN_ICONS_SUCCEED,
    GET_L1_CAPITAL_SUCCEED,
    GET_L1_CAPITAL_FAILED,
    GET_TRANSACTION_RECORDS_FAILED,
    GET_TRANSACTION_RECORDS_SUCCEED,
    GET_AMPL_REWARDS_SUCCEED,
    GET_AMPL_REWARDS_FAILED,
    REGISTER_AMPL_REWARDS,
    REGISTER_AMPL_REWARDS_SUCCEED,
    NOT_QUALIFIED,
    ALREADY_REGISTERED,
    REGISTER_AMPL_REWARDS_FAILED,
    GET_WITHDRAW_FEE_SUCCESS,
    GET_WITHDRAW_FEE_FAILED,
    WALLET_SIGNING,
    WALLET_SIGNING_CANCELLED,
    CLEAR_INFO,
    CLEAR_FEE,
    GET_TRANSFER_FEE_SUCCESS,
    GET_TRANSFER_FEE_FAILED,
    BUY_IDO,
    BUY_IDO_FAILED,
    BUY_IDO_SUCCEED,
    GET_IDO_INFO,
    GET_IDO_INFO_FAILED,
    GET_IDO_INFO_SUCCEED,
    CLEAR_IDO_INFO,
    GET_IDO_INVESTMENT_INFO,
    GET_IDO_INVESTMENT_INFO_SUCCEED,
    GET_IDO_INVESTMENT_INFO_FAILED,
    GET_IDO_MIGRATION_INFO,
    GET_IDO_MIGRATION_INFO_SUCCEED,
    GET_IDO_MIGRATION_INFO_FAILED,
    MIGRATE_SIGNING_CANCELLED,
    MIGRATE_IDO,
    MIGRATE_IDO_SUCCEED,
    MIGRATE_IDO_FAILED
} from '../constants';

export function wallet(state = {
    userCapitals: [],
    tokenList: [],
    tokenIcons: {},
    l1Capital: [],
    depositFinished: true,
    depositSucceed: false,
    depositHash: '',
    depositReceipt: {},
    depositConfirmationNumber: 0,
    amplRewardsInfo: {
        totalRewards: 0,
        registeredUsers: 0,
        rewardsPerUser: 0,
        hasClaimed: null
    },
    message: '',
    rewardMessage: '',
    withdrawFinished: true,
    withdrawSucceed: false,
    withdrawMsg: '',
    withdrawFeeObj: {
        amount: 0,
        base: ''
    },
    transferFeeObj: {},
    walletSigning: false,
    walletLoading: false,
    walletMsg: '',
    depositMsg: '',
    transferFinished:true,
    transferSucceed:false,
    transferMsg:'',
    buyIDOFinished: true,
    buyIDOSucceed: false,
    buyIDOMsg: '',
    idoInfo: {
        startAt: "",
        endAt: "",
    },
    idoInfoMsg: '',
    idoInvestmentInfo:{},
    idoMigrationInfo:{}
}, action) {
    switch (action.type) {
        case CLEAR_INFO:
            return {
                ...state,
                depositHash: '',
                depositReceipt: {},
                depositConfirmationNumber: 0,
                depositFinished: true,
                depositSucceed: false,
                buyIDOFinished: true,
                buyIDOSucceed: false,
                buyIDOMsg: '',
                migrateIDOFinished: true,
                migrateIDOSucceed: false,
                migrateIDOMsg: ''
            }
        case WALLET_SIGNING:
            return {
                ...state,
                walletSigning: action.loading,
                withdrawSucceed: false,
                withdrawFinished: false,
                walletMsg: '',
                withdrawMsg: ''
            }
        case WALLET_SIGNING_CANCELLED:
            return {
                ...state,
                walletSigning: action.loading,
                walletMsg: action.message,
                withdrawFinished: true,
                withdrawMsg: action.message,
                depositFinished: true,
                buyIDOFinished: true,
                buyIDOMsg: action.message
            }
        case MIGRATE_SIGNING_CANCELLED:
            return {
                ...state,
                walletSigning: action.loading,
                walletMsg: action.message,
                migrateIDOFinished: true,
                migrateIDOMsg: action.message
            }
        case GET_USER_CAPITAL:
            return {
                ...state,
                walletLoading: true
            }
        case GET_USER_CAPITAL_SUCCEED:
            return {
                ...state,
                userCapitals: action.data,
                walletLoading: false
            }
        case GET_USER_CAPITAL_FAILED:
            return {
                ...state,
                message: action.message,
                walletLoading: false
            }
        case GET_ALL_TOKEN_STATUS:
            return {
                ...state,
                walletLoading: true
            }
        case GET_ALL_TOKEN_STATUS_SUCCEED:
            return {
                ...state,
                tokenList: action.data,
                walletLoading: false
            }
        case GET_ALL_TOKEN_STATUS_FAILED:
            return {
                ...state,
                message: action.message,
                walletLoading: false
            }
        case GET_ALL_TOKEN_ICONS_SUCCEED:
            return {
                ...state,
                tokenIcons: action.iconMaps
            }
        case GET_L1_CAPITAL_SUCCEED:
            return {
                ...state,
                l1Capital: action.data,
                depositMsg: ''
            }
        case GET_ALL_TOKEN_STATUS_FAILED:
            return {
                ...state,
                message: action.message,
            }
        case WITHDRAW:
            return {
                ...state,
                withdrawFinished: false
            }
        case WITHDRAW_SUCCEED:
            return {
                ...state,
                withdrawFinished: true,
                withdrawSucceed: true,
                withdrawMsg: action.message,
                walletSigning: false
            }
        case WITHDRAW_FAILED:
            return {
                ...state,
                withdrawFinished: true,
                withdrawSucceed: false,
                withdrawMsg: action.error,
                walletSigning: false
            }
        case TRANSFER:
            return {
                ...state,
                transferFinished: false
            }
        case TRANSFER_SUCCEED:
            return {
                ...state,
                transferFinished: true,
                transferSucceed: true,
                transferMsg: action.message,
                walletSigning: false
            }
        case TRANSFER_FAILED:
            return {
                ...state,
                transferFinished: true,
                transferSucceed: false,
                transferMsg: action.error,
                walletSigning: false
            }
        case DEPOSIT:
            return {
                ...state,
                depositFinished: false,
                walletSigning: true,
                depositMsg: ''
            }
        case DEPOSIT_HASH:
            return {
                ...state,
                depositHash: action.hash,
                depositMsg: '',
                walletSigning: false,
            }
        case DEPOSIT_RECEIPT:
            return {
                ...state,
                depositReceipt: action.receipt,
                depositMsg: ''
            }
        case DEPOSIT_SUCCEED:
            return {
                ...state,
                depositFinished: true,
                depositSucceed: true,
                depositConfirmationNumber: action.confirmationNumber,
                depositHash: action.receipt.transactionHash,
                depositReceipt: action.receipt,
                depositMsg: '',
            }
        case DEPOSIT_FAILED:
            return {
                ...state,
                depositFinished: true,
                depositSucceed: false,
                depositMsg: action.error,
                walletSigning: false
            }
        case GET_TRANSACTION_RECORDS_SUCCEED:
            return {
                ...state,
                transactionRecords: action.data.length > 0 ? action.data.reverse() : action.data
            }
        case GET_TRANSACTION_RECORDS_FAILED:
            return {
                ...state,
                message: action.error
            }

        case GET_AMPL_REWARDS_SUCCEED:
            return {
                ...state,
                rewardMessage: '',
                amplRewardsInfo: action.data
            }
        case GET_AMPL_REWARDS_FAILED:
            return {
                ...state,
                rewardMessage: action.data
            }
        case REGISTER_AMPL_REWARDS:
            return {
                ...state,
                rewardMessage: '',
                walletLoading: true
            }
        case REGISTER_AMPL_REWARDS_SUCCEED:
            return {
                ...state,
                walletLoading: false,
                rewardMessage: action.message
            }
        case NOT_QUALIFIED:
            return {
                ...state,
                rewardMessage: action.message
            }
        case ALREADY_REGISTERED:
            return {
                ...state,
                rewardMessage: action.message
            }
        case REGISTER_AMPL_REWARDS_FAILED:
            return {
                ...state,
                rewardMessage: action.message
            }
        case GET_WITHDRAW_FEE_SUCCESS:
            return {
                ...state,
                withdrawFeeObj: action.data
            }
        case GET_WITHDRAW_FEE_FAILED:
            return {
                ...state,
                message: action.message
            }
        case GET_TRANSFER_FEE_SUCCESS:
            return {
                ...state,
                transferFeeObj: action.data
            }
        case GET_TRANSFER_FEE_FAILED:
            return {
                ...state,
                message: action.message
            }
        case CLEAR_FEE:
            return {
                ...state,
                withdrawFeeObj: {}
            }
        case BUY_IDO:
            return {
                ...state,
                buyIDOFinished: false,
            }
        case BUY_IDO_SUCCEED:
            return {
                ...state,
                buyIDOFinished: true,
                buyIDOSucceed: true,
                buyIDOMsg: action.message,
                walletSigning: false
            }
        case BUY_IDO_FAILED:
            return {
                ...state,
                buyIDOFinished: true,
                buyIDOSucceed: false,
                buyIDOMsg: action.error,
                walletSigning: false
            }
        case GET_IDO_INFO:
            return {
                ...state,
                idoInfoMsg: ''
            }
        case GET_IDO_INFO_SUCCEED:
            return {
                ...state,
                idoInfo: {...action.data.idoStat, ...action.data},
                idoInfoMsg: 'succeed'
            }
        case GET_IDO_INFO_FAILED:
            return {
                ...state,
                idoInfoMsg: action.error
            }
        case CLEAR_IDO_INFO:
            return {
                ...state,
                idoInfo: {
                    startAt: "",
                    endAt: "",
                },
            }
        case GET_IDO_INVESTMENT_INFO:
            return {
                ...state,
                idoInvestmentInfoMsg: ''
            }
        case GET_IDO_INVESTMENT_INFO_SUCCEED:
            return {
                ...state,
                idoInvestmentInfo: action.data,
                idoInvestmentInfoMsg: 'succeed'
            }
        case GET_IDO_INVESTMENT_INFO_FAILED:
            return {
                ...state,
                idoInvestmentInfoMsg: action.error
            }

        case GET_IDO_MIGRATION_INFO:
            return {
                ...state,
                idoMigrationInfoMsg: ''
            }
        case GET_IDO_MIGRATION_INFO_SUCCEED:
            return {
                ...state,
                idoMigrationInfo: action.data,
                idoMigrationInfoMsg: 'succeed'
            }
        case GET_IDO_MIGRATION_INFO_FAILED:
            return {
                ...state,
                idoMigrationInfoMsg: action.error
            }

        case MIGRATE_IDO:
            return {
                ...state,
                migrateIDOFinished: false,
            }
        case MIGRATE_IDO_SUCCEED:
            return {
                ...state,
                migrateIDOFinished: true,
                migrateIDOSucceed: true,
                migrateIDOMsg: action.message,
                walletSigning: false
            }
        case MIGRATE_IDO_FAILED:
            return {
                ...state,
                migrateIDOFinished: true,
                migrateIDOSucceed: false,
                migrateIDOMsg: action.error,
                walletSigning: false
            }
        default:
            return state;
    }

    return state;
};
