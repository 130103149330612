import {
    GET_INVITE_INFO,
    GET_INVITE_INFO_SUCCEED,
    GET_INVITE_INFO_FAILED,
    GET_INVITE_CODE,
    GET_INVITE_CODE_SUCCEED,
    GET_INVITE_CODE_FAILED,
    REGISTER_INVITER,
    REGISTER_INVITER_SUCCEED,
    REGISTER_INVITER_FAILED,
    GET_INVITEE,
    GET_INVITEE_SUCCEED,
    GET_INVITEE_FAILED,
    CLEAR_INVITE_INFO,
    CLEAR_MODAL_INFO,
    GET_RANK_DETAIL,
    GET_RANK_DETAIL_SUCCEED,
    GET_RANK_DETAIL_FAILED,
    GET_RANK_BONUS_TRANSACTIONS,
    GET_RANK_BONUS_TRANSACTIONS_SUCCEED,
    GET_RANK_BONUS_TRANSACTIONS_FAILED,
    GET_RANK_SWITCH,
    GET_RANK_SWITCH_SUCCEED,
    GET_RANK_SWITCH_FAILED,
} from "../constants";

export function invite (state = {
    inviteInfo:{
        uid: -1,
        level: 0,
        totalInvited: 0,
        l1Invited: 0,
        l2Invited: 0,
        l3Invited: 0,
        totalBonus: 0,
        vestedBonus: 0,
        remainingBonus: 0
    },
    inviteInfoMsg: '',
    inviteCode: '',
    inviteCodeMsg: '',
    registerInviterMsg: '',
    invitees: [{username: '', createdAt: '2022-11-07T19:46:48.000+00:00'}],
    inviteeMsg: '',
    loading: false,
    registerInviterSucceed: false,
    rankBonusTransactions: [
        {
            "id": 733,
            "uid": 38887,
            "orderId": "167052472111438887",
            "type": "RankingBonus",
            "distributionType": "Immediate",
            "deliverCurrency": "DBO-USDT",
            "deliverAmount": 400.0000000000000000,
            "receiveCurrency": "DBO",
            "receiveAmount": 3000.0000000000000000,
            "createdAt": "2022-12-08T18:38:43.000+00:00",
            "updatedAt": "2022-12-08T18:38:43.000+00:00"
        },
        {
            "id": 753,
            "uid": 38887,
            "orderId": "167060448580638887",
            "type": "RankingBonus",
            "distributionType": "Immediate",
            "deliverCurrency": "DBO-USDT",
            "deliverAmount": 400.0000000000000000,
            "receiveCurrency": "DBO",
            "receiveAmount": 2000.0000000000000000,
            "createdAt": "2022-12-09T16:48:08.000+00:00",
            "updatedAt": "2022-12-09T16:48:08.000+00:00"
        }
    ],
    rankDetails: {
        id: -1,
        rank1Username: null,
        rank1PurchaseLp: 0,
        rank1LastPurchaseTime: null,
        rank2Username: null,
        rank2PurchaseLp: 0,
        rank2LastPurchaseTime: null,
        rank3Username: null,
        rank3PurchaseLp: 0,
        rank3LastPurchaseTime: null,
        createdAt: "2022-12-07T16:00:03.000+00:00",
        updatedAt: "2022-12-07T16:00:03.000+00:00"
    },
    rankSwitch: false
}, action){
    switch (action.type) {
        case CLEAR_MODAL_INFO:
            return {
                ...state,
                inviteInfoMsg: '',
                inviteCodeMsg: '',
                registerInviterMsg: '',
                inviteeMsg: ''
            }
        case CLEAR_INVITE_INFO:
            return {
                ...state,
                inviteInfo:{
                    uid: -1,
                    level: 0,
                    totalInvited: 0,
                    l1Invited: 0,
                    l2Invited: 0,
                    l3Invited: 0,
                    totalBonus: 0,
                    vestedBonus: 0,
                    remainingBonus: 0
                },
                inviteInfoMsg: '',
                inviteCode: '',
                inviteCodeMsg: '',
                registerInviterMsg: '',
                invitees: [{username: '', createdAt: '2022-11-07T19:46:48.000+00:00'}],
                inviteeMsg: '',
                loading: false,
                registerInviterSucceed: false,
            }
        case GET_INVITE_INFO:
            return{
                ...state,
                getInviteInfoLoading: true
            }
        case GET_INVITE_INFO_SUCCEED:
            return{
                ...state,
                inviteInfo: action.res.data,
                getInviteInfoLoading: false
            }
        case GET_INVITE_INFO_FAILED:
            return{
                ...state,
                inviteInfoMsg: action.error,
                getInviteInfoLoading: false
            }
        case GET_INVITE_CODE:
            return{
                ...state,
                loading: true
            }
        case GET_INVITE_CODE_SUCCEED:
            return{
                ...state,
                inviteCode: action.res.data === null ? null : action.res.data.code,
                loading: false
            }
        case GET_INVITE_CODE_FAILED:
            return{
                ...state,
                inviteCodeMsg: action.error,
                loading: false
            }
        case REGISTER_INVITER:
            return{
                ...state,
                loading: true
            }
        case REGISTER_INVITER_SUCCEED:
            return{
                ...state,
                registerInviterMsg: action.res.data,
                registerInviterSucceed: true,
                loading: false
            }
        case REGISTER_INVITER_FAILED:
            return{
                ...state,
                registerInviterMsg: action.error,
                registerInviterSucceed: false,
                loading: false
            }
        case GET_INVITEE:
            return{
                ...state,
                loading: true
            }
        case GET_INVITEE_SUCCEED:
            return{
                ...state,
                invitees: action.res.data,
                loading: false
            }
        case GET_INVITEE_FAILED:
            return{
                ...state,
                inviteeMsg: action.error,
                loading: false
            }
        case GET_RANK_DETAIL:
            return{
                ...state,
                loading: true
            }
        case GET_RANK_DETAIL_SUCCEED:
            return{
                ...state,
                rankDetails: action.res.data,
                loading: false
            }
        case GET_RANK_DETAIL_FAILED:
            return{
                ...state,
                loading: false
            }
        case GET_RANK_BONUS_TRANSACTIONS:
            return{
                ...state,
                loading: true
            }
        case GET_RANK_BONUS_TRANSACTIONS_SUCCEED:
            return{
                ...state,
                rankBonusTransactions: action.res.data,
                loading: false
            }
        case GET_RANK_BONUS_TRANSACTIONS_FAILED:
            return{
                ...state,
                loading: false
            }
        case GET_RANK_SWITCH:
            return{
                ...state,
                loading: true
            }
        case GET_RANK_SWITCH_SUCCEED:
            return{
                ...state,
                rankSwitch: action.res.data,
                loading: false
            }
        case GET_RANK_SWITCH_FAILED:
            return{
                ...state,
                loading: false
            }
        default:
            return state;
    }
    return state;
}
