import React, { useEffect, useState } from 'react';
import { Avatar, Backdrop, Button, Fade, Grid, Modal, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import useWindowDimensions from '../../utils/WindowDimensions'
import NewCustomTextField from '../CommonElements/NewCustomTextField'
import NewCustomCoinSelector from '../CommonElements/NewCustomCoinSelector'

import { walletActions } from '../../redux/actions/walletActions';
import { authActions } from '../../redux/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import { roundingDown } from '../../utils/RoundingDown'
import { countDecimals, getChain } from "../../utils/Common";
import { history } from '../../utils/History';
import { onClickConnect, onClickInstall, unlock, withdraw } from '../../utils/Sign'
import { isValidAddress } from "ethereumjs-util";
import CustomButton from '../CommonElements/CustomButton';
import i18n from "../../i18n";
import withdrawIcon from "../../images/wallet/withdrawIcon.png";
import backIcon from "../../images/backIcon.png";
import metamaskIcon from "../../images/metamaskIcon.png";
import Lottie from 'lottie-react-web'
import animation from "../Animations/127882-gamification-onboarding-animation.json";
import animationSuccess from "../Animations/lf30_editor_g02qsqwx.json";
import animationCancle from "../Animations/98457-transaction-canceled.json";


import { isMobile } from 'react-device-detect';
import { TrendingUpRounded } from '@material-ui/icons';

// const confirmIcon = 'https://cdn.swapallapp.com/l2/images/confirmIcon.png'

const Web3 = require("web3");


function Withdraw({ t, address, setOpenSliderModal, handleCloseSliderModal, setSignUpPayload, sliderInfo, chainId, network,
    sendBackButton1, sendBackButton1Disabled, button1, button2Disabled
}) {
    const { width } = useWindowDimensions();
    const useStyles = makeStyles((theme) => ({
        root: {
            ...theme.typography.button,
            backgroundColor: '#010846',
            padding: theme.spacing(1),
            flexGrow: 1,
            textAlign: 'center'
        },
        depositBox: {
            width: 600,
            borderRadius: 24,
            backgroundColor: '#101B66',
            justifyContent: 'center',
            display: 'inline-flex',
            marginTop: 100,
            paddingTop: 30,
            paddingBottom: 30
        },
        depositContent: {
            width: 500,
            alignItems: 'flex-start',
        },
        wrapper: {
            color: 'white',
            alignItems: 'flex-start',
            display: 'flex',
            fontSize: 25
        },
        fieldWrapper: {
            justifyContent: 'baseline',
            width: '100%',
            margin: 0
        },
        result: {
            fontSize: 10,
            color: '#000000',
            width: 200
        },
        btn: {
            backgroundColor: '#83ACF4',
            color: '#010846',
            borderColor: 'transparent',
            borderRadius: 25,
            height: 45,
            fontWeight: 600,
            minWidth: 100
        },
        btn_disabled: {
            backgroundColor: '#83ACF4',
            color: '#010846',
            borderColor: 'transparent',
            borderRadius: 25,
            height: 45,
            fontWeight: 600,
            opacity: 0.2
        },
        capitalList: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: 'transparent',
        },
        backBtn: {
            float: 'left'
        },
        backArrow: {
            width: 20,
            height: 20
        },
        contentWrapper: {
            fontSize: 15,
            color: 'white'
        },
        inputField: {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            width: '100%',
            color: 'red'
        },
        fillAddress: {
            color: 'white',
            fontSize: 15,
            backgroundColor: '#101B66'
        },
        helperText: {
            color: '#ff3434'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // width: width * 0.5,
            // height: height * 0.5
        },
        paper: {
            backgroundColor: 'white',
            border: 'transparent',
            // boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            borderRadius: 20,
            textAlign: 'center'
        },
        feeContentLeft: {
            color: 'white',
            fontSize: 15,
            textAlign: 'left'
        },
        feeContentRight: {
            color: 'white',
            fontSize: 15,
            textAlign: 'right'
        },
        defaultFont: {
            fontWeight: '500', color: '#363636', fontFamily: 'Avenir', textTransform: 'none'
        },
    }));
    const classes = useStyles();

    const [withdrawTo, setWithdrawTo] = useState('')
    const [withdrawAmount, setWithdrawAmount] = useState('')
    const [addrWarning, setAddrWarning] = React.useState('')
    const [coin, setCoin] = React.useState('');
    const [capital, setCapital] = React.useState({ free: 0 })
    const [warning, setWarning] = React.useState('')
    const [validAddress, setValidAddress] = React.useState(false)
    const [validAmount, setValidAmount] = React.useState(false)
    const [coins, setCoins] = React.useState([])
    const [coinInfo, setCoinInfo] = React.useState({})

    const { token, loggedIn, registered, loading, userInfo } = useSelector(state => state.auth)
    const { tokenList, tokenIcons, userCapitals, withdrawSucceed, withdrawFinished, withdrawMsg, withdrawFeeObj, walletSigning, walletMsg } = useSelector(state => state.wallet)
    const dispatch = useDispatch();
    const inputRef = React.useRef();

    const [openNote, setOpenNote] = useState(false)
    const [time, setTime] = useState('')
    const [receivingAmount, setReceivingAmount] = useState('--')
    const [receivingBase, setReceivingBase] = useState('')
    const [chain, setChain] = useState('')

    const [minWithdrawAmount, setMinWithdrawAmount] = useState(0)
    const [dailyWithdrawQuota, setDailyWithdrawQuota] = useState(0)

    const [moneyOut, setMoneyOut] = useState(true)

    const allIn = () => {
        handleAmountChange(roundingDown(capital.free, 4))
    }

    const fillAddress = () => {
        handleAddressChange(address)
    }

    const handleOpenNote = () => {
        setOpenNote(true);
    };

    const handleCloseNote = () => {
        setOpenNote(false);
        setWithdrawAmount("")
    };

    const handleAddressChange = (addr) => {
        setWithdrawTo(addr);
        if (addr.length === 42 && isValidAddress(addr)) {
            setAddrWarning('')
            setValidAddress(true)
        } else {
            if (addr === '') {
                setAddrWarning('')
            } else {
                setAddrWarning(t('invalidAddress'))
            }
            setValidAddress(false)
        }
    };

    const handleAmountChange = (amount) => {
        if (parseFloat(amount) >= 0) {
            if (countDecimals(parseFloat(amount)) <= 8) {
                setWithdrawAmount(amount)
            } else {
                setWithdrawAmount(roundingDown(parseFloat(amount), 8).toString())
            }
        } else {
            setWithdrawAmount('')
        }

        if (withdrawFeeObj !== null && Object.keys(withdrawFeeObj).length > 0 && withdrawFeeObj.base !== '') {
            if (withdrawFeeObj.base === coin) {
                setReceivingAmount(roundingDown(parseFloat(amount) - withdrawFeeObj.amount, 4))
            } else {
                setReceivingAmount(roundingDown(parseFloat(amount), 4))
            }
            setReceivingBase(coin)
            let feeCapital = userCapitals.find(item => item.token === withdrawFeeObj.base)
            if (feeCapital === undefined) {
                feeCapital = {
                    base: '',
                    amount: 0
                }
            }

            if (amount === "") {
                setValidAmount(false)
                setWarning('')
                setReceivingAmount('--')
            }
            else if (!parseFloat(amount)) {
                setValidAmount(false)
                setWarning(t('invalidInput'))
                setReceivingAmount('--')
            }
            else if (parseFloat(amount) > dailyWithdrawQuota) {
                setValidAmount(false)
                setWarning(t('higherThanDailyWithdrawLimit'))
            }
            else if (parseFloat(amount) <= capital.free && parseFloat(amount) > 0) {
                if (withdrawFeeObj.base === coin) {
                    if (parseFloat(amount) > withdrawFeeObj.amount) {
                        if ((parseFloat(amount) < minWithdrawAmount + withdrawFeeObj.amount && minWithdrawAmount !== 0) || (parseFloat(amount) <= minWithdrawAmount + withdrawFeeObj.amount && minWithdrawAmount === 0)) {
                            setValidAmount(false)
                            setWarning(t('lowerThanMinWithdrawLimit'))
                        } else {
                            setValidAmount(true)
                            setWarning('')
                        }
                    } else {
                        setValidAmount(false)
                        setWarning(t('enterAmountHigherThanFee'))
                        setReceivingAmount('--')
                    }
                } else {
                    if (feeCapital.free > withdrawFeeObj.amount) {
                        setValidAmount(true)
                        setWarning('')
                    } else {
                        setValidAmount(false)
                        setWarning(t('insufficient_fee') + ' ' + withdrawFeeObj.base + ' ' + t('insufficient_fund'))
                        setReceivingAmount('--')
                    }
                }
            } else if (amount > capital.free) {
                setValidAmount(false)
                setWarning(t('insufficient_fund'))
                setReceivingAmount('--')
            } else {
                setValidAmount(false)
                setWarning(t('insufficient_fee') + ' ' + withdrawFeeObj.base + ' ' + t('insufficient_fund'))
                setReceivingAmount('--')
            }
        }
    };

    const handleCoinChange = (option) => {
        if (option.value !== t('noCapital')) {
            setCoin(option.value);
            let coinInfo = coins.find(item => item.label === option.value)
            setCoinInfo(coinInfo)
            setWarning('')
            setMinWithdrawAmount(coinInfo.minWithdrawAmount)
            setDailyWithdrawQuota(coinInfo.dailyWithdrawQuota)
            handleAmountChange('')
            console.log('contractWithdrawKey: ', coinInfo.contractWithdrawKey)
            dispatch(walletActions.getFee({ token, amount: '1', action: coinInfo.contractWithdrawKey }))


        } else {
            setCoinInfo({})
            setCoin('')
            setWarning('')
            setValidAmount(false)
            dispatch(walletActions.clearFee())
            setMinWithdrawAmount(0)
            setDailyWithdrawQuota(0)
        }


    };

    const confirmWithdraw = async () => {
        await withdraw(address, chainId, network, Web3,
            registered, dispatch, walletSigning, setTime, handleOpenNote,
            coinInfo.value, chain, withdrawAmount, withdrawTo, token)
    }

    const clear = () => {
        setWithdrawTo('')
        setAddrWarning('')
        setValidAddress(false)
    }

    const handleFinish = (changedAmount) => {
        let coinInfo = coins.find(item => item.label === coin)
        if (changedAmount) {
            if (validAmount) {
                dispatch(walletActions.getFee({ token, amount: withdrawAmount, action: coinInfo.contractWithdrawKey }))
            }
            if (validAddress) {
                setAddrWarning('')
            }
        }
    }

    useEffect(() => {
        if (loggedIn) {
            dispatch(walletActions.getUserCapital(token))
            dispatch(walletActions.getAllTokenStatus(token))
            dispatch(authActions.getUserInfo(token))
        }

        return () => {
        }
    }, [])

    useEffect(() => {
        if (loggedIn) {
            dispatch(walletActions.getUserCapital(token))
            dispatch(walletActions.getAllTokenStatus(token))
            dispatch(authActions.getUserInfo(token))
            setWithdrawTo(address)
            setValidAddress(true)
            handleAmountChange('')
            handleCoinChange({ value: t('noCapital') })
        }
        return () => {
        }
    }, [loggedIn, address])


    useEffect(() => {
        let _coins = []
        let _capital = userCapitals.find(item => item.token === coin)
        let chain = getChain(network, chainId)
        let _chain = ''
        if (chain[0] !== undefined) {
            _chain = chain[0].toUpperCase() + chain.substring(1).toLowerCase()
        }
        let token
        if (_capital === undefined) {
            _capital = {
                free: 0,
                token: coin
            }
        }
        setCapital(_capital)

        for (let i = 0; i < userCapitals.length; i++) {
            token = tokenList.find(item => item.token === userCapitals[i].token && chain === item.chain)
            let canAdd = true
            if (token === undefined) {
                token = {
                    token: userCapitals[i].token,
                    contractWithdrawIsOn: false,
                    contractWithdrawFeeKey: '',
                    contractBalanceIsSufficient: false
                }
            }
            if (token.contractWithdrawIsOn && token.contractBalanceIsSufficient) {
                let chain = getChain(Number(network), Web3.utils.toDecimal(chainId))
                if (canAdd) {

                    let price = token.price
                    if (price === undefined) {
                        price = 0
                    }
                    let dailyMoneyOutLimit = userInfo.dailyMoneyOutLimit
                    if (dailyMoneyOutLimit === undefined || dailyMoneyOutLimit === null) {
                        dailyMoneyOutLimit = 0
                    }
                    if (price !== 0) {
                        dailyMoneyOutLimit = dailyMoneyOutLimit / price
                    }

                    _coins.push({
                        label: userCapitals[i].token,
                        value: userCapitals[i].token,
                        contractWithdrawKey: token.contractWithdrawFeeKey,
                        minWithdrawAmount: token.minContractWithdrawAmount,
                        dailyWithdrawQuota: price !== 0 ? Math.min(userCapitals[i]['withdrawLimit' + _chain], dailyMoneyOutLimit) : userCapitals[i]['withdrawLimit' + _chain]
                    })
                }
                setChain(chain)
            }
        }
        if (_coins.length === 0) {
            _coins.push({
                label: t('noCapital'),
                value: ''
            })
        }
        setCoins(_coins)

        return () => {
        }

    }, [i18n.language, coin, address, userCapitals, tokenList, chainId, network, userInfo])

    useEffect(() => {
        if (validAmount) {
            setAddrWarning('')
        } else {
            setReceivingAmount('--')
            setReceivingBase('')
        }
        return () => {
        }
    }, [validAmount])

    /**
     * Update fee
     */
    useEffect(() => {
        if (withdrawFeeObj !== null && Object.keys(withdrawFeeObj).length > 0 && withdrawFeeObj.base !== '') {
            if (withdrawAmount === '') {
                setReceivingAmount('--')
            }
            setReceivingBase(coin)
        } else if (withdrawFeeObj === null) {
            setValidAmount(false)
            setWarning(`${coin} ${t('withdrawNotAvailable')}`)
        } else {
            setReceivingAmount('--')
            setReceivingBase('')
        }
    }, [withdrawFeeObj])

    useEffect(() => {
        if (userInfo.withdraw !== undefined) {
            setMoneyOut(!!userInfo.withdraw)
        }
    }, [userInfo])

    const lottieTest = (
        <div style={{ width: 100, height: 100, backgroundColor: 'pink' }}>

            <Lottie

                isPaused={!openNote}
                options={{

                    animationData: animation,
                    loop: true,

                }}
            />
        </div>
    )
    const confirmViewBody = (
        <div style={{
            backgroundColor: 'white', borderRadius: 24, width: width < 950 ? "91%" : 520, overflow: 'hidden', outline: 0, display: 'flex', alignItems: 'center', flexDirection: 'column'
        }}>
            {/* <div style={{marginTop: 12,width:'100%',display:'flex',justifyContent:'flex-end'}}>
                <div style={{ width: 40, height: 40,marginRight:12 }}>

                    <Lottie

                        isPaused={openNote}
                        options={{

                            animationData: animation,
                            loop: true,

                        }}
                    />
                </div>
            </div> */}

            {
                withdrawFinished ?
                    withdrawSucceed ?
                        <div style={{ width: width < 950 ? 60 : 100, height: width < 950 ? 60 : 100, marginTop: 24 }}>
                            <Lottie
                                speed={0.8}
                                options={{
                                    animationData: animationSuccess,
                                    loop: false,
                                }}
                            />
                        </div>
                        :
                        <div style={{ width: width < 950 ? 60 : 100, height: width < 950 ? 60 : 100, marginTop: 24 }}>
                            <Lottie
                                speed={0.8}
                                options={{
                                    animationData: animationCancle,
                                    loop: false,
                                }}
                            />


                        </div>

                    :

                    <div style={{ width: width < 950 ? 60 : 100, height: width < 950 ? 60 : 100, marginTop: 24 }}>


                        <Lottie

                            speed={0.5}
                            options={{

                                animationData: animation,
                                loop: true,

                            }}
                        />


                    </div>
            }

            <div style={{ width: "90%", display: 'flex', justifyContent: 'center', marginTop: 12 }}>
                {walletSigning ?
                    <div style={{ marginBottom: 24 }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar src={metamaskIcon} variant="square" style={{ width: width < 950 ? 24 : 32, height: width < 950 ? 24 : 32, marginRight: 8 }} />
                            <Typography className={classes.defaultFont} style={{ fontSize: width < 950 ? 16 : 20, fontWeight: 'bold', color: '#363636', textAlign: 'center' }}>
                                {t('confirming')}
                            </Typography>

                        </div>
                        <Typography className={classes.defaultFont} style={{ marginTop: 8, fontSize: 12, fontWeight: 'bold', color: '#8786A7', textAlign: 'center' }}>
                        {t('AlreadyConfirmed')}
                        </Typography>
                    </div>

                    :
                    <div style={{ marginBottom: 24, display: 'flex', flexDirection: 'column' }}>
                        <Typography className={classes.defaultFont} style={{ fontSize: width < 950 ? 16 :20, fontWeight: 'bold', marginTop: 8,  color: '#363636', textAlign: 'center' }}>
                            {t(withdrawMsg)}

                        </Typography>
                        <Button onClick={handleCloseNote}>
                            <Typography className={classes.defaultFont} style={{ fontSize: 12, fontWeight: 'bold', color: '#8786A7', textAlign: 'center' }}>
                            {t('CloseWindow')}
                            </Typography>
                        </Button>
                    </div>


                }
            </div>

            {walletSigning ?
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', backgroundColor: 'white' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '89%', marginTop: 40 }}>
                        <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#8786A7', fontWeight: 'bold' }}>
                            {t('withdrawAmount')}
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* <Avatar variant="square" src={getIcons(pairOne, tokenIcons, true)} style={{ height: 20, width: 20, marginRight: 8 }} /> */}
                            <Typography style={{ fontSize: 14, color: '#251CFF', fontWeight: 'bold', }} >
                                {withdrawAmount}
                            </Typography>


                        </div>
                    </div>



                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '89%', marginTop: 16 }}>
                        <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#8786A7', fontWeight: 'bold' }}>
                            {t('withdrawAddress')}
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* <Avatar variant="square" src={getIcons(pairTwo, tokenIcons, true)} style={{ height: 20, width: 20, marginRight: 8 }} /> */}
                            <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#363636', fontWeight: 'bold', }} >

                                {width < 950 ? `${withdrawTo.slice(0, 8)}...${withdrawTo.slice(withdrawTo.length - 3)}` : withdrawTo}
                            </Typography>
                        </div>
                    </div>



                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 16, width: '89%' }}>
                        <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#8786A7', fontWeight: 'bold' }}>
                            {t('action')}
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* <Avatar variant="square" src={getIcons(pairOne, tokenIcons, true)} style={{ height: 20, width: 20, marginRight: 8 }} /> */}
                            <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#363636', fontWeight: 'bold' }} >
                                {t('withdrawAction')}
                            </Typography>


                        </div>
                    </div>



                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 16, width: '89%', marginBottom: 40 }}>
                        <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#8B96A7', fontWeight: 'bold' }}>
                            {t('status')}
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                            {/* <Avatar variant="square" src={getIcons(pairOne, tokenIcons, true)} style={{ height: 20, width: 20, marginRight: 8 }} /> */}
                            <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#363636', fontWeight: 'bold', textAlign: 'center', marginBottom: 12 }} >
                                {walletSigning ? t('loading') : withdrawSucceed ? t(withdrawMsg) : t(walletMsg)}
                            </Typography>
                        </div>
                    </div>
                </div>
                :
                null
            }
        </div>
    );

    const phoneContent = (
        <div style={{ width: "100%", display: 'flex', flexDirection: width < 960 ? "column" : 'row', justifyContent: width < 960 ? "unset" : 'space-between', marginTop: "0" }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: 'space-between',
                width: "100%",
            }}>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16, marginTop: 16 }}>
                    <Typography style={{ fontSize: 24, fontFamily: 'Syncopate', color: '#251CFF', marginLeft: 24 }}>{t('withdraw')}</Typography>

                    <Button style={{ color: "white", backgroundColor: '#251CFF', fontSize: 12, textTransform: 'none', height: 32, marginRight: 24, borderRadius: 8 }} onClick={() => {
                        history.back()
                    }} >
                        {t("back")}
                    </Button>
                </div>

                <Typography className={classes.defaultFont} style={{ fontSize: 12, color: '#8786A7', textAlign: 'left', marginLeft: 24, marginRight: 24 }}>
                    {moneyOut ? t('withdrawContent') : t('moneyOutClosed')}
                </Typography>
                {
                    width < 960 ?
                        null
                        :
                        <img src={withdrawIcon} style={{ width: '70%' }}>
                        </img>
                }

            </div>


            <div style={{ width: width < 960 ? "100%" : '60%', height: 540 }}>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>




                    {
                        moneyOut ? <Grid container spacing={2} className={classes.fieldWrapper} style={{}}>

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div style={{}}>

                                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 24 }}>
                                        <div style={{ width: '91%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Typography style={{ textAlign: 'left', color: '#AFBDD0', fontFamily: 'Avenir', fontWeight: '600', fontSize: isMobile ? 12 : 14 }}>My Wallet</Typography>
                                            {

                                                loggedIn ?
                                                    <Button
                                                        style={{ textTransform: 'none', opacity: !loggedIn ? 0.2 : 1, minHeight: 0, minWidth: 0 }}
                                                        onClick={fillAddress}
                                                        disabled={!loggedIn}
                                                    >

                                                        <Typography style={{ textAlign: 'left', color: '#251CFF', fontFamily: 'Avenir', fontWeight: '600', fontSize: isMobile ? 12 : 14 }}>Fill Address </Typography>
                                                    </Button>
                                                    :
                                                    <Button
                                                        style={{ textTransform: 'none', minHeight: 0, minWidth: 0 }}
                                                        onClick={(!registered || !loggedIn) && !loading ? () => unlock(address, chainId, network, '', Web3, registered, dispatch) : null} disabled={button2Disabled}

                                                    >

                                                        <Typography style={{ textAlign: 'left', color: '#251CFF', fontFamily: 'Avenir', fontWeight: '600', fontSize: isMobile ? 12 : 14 }}>Unlock Wallet</Typography>
                                                    </Button>

                                            }


                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '91%', backgroundColor: 'white', marginTop: 8, borderRadius: 16, border: '1px solid #DCDCDC' }}>
                                            <div style={{ marginLeft: 12, marginRight: 12, marginTop: 12, marginBottom: 12 }}>
                                                <NewCustomTextField
                                                    type="text"
                                                    rightbuttonlabel={''}
                                                    showcancellbutton={true}
                                                    clear={clear}
                                                    onChange={(e) => handleAddressChange(e.target.value)}
                                                    onBlur={() => handleFinish(false)}
                                                    // variant="standard"
                                                    value={withdrawTo}
                                                    helperText={addrWarning}
                                                    disabled={true}
                                                    error={addrWarning !== ''}
                                                    isAddressInput={true}

                                                >

                                                </NewCustomTextField>
                                                {/* <Typography style={{ marginLeft: 8, fontFamily: 'Avenir', fontWeight: '500', color: warning == "" ? "#AFBDD0" : '#FF4646', fontSize: isMobile ? 10 : 16 }}> {warning == "" ? "please enter deposit amount" : warning}</Typography> */}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* new input component */}
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 12 }}>
                                <div style={{}}>

                                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 24 }}>
                                        <div style={{ width: '91%', alignItems: 'flex-start', }}>
                                            <Typography style={{ textAlign: 'left', color: '#AFBDD0', fontFamily: 'Avenir', fontWeight: '600', fontSize: isMobile ? 12 : 14 }}>Withdraw Amount</Typography>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '91%', backgroundColor: 'white', marginTop: 8, borderRadius: 16, border: '1px solid #DCDCDC' }}>
                                            <div style={{ marginLeft: isMobile ? 12 : 24, marginTop: isMobile ? 10 : 16, marginBottom: 12 }}>
                                                <NewCustomTextField
                                                    disabled={!loggedIn}
                                                    inputRef={inputRef}
                                                    onChange={(e) => handleAmountChange(e.target.value)}
                                                    value={withdrawAmount}

                                                    placeholder={"0.00"}
                                                    rightbuttonlabel={t(capital.free > 0.0001 ? 'all' : null)}
                                                    onrightbuttonclick={allIn}
                                                    customtype="number"
                                                    coin={coin}

                                                    tokenIcons={tokenIcons}
                                                    coins={coins}
                                                    plachHolder={t("selectCoin")}
                                                    inputTitle={t("searchCoin")}
                                                    handleChange={handleCoinChange}
                                                >

                                                </NewCustomTextField>
                                                <Typography style={{ marginLeft: 8, fontFamily: 'Avenir', fontWeight: '500', color: warning == "" ? "#AFBDD0" : '#FF4646', fontSize: isMobile ? 12 : 16 }}> {warning == "" ? t("enterAmount") : warning}</Typography>
                                            </div>



                                            <div style={{ display: 'flex', flexDirection: 'column', marginRight: isMobile ? 12 : 24, alignItems: 'flex-end' }}>
                                                <NewCustomCoinSelector
                                                    coin={coin}
                                                    isloggedIn={loggedIn}

                                                    tokenIcons={tokenIcons}
                                                    coins={coins}
                                                    plachHolder={t("selectCoin")}
                                                    inputTitle={t("searchCoin")}
                                                    handleChange={handleCoinChange}
                                                />
                                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8, alignItems: 'center', justifyContent: 'center', marginRight: 8 }}>
                                                    <Typography style={{ textAlign: 'left', color: '#AFBDD0', fontFamily: 'Avenir', fontWeight: '500', fontSize: isMobile ? 12 : 14 }}>{t("availableCapital")}  </Typography>
                                                    <Typography style={{ textAlign: 'left', marginLeft: 4, color: '#271CFF', fontFamily: 'Avenir', fontWeight: '500', fontSize: isMobile ? 12 : 14 }}>{loggedIn ? roundingDown(capital.free, 4) : '--'}</Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: "10vh", justifyContent: 'center' }}>
                                <div style={{ width: '91%', display: 'flex', flexDirection: 'column', borderRadius: 12, border: '1px solid #f5f5f5', alignItems: 'center', justifyContent: 'center' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '91%', alignItems: 'center', marginTop: 12, marginBottom: 6 }}>
                                        <Typography className={classes.defaultFont} style={{ color: '#8B96A7', fontSize: 14 }}>{t('fee')}</Typography>
                                        <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#8B96A7' }}>{`${!withdrawFeeObj || Object.keys(withdrawFeeObj).length === 0 ? '--' : roundingDown(withdrawFeeObj.amount, 4)} ${!withdrawFeeObj || Object.keys(withdrawFeeObj).length === 0 ? '' : withdrawFeeObj.base}`}</Typography>
                                    </div>
                                    <div style={{ height: 1, width: '91%', backgroundColor: '#f5f5f5' }}></div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '91%', alignItems: 'center', marginTop: 6, marginBottom: 12 }}>

                                        <Typography className={classes.defaultFont} style={{ color: '#8B96A7', fontSize: 14 }}>{t('amountReceiving')}</Typography>
                                        <Typography style={{ fontSize: 14, color: '#251CFF' }}>{`${receivingAmount} ${receivingBase}`}</Typography>
                                    </div>
                                </div>

                            </div>



                            <div style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "8%" }}>
                                {
                                    address.length < 42 || !isValidAddress(address) ?
                                        <CustomButton buttonstyle="connectStyle" style={{ width: '91%' }} onClick={!window.ethereum ? () => onClickInstall(sendBackButton1, sendBackButton1Disabled) : () => onClickConnect(network, chainId, address, dispatch)}>
                                            {button1}
                                        </CustomButton> : null
                                }
                                {
                                    address.length === 42 && isValidAddress(address) ?
                                        loggedIn ?
                                            <CustomButton style={{ opacity: !validAmount || !validAddress ? 0.2 : 1, width: '91%' }} onClick={confirmWithdraw} disabled={!validAmount || !validAddress}>
                                                {t('confirm')}
                                            </CustomButton> :
                                            <CustomButton buttonstyle="unlockStyle" style={{ width: '91%' }} onClick={(!registered || !loggedIn) && !loading ? () => unlock(address, chainId, network, '', Web3, registered, dispatch) : null} disabled={button2Disabled}>
                                                {t('unlock')}
                                            </CustomButton> : null
                                }
                            </div>
                        </Grid> : null
                    }
                </div>
            </div>
        </div>
    )

    const deskContainer = (
        <div style={{ width: '71%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: "3%" }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                backgroundColor: "#F1F9FF",
                justifyContent: 'space-between',
                width: '38%',
                height: 656,
                paddingBottom: 24

            }}>
                <Typography style={{ fontSize: 32, fontFamily: 'Syncopate', color: '#251CFF', marginLeft: 24, marginTop: 24 }}>{t('withdraw')}</Typography>


                <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#8FB9E1', textAlign: 'left', marginLeft: 24, marginRight: 24 }}>
                    {moneyOut ? t('withdrawContent') : t('moneyOutClosed')}
                </Typography>


                <img alt="" src={withdrawIcon} style={{ width: '70%' }} />



            </div>


            <div style={{ backgroundColor: '#F5F5F5', width: '60%', height: 720 }}>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', height: "100%" }}>

                    <Button style={{ backgroundColor: 'white', marginLeft: 24, marginTop: 24 }} onClick={() => {
                        history.back()
                    }} >
                        <img alt="" style={{ height: 24 }} src={backIcon} />
                    </Button>


                    <div style={{ height: 1, marginTop: 0, marginBottom: 20, backgroundColor: '#2435AC' }} />
                    {
                        moneyOut ?
                            <div style={{ width: '100%', }}>

                                {/* new input component */}
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>


                                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                        <div style={{ width: '91%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Typography style={{ textAlign: 'left', color: '#AFBDD0', fontFamily: 'Avenir', fontWeight: '600', fontSize: 14 }}>{t('l1Wallet')}</Typography>
                                            {

                                                loggedIn ?
                                                    <Button
                                                        style={{ textTransform: 'none', opacity: !loggedIn ? 0.2 : 1, minHeight: 0, minWidth: 0 }}
                                                        onClick={fillAddress}
                                                        disabled={!loggedIn}
                                                    >

                                                        <Typography style={{ textAlign: 'left', color: '#251CFF', fontFamily: 'Avenir', fontWeight: '600', fontSize: 14 }}>{t("fullAddress")} </Typography>
                                                    </Button>
                                                    :
                                                    <Button
                                                        style={{ textTransform: 'none', minHeight: 0, minWidth: 0 }}
                                                        onClick={(!registered || !loggedIn) && !loading ? () => unlock(address, chainId, network, '', Web3, registered, dispatch) : null} disabled={button2Disabled}

                                                    >

                                                        <Typography style={{ textAlign: 'left', color: '#251CFF', fontFamily: 'Avenir', fontWeight: '600', fontSize: 14 }}>{t("unlock")}</Typography>
                                                    </Button>

                                            }


                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '91%', backgroundColor: 'white', marginTop: 8, borderRadius: 16, border: '1px solid #DCDCDC' }}>
                                            <div style={{ marginLeft: 12, marginRight: 12, marginTop: 12, marginBottom: 12, width: '100%' }}>
                                                <NewCustomTextField


                                                    isAddressInput={true}
                                                    type="text"
                                                    rightbuttonlabel={''}
                                                    showcancellbutton={true}
                                                    clear={clear}
                                                    onChange={(e) => handleAddressChange(e.target.value)}
                                                    onBlur={() => handleFinish(false)}
                                                    // variant="standard"
                                                    value={withdrawTo}
                                                    helperText={addrWarning}
                                                    disabled={true}
                                                    error={addrWarning !== ''}
                                                >

                                                </NewCustomTextField>
                                            </div>

                                        </div>
                                    </div>

                                </div>


                                {/* new input component */}
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 12, width: '100%' }}>
                                    <div style={{ width: '100%' }}>

                                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 24 }}>
                                            <div style={{ width: '91%', alignItems: 'flex-start', }}>
                                                <Typography style={{ textAlign: 'left', color: '#AFBDD0', fontFamily: 'Avenir', fontWeight: '600', fontSize: 14 }}>{t("withdrawAmount")}</Typography>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '91%', backgroundColor: 'white', marginTop: 8, borderRadius: 16, border: '1px solid #DCDCDC' }}>
                                                <div style={{ marginLeft: 24, marginTop: isMobile ? 10 : 16, marginBottom: 12 }}>
                                                    <NewCustomTextField
                                                        disabled={!loggedIn}
                                                        inputRef={inputRef}
                                                        onChange={(e) => handleAmountChange(e.target.value)}
                                                        value={withdrawAmount}
                                                        placeholder={"0.00"}
                                                        rightbuttonlabel={t(capital.free > 0.0001 ? 'all' : null)}
                                                        onrightbuttonclick={allIn}
                                                        customtype="number"
                                                        coin={coin}

                                                        tokenIcons={tokenIcons}
                                                        coins={coins}
                                                        plachHolder={t("selectCoin")}
                                                        inputTitle={t("searchCoin")}
                                                        handleChange={handleCoinChange}
                                                    >
                                                    </NewCustomTextField>
                                                    <Typography style={{ marginLeft: 8, fontFamily: 'Avenir', fontWeight: '500', color: warning == "" ? "#AFBDD0" : '#FF4646', fontSize: 16 }}> {warning === "" ? t("enterAmount") : warning}</Typography>
                                                </div>



                                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: 24, alignItems: 'flex-end' }}>
                                                    <NewCustomCoinSelector
                                                        // style={{ width: width < 960 ? "unset" : '42%' }}
                                                        coin={coin}
                                                        isloggedIn={loggedIn}

                                                        tokenIcons={tokenIcons}
                                                        coins={coins}
                                                        plachHolder={t("selectCoin")}
                                                        inputTitle={t("searchCoin")}
                                                        handleChange={handleCoinChange}
                                                    />
                                                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8, alignItems: 'center', justifyContent: 'center', marginRight: 8 }}>
                                                        <Typography style={{ textAlign: 'left', color: '#AFBDD0', fontFamily: 'Avenir', fontWeight: '500', fontSize: 14 }}>{t("availableCapital")}  </Typography>
                                                        <Typography style={{ textAlign: 'left', marginLeft: 4, color: '#271CFF', fontFamily: 'Avenir', fontWeight: '500', fontSize: 14 }}>{loggedIn ? roundingDown(capital.free, 4) : '--'}</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: 32, justifyContent: 'center' }}>
                                    <div style={{ width: '91%', display: 'flex', flexDirection: 'column', borderRadius: 16, border: '1px solid #DCDCDC', alignItems: 'center', justifyContent: 'center' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '91%', alignItems: 'center', marginTop: 24, marginBottom: 12 }}>
                                            <Typography className={classes.defaultFont} style={{ color: '#8B96A7', fontSize: 16 }}>{t('fee')}</Typography>
                                            <Typography className={classes.defaultFont} style={{ fontSize: 16, color: '#8B96A7' }}>{`${!withdrawFeeObj || Object.keys(withdrawFeeObj).length === 0 ? '--' : roundingDown(withdrawFeeObj.amount, 4)} ${!withdrawFeeObj || Object.keys(withdrawFeeObj).length === 0 ? '' : withdrawFeeObj.base}`}</Typography>
                                        </div>
                                        <div style={{ height: 1, width: '91%', backgroundColor: '#DCDCDC' }}></div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '91%', alignItems: 'center', marginTop: 12, marginBottom: 24 }}>

                                            <Typography className={classes.defaultFont} style={{ color: '#8B96A7', fontSize: 16 }}>{t('amountReceiving')}</Typography>
                                            <Typography style={{ fontSize: 16, color: '#251CFF' }}>{`${receivingAmount} ${receivingBase}`}</Typography>
                                        </div>
                                    </div>

                                </div>


                                <div style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "8%" }}>
                                    {
                                        address.length < 42 || !isValidAddress(address) ?
                                            <CustomButton buttonstyle="connectStyle" style={{ width: '91%' }} onClick={!window.ethereum ? () => onClickInstall(sendBackButton1, sendBackButton1Disabled) : () => onClickConnect(network, chainId, address, dispatch)}>
                                                {button1}
                                            </CustomButton> : null
                                    }
                                    {
                                        address.length === 42 && isValidAddress(address) ?
                                            loggedIn ?
                                                <CustomButton style={{ opacity: !validAmount || !validAddress ? 0.2 : 1, width: '91%' }} onClick={confirmWithdraw} disabled={!validAmount || !validAddress}>
                                                    {t('confirm')}
                                                </CustomButton> :
                                                <CustomButton buttonstyle="unlockStyle" style={{ width: '91%' }} onClick={(!registered || !loggedIn) && !loading ? () => unlock(address, chainId, network, '', Web3, registered, dispatch) : null} disabled={button2Disabled}>
                                                    {t('unlock')}
                                                </CustomButton> : null
                                    }
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
            </div>
        </div>
    )

    return (
        <div style={{ display: 'flex', minHeight: 900, flexDirection: 'row', justifyContent: 'center' }}>
            {/* {lottieTest} */}
            {
                width < 960 ?
                    phoneContent
                    :
                    deskContainer
            }
            <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                aria-labelledby="server-modal-title"
                aria-describedby="server-modal-description"
                className={classes.modal}
                open={openNote}
                // open={true}

                onClose={handleCloseNote}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                disableEscapeKeyDown={!withdrawFinished}
                disableBackdropClick={!withdrawFinished}
            >
                <Fade
                    in={openNote}
                    // in={true}
                >
                    {confirmViewBody}
                </Fade>
            </Modal>
        </div>
    );
}

export default withTranslation()(Withdraw);
