import React, { useState } from 'react';
import { makeStyles, } from '@material-ui/core/styles';
import './CustomTextField.css';
import { Avatar, Box, Button, Grid, IconButton, List, ListItem, Modal, Typography, Fade } from '@material-ui/core';
import { getIcons } from "../../utils/Common";
import CustomTextField from './CustomTextField'
import useWindowDimensions from "../../utils/WindowDimensions";
import closeIcon from "../../images/closeIcon.png";

// const closeIcon = 'https://cdn.swapallapp.com/l2/images/cross.png'
const customDropBox1 = 'https://cdn.swapallapp.com/l2/images/customDropBox1.png'

const useStylesInput = makeStyles((theme) => ({
    root: {

        'label + &': {
            marginTop: theme.spacing(3),
        },

        // 'label.Mui-focused': {
        //   color: 'green',
        // },
        '&:hover': {
            backgroundColor: '#1B2A8C',
        },
        '&$focused': {
            backgroundColor: '#1F32B4',
        },
        backgroundColor: '#000740',
        borderRadius: 16,
        alignItems: 'center',
        // backgroundColor: 'red',
        overflow: 'hidden'
    },
    modalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        // bgcolor: 'background.paper',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
        backgroundColor: '#f5f5f5',
        height: 500,
        borderRadius: 20,

    },

    modalBoxPhoneSize: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "80%",
        // bgcolor: 'background.paper',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
        backgroundColor: '#f5f5f5',
        height: 500,
        borderRadius: 20,

    },

    selectCoinButPhoneSize: {
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 12,

        height: 56,
        borderRadius: 12,
        width: 84,
        fontWeight: 'bold',

        border: 'none',
        lineHeight: 1.5,
        backgroundColor: '#251CFF',

        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#6BFFCD',

            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            borderColor: '#005cbf',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
    },
    selectCoinBut: {
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 12,

        height: 56,
        borderRadius: 12,
        width: "100%",
        fontWeight: 'bold',
        padding: '6px 12px',
        border: 'none',
        lineHeight: 1.5,
        backgroundColor: '#251CFF',

        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#6BFFCD',

            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            borderColor: '#005cbf',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
    },

    input: {
        position: 'relative',
        // backgroundColor: '#1F32B4',
        fontSize: 36,
        // width: 'auto',
        padding: '10px 12px',
        color: '#1DF0A9',

        fontFamily: [
            'Arial',
        ].join(','),

        '&:focused': {
            color: '#1DF0A9'
        }
    }


}));



export default function CustomCoinSelector(props, t) {
    const classes = useStylesInput();
    const inputRef = React.useRef();

    const [open, setOpen] = React.useState(false);
    const [searchInput, setSearchInput] = useState('')
    const [filteredCoins, setFilteredCoins] = useState([])
    const { height, width } = useWindowDimensions();

    const handleSearch = (e) => {
        let text = e.toLowerCase()
        setSearchInput(e)
        let trucks = props.coins
        let filteredCoins = trucks.filter((item) => {
            return item.value.toLowerCase().match(text)
        })
        if (!text || text == "") {
            setFilteredCoins([])

        } else {
            setFilteredCoins(filteredCoins)
        }
    }

    const handleClose = () => {
        setOpen(false);
        setSearchInput("")
        setFilteredCoins([])
    };
    const handleOpen = () => {

        setOpen(true);
    };


    const handleCoinChange = (option) => {

        const resultCoin = { label: option.value ? option.value : option, value: option.value ? option.value : option }

        // console.log("!!",option.value)
        handleClose()
        if (option) {
            props.handleChange(resultCoin, props.name)
        } else {

            props.handleChange(t('noCapital'))
        }

    };
    return <div>
        {
            width > 600 ?
                <Button onClick={handleOpen} className={classes.selectCoinBut} width={props.width}>
                    <Avatar alt="Coin Icon" style={{ width: 24, height: 24 }} src={getIcons(props.coin, props.tokenIcons, true)} />
                    <Typography style={{ marginLeft: 4, fontSize: 12, fontWeight: "bold", color: 'white' }}>
                        {
                            props.coin === "" ?
                                props.plachHolder
                                :
                                props.coin
                        }
                    </Typography>
                    <img alt='' style={{ width: 12, objectFit: 'contain', marginLeft: 8 }} src={customDropBox1} />

                </Button>
                :
                <Button onClick={handleOpen} className={classes.selectCoinButPhoneSize} width={props.width}>
                    {/* <Avatar alt="Coin Icon" style={{ width: 18, height: 18 }} src={getIcons(props.coin, props.tokenIcons, true)} /> */}
                    <Typography style={{ marginLeft: 4, fontSize: 10, fontWeight: "bold", color: 'white', fontFamily: "'Avenir" }}>
                        {
                            props.coin === "" ?
                                props.plachHolder
                                :
                                props.coin
                        }
                    </Typography>
                    <img alt='' style={{ width: 8, objectFit: 'contain', marginLeft: 4 }} src={customDropBox1} />

                </Button>
        }

        {
            width > 600 ?
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description"
                >
                    <Fade
                        in={open}
                    >
                        <Box className={classes.modalBox}>
                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', marginLeft: 24, marginRight: 24 }}>
                                <h2 style={{ fontSize: 24, color: '#363636' }} id="parent-modal-title">{props.plachHolder}</h2>
                                <Button onClick={handleClose}>
                                    <Avatar alt="Coin Icon" style={{ width: 24, height: 24 }} src={closeIcon} />
                                </Button>
                            </div>
                            <CustomTextField
                                label={props.inputTitle}
                                // disabled={!loggedIn}
                                style={{ marginLeft: 24, textTransform: 'none', marginTop: 0, height: 80 }}
                                inputRef={inputRef}
                                onChange={(e) => handleSearch(e.target.value)}
                                value={searchInput}
                            >

                            </CustomTextField>
                            {
                                props.coins ?
                                    <List style={{ height: 320, overflow: 'auto' }}>
                                        {
                                            filteredCoins.length == 0 ?
                                                props.coins.map((option) => (
                                                    <ListItem style={{}} disablePadding>
                                                        {option.value === '' ?
                                                            <Grid item xs={12} >

                                                                <Typography style={{ marginLeft: 16, fontSize: 16, fontWeight: "bold", color: '#363636' }}> {option.label}</Typography>
                                                            </Grid>
                                                            :
                                                            <Button onClick={() => handleCoinChange(option)}>
                                                                <Avatar alt="Coin Icon" style={{ width: 24, height: 24 }} src={getIcons(option.label, props.tokenIcons, true)} />
                                                                <Typography style={{ marginLeft: 16, fontSize: 16, fontWeight: "bold", color: '#363636' }}> {option.label}</Typography>
                                                            </Button>}
                                                    </ListItem>
                                                ))
                                                :
                                                filteredCoins.map((option) => (
                                                    <ListItem disablePadding>
                                                        {option.value === '' ?
                                                            <Grid item xs={12} >
                                                                <Typography style={{ marginLeft: 16, fontSize: 16, fontWeight: "bold", color: '#363636' }}> {option.label}</Typography>
                                                            </Grid>
                                                            :
                                                            <Button onClick={() => handleCoinChange(option.value)}>
                                                                <Avatar alt="Coin Icon" style={{ width: 24, height: 24 }} src={getIcons(option.label, props.tokenIcons, true)} />
                                                                <Typography style={{ marginLeft: 16, fontSize: 16, fontWeight: "bold", color: '#363636' }}> {option.label}</Typography>
                                                            </Button>}
                                                    </ListItem>
                                                ))
                                        }

                                    </List>
                                    :
                                    <Typography style={{ marginLeft: 16, fontSize: 16, fontWeight: "bold", color: '#363636' }}> !!!!!!!!!!</Typography>


                            }



                        </Box>
                    </Fade>
                </Modal>
                :
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description"
                >
                    <Fade
                        in={open}
                    >
                        <Box className={classes.modalBoxPhoneSize}>
                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', marginLeft: 16, marginRight: 0 }}>
                                <h2 style={{ fontSize: 24, color: '#8786A7' }} id="parent-modal-title">{props.plachHolder}</h2>
                                <IconButton onClick={handleClose}>
                                    <Avatar alt="Coin Icon" style={{ width: 24, height: 24 }} src={closeIcon} />
                                </IconButton>
                            </div>
                            <CustomTextField
                                label={props.inputTitle}
                                // disabled={!loggedIn}
                                style={{ marginLeft: 12, marginRight: 12, textTransform: 'none', marginTop: 0, height: 80 }}
                                inputRef={inputRef}
                                onChange={(e) => handleSearch(e.target.value)}
                                value={searchInput}
                            >

                            </CustomTextField>
                            {
                                props.coins ?
                                    <List style={{ height: 320, overflow: 'auto' }}>
                                        {
                                            filteredCoins.length == 0 ?
                                                props.coins.map((option) => (
                                                    <ListItem style={{}} disablePadding>
                                                        {option.value === '' ?
                                                            <Grid item xs={12} >

                                                                <Typography style={{ marginLeft: 16, fontSize: 16, fontWeight: "bold", color: '#8786A7' }}> {option.label}</Typography>
                                                            </Grid>
                                                            :
                                                            <Button onClick={() => handleCoinChange(option)}>
                                                                <Avatar alt="Coin Icon" style={{ width: 24, height: 24 }} src={getIcons(option.label, props.tokenIcons, true)} />
                                                                <Typography style={{ marginLeft: 16, fontSize: 16, fontWeight: "bold", color: '#8786A7' }}> {option.label}</Typography>
                                                            </Button>}
                                                    </ListItem>
                                                ))
                                                :
                                                filteredCoins.map((option) => (
                                                    <ListItem disablePadding>
                                                        {option.value === '' ?
                                                            <Grid item xs={12} >
                                                                <Typography style={{ marginLeft: 16, fontSize: 16, fontWeight: "bold", color: '#8786A7' }}> {option.label}</Typography>
                                                            </Grid>
                                                            :
                                                            <Button onClick={() => handleCoinChange(option.value)}>
                                                                <Avatar alt="Coin Icon" style={{ width: 24, height: 24 }} src={getIcons(option.label, props.tokenIcons, true)} />
                                                                <Typography style={{ marginLeft: 16, fontSize: 16, fontWeight: "bold", color: '#8786A7' }}> {option.label}</Typography>
                                                            </Button>}
                                                    </ListItem>
                                                ))
                                        }

                                    </List>
                                    :
                                    <Typography style={{ marginLeft: 16, fontSize: 16, fontWeight: "bold", color: '#8786A7' }}> </Typography>


                            }



                        </Box>
                    </Fade>
                </Modal>
        }

    </div>



}


