import 'intl';
import 'intl/locale-data/jsonp/en';
import Web3 from "web3";
import * as Contract from "../config/Contract.json";
const unknowCoinIcon = 'https://cdn.swapallapp.com/l2/images/unknowCoinIcon.png';

export const FormatNumber = (number) => {
    if (!isNaN(number)) {
        return Intl.NumberFormat().format(number)
    }
}

export function getIcons(name, map, isSmall) {
    if (name) {
        if (Object.keys(map).length !== 0 && map[name] !== undefined) {
            let url = ''
            if (isSmall) {
                url = map[name].smallLogoUrl === undefined || map[name].smallLogoUrl === null ? '' : map[name].smallLogoUrl
            } else {
                url = map[name].bigLogoUrl === undefined || map[name].bigLogoUrl === null ? '' : map[name].bigLogoUrl
            }
            return url === '' ? unknowCoinIcon : url
        } else {
            return unknowCoinIcon

        }
    } else {
        return unknowCoinIcon
    }
}

export function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export const convertTimeString = (timeString) => {
    let availableTime = new Date((Date.parse(timeString)));
    let minute = availableTime.getUTCMinutes() >= 10 ? availableTime.getUTCMinutes() : '0' + availableTime.getUTCMinutes().toString()
    return availableTime.getUTCFullYear() + '-' + (availableTime.getUTCMonth() + 1) + '-' + availableTime.getUTCDate() + ' ' +
        ('0' + availableTime.getUTCHours()).slice(-2) + ':' + minute;
}

export const formDateString = (timeStamp) => {
    let date = new Date(timeStamp);
    let dateString = date.getUTCFullYear() + '-' + (date.getUTCMonth() + 1) + '-' + date.getUTCDate() + ' ' +
        ('0' + date.getUTCHours()).slice(-2) + ':' + ('0' + date.getUTCMinutes()).slice(-2);
    return dateString
}

export const formYYMMDD = (timeStamp) => {
    let date = new Date(timeStamp);
    let dateString = date.getUTCFullYear() + '-' + (date.getUTCMonth() + 1) + '-' + date.getUTCDate()
    return dateString
}

export const getChain = (networkId, chainId) => {
    let _chainId = chainId
    let _networkId
    if (typeof chainId === 'string') {
        _chainId = Web3.utils.hexToNumber(chainId)
    }
    if (typeof networkId === 'string') {
        _networkId = Number(networkId)
    }
    let chainInfo = Contract.default[_chainId.toString()] === undefined ? Contract.default[_networkId.toString()] === undefined ? { name: '' } : Contract.default[_networkId.toString()] : Contract.default[_chainId.toString()]
    let chainName = chainInfo.name === undefined ? '' : chainInfo.name
    return chainName
}

export const getChainName = (networkId, chainId, t) => {
    let chain = getChain(networkId, chainId)
    let suffix = isTest(networkId, chainId) ? t('test') : ''
    return chain + suffix
}

export const isTest = (networkId, chainId) => {
    let _chainId = chainId
    if (typeof chainId === 'string') {
        _chainId = Web3.utils.hexToNumber(chainId)
    }
    return Contract.default[_chainId.toString()].isTest
}

export const getChainInfoByName = (chain, isTest) => {
    const suffix = isTest ? '_test' : ''
    let info = { networkId: Contract.default[chain + suffix].networkId, chainId: Contract.default[chain + suffix].chainId }
    return info
}


export const getScan = (networkId, chainId) => {
    let _chainId = chainId
    if (typeof chainId === 'string') {
        _chainId = Web3.utils.hexToNumber(chainId)
    }
    const suffix = isTest(networkId, _chainId) ? '_test' : ''
    let scan = Contract.default[getChain(networkId, _chainId) + suffix].scan
    if (scan === undefined) {
        scan = ''
    }
    return scan
}

export const countDecimals = function (num) {
    if (isNumeric(parseFloat(num))) {
        if (Math.floor(num) === num) return 0;
        return num.toString().split(".")[1].length || 0;
    } else {
        return 0
    }

}
