import React, { useEffect, useState } from 'react';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import Home from './components/Home/Home';
import Footer from './components/Footer/Footer';
import Wallet from './components/Home/Wallet';
import Withdraw from './components/Withdraw/Withdraw';
import Transfer from './components/Transfer/Transfer';

import Deposit from './components/Deposit/Deposit';
import Records from './components/Records/Records';
import IDOHome from './components/IDO/IDOHome';
import IDOInvite from './components/IDO/IDOInvite'

import Swap from './components/Swap/Swap';
import SwapRecord from './components/Swap/components/SwapRecords'

import DApp from './components/DApp/DApp'
import Node from './components/DApp/Node'
import Rank from './components/DApp/Rank'

import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { onBoard } from "./utils/Sign";
import { isValidAddress } from "ethereumjs-util";
import { authActions } from "./redux/actions";
import i18n from './i18n';
import useWindowDimensions from "./utils/WindowDimensions";
import ReleaseBar from "./components/ReleaseBar/ReleaseBar";
import SliderModal from './components/Note/SliderModal'

function App({ t, props }) {
    const [navBarHeight, setNavBarHeight] = useState(0)
    const [address, setAddress] = useState('')
    const [chainId, setChainId] = useState(0)
    const [network, setNetwork] = useState('')
    const [code, setCode] = useState('')
    const [button1, setButton1] = useState('')
    const [button1Disabled, setButton1Disabled] = useState(true)
    const [button2, setButton2] = useState('')
    const [button2Disabled, setButton2Disabled] = useState(true)

    const [openSliderModal, setOpenSliderModal] = useState(false)
    const [sliderInfo, setSliderInfo] = useState({})
    const [signUpPayload, setSignUpPayload] = useState({})

    const { height, width } = useWindowDimensions();

    const handleCloseSliderModal = () => {
        setOpenSliderModal(false)
    }

    const sendBackButton1 = (msg) => {
        setButton1(msg)
    }

    const sendBackButton1Disabled = (status) => {
        setButton1Disabled(status)
    }

    const sendBackButton2 = (msg) => {
        setButton2(msg)
    }

    const sendBackButton2Disabled = (status) => {
        setButton2Disabled(status)
    }

    const sendBackHeight = (height) => {
        setNavBarHeight(height)
    }

    const sendBackAddr = (addr) => {
        setAddress(addr)
    }

    const sendBackChainId = (chainId) => {
        setChainId(chainId)
    }

    const sendBackNetworkId = (networkId) => {
        setNetwork(networkId)
    }

    const { registered, loggedIn, loggingIn, loading } = useSelector(state => state.auth)
    const dispatch = useDispatch();

    const withLoggedInState = Component => {
        return function NewComponent({ isLoggedIn, ...props }) {
            return (
                <div>
                    {!isLoggedIn && <Redirect to='/' />}
                    <Component {...props} />
                </div>
            )
        }
    }

    const updateButtons = () => {
        const accountButtonsDisabled = window.ethereum === undefined
        if (accountButtonsDisabled) {
            setButton2Disabled(true)
        } else {
            setButton2Disabled(false)
        }
        if (window.ethereum === undefined) {
            setButton1(t('noWallet'))
            setButton1Disabled(false)
        } else if (address.length === 42 && isValidAddress(address)) {
            setButton1(t('connected'))
            setButton1Disabled(true)
            if (onBoard) {
                onBoard.stopOnboarding()
            }
        } else {
            setButton1(t('connect'))
            setButton1Disabled(false)
        }
    }

    const dispatchOnSlide = (payload, sliderInfo) => {
        payload.verifyData = sliderInfo
        dispatch(authActions.signUp(payload))
    }


    useEffect(() => {
        if (address.length === 42 && isValidAddress(address)) {
            dispatch(authActions.checkUser(address))
        }
        return () => {
        }
    }, [address, network, chainId])

    useEffect(() => {
        let reg = new RegExp("(^|&)" + "ref" + "=([^&]*)(&|$)", "i");
        let r = window.location.search.substr(1).match(reg);
        let context = "";
        if (r != null)
            context = r[2];
        context = (context === null || context === "" || context === "undefined") ? "" : context;
        setCode(context)
        return () => {
        }
    }, [])

    useEffect(() => {
        if (loggingIn) {
            setButton2(t('loggingIn'))
        } if (!registered) {
            setButton2(t('registered'))
        } if (registered && !loggedIn) {
            setButton2(t('unlock'))
        } if (registered && loggedIn) {
            setSliderInfo({})
            setSignUpPayload({})
            setButton2(t('loggedIn'))
            setButton2Disabled(true)
        }
        updateButtons()
        return () => {
        }
    }, [i18n.language, registered, loggedIn, loggingIn, loading])

    useEffect(() => {
        updateButtons()
        return () => {
        }
    }, [button1Disabled, button2Disabled, button1, address])

    useEffect(() => {
        if (sliderInfo.token !== undefined && !openSliderModal && signUpPayload.personalSign !== undefined) {
            dispatchOnSlide(signUpPayload, sliderInfo)
        }
    }, [sliderInfo, openSliderModal, signUpPayload])


    const LoggedInRoute = withLoggedInState(Route)

    return (
        <Router>
            <ReleaseBar />
            <Navbar width={width} button1={button1} sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled}
                sendBackAddr={sendBackAddr} sendBackChainId={sendBackChainId} sendBackNetworkId={sendBackNetworkId} sendBackHeight={sendBackHeight} />
            <Switch>
                <Route path='/' render={({ match: { url } }) => (
                    <>
                        <Route path={`${url}/`} exact >
                            <Redirect to="/ido" />
                            <Home sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}
                                  sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}
                                  address={address} network={network} chainId={chainId} code={code} navBarHeight={navBarHeight} setOpenSliderModal={setOpenSliderModal} handleCloseSliderModal={handleCloseSliderModal} dispatchOnSlide={dispatchOnSlide} sliderInfo={sliderInfo} setSignUpPayload={setSignUpPayload} setSliderInfo={setSliderInfo}  />
                        </Route>
                        <Route path={'/wallet'} >
                            {
                                loggedIn ?
                                    <Wallet sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}
                                            sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}
                                            address={address} network={network} chainId={chainId} navBarHeight={navBarHeight} setOpenSliderModal={setOpenSliderModal} handleCloseSliderModal={handleCloseSliderModal} dispatchOnSlide={dispatchOnSlide} sliderInfo={sliderInfo} setSignUpPayload={setSignUpPayload} setSliderInfo={setSliderInfo}  /> :
                                    <Home sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}
                                          sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}
                                          address={address} network={network} chainId={chainId} code={code} navBarHeight={navBarHeight} setOpenSliderModal={setOpenSliderModal} handleCloseSliderModal={handleCloseSliderModal} dispatchOnSlide={dispatchOnSlide} sliderInfo={sliderInfo} setSignUpPayload={setSignUpPayload} setSliderInfo={setSliderInfo}  />
                            }
                        </Route>
                        <Route path={`/withdraw`} >
                            <Withdraw sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}
                                      sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}
                                      address={address} network={network} chainId={chainId} navBarHeight={navBarHeight} setOpenSliderModal={setOpenSliderModal} handleCloseSliderModal={handleCloseSliderModal} dispatchOnSlide={dispatchOnSlide} sliderInfo={sliderInfo} setSignUpPayload={setSignUpPayload} setSliderInfo={setSliderInfo}  />
                        </Route>
                        <Route path={`/deposit`} >
                            <Deposit sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}
                                     sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}
                                     address={address} network={network} chainId={chainId} navBarHeight={navBarHeight} setOpenSliderModal={setOpenSliderModal} handleCloseSliderModal={handleCloseSliderModal} dispatchOnSlide={dispatchOnSlide} sliderInfo={sliderInfo} setSignUpPayload={setSignUpPayload} setSliderInfo={setSliderInfo}  />
                        </Route>
                        <Route path={`/transfer`} >
                            <Transfer sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}
                                      sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}
                                      address={address} network={network} chainId={chainId} navBarHeight={navBarHeight} setOpenSliderModal={setOpenSliderModal} handleCloseSliderModal={handleCloseSliderModal} dispatchOnSlide={dispatchOnSlide} sliderInfo={sliderInfo} setSignUpPayload={setSignUpPayload} setSliderInfo={setSliderInfo}  />
                        </Route>
                        <Route path={`/records`} >
                            <Records sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}
                                     sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}
                                     address={address} network={network} chainId={chainId} navBarHeight={navBarHeight} setOpenSliderModal={setOpenSliderModal} handleCloseSliderModal={handleCloseSliderModal} dispatchOnSlide={dispatchOnSlide} sliderInfo={sliderInfo} setSignUpPayload={setSignUpPayload} setSliderInfo={setSliderInfo}  />
                        </Route>

                        <Route path='/swap' render={({ match: { url } }) => (
                            <>
                                <Route path={`${url}/`} exact>
                                    <Swap sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}
                                        sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}
                                        address={address} network={network} chainId={chainId} navBarHeight={navBarHeight} />
                                </Route>
                                <Route path={`${url}/records`} >
                                    {!loggedIn ?
                                        <Redirect to='/swap' />
                                        : <SwapRecord sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}
                                            sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}
                                            address={address} network={network} chainId={chainId} navBarHeight={navBarHeight} />
                                    }
                                </Route>
                            </>
                        )} />

                        <Route path='/ido' render={({ match: { url } }) => (
                            <>
                                <Route path={`${url}/`} exact>
                                    <IDOHome sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}
                                             sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}
                                             address={address} network={network} chainId={chainId} code={code} navBarHeight={navBarHeight} width={width} height={height} registered={registered} loggedIn={loggedIn}
                                             loading={loading} setOpenSliderModal={setOpenSliderModal} setSignUpPayload={setSignUpPayload}
                                    />
                                </Route>
                                <Route path={`${url}/invite`} >
                                    <IDOInvite sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}
                                               sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}
                                               address={address} network={network} chainId={chainId} code={code} navBarHeight={navBarHeight} setOpenSliderModal={setOpenSliderModal} handleCloseSliderModal={handleCloseSliderModal} dispatchOnSlide={dispatchOnSlide} sliderInfo={sliderInfo} setSignUpPayload={setSignUpPayload} setSliderInfo={setSliderInfo}  width={width} height={height} registered={registered} loggedIn={loggedIn}
                                               loading={loading}
                                    />
                                </Route>
                            </>
                        )} />

                        <Route path='/dapp' render={({ match: { url } }) => (
                            <>
                                <Route path={`${url}/`} exact>
                                    <DApp sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}
                                             sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}
                                             address={address} network={network} chainId={chainId} code={code} navBarHeight={navBarHeight} width={width} height={height} registered={registered} loggedIn={loggedIn}
                                             loading={loading} setOpenSliderModal={setOpenSliderModal} setSignUpPayload={setSignUpPayload}
                                    />
                                </Route>
                                <Route path={`${url}/node`} >
                                    {
                                        loggedIn ?
                                            <Node sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}
                                                  sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}
                                                  address={address} network={network} chainId={chainId} code={code} navBarHeight={navBarHeight} width={width} height={height} registered={registered} loggedIn={loggedIn}
                                                  loading={loading} setOpenSliderModal={setOpenSliderModal} setSignUpPayload={setSignUpPayload}
                                            /> :  <Redirect to="/dapp" />
                                    }

                                </Route>
                                <Route path={`${url}/rank`} >
                                    {
                                        loggedIn ?
                                            <Rank sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}
                                                  sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}
                                                  address={address} network={network} chainId={chainId} code={code} navBarHeight={navBarHeight} width={width} height={height} registered={registered} loggedIn={loggedIn}
                                                  loading={loading} setOpenSliderModal={setOpenSliderModal} setSignUpPayload={setSignUpPayload}
                                            />
                                            : <Redirect to={'/dapp'} />
                                    }
                                </Route>
                            </>
                        )} />
                    </>
                )} />
                {/*<Route exact path='/' >*/}
                {/*    <PoolRoot sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}*/}
                {/*        sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}*/}
                {/*        address={address} network={network} chainId={chainId} navBarHeight={navBarHeight} setOpenSliderModal={setOpenSliderModal} handleCloseSliderModal={handleCloseSliderModal} dispatchOnSlide={dispatchOnSlide} sliderInfo={sliderInfo} setSignUpPayload={setSignUpPayload} setSliderInfo={setSliderInfo} />*/}
                {/*</Route>*/}
                {/*<Route path='/pool' >*/}
                {/*    <Pool sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}*/}
                {/*          sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}*/}
                {/*          address={address} network={network} chainId={chainId} navBarHeight={navBarHeight} setOpenSliderModal={setOpenSliderModal} handleCloseSliderModal={handleCloseSliderModal} dispatchOnSlide={dispatchOnSlide} sliderInfo={sliderInfo} setSignUpPayload={setSignUpPayload} setSliderInfo={setSliderInfo} />*/}
                {/*</Route>*/}
                {/*<Route path='/swap' render={({ match: { url } }) => (*/}
                {/*    <>*/}
                {/*        <Route path={`${url}/`} exact>*/}
                {/*            <Swap sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}*/}
                {/*                  sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}*/}
                {/*                  address={address} network={network} chainId={chainId} navBarHeight={navBarHeight} setOpenSliderModal={setOpenSliderModal} handleCloseSliderModal={handleCloseSliderModal} dispatchOnSlide={dispatchOnSlide} sliderInfo={sliderInfo} setSignUpPayload={setSignUpPayload} setSliderInfo={setSliderInfo} />*/}
                {/*        </Route>*/}
                {/*        <Route path={`${url}/records`} >*/}
                {/*            {!loggedIn ?*/}
                {/*                <Redirect to='/swap' />*/}
                {/*                : <SwapRecord sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}*/}
                {/*                              sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}*/}
                {/*                              address={address} network={network} chainId={chainId} navBarHeight={navBarHeight} setOpenSliderModal={setOpenSliderModal} handleCloseSliderModal={handleCloseSliderModal} dispatchOnSlide={dispatchOnSlide} sliderInfo={sliderInfo} setSignUpPayload={setSignUpPayload} setSliderInfo={setSliderInfo} />*/}
                {/*            }*/}
                {/*        </Route>*/}
                {/*    </>*/}
                {/*)} />*/}

                {/*<Route path='/layer3' render={({ match: { url } }) => (*/}
                {/*    <>*/}
                {/*        <Route path={`${url}/`} exact>*/}
                {/*            <Layer3 sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}*/}
                {/*                    sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}*/}
                {/*                    address={address} network={network} chainId={chainId} navBarHeight={navBarHeight} setOpenSliderModal={setOpenSliderModal} handleCloseSliderModal={handleCloseSliderModal} dispatchOnSlide={dispatchOnSlide} sliderInfo={sliderInfo} setSignUpPayload={setSignUpPayload} setSliderInfo={setSliderInfo} />*/}
                {/*        </Route>*/}

                {/*    </>*/}
                {/*)} />*/}



                {/*<Route path='/stakePool' >*/}
                {/*    <StakePool sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}*/}
                {/*               sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}*/}
                {/*               address={address} network={network} chainId={chainId} navBarHeight={navBarHeight} setOpenSliderModal={setOpenSliderModal} handleCloseSliderModal={handleCloseSliderModal} dispatchOnSlide={dispatchOnSlide} sliderInfo={sliderInfo} setSignUpPayload={setSignUpPayload} setSliderInfo={setSliderInfo} />*/}
                {/*</Route>*/}


                {/*<Route path='/earn' >*/}
                {/*    <Earn sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}*/}
                {/*          sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}*/}
                {/*          address={address} network={network} chainId={chainId} navBarHeight={navBarHeight} setOpenSliderModal={setOpenSliderModal} handleCloseSliderModal={handleCloseSliderModal} dispatchOnSlide={dispatchOnSlide} sliderInfo={sliderInfo} setSignUpPayload={setSignUpPayload} setSliderInfo={setSliderInfo} />*/}
                {/*</Route>*/}

                {/*<Route path='/collectReward' component={CollectReward} >*/}
                {/*    <CollectReward sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}*/}
                {/*                   sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}*/}
                {/*                   address={address} network={network} chainId={chainId} navBarHeight={navBarHeight} setOpenSliderModal={setOpenSliderModal} handleCloseSliderModal={handleCloseSliderModal} dispatchOnSlide={dispatchOnSlide} sliderInfo={sliderInfo} setSignUpPayload={setSignUpPayload} setSliderInfo={setSliderInfo} navBarHeight={navBarHeight} setOpenSliderModal={setOpenSliderModal} handleCloseSliderModal={handleCloseSliderModal} dispatchOnSlide={dispatchOnSlide} sliderInfo={sliderInfo} setSignUpPayload={setSignUpPayload} setSliderInfo={setSliderInfo} />*/}
                {/*</Route>*/}
                {/*<Route path='/ReferReward' component={ReferReward} >*/}
                {/*    <ReferReward sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}*/}
                {/*                 sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}*/}
                {/*                 address={address} network={network} chainId={chainId} navBarHeight={navBarHeight} setOpenSliderModal={setOpenSliderModal} handleCloseSliderModal={handleCloseSliderModal} dispatchOnSlide={dispatchOnSlide} sliderInfo={sliderInfo} setSignUpPayload={setSignUpPayload} setSliderInfo={setSliderInfo} navBarHeight={navBarHeight} setOpenSliderModal={setOpenSliderModal} handleCloseSliderModal={handleCloseSliderModal} dispatchOnSlide={dispatchOnSlide} sliderInfo={sliderInfo} setSignUpPayload={setSignUpPayload} setSliderInfo={setSliderInfo} />*/}
                {/*</Route>*/}
                {/*<Route path='/ReferCommision' component={ReferReward} >*/}
                {/*    <ReferCommision sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}*/}
                {/*                    sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}*/}
                {/*                    address={address} network={network} chainId={chainId} navBarHeight={navBarHeight} setOpenSliderModal={setOpenSliderModal} handleCloseSliderModal={handleCloseSliderModal} dispatchOnSlide={dispatchOnSlide} sliderInfo={sliderInfo} setSignUpPayload={setSignUpPayload} setSliderInfo={setSliderInfo} navBarHeight={navBarHeight} setOpenSliderModal={setOpenSliderModal} handleCloseSliderModal={handleCloseSliderModal} dispatchOnSlide={dispatchOnSlide} sliderInfo={sliderInfo} setSignUpPayload={setSignUpPayload} setSliderInfo={setSliderInfo} />*/}
                {/*</Route>*/}
                {/*<Route path='/comingSoon' component={ComingSoon}>*/}
                {/*    <ComingSoon sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}*/}
                {/*                sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}*/}
                {/*                address={address} network={network} chainId={chainId} navBarHeight={navBarHeight} setOpenSliderModal={setOpenSliderModal} handleCloseSliderModal={handleCloseSliderModal} dispatchOnSlide={dispatchOnSlide} sliderInfo={sliderInfo} setSignUpPayload={setSignUpPayload} setSliderInfo={setSliderInfo} navBarHeight={navBarHeight} setOpenSliderModal={setOpenSliderModal} handleCloseSliderModal={handleCloseSliderModal} dispatchOnSlide={dispatchOnSlide} sliderInfo={sliderInfo} setSignUpPayload={setSignUpPayload} setSliderInfo={setSliderInfo} />*/}
                {/*</Route>*/}
                {/*<Route path='/register' >*/}
                {/*    <Home sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}*/}
                {/*          sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}*/}
                {/*          address={address} network={network} chainId={chainId} code={code} navBarHeight={navBarHeight} setOpenSliderModal={setOpenSliderModal} handleCloseSliderModal={handleCloseSliderModal} dispatchOnSlide={dispatchOnSlide} sliderInfo={sliderInfo} setSignUpPayload={setSignUpPayload} setSliderInfo={setSliderInfo} />*/}
                {/*</Route>*/}

                {/*<Route path='/vote' render={({ match: { url } }) => (*/}
                {/*    <>*/}
                {/*        <Route path={`${url}/`} exact>*/}
                {/*            <Vote sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}*/}
                {/*                  sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}*/}
                {/*                  address={address} network={network} chainId={chainId} navBarHeight={navBarHeight} setOpenSliderModal={setOpenSliderModal} handleCloseSliderModal={handleCloseSliderModal} dispatchOnSlide={dispatchOnSlide} sliderInfo={sliderInfo} setSignUpPayload={setSignUpPayload} setSliderInfo={setSliderInfo} width={width} />*/}
                {/*        </Route>*/}
                {/*        <Route path={`${url}/records`} >*/}
                {/*            <VoteRecord sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}*/}
                {/*                        sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}*/}
                {/*                        address={address} network={network} chainId={chainId} navBarHeight={navBarHeight} setOpenSliderModal={setOpenSliderModal} handleCloseSliderModal={handleCloseSliderModal} dispatchOnSlide={dispatchOnSlide} sliderInfo={sliderInfo} setSignUpPayload={setSignUpPayload} setSliderInfo={setSliderInfo} />*/}
                {/*        </Route>*/}
                {/*    </>*/}
                {/*)} />*/}
                {/*<Route path='/ido' render={({ match: { url } }) => (*/}
                {/*    <>*/}
                {/*        <Route path={`${url}/`} exact>*/}
                {/*            <IDOHome sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}*/}
                {/*                     sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}*/}
                {/*                     address={address} network={network} chainId={chainId} navBarHeight={navBarHeight} setOpenSliderModal={setOpenSliderModal} handleCloseSliderModal={handleCloseSliderModal} dispatchOnSlide={dispatchOnSlide} sliderInfo={sliderInfo} setSignUpPayload={setSignUpPayload} setSliderInfo={setSliderInfo} width={width} />*/}
                {/*        </Route>*/}
                {/*        <Route path={`${url}/invite`} >*/}
                {/*            <IDOInvite sendBackButton1={sendBackButton1} sendBackButton1Disabled={sendBackButton1Disabled} button1={button1} button1Disabled={button1Disabled}*/}
                {/*                       sendBackButton2={sendBackButton2} sendBackButton2Disabled={sendBackButton2Disabled} button2={button2} button2Disabled={button2Disabled}*/}
                {/*                       address={address} network={network} chainId={chainId} code={code} navBarHeight={navBarHeight} setOpenSliderModal={setOpenSliderModal} handleCloseSliderModal={handleCloseSliderModal} dispatchOnSlide={dispatchOnSlide} sliderInfo={sliderInfo} setSignUpPayload={setSignUpPayload} setSliderInfo={setSliderInfo} width={width} height={height} registered={registered} loggedIn={loggedIn}*/}
                {/*                       loading={loading}*/}
                {/*            />*/}
                {/*        </Route>*/}
                {/*    </>*/}
                {/*)} />*/}
            </Switch>
            <SliderModal t={t} width={width} height={height} openSliderModal={openSliderModal} handleCloseSliderModal={handleCloseSliderModal} dispatchOnSlide={dispatchOnSlide} sliderInfo={sliderInfo} setSignUpPayload={setSignUpPayload} setSliderInfo={setSliderInfo} />
            <Footer width={width} />
        </Router>
    )
}

export default withTranslation()(App);
