import {walletService} from '../services/walletService';
import {
    ALREADY_REGISTERED,
    CLEAR_FEE,
    CLEAR_INFO,
    DEPOSIT,
    DEPOSIT_FAILED,
    DEPOSIT_HASH,
    DEPOSIT_RECEIPT,
    DEPOSIT_SUCCEED,
    GET_ALL_TOKEN_ICONS_SUCCEED,
    GET_ALL_TOKEN_STATUS,
    GET_ALL_TOKEN_STATUS_SUCCEED,
    GET_AMPL_REWARDS_FAILED,
    GET_AMPL_REWARDS_SUCCEED,
    GET_L1_CAPITAL_FAILED,
    GET_L1_CAPITAL_SUCCEED,
    GET_TRANSACTION_RECORDS_FAILED,
    GET_TRANSACTION_RECORDS_SUCCEED,
    GET_TRANSFER_FEE,
    GET_TRANSFER_FEE_FAILED,
    GET_TRANSFER_FEE_SUCCESS,
    GET_USER_CAPITAL,
    GET_USER_CAPITAL_FAILED,
    GET_USER_CAPITAL_SUCCEED,
    GET_WITHDRAW_FEE,
    GET_WITHDRAW_FEE_FAILED,
    GET_WITHDRAW_FEE_SUCCESS,
    IN_REBASE,
    NOT_QUALIFIED,
    REGISTER_AMPL_REWARDS,
    REGISTER_AMPL_REWARDS_FAILED,
    REGISTER_AMPL_REWARDS_SUCCEED,
    TRANSFER,
    TRANSFER_FAILED,
    TRANSFER_SUCCEED,
    WALLET_SIGNING,
    WALLET_SIGNING_CANCELLED,
    WITHDRAW,
    WITHDRAW_FAILED,
    WITHDRAW_SUCCEED,
    ADD_TOKEN_FAILED,
    ADD_TOKEN_SUCCEED,
    BUY_IDO,
    BUY_IDO_SUCCEED,
    BUY_IDO_FAILED,
    GET_IDO_INFO,
    GET_IDO_INFO_SUCCEED,
    GET_IDO_INFO_FAILED,
    CLEAR_IDO_INFO,
    GET_IDO_INVESTMENT_INFO,
    GET_IDO_INVESTMENT_INFO_FAILED,
    GET_IDO_INVESTMENT_INFO_SUCCEED,
    GET_IDO_MIGRATION_INFO,
    GET_IDO_MIGRATION_INFO_FAILED,
    GET_IDO_MIGRATION_INFO_SUCCEED,
    MIGRATE_IDO,
    MIGRATE_IDO_FAILED,
    MIGRATE_IDO_SUCCEED,
    MIGRATE_SIGNING_CANCELLED
} from '../constants';
import {alertActions} from './alertActions';
import Web3 from 'web3'
import * as Contract from "../../config/Contract.json";
import {getChain, isTest} from "../../utils/Common";
import {BigNumber} from "bignumber.js";
import {inviteActions} from "./inviteActions";
import {authActions} from "./authActions";

let web3 = new Web3(window.ethereum)

export const walletActions = {
    getUserCapital,
    withdraw,
    transfer,
    getAllTokenStatus,
    deposit,
    getL1Capital,
    getTransactionRecords,
    getAmplRewards,
    registerAmplRewards,
    getFee,
    walletSigning,
    walletSigningCancelled,
    clearInfo,
    clearFee,
    getTransferFee,
    addToken,
    buyIDO,
    getIDOInfo,
    clearIDOInfo,
    getInvestmentInfo,
    getMigrationInfo,
    migrateIDO,
    migrateSigningCancelled
};

function clearInfo() {
    return dispatch => {
        dispatch(clearInfo())
    }
    function clearInfo() { return { type: CLEAR_INFO } }
}

function walletSigning() {
    return dispatch => {
        dispatch(walletSigning(true))
    }
    function walletSigning(loading) { return { type: WALLET_SIGNING, loading } }
}

function walletSigningCancelled(err) {
    return dispatch => {
        dispatch(walletSigningCancelled(false))
    }
    function walletSigningCancelled(loading) { return { type: WALLET_SIGNING_CANCELLED, loading, message: 'actionCancelled' } }
}

function migrateSigningCancelled(err) {
    return dispatch => {
        dispatch(migrateSigningCancelled(false))
    }
    function migrateSigningCancelled(loading) { return { type: MIGRATE_SIGNING_CANCELLED, loading, message: 'actionCancelled' } }
}

function getUserCapital(token) {
    return dispatch => {
        dispatch(request());
        walletService.getUserCapital(token)
            .then(
                res => {
                    dispatch(success(res.data));
                },
                error => {
                    if (error === 'This username has been used by another account.') {
                        dispatch('add succeed!');
                    } else {
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                    }
                }
            );
    };

    function request() { return { type: GET_USER_CAPITAL } }
    function success(data) { return { type: GET_USER_CAPITAL_SUCCEED, data } }
    function failure(error) { return { type: GET_USER_CAPITAL_FAILED, error } }
}

function withdraw(payload) {
    return dispatch => {
        dispatch(request());
        walletService.withdraw(payload)
            .then(
                res => {
                    dispatch(success('withdrawSucceed'));
                },
                error => {
                    console.log('withdraw error: ', error)
                    let toast = ''
                    if (error.message === 'Service unavailable') {
                        toast = 'withdrawNotAvailable'
                    } else if (error.status === 503) {
                        toast = 'intercepted'
                    } else if (error.status === 409) {
                        toast = 'airdropping'
                    } else if (error.message === 'TooFrequentError') {
                        toast = 'tooFrequent'
                    } else if (error.message === 'LessThanMinimumAmount') {
                        toast = 'lessThanMin'
                    } else if (error.message === 'Over limit') {
                        toast = 'overLimit'
                    } else if (error.message === 'Need deposit before withdraw') {
                        console.log(error.message === 'Need deposit before withdraw')
                        toast = 'noDepositBeforeWithdraw'
                    } else if (error.message === 'Need deposit before transfer') {
                        toast = 'noDepositBeforeTransfer'
                    } else if (error.message === 'Insufficient fund.') {
                        toast = 'insufficient_fund'
                    } else if (error.message === 'Over daily money out limit' || error.data === 'Over daily money out limit') {
                        toast = 'overDailyMoneyOutLimit'
                    } else if (error.message === 'Withdraw is ban.' || error.data === 'Withdraw is ban.') {
                        toast = 'moneyOutClosed'
                    } else {
                        toast = 'unCaught'
                    }
                    dispatch(failure(toast));
                    dispatch(alertActions.error(toast));
                }
            );
    };

    function request() { return { type: WITHDRAW } }
    function success(message) { return { type: WITHDRAW_SUCCEED, message } }
    function failure(error) { return { type: WITHDRAW_FAILED, error } }
}

function transfer(payload) {
    return dispatch => {
        dispatch(request());
        walletService.transfer(payload)
            .then(
                res => {
                    dispatch(success('transferSucceed'));
                },
                error => {
                    let toast = ''
                    if (error.data === 'Service unavailable') {
                        toast = 'transferNotAvailable'
                    } else if (error.status === 503) {
                        toast = 'intercepted'
                    } else if (error.status === 409) {
                        toast = 'airdropping'
                    } else if (error.data === 'Invalid receiver.') {
                        toast = 'invalidReceiver'
                    } else if (error.data === 'Need deposit before transfer') {
                        toast = 'needDepositBeforeTransfer'
                    } else if (error.data === 'Over limit') {
                        toast = 'overLimit'
                    } else if (error.data === 'TooFrequentError') {
                        toast = 'tooFrequentError'
                    } else if (error.message === 'Over daily money out limit' || error.data === 'Over daily money out limit') {
                        toast = 'overDailyMoneyOutLimit'
                    } else if (error.message === 'Transfer is ban.' || error.data === 'Transfer is ban.') {
                        toast = 'moneyOutClosed'
                    } else {
                        toast = 'unCaught'
                    }
                    dispatch(failure(toast));
                    dispatch(alertActions.error(toast));
                }
            );
    };

    function request() { return { type: TRANSFER } }
    function success(message) { return { type: TRANSFER_SUCCEED, message } }
    function failure(error) { return { type: TRANSFER_FAILED, error } }
}

function deposit(payload) {
    return dispatch => {
        dispatch(request());
        let transactionParameters = {}
        let suffix = isTest(payload.network, payload.chainId) ? '_test' : ''
        let chain = getChain(payload.network, payload.chainId) + suffix
        let inRebase = false
        try {
            /**
             * Production check
             */
            // if (chain.includes('_test')) {
            //     dispatch(failure('wrongNetwork'))
            // } else {
            if ((payload.coin === 'ETH' && chain.includes('ETH')) ||
                (payload.coin === 'HT' && chain.includes('HECO')) ||
                (payload.coin === 'BNB' && chain.includes('BSC')) ||
                (payload.coin === 'HOO' && chain.includes('HSC'))
            ) {               transactionParameters = {
                    // nonce: web3.utils.toHex(nonce), // ignored by MetaMask
                    // gasPrice: gasPrice, // customizable by user during MetaMask confirmation.
                    // gas: web3.utils.toHex(21000), // customizable by user during MetaMask confirmation.
                    to: Contract.default[chain].dacb.address, // Required except during contract publications.
                    from: payload.l2Address, // must match user's active address.
                    value: web3.utils.toWei(payload.amount, 'ether'), // Only required to send ether to the recipient from the initiating external account.
                    // chainId: chainId, // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
                };
            } else {
                let contractInstance
                if (payload.coin === 'SATO') {
                    contractInstance = new web3.eth.Contract(Contract.default[chain].coins[payload.coin].abi, Contract.default[chain].coins[payload.coin].address);
                    contractInstance.methods.tokenPaused().call({}, function (err, res) {
                        console.log('SATO transaction paused: ', res)
                        if (err) {
                            console.log('get rebase status failed: ', err)
                        }
                        inRebase = res
                    })
                } else {
                    contractInstance = new web3.eth.Contract(Contract.transferErc20.abi, Contract.default[chain].coins[payload.coin].address);
                }
                BigNumber.config({ DECIMAL_PLACES: 8 })
                let amount = new BigNumber(parseFloat(payload.amount) * Math.pow(10, Contract.default[chain].coins[payload.coin].decimals))
                console.log('payload.amount: ', amount.toFixed())
                // let amount = parseFloat(payload.amount) * Math.pow(10, Contract.default[chain].coins[payload.coin].decimals)
                let contractData = contractInstance.methods.transfer(Contract.default[chain].dacb.address, web3.utils.toBN(amount.toFixed())).encodeABI()
                // // calculate ERC20 token amount
                // // call transfer function
                // contract.transfer(Contract[chain + '_test].dacb.address, value, (error, txHash) => {
                //     // it returns tx hash because sending tx
                //     console.log(txHash);
                // });

                transactionParameters = {
                    // nonce: web3.utils.toHex(nonce), // ignored by MetaMask
                    // gasPrice: gasPrice, // customizable by user during MetaMask confirmation.
                    // gas: web3.utils.toHex(21000), // customizable by user during MetaMask confirmation.
                    to: Contract.default[chain].coins[payload.coin].address, // Required except during contract publications.
                    from: payload.l2Address, // must match user's active address.
                    data: contractData,
                    // value: web3.utils.toWei(0, 'ether'), // Only required to send ether to the recipient from the initiating external account.
                    // chainId: chainId, // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
                };
            }
            if (payload.coin === 'SATO' && inRebase) {
                dispatch(failure('inRebase'))
            } else {
                web3.eth.sendTransaction(transactionParameters)
                    .on('transactionHash', function (hash) {
                        dispatch(onHash(hash))
                    })
                    .on('receipt', function (receipt) {
                        dispatch(onReceipt(receipt))
                    })
                    .on('confirmation', function (confirmationNumber, receipt) {
                        if (confirmationNumber === 5) {
                            dispatch(success(confirmationNumber, receipt))
                        }
                    })
                    .on('error', function (error) {
                        console.log('rejected: ', error)
                        if (error.code === 4001) {
                            dispatch(failure('actionCancelled'))
                        } else {
                            dispatch(failure(error.message))
                        }
                    });
            }
            // }

        } catch (error) {
            if (error.toString().includes('Value must be an integer, hex string, BN or BigNumber instance. Note, decimals are not supported. Given value: "undefined"')) {
                dispatch(failure('notSent'))
            } else {
                dispatch(failure('unCaught'))
            }
        }

    };

    function request(payload) { return { type: DEPOSIT, payload } }
    function onHash(hash) { return { type: DEPOSIT_HASH, hash } }
    function onReceipt(receipt) { return { type: DEPOSIT_RECEIPT, receipt } }
    function success(confirmationNumber, receipt) { return { type: DEPOSIT_SUCCEED, confirmationNumber, receipt } }
    function failure(error) { return { type: DEPOSIT_FAILED, error } }
    function inRebase(message) { return { type: IN_REBASE, message } }
}

function getAllTokenStatus(token) {
    return dispatch => {
        dispatch(request());
        walletService.getAllTokenStatus(token)
            .then(
                res => {
                    dispatch(success(res.data));
                    let iconMaps = {}
                    for (let i = 0; i < res.data.length; i++) {
                        let name = res.data[i].token
                        let logo = {}
                        logo.bigLogoUrl = res.data[i].bigLogoUrl
                        logo.smallLogoUrl = res.data[i].smallLogoUrl
                        iconMaps[name] = logo
                    }
                    dispatch(successIcon(iconMaps));
                },
                error => {
                    if (error === 'This username has been used by another account.') {
                        dispatch('add succeed!');
                    } else {
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                    }
                }
            );
    };

    function request() { return { type: GET_ALL_TOKEN_STATUS } }
    function successIcon(iconMaps) { return { type: GET_ALL_TOKEN_ICONS_SUCCEED, iconMaps } }
    function success(data) { return { type: GET_ALL_TOKEN_STATUS_SUCCEED, data } }
    function failure(message) { return { type: GET_ALL_TOKEN_STATUS_SUCCEED, message } }
}

function getL1Capital(address, network, chainId) {
    return dispatch => {
        walletService.getL1Capital(address, network, chainId)
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }


    function success(data) { return { type: GET_L1_CAPITAL_SUCCEED, data } }
    function failure(message) { return { type: GET_L1_CAPITAL_FAILED, message } }
}

function getTransactionRecords(token) {
    return dispatch => {
        walletService.getTransactionRecords(token)
            .then(
                res => {
                    dispatch(success(res.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }


    function success(data) { return { type: GET_TRANSACTION_RECORDS_SUCCEED, data } }
    function failure(error) { return { type: GET_TRANSACTION_RECORDS_FAILED, error } }
}

function getAmplRewards(token) {
    return dispatch => {
        walletService.getAmplRewards(token)
            .then(
                res => {
                    dispatch(success(res.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }


    function success(data) { return { type: GET_AMPL_REWARDS_SUCCEED, data } }
    function failure(error) { return { type: GET_AMPL_REWARDS_FAILED, error } }
}

function registerAmplRewards(token) {
    return dispatch => {
        dispatch(request());
        walletService.registerAmplRewards(token)
            .then(
                res => {
                    dispatch(success('RegisterSuccess'));
                },
                error => {
                    if (error.data === "NotEligibleError") {
                        dispatch(alreadyRegistered(error.data));
                    } else if (error.data === 'AlreadyRegisterError') {
                        dispatch(notQualified(error.data));
                    } else {
                        dispatch(failure('NotAvailable'));
                    }

                }
            );
    }

    function request() { return { type: REGISTER_AMPL_REWARDS } }
    function success(message) { return { type: REGISTER_AMPL_REWARDS_SUCCEED, message } }
    function alreadyRegistered(message) { return { type: ALREADY_REGISTERED, message } }
    function notQualified(message) { return { type: NOT_QUALIFIED, message } }
    function failure(message) { return { type: REGISTER_AMPL_REWARDS_FAILED, message } }
}

function getFee(payload) {

    return dispatch => {
        dispatch(request());
        walletService.getFee(payload)
            .then(
                res => {
                    dispatch(success(res.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request() { return { type: GET_WITHDRAW_FEE } }
    function success(data) { return { type: GET_WITHDRAW_FEE_SUCCESS, data } }
    function failure(message) { return { type: GET_WITHDRAW_FEE_FAILED, message } }
}

function clearFee() {
    return dispatch => {
        dispatch(clearFee())
    }
    function clearFee() { return { type: CLEAR_FEE } }
}


function getTransferFee(token) {
    return dispatch => {
        dispatch(request());
        walletService.getTransferFee(token)
            .then(

                res => {
                    dispatch(success(res.data));

                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));

                }
            );
    }

    function request() { return { type: GET_TRANSFER_FEE } }
    function success(data) { return { type: GET_TRANSFER_FEE_SUCCESS, data } }
    function failure(message) { return { type: GET_TRANSFER_FEE_FAILED, message } }
}

function addToken(coin, network, chainId) {
    return dispatch => {
        walletService.addToken(coin, network, chainId)
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }


    function success(data) { return { type: ADD_TOKEN_SUCCEED, data } }
    function failure(message) { return { type: ADD_TOKEN_FAILED, message } }
}

function buyIDO(payload, token) {
    return dispatch => {
        dispatch(request());
        walletService.buyIDO(payload, token)
            .then(
                res => {
                    dispatch(success('buyIDOSucceed'));
                    dispatch(inviteActions.getInviteCode(token))
                    dispatch(authActions.getUserInfo(token))
                },
                error => {
                    let toast = ''
                    console.log('buy ido failed error: ', error)
                    if (error.data === 'Service unavailable') {
                        toast = 'transferNotAvailable'
                    } else if (error.status === 503) {
                        toast = 'intercepted'
                    } else if (error.status === 409) {
                        toast = 'airdropping'
                    } else if (error.data === 'Invalid receiver.') {
                        toast = 'invalidReceiver'
                    } else if (error.data === 'Over limit') {
                        toast = 'overLimit'
                    } else if (error.data === 'TooFrequentError') {
                        toast = 'tooFrequentError'
                    } else if (error.message === 'Purchase dbo failed.') {
                        toast = 'buyIDOFailed'
                    }
                    else {
                        toast = 'unCaught'
                    }
                    dispatch(failure(toast));
                    dispatch(alertActions.error(toast));
                }
            );
    };

    function request() { return { type: BUY_IDO } }
    function success(message) { return { type: BUY_IDO_SUCCEED, message } }
    function failure(error) { return { type: BUY_IDO_FAILED, error } }
}

function getIDOInfo(token) {
    return dispatch => {
        dispatch(request());
        walletService.getIDOInfo(token)
            .then(
                res => {
                    dispatch(success(res.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request() { return { type: GET_IDO_INFO } }
    function success(data) { return { type: GET_IDO_INFO_SUCCEED, data } }
    function failure(error) { return { type: GET_IDO_INFO_FAILED, error } }
}

function clearIDOInfo() {
    return dispatch => {
        dispatch(clearIDOInfo())
    }
    function clearIDOInfo() { return { type: CLEAR_IDO_INFO } }
}

function getInvestmentInfo(token) {
    return dispatch => {
        dispatch(request());
        walletService.getInvestmentInfo(token)
            .then(
                res => {
                    dispatch(success(res.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request() { return { type: GET_IDO_INVESTMENT_INFO } }
    function success(data) { return { type: GET_IDO_INVESTMENT_INFO_SUCCEED, data } }
    function failure(error) { return { type:GET_IDO_INVESTMENT_INFO_FAILED, error } }
}

function getMigrationInfo(token) {
    return dispatch => {
        dispatch(request());
        walletService.getMigrationInfo(token)
            .then(
                res => {
                    dispatch(success(res.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    }

    function request() { return { type: GET_IDO_MIGRATION_INFO } }
    function success(data) { return { type: GET_IDO_MIGRATION_INFO_SUCCEED, data } }
    function failure(error) { return { type:GET_IDO_MIGRATION_INFO_FAILED, error } }
}

function migrateIDO(payload, token) {
    return dispatch => {
        dispatch(request());
        walletService.migrateIDO(payload, token)
            .then(
                res => {
                    dispatch(success('migrateIDOSucceed'));
                },
                error => {
                    let toast = ''
                    console.log('Ido migrate failed error: ', error)
                    if (error.data === 'Service unavailable') {
                        toast = 'transferNotAvailable'
                    } else if (error.status === 503) {
                        toast = 'intercepted'
                    } else if (error.status === 409) {
                        toast = 'airdropping'
                    } else if (error.data === 'Invalid receiver.') {
                        toast = 'invalidReceiver'
                    } else if (error.data === 'Over limit') {
                        toast = 'overLimit'
                    } else if (error.data === 'TooFrequentError') {
                        toast = 'tooFrequentError'
                    } else if (error.message === 'Sap migration failed.') {
                        toast = 'migrateIDOFailed'
                    }
                    else {
                        toast = 'unCaught'
                    }
                    dispatch(failure(toast));
                    dispatch(alertActions.error(toast));
                }
            );
    };

    function request() { return { type: MIGRATE_IDO } }
    function success(message) { return { type: MIGRATE_IDO_SUCCEED, message } }
    function failure(error) { return { type: MIGRATE_IDO_FAILED, error } }
}