export const BASE_API_URL = 'https://swapallapi.com:20000/api/defi/yield-farming';
// export const BASE_API_URL = 'http://52.204.184.252:8080/api/defi/yield-farming';

export const REFERRAL_LINK = 'https://swapalltrade.com/register?ref='

export const CHECK_USER = BASE_API_URL + '/user/sign-up/username/checker?username=';
export const LOGIN = BASE_API_URL + '/user/metamask/login';
export const SIGN_UP = BASE_API_URL + '/user/metamask/sign-up';
export const ADD_LIQUIDITY = BASE_API_URL + '/financial/liquidity-pool/liquidity/add';
export const REMOVE_LIQUIDITY = BASE_API_URL + '/financial/liquidity-pool/liquidity/remove';
export const GET_USER_CAPITAL = BASE_API_URL + '/user/capital';

export const WITHDRAW = BASE_API_URL + '/financial/contractWithdraw';
export const TRANSFER = BASE_API_URL + '/financial/transfer';

export const GET_ALL_LIQUIDITY_POOL = BASE_API_URL + '/financial/liquidity-pool/layer2'
export const GET_MY_LIQUIDITY_POOL = BASE_API_URL + '/user/assets/detail/liquidity-pool'
export const GET_LIQUIDITY_TRANSACTIONS = BASE_API_URL + '/transaction/liquidity-transaction/find/type'
export const GET_LIQUIDITY_COLLECT_TRANSACTIONS = BASE_API_URL + '/transaction/find?type=7'
export const GET_ADD_LIQUIDITY_QUOTE = BASE_API_URL + '/financial/liquidity-pool/liquidity/add/quote'
export const GET_REMOVE_LIQUIDITY_QUOTE = BASE_API_URL + '/financial/liquidity-pool/liquidity/layer2/remove/quote'
export const HARVEST_LIQUIDITY_REWARD = BASE_API_URL + '/financial/liquidity-pool/harvest'
export const GET_LIQUIDITY_POOL_APY = BASE_API_URL + '/financial/apy?poolType=LIQUIDITY'
export const GET_CURRENT_TIME = BASE_API_URL + '/util/time'
export const GET_MY_LOCKER = BASE_API_URL + '/financial/lp-token/lockers/all'
export const LOCK_LP = BASE_API_URL + '/financial/lp-token/locker/lock'
export const UNLOCK_LP = BASE_API_URL + '/financial/lp-token/locker/unlock'
export const GET_UNLOCKABLE = BASE_API_URL + '/financial/lp-token/lockers/unlockable'
export const UNLOCK_ALL_LP = BASE_API_URL + '/financial/lp-token/locker/unlock/all'
export const GET_POOL_BANNER = BASE_API_URL + '/bannerInfo/banner/'

export const GET_ALL_TOKENS_STATUS = BASE_API_URL + '/financial/tokens'
export const GET_TRANSACTION_RECORDS = BASE_API_URL + '/transaction/find?'
export const GET_AMPL_REWARDS = BASE_API_URL + '/financial/ampl-holder-rewards'
export const REGISTER_AMPL_REWARDS = BASE_API_URL + '/financial/ampl-holder-rewards/register'
export const GET_FEE = BASE_API_URL + '/financial/getFeeWithActionAndAmount/'
export const GET_TRANSFER_FEE = BASE_API_URL + '/user/transfer-info'
export const SWAP_QUOTE = BASE_API_URL + '/financial/liquidity-pool/quote/swap'
export const SWAP = BASE_API_URL + '/financial/liquidity-pool/swap'
export const GET_SWAP_TRANSACTIONS = BASE_API_URL + '/transaction/find?type=5'

export const GET_ALL_STAKE_POOL = BASE_API_URL + '/financial/pool/layer2'
export const GET_MY_STAKE_POOL = BASE_API_URL + '/user/layer2/staking-info'
export const GET_STAKE_TRANSACTIONS = BASE_API_URL + '/transaction/find?'
export const GET_STAKE_COLLECT_TRANSACTIONS = BASE_API_URL + '/transaction/find?type=6'
export const HARVEST_STAKE_REWARD = BASE_API_URL + '/financial/pool/harvest'
export const STAKE = BASE_API_URL + '/financial/stake'
export const UNSTAKE = BASE_API_URL + '/financial/unstake'
export const GET_REFERRAL_CODE = BASE_API_URL + '/user/referral-code'
export const GET_REFERRAL_INFO = BASE_API_URL + '/user/referral/invited'
export const GET_REFERRAL_COMMISION = BASE_API_URL + '/transaction/referral-bonus/find'
export const HARVEST_ALL = BASE_API_URL + '/financial/referral-bonus/harvest-all'
export const STAKE_LOCK = BASE_API_URL + '/financial/coin-staked/locker/lock'
export const GET_MY_STAKE_LOCKER = BASE_API_URL + '/financial/coin-staked/lockers/all'
export const STAKE_UNLOCK = BASE_API_URL + '/financial/coin-staked/locker/unlock'
export const GET_STAKE_UNLOCKABLE = BASE_API_URL + '/financial/coin-staked/lockers/unlockable'
export const UNLOCK_ALL_STAKE = BASE_API_URL + '/financial/coin-staked/locker/unlock/all'

export const GET_ALL_EARN_POOL = BASE_API_URL + '/financial/swapall-earn/info/all'
export const GET_MY_EARN_POOL = BASE_API_URL + '/user/layer2/swapall-earn/my-staking'
export const GET_EARN_TRANSACTIONS = BASE_API_URL + '/transaction/swapall-earn-transaction/find/all'
export const HARVEST_EARN_REWARD = BASE_API_URL + '/financial/swapall-earn/reward/harvest'
export const EARN_STAKE = BASE_API_URL + '/financial/swapall-earn/stake'
export const EARN_UNSTAKE = BASE_API_URL + '/financial/swapall-earn/unstake'
export const GET_ETH_PRICE = BASE_API_URL + '/financial/price/real-time/eth'
export const GET_USERINFO = BASE_API_URL + '/user/info'

export const GET_RELEASE_DETAIL = BASE_API_URL + '/config/release/detail'

export const GET_PROPOSALS = BASE_API_URL + '/proposal/list/'
export const GET_VOTE_HISTORY = BASE_API_URL + '/proposal/history'
export const VOTE = BASE_API_URL + '/proposal/vote'

export const MAX_PRICE_IMPACT = BASE_API_URL + '/config/swap/max-price-impact'

export const GET_LAYER3_REWARD = BASE_API_URL + '/user/layer2/beta-testing-info'

export const GET_INVITE_INFO = BASE_API_URL + '/ido-referral/getIDOReferralInfo'
export const GET_INVITE_CODE = BASE_API_URL + '/ido-referral/getIDOReferralCode'
export const GET_INVITEE = BASE_API_URL + '/ido-referral/getL1InvitedList'
export const REGISTER_INVITER = BASE_API_URL + '/ido-referral/bind?code='

export const BUY_IDO = BASE_API_URL + '/ido/purchase'
export const GET_IDO_INFO = BASE_API_URL + '/ido/stat'

export const GET_IDO_INVESTMENT_INFO = BASE_API_URL + '/ido/investment/detail'
export const GET_IDO_MIGRATION_INFO = BASE_API_URL + '/ido/migration/detail'
export const MIGRATE_IDO = BASE_API_URL + '/ido/migration'

export const GET_RANK_DETAILS = BASE_API_URL + '/ido/ranking/detail'
export const GET_RANK_BONUS_TRANSACTIONS = BASE_API_URL + '/ido/ranking/bonus-transactions'
export const GET_RANK_SWITCH = BASE_API_URL + '/config/ido/ranking'
