import {withTranslation} from "react-i18next";
import {Backdrop, Modal, makeStyles, Typography, Button} from "@material-ui/core";
import closeIcon from "../../../images/closeIcon.png"
import React from "react";
import {useHistory} from "react-router-dom";
import i18n from "../../../i18n";

const ReferralReminderModal = (props) => {
    const {
        onCancel: handleClose,
        modalVisible,
        t,
        width,

    } = props;

    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow:'scroll',
        },
    }));
    const classes = useStyles();
    const history = useHistory()

    const ReferralReminderModalBody = (
            <div style={{ width: 354, borderRadius: 20, backgroundColor: "#F5F5F5"}}>
                <div style={{margin:"30px 20px"}}>
                    <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                        <Typography style={{color: "#251CFF", fontFamily: "PingFang SC", fontSize: 20, fontWeight: 600}}>
                            {t('bindReferralCode')}
                        </Typography>
                        <img src={closeIcon} style={{height:18, width:18, cursor:"pointER"}} onClick={()=>{handleClose()}}/>
                    </div>
                    <Typography style={{marginTop:42, color: "#1C2237", fontFamily: "PingFang SC", fontSize: 14, fontWeight: 600}}>
                        {t('bindReferralCodeSubtitle')}
                    </Typography>
                    <Button
                        style={{marginTop:127, width:"100%", borderRadius: 12, backgroundColor: "#271CFF", height: 53, color: "#FFFFFF",
                        fontFamily: "PingFang SC",
                        fontSize: 18,
                        fontWeight: 600,
                        textTransform:"none"}}
                        onClick={()=>{history.push('/ido/invite')}}
                    >
                        {t('bindACode')}
                    </Button>
                </div>
            </div>
    )

    return (
        <>
            <Modal
                disableEnforceFocus
                disableAutoFocus
                aria-labelledby="server-modal-title"
                aria-describedby="server-modal-description"
                open={modalVisible}
                onClose={handleClose}
                className={classes.modal}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    className:
                        'modalBackground'
                }}
                closeAfterTransition
            >
                {ReferralReminderModalBody}
            </Modal>
        </>
    )
}

export default withTranslation()(ReferralReminderModal);
