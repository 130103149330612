import {
    GET_REFERRAL_CODE,
    GET_REFERRAL_CODE_SUCCEED,
    GET_REFERRAL_CODE_FAILED,
    GET_REFERRAL_INFO,
    GET_REFERRAL_INFO_SUCCEED,
    GET_REFERRAL_INFO_FAILED,
    GET_REFERRAL_COMMISION,
    GET_REFERRAL_COMMISION_SUCCEED,
    GET_REFERRAL_COMMISION_FAILED,
    HARVEST_ALL,
    HARVEST_ALL_SUCCEED,
    HARVEST_ALL_FAILED
   
} from "../constants";

export function refer (state = {
    referralCode:[],
    referralInfo:[],
    referralCommision:[],
    harvestinfo:[]
}, action){
    switch (action.type) {
            case GET_REFERRAL_CODE:
            return{
                ...state,
                loading: true
            }
        case GET_REFERRAL_CODE_SUCCEED:
            return{
                ...state,
                referralCode: action.res.data,
                loading: false
            }
        case GET_REFERRAL_CODE_FAILED:
            return{
                ...state,
                message: action.error,
                loading: false
            }
            case GET_REFERRAL_INFO:
                return{
                    ...state,
                    loading: true
                }
            case GET_REFERRAL_INFO_SUCCEED:
                return{
                    ...state,
                    referralInfo: action.res.data,
                    loading: false
                }
            case GET_REFERRAL_INFO_FAILED:
                return{
                    ...state,
                    message: action.error,
                    loading: false
                }
                case GET_REFERRAL_COMMISION:
                    return{
                        ...state,
                        loading: true
                    }
                case GET_REFERRAL_COMMISION_SUCCEED:
                    return{
                        ...state,
                        referralCommision: action.res.data,
                        loading: false
                    }
                case GET_REFERRAL_COMMISION_FAILED:
                    return{
                        ...state,
                        message: action.error,
                        loading: false
                    }
                    case HARVEST_ALL:
                        return{
                            ...state,
                            loading: true
                        }
                    case HARVEST_ALL_SUCCEED:
                        return{
                            ...state,
                            // harvestinfo: action.res.message,
                            // loading: false
                            harvestMessage: action.res.data,
                            harvestTitle: action.res.message,
                            harvestLoading: false,
                            harvestSucceed: true
                        }
                    case HARVEST_ALL_FAILED:
                        return{
                            ...state,
                            // message: action.error,
                            // loading: false
                            harvestMessage: action.error.data,
                            harvestTitle: action.error.message,
                            harvestLoading: false,
                            harvestFailed: true,
                        }
        default:
            return state;
    }
    return state;
};
