import React, { useEffect, useRef, useState } from 'react';
import './Swap.css';
import { withTranslation } from 'react-i18next';
import { Avatar, Button, IconButton, makeStyles, Modal, Snackbar, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { isValidAddress } from 'ethereumjs-util'
import useWindowDimensions from "../../utils/WindowDimensions";
import { onClickConnect, onClickInstall, swap, unlock } from '../../utils/Sign'
import { roundingDown } from "../../utils/RoundingDown";
import { getIcons } from "../../utils/Common";
import { walletActions } from "../../redux/actions/walletActions";
import { swapActions } from "../../redux/actions/swapActions";
import { Link } from "react-router-dom";
import CustomTextField from '../CommonElements/CustomTextField'
import { poolActions } from "../../redux/actions/poolActions";
import CloseIcon from '@material-ui/icons/Close';
import { Helmet } from "react-helmet";
import CustomCoinSelector from '../CommonElements/CustomCoinSelector'
import lightIcon from "../../images/swap/lightIcon.png";
import swapCoinIcon from "../../images/swap/swapCoinIcon.png";
import swapSideBanner from "../../images/swap/swapSideBanner.png";
import NewCustomTextField from '../CommonElements/NewCustomTextField'
import { isMobile } from 'react-device-detect';
import CustomButton from '../CommonElements/CustomButton';
import NewCustomCoinSelector from '../CommonElements/NewCustomCoinSelector'
const exchangeIcon = 'https://cdn.swapallapp.com/l2/images/exchangeIcon.png'
const confirmIcon = 'https://cdn.swapallapp.com/l2/images/confirmIcon.png'


const Web3 = require("web3");


function Swap({ t, navBarHeight, address, setOpenSliderModal, handleCloseSliderModal, setSignUpPayload, sliderInfo, network, chainId, code,
    sendBackButton1, sendBackButton1Disabled, button1, button1Disabled,
    sendBackButton2, sendBackButton2Disabled, button2, button2Disabled
}) {
    const { height, width } = useWindowDimensions();
    const dispatch = useDispatch();
    const { maxPriceImpact, swapQuote, swapQuoteLoading, swapLoading, swapFailed, swapSucceed, swapSigning, swapMessage, swapPrice } = useSelector(state => state.swap)
    const { liquidityPools } = useSelector(state => state.pool)
    const { token, registered, loggedIn, loggingIn, loading } = useSelector(state => state.auth)
    const { tokenList, tokenIcons, userCapitals, withdrawFinished, withdrawSucceed, withdrawMsg, withdrawFeeObj, walletSigning } = useSelector(state => state.wallet)
    const useStyles = makeStyles((theme) => ({
        root: {
            ...theme.typography.button,
            backgroundColor: "#010746",
            padding: theme.spacing(1),
            flexGrow: 1,
            height: '100%',
            textAlign: 'center',
            justifyContent: 'center',
        },
        btn: {
            backgroundColor: '#83ACF4',
            color: '#010846',
            borderColor: 'transparent',
            borderRadius: 25,
            height: 45,
            fontWeight: 600,
            minWidth: 100
        },
        btn_disabled: {
            backgroundColor: '#83ACF4',
            color: '#010846',
            borderColor: 'transparent',
            borderRadius: 25,
            height: 45,
            fontWeight: 600,
            opacity: 0.2
        },
        paper: {
            position: 'absolute',
            width: 400,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        defaultFont: {
            fontWeight: '400', color: '#363636', fontFamily: 'Avenir', textTransform: 'none'
        },
    }));
    const classes = useStyles();
    const [initialPrice, setInitialPrice] = useState(true);
    const [amount1, setAmount1] = useState("");
    const [amount2, setAmount2] = useState("");
    const amount1All = useRef(false);
    const [inputFocused, setInputFocused] = useState(false)
    const [pairOne, setPairOne] = useState('');
    const [pairOneList, setPairOneList] = useState([]);
    const [pairTwo, setPairTwo] = useState('');
    const [pairTwoList, setPairTwoList] = useState([]);
    const [allCoinList, setAllCoinList] = useState([{ label: 'USDT', value: 'USDT' }, { label: 'SAP', value: 'SAP' }]);
    const coinFree1 = userCapitals.find(item => item.token === pairOne);
    const coinFree2 = userCapitals.find(item => item.token === pairTwo);
    const [submitDisable, setSubmitDisable] = useState(true);
    const [confirmViewVisible, setConfirmViewVisible] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [snackBarVisible, setSnackBarVisible] = useState(false);
    const [amount1Warning, setAmount1Warning] = useState(' ');

    const initCoins = () => {
        dispatch(swapActions.getMaxPriceImpact(token))
        dispatch(swapActions.getSwapPrice({
            tokenFrom: 'USDT',
            tokenTo: 'SAP',
            amount: 1
        }, token))

        setPairOne('USDT');
        setPairTwo('SAP');
        setPairOneList(allCoinList);
        setPairTwoList(allCoinList);
    }

    useEffect(() => {
        dispatch(poolActions.getAllLiquidityPool())
        return () => {
            dispatch(swapActions.clearSwapQuote())
        }
    }, [])

    useEffect(() => {
        let allCoinList = []

        for (let i = 0; i < liquidityPools.length; i++) {
            let obj1 = {
                label: liquidityPools[i].token1,
                value: liquidityPools[i].token1
            }
            let obj2 = {
                label: liquidityPools[i].token2,
                value: liquidityPools[i].token2
            }
            if (allCoinList.length === 0) {
                allCoinList.push(obj1.value)
                allCoinList.push(obj2.value)
            } else {
                if (!allCoinList.includes(obj1.value)) {
                    allCoinList.push(obj1.value)
                }
                if (!allCoinList.includes(obj2.value)) {
                    allCoinList.push(obj2.value)
                }
            }
        }
        for (let i = 0; i < allCoinList.length; i++) {
            allCoinList[i] = { label: allCoinList[i], value: allCoinList[i] }
        }
        setAllCoinList(allCoinList)
        setPairOneList(allCoinList);
        setPairTwoList(allCoinList);
        return () => {
        }
    }, [liquidityPools])

    useEffect(() => {
        if (loggedIn) {
            dispatch(walletActions.getUserCapital(token))
            dispatch(walletActions.getAllTokenStatus(token));
            initCoins()
        }
        return () => {
        }
    }, [loggedIn])

    useEffect(() => {
        if (swapLoading === false) {
            dispatch(poolActions.getAllLiquidityPool())
            if (loggedIn) {
                setInitialPrice(true)
                dispatch(walletActions.getUserCapital(token))
                dispatch(swapActions.getSwapPrice({
                    tokenFrom: pairOne,
                    tokenTo: pairTwo,
                    amount: 1
                }, token))
            }
            if (swapFailed) {
                setSnackBarMessage(t(swapMessage))
                setSnackBarVisible(true)
                dispatch(swapActions.clearSwapState())
            } else if (swapSucceed) {
                setSnackBarMessage(t('swap-succeed'))
                setSnackBarVisible(true)
                dispatch(swapActions.clearSwapState())
            }
        }
        return () => {
        }
    }, [swapLoading])

    useEffect(() => {
        if (swapQuote.priceImpact < maxPriceImpact && !inputFocused && amount1 > 0.0001 && amount2 > 0 && coinFree1 && amount1 <= coinFree1.free) {
            setSubmitDisable(false)
        } else {
            setSubmitDisable(true)
        }
        if (amount1 <= 0.0001 && amount1 != "") {
            setAmount1Warning(t('swap-minLimit'))
        } else if ((!coinFree1 || amount1 > coinFree1.free) && amount1 != "") {
            setAmount1Warning(t('insufficient_fund'))
        } else if (swapQuote.priceImpact >= maxPriceImpact && amount1 != "") {
            setAmount1Warning(t('swap-priceImpact') + maxPriceImpact * 100 + '%')
        } else {
            setAmount1Warning("")
        }
        if (amount1All.current) {
            amount1All.current = false;
            dispatch(swapActions.swapQuote({
                tokenFrom: pairOne,
                tokenTo: pairTwo,
                amount: amount1,
            }, token))
        }
        return () => {
        }
    }, [amount1, amount2, t, userCapitals])

    useEffect(() => {
        dispatch(walletActions.getUserCapital(token))
        if (!initialPrice) {
            setAmount2(roundingDown(swapQuote.amount, 8))
        }
        return () => {
        }
    }, [swapQuote])
    useEffect(() => {
        dispatch(walletActions.getUserCapital(token))
        if (initialPrice) {
            setAmount1("");
            setAmount2("");
            setInitialPrice(false);
        }
        return () => {
        }
    }, [swapPrice])

    const handleChange = (event) => {
        if (event.target.name === "amount1") {
            setAmount1(event.target.value)
        } else if (event.target.name === "amount2") {
            setAmount2(event.target.value)
        }
    };

    const handleCoinChange = (coin, name) => {
        if (name === "coin1") {
            if (coin.value === pairTwo) {
                handleSwitch()
            } else {
                setInitialPrice(true)
                setPairOne(coin.value)
                setAmount1("");
                setAmount2("");
                dispatch(swapActions.getSwapPrice({
                    tokenFrom: coin.value,
                    tokenTo: pairTwo,
                    amount: 1,
                }, token))
            }
        } else if (name === "coin2") {
            if (coin.value === pairOne) {
                handleSwitch()
            } else {
                setInitialPrice(true)
                setPairTwo(coin.value)
                setAmount1("");
                setAmount2("");
                dispatch(swapActions.getSwapPrice({
                    tokenFrom: pairOne,
                    tokenTo: coin.value,
                    amount: 1,
                }, token))
            }
        }
    };
    // const handleCoinOneChange = (event) =>{

    // }

    const handleSwitch = () => {
        setInitialPrice(true)
        setAmount1("");
        setAmount2("");
        setPairOne(pairTwo);
        setPairOneList(pairTwoList);
        setPairTwo(pairOne);
        setPairTwoList(pairOneList);
        dispatch(swapActions.getSwapPrice({
            tokenFrom: pairTwo,
            tokenTo: pairOne,
            amount: 1,
        }, token))
    }

    const handleSubmit = async () => {
        setConfirmViewVisible(true)
        await swap(address, chainId, network, Web3, dispatch, swapSigning, pairOne, pairTwo, amount1, amount2, setAmount1, setAmount2, token, setSnackBarMessage, setSnackBarVisible, t)
        setConfirmViewVisible(false)
    }

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBarVisible(false);
    };

    const confirmViewBody = (
        <div style={{
            backgroundColor: 'white', borderRadius: 24, width: 480, overflow: 'hidden', outline: 0, display: 'flex', alignItems: 'center', flexDirection: 'column'
        }}>

            <Avatar src={confirmIcon} variant="square" style={{ height: 54 * 1.2, width: 77 * 1.2, marginTop: 24 }} />

            <Typography style={{ fontSize: 16, fontWeight: 'bold', marginTop: 8, color: '#363636' }}>
                {t('swap-confirm-title')}
            </Typography>
            <Typography style={{ fontSize: 12, fontWeight: 'bold', marginTop: -4, color: '#8786A7' }}>
                {t('swap-confirm-content')}
            </Typography>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '89%', marginTop: 40 }}>
                <Typography style={{ fontSize: 14, color: '#363636', fontWeight: 'bold' }}>
                    {t('swap-from')}
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar variant="square" src={getIcons(pairOne, tokenIcons, true)} style={{ height: 20, width: 20, marginRight: 8 }} />
                    <Typography style={{ fontSize: 14, color: '#363636', fontWeight: 'bold', }} >
                        {amount1 + " " + pairOne}
                    </Typography>


                </div>
            </div>


            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '89%', marginTop: 16 }}>
                <Typography style={{ fontSize: 14, color: '#363636', fontWeight: 'bold' }}>
                    {t('swap-confirm-to')}
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar variant="square" src={getIcons(pairTwo, tokenIcons, true)} style={{ height: 20, width: 20, marginRight: 8 }} />
                    <Typography style={{ fontSize: 14, color: '#363636', fontWeight: 'bold', }} >
                        {amount2 + " " + pairTwo}
                    </Typography>
                </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 16, width: '89%', marginBottom: 40 }}>
                <Typography style={{ fontSize: 14, color: '#363636', fontWeight: 'bold' }}>
                    {t('swap-fee')}
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar variant="square" src={getIcons(pairOne, tokenIcons, true)} style={{ height: 20, width: 20, marginRight: 8 }} />
                    <Typography style={{ fontSize: 14, color: '#363636', fontWeight: 'bold' }} >
                        {swapQuote ? roundingDown(swapQuote.swapFee, 4) : 0} {pairOne}
                    </Typography>


                </div>
            </div>

            {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 8, width: '89%',marginBottom:40 }}>
                <Typography style={{ fontSize: 14, color: 'white', fontWeight: 'bold' }}>
                    矿池占比
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography style={{ fontSize: 14, color: 'white', fontWeight: 'bold' }} >
                        {roundingDown(addLiquidityQuote.shareOfPool * 100, 8)}%
                    </Typography>


                </div>
            </div> */}

        </div>
    );
    // const originalUIContainer = (
    //     <div style={{
    //         display: "flex",
    //         flexDirection: "column",
    //         alignItems: "center",
    //         backgroundColor: "#f9f9f9",
    //         width: width < 960 ? "100%" : '60%',
    //         height: 640,
    //     }}>
    //         <div className='cards__wrapper'>

    //             <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

    //                 <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
    //                     <Typography className={classes.defaultFont} style={{ fontSize: width < 960 ? 12 : 14 }}>
    //                         {t('price')}:1 {pairOne} ≈ {swapPrice} {pairTwo}
    //                     </Typography>


    //                     <Typography className={classes.defaultFont} style={{ fontSize: width < 960 ? 12 : 14, color: '' }}>



    //                         {coinFree1 ?
    //                             pairOne + " " + t("swap-Balance") + " " + roundingDown(coinFree1.free, 4)
    //                             :
    //                             t("swap-noCapital")}
    //                     </Typography>

    //                     <Typography className={classes.defaultFont} style={{ fontSize: width < 960 ? 12 : 14, color: '' }}>



    //                         {coinFree2 ?
    //                             pairTwo + " " + t("swap-Balance") + " " + roundingDown(coinFree2.free, 4) + " " + pairTwo
    //                             :
    //                             t("swap-noCapital")}
    //                     </Typography>



    //                 </div>

    //                 {loggedIn ?
    //                     <div>
    //                         <Link to='/swap/records' style={{ textDecoration: 'none' }}>
    //                             <Button style={{ backgroundColor: '#251CFF', borderRadius: 8, textTransform: 'none' }}>

    //                                 <Typography style={{ fontSize: 14, fontWeight: 'bold', color: 'white' }}>
    //                                     {t("swap-record")}
    //                                 </Typography>
    //                             </Button>
    //                         </Link>
    //                     </div>
    //                     :
    //                     null
    //                 }
    //             </div>

    //             <div style={{ width: '100%', backgroundColor: '#979797', height: 1, marginTop: 24, marginBottom: 24, opacity: 0.1 }}></div>


    //             {/* <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 24 }}>

    //                 <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
    //                     <Typography style={{ fontSize: 18, color: 'white', fontWeight: 'bold' }} >
    //                         {pairOne} {t("swap-swap")} {pairTwo}
    //                     </Typography>


    //                     <Typography style={{ fontSize: 14, color: '#8FB9E1', marginTop: -4, fontWeight: 'bold' }}>
    //                         {t('swap-fee')}:{swapQuote ? roundingDown(swapQuote.swapFee, 4) : 0} {pairOne}
    //                     </Typography>
    //                 </div>

    //                 <div style={{ flexDirection: 'row', display: 'flex', backgroundColor: 'white', height: 48, alignItems: 'center', width: 100, justifyContent: 'space-between', borderRadius: 24 }}>

    //                     <Avatar variant="square" src={getIcons(pairOne, tokenIcons, true)} style={{ height: 28, width: 28, marginLeft: 16 }} />
    //                     <Avatar variant="square" src={getIcons(pairTwo, tokenIcons, true)} style={{ height: 28, width: 28, marginRight: 16 }} />
    //                 </div>
    //             </div> */}


    //             {/* <Typography>
    //             手续费:{swapQuote ? roundingDown(swapQuote.swapFee, 4) : 0} {pairOne}
    //         </Typography> */}

    //             {/* {loggedIn ?
    //             <div>

    //                 <Typography>
    //                     侧链余额:{coinFree1 ? roundingDown(coinFree1.free, 4) : '无'}
    //                 </Typography>
    //             </div>
    //             : null
    //         } */}

    //             <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', height: 100, marginTop: "10%" }}>

    //                 <CustomTextField
    //                     label={
    //                         coinFree1 ?
    //                             t('swap-enter') + " " + pairOne + " " + t("swap-amount")
    //                             :
    //                             t("swap-noCapital")
    //                     }
    //                     disabled={!loggedIn || !(pairOne && pairTwo) || swapQuoteLoading}
    //                     id="amount1"
    //                     name="amount1"
    //                     style={{ width: '70%', textTransform: 'none' }}
    //                     // inputRef={inputRef}
    //                     onChange={handleChange}
    //                     value={amount1}
    //                     helperText={amount1Warning}
    //                     error={amount1Warning !== ''}
    //                     onFocus={() => {
    //                         setInputFocused(true)
    //                     }}

    //                     onBlur={() => {
    //                         setInputFocused(false)
    //                         dispatch(swapActions.swapQuote({
    //                             tokenFrom: pairOne,
    //                             tokenTo: pairTwo,
    //                             amount: amount1,
    //                         }, token))
    //                     }}
    //                     // error={warning !== ''}
    //                     rightbuttonlabel={t('all')}
    //                     onrightbuttondisable={!loggedIn || inputFocused || swapQuoteLoading}
    //                     onrightbuttonclick={() => {
    //                         amount1All.current = true
    //                         if (coinFree1) {
    //                             if (roundingDown(coinFree1.free, 8) != amount1) {
    //                                 setAmount1(roundingDown(coinFree1.free, 8))
    //                             } else {
    //                                 amount1All.current = false
    //                             }
    //                         } else {
    //                             setAmount1("0")
    //                         }
    //                     }}
    //                     customtype="number"

    //                 >

    //                 </CustomTextField>

    //                 <div style={{ width: '25%', display: 'flex', flexDirection: 'column' }}>

    //                     {/* <CustomDropBox
    //                     label={t("selectCoin")}
    //                     name="coin1"
    //                     disabled={!loggedIn || swapQuoteLoading}
    //                     value={pairOne}
    //                     onChange={handleCoinChange}
    //                 >
    //                     {pairOneList.map((option) => (

    //                         <MenuItem key={option.value} value={option.value}>
    //                             <Grid container style={{ display: 'flex' }} >
    //                                 <Grid item xs={3} >
    //                                     <Avatar variant="square" alt="Coin Icon" style={{ width: 20, height: 20 }} src={getIcons(option.label, tokenIcons, true)} />
    //                                 </Grid>
    //                                 <Grid item xs={9} >
    //                                     <Typography style={{ color: '#010846', fontWeight: 'bold' }}>
    //                                         {option.label}
    //                                     </Typography>
    //                                 </Grid>
    //                             </Grid>
    //                         </MenuItem>
    //                     ))}
    //                 </CustomDropBox> */}
    //                     <CustomCoinSelector
    //                         coin={pairOne}
    //                         plachHolder={t("selectCoin")}
    //                         inputTitle={t("searchCoin")}
    //                         tokenIcons={tokenIcons}
    //                         coins={pairOneList}
    //                         handleChange={handleCoinChange}
    //                         name="coin1"
    //                     />

    //                 </div>

    //             </div>


    //             <div style={{ width: '100%', alignItems: 'center', marginTop: 0, marginBottom: 42, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>

    //                 <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
    //                     <>
    //                         <img style={{ height: 40 }} src={lightIcon}></img>
    //                         <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 16 }}>
    //                             <Typography className={classes.defaultFont} style={{ fontSize: 14 }} >
    //                                 Swap Transfer Fee
    //                             </Typography>


    //                             <Typography className={classes.defaultFont} style={{ fontSize: 20, color: '#8FB9E1', marginTop: -4 }}>
    //                                 {swapQuote ? roundingDown(swapQuote.swapFee, 4) : 0} {pairOne}
    //                             </Typography>
    //                         </div>
    //                     </>

    //                 </div>

    //                 <Button
    //                     style={{ border: '0', minHeight: 0, minWidth: 0, padding: 0 }}
    //                     onClick={handleSwitch}
    //                     disabled={!loggedIn || inputFocused}
    //                 >

    //                     <img src={swapCoinIcon} style={{ height: 40, width: 40, }} />
    //                 </Button>

    //             </div>




    //             <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', height: 100 }}>

    //                 <CustomTextField
    //                     value={amount2 == 0 ? "" : amount2}


    //                     label={
    //                         coinFree2 ?
    //                             t("swap-toAmount")
    //                             :
    //                             t("swap-toAmount") + " " + pairTwo
    //                     }
    //                     disabled
    //                     id="amount2"
    //                     name="amount2"
    //                     style={{ width: '70%', textTransform: 'none' }}
    //                     onChange={handleChange}
    //                     // helperText={" "}
    //                     // error={amount1Warning !== ''}
    //                     customtype="number"
    //                 >

    //                 </CustomTextField>

    //                 {/*
    //             <TextField
    //                 select
    //                 label="选择币种"
    //                 name="coin2"
    //                 disabled={!loggedIn || swapQuoteLoading}
    //                 value={pairTwo}
    //                 onChange={handleCoinChange}
    //             >
    //                 {pairTwoList.map((option) => (
    //                     <MenuItem key={option.value} value={option.value}>
    //                         <Avatar alt="Coin Icon" style={{ width: 20, height: 20 }} src={getIcons(option.label, tokenIcons, true)} />
    //                         {option.label}
    //                     </MenuItem>
    //                 ))}
    //             </TextField> */}
    //                 <div style={{ width: '25%', display: 'flex', flexDirection: 'column' }}>

    //                     {/* <CustomDropBox
    //                     label={t('selectCoin')}
    //                     name="coin2"
    //                     disabled={!loggedIn || swapQuoteLoading}
    //                     value={pairTwo}
    //                     onChange={handleCoinChange}
    //                 >
    //                     {pairTwoList.map((option) => (

    //                         <MenuItem key={option.value} value={option.value}>
    //                             <Grid container style={{ display: 'flex' }} >
    //                                 <Grid item xs={3} >
    //                                     <Avatar variant="square" alt="Coin Icon" style={{ width: 20, height: 20 }} src={getIcons(option.label, tokenIcons, true)} />
    //                                 </Grid>
    //                                 <Grid item xs={9} >
    //                                     <Typography style={{ color: '#010846', fontWeight: 'bold' }}>
    //                                         {option.label}
    //                                     </Typography>
    //                                 </Grid>
    //                             </Grid>
    //                         </MenuItem>
    //                     ))}
    //                 </CustomDropBox> */}

    //                     <CustomCoinSelector
    //                         coin={pairTwo}
    //                         plachHolder={t("selectCoin")}
    //                         inputTitle={t("searchCoin")}
    //                         tokenIcons={tokenIcons}
    //                         coins={pairTwoList}
    //                         handleChange={handleCoinChange}
    //                         name="coin2"
    //                     />
    //                 </div>
    //             </div>




    //             {/* <div>
    //             <TextField
    //                 value={amount1}
    //                 label="输入金额"
    //                 name="amount1"
    //                 disabled={!loggedIn || !(pairOne && pairTwo) || swapQuoteLoading}
    //                 onChange={handleChange}
    //                 helperText={amount1Warning}
    //                 error={amount1Warning !== ''}
    //                 InputLabelProps={{
    //                     shrink: true,
    //                 }}
    //                 InputProps={{
    //                     inputComponent: NumberFormatCustom,
    //                     endAdornment: <InputAdornment position="start">
    //                         <Button
    //                             variant="outlined"
    //                             onClick={() => {
    //                                 amount1All.current = true
    //                                 setAmount1(roundingDown(coinFree1.free, 8))
    //                             }}
    //                             disabled={coinFree1 ? swapQuoteLoading : true}
    //                         >
    //                             全部
    //                             </Button>
    //                     </InputAdornment>,
    //                 }}
    //                 onBlur={() => {
    //                     dispatch(swapActions.swapQuote({
    //                         tokenFrom: pairOne,
    //                         tokenTo: pairTwo,
    //                         amount: amount1,
    //                     }, token))
    //                 }}
    //             />
    //             <TextField
    //                 select
    //                 label="选择币种"
    //                 name="coin1"
    //                 disabled={!loggedIn || swapQuoteLoading}
    //                 value={pairOne}
    //                 onChange={handleCoinChange}
    //             >
    //                 {pairOneList.map((option) => (
    //                     <MenuItem key={option.value} value={option.value}>
    //                         <Avatar alt="Coin Icon" style={{ width: 20, height: 20 }} src={getIcons(option.label, tokenIcons, true)} />
    //                         {option.label}
    //                     </MenuItem>
    //                 ))}
    //             </TextField>
    //         </div> */}

    //             {/* {loggedIn ?
    //             <Typography>
    //                 侧链余额:{coinFree2 ? roundingDown(coinFree2.free, 4) : '无'}
    //             </Typography>
    //             : null
    //         } */}

    //             {/* <div>
    //             <TextField
    //                 value={amount2}
    //                 label="换得金额(预估)"
    //                 name="amount2"
    //                 disabled
    //                 onChange={handleChange}
    //                 InputLabelProps={{
    //                     shrink: true,
    //                 }}
    //                 InputProps={{
    //                     inputComponent: NumberFormatCustom,
    //                 }}
    //             />
    //             <TextField
    //                 select
    //                 label="选择币种"
    //                 name="coin2"
    //                 disabled={!loggedIn || swapQuoteLoading}
    //                 value={pairTwo}
    //                 onChange={handleCoinChange}
    //             >
    //                 {pairTwoList.map((option) => (
    //                     <MenuItem key={option.value} value={option.value}>
    //                         <Avatar alt="Coin Icon" style={{ width: 20, height: 20 }} src={getIcons(option.label, tokenIcons, true)} />
    //                         {option.label}
    //                     </MenuItem>
    //                 ))}
    //             </TextField>
    //         </div> */}
    //             {/* {loggedIn ?
    //             <div>
    //                 <Typography>
    //                     价格:1 {pairOne} = {swapQuote.pools.length > 0 ? price : 0
    //                     } {pairTwo}
    //                 </Typography>
    //                 <Typography>
    //                     手续费:{swapQuote ? roundingDown(swapQuote.swapFee, 4) : 0} {pairOne}
    //                 </Typography>
    //             </div>
    //             : null
    //         } */}

    //         </div>


    //         {
    //             address.length < 42 || !isValidAddress(address) ?
    //                 <Button style={{ borderRadius: 12, height: 70, width: '91%', backgroundColor: '#4477F1', textTransform: 'none' }} onClick={!window.ethereum ? () => onClickInstall(sendBackButton1, sendBackButton1Disabled) : onClickConnect}>
    //                     <Typography style={{ fontSize: 16, fontWeight: 'bold', color: '#010846' }}>
    //                         {button1}
    //                     </Typography>
    //                 </Button>
    //                 :
    //                 null
    //         }
    //         {
    //             address.length === 42 && isValidAddress(address) && !loggedIn ?
    //                 <Button style={{ borderRadius: 12, height: 70, width: '91%', backgroundColor: '#75E2F9', textTransform: 'none' }}
    //                     className={button2Disabled ? classes.btn_disabled : classes.btn} onClick={(!registered || !loggedIn) && !loading ? () => unlock(address, chainId, network, code, Web3, registered, dispatch) : null} variant="outlined" color="primary" disabled={button2Disabled}>
    //                     <Typography style={{ fontSize: 16, fontWeight: 'bold', color: '#010846' }}>
    //                         {button2}
    //                     </Typography>
    //                 </Button> : null
    //         }
    //         {
    //             address.length === 42 && isValidAddress(address) && loggedIn ?
    //                 <Button
    //                     variant="outlined"
    //                     color="primary"
    //                     disabled={!loggedIn || submitDisable}
    //                     onClick={handleSubmit}
    //                     style={{ borderRadius: 12, height: 70, width: '91%', backgroundColor: '#1DF0A9', opacity: !loggedIn || submitDisable ? 0.5 : 1, textTransform: 'none' }}
    //                 >
    //                     <Typography style={{ fontSize: 16, fontWeight: 'bold', color: '#010846' }}>
    //                         {t('swap-swap')}
    //                     </Typography>
    //                 </Button> : null
    //         }
    //         {/*确认页*/}
    //         <Modal
    //             open={confirmViewVisible}
    //             onClose={() => {
    //                 if (!swapSigning) {
    //                     setConfirmViewVisible(false)
    //                 }
    //             }}
    //             style={{ alignItems: "center", display: 'flex', justifyContent: 'center' }}
    //         >
    //             {confirmViewBody}
    //         </Modal>
    //         {/*兑换结果提示框*/}
    //         <Snackbar
    //             open={snackBarVisible}
    //             onClose={handleSnackClose}
    //             anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    //             autoHideDuration={5000}
    //             message={snackBarMessage}
    //             key={"resultPopup"}
    //             action={
    //                 <React.Fragment>
    //                     <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackClose}>
    //                         <CloseIcon fontSize="small" />
    //                     </IconButton>
    //                 </React.Fragment>
    //             }
    //         />
    //     </div >
    // )
    const deskContainer = (
        <div style={{ width: '71%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: "3%" }}>

            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                backgroundColor: "#F1F9FF",
                justifyContent: 'space-between',
                width: width < 960 ? "100%" : '38%',
                height: 640,
                paddingBottom: 24,
            }}>
                <Typography style={{ fontSize: width < 960 ? 24 : 32, fontFamily: 'Syncopate', color: '#251CFF', marginLeft: 24, marginTop: 24 }}>{t('swap-title')}</Typography>


                {address.length === 42 && isValidAddress(address) && loggedIn ?
                    <Typography className={classes.defaultFont} style={{ fontSize: 14, fontWeight: 'bold', color: '#8FB9E1', textAlign: 'left', marginLeft: 24, marginRight: 24 }}>
                        {t('swap-announce')}
                    </Typography>
                    :
                    <Typography className={classes.defaultFont} style={{ fontSize: width < 960 ? 12 : 14, fontWeight: 'bold', color: '#8FB9E1', textAlign: 'left', marginLeft: 24, marginRight: 24 }}>
                        {t('swap-announce')}
                    </Typography>
                }


                <img alt='' src={swapSideBanner} style={{ width: '96%' }}>
                </img>

            </div>
            <div style={{ backgroundColor: '#F5F5F5', width: '60%', height: 760 }}>

                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 32, marginRight: 40, alignItems: 'center' }}>


                    {loggedIn ?
                        <div>
                            <Link to='/swap/records' style={{ textDecoration: 'none' }}>
                                <Button style={{ backgroundColor: '#251CFF', borderRadius: 8, textTransform: 'none' }}>

                                    <Typography style={{ fontSize: 14, fontWeight: 'bold', color: 'white' }}>
                                        {t("swap-record")}
                                    </Typography>
                                </Button>
                            </Link>
                        </div>
                        :
                        null
                    }
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>



                    {/*1 new input component */}
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 24 }}>
                        <div style={{ width: '91%', alignItems: 'flex-start', }}>
                            <Typography style={{ textAlign: 'left', color: '#AFBDD0', fontFamily: 'Avenir', fontWeight: '600', fontSize: isMobile ? 12 : 14 }}>{t("depositAmount")}</Typography>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '91%', backgroundColor: 'white', marginTop: 8, borderRadius: 16, border: '1px solid #DCDCDC' }} >
                            <div style={{ marginLeft: isMobile ? 12 : 24, marginTop: 12, marginBottom: 12 }}>
                                <NewCustomTextField
                                    disabled={!loggedIn}
                                    // inputRef={inputRef}
                                    id="amount1"
                                    name="amount1"
                                    onChange={handleChange}
                                    value={amount1}
                                    placeholder={"0.00"}
                                    rightbuttonlabel={t('all')}
                                    onrightbuttonclick={() => {
                                        amount1All.current = true
                                        if (coinFree1) {
                                            if (roundingDown(coinFree1.free, 8) !== amount1) {
                                                setAmount1(roundingDown(coinFree1.free, 8))
                                            } else {
                                                amount1All.current = false
                                            }
                                        } else {
                                            setAmount1("0")
                                        }
                                    }}
                                    customtype="number"
                                    coin={pairOne}
                                    tokenIcons={tokenIcons}
                                    coins={pairOneList}
                                    handleChange={handleCoinChange}
                                    onFocus={() => {
                                        setInputFocused(true)
                                    }}

                                    onBlur={() => {
                                        setInputFocused(false)
                                        dispatch(swapActions.swapQuote({
                                            tokenFrom: pairOne,
                                            tokenTo: pairTwo,
                                            amount: amount1,
                                        }, token))
                                    }}
                                >

                                </NewCustomTextField>
                                <Typography style={{ marginLeft: 4, fontFamily: 'Avenir', fontWeight: '500', color: amount1Warning === "" ? "#AFBDD0" : '#FF4646', fontSize: 14 }}> {amount1Warning === "" ? t("enterAmount") : amount1Warning}</Typography>
                            </div>



                            <div style={{ display: 'flex', flexDirection: 'column', marginRight: isMobile ? 12 : 24 }}>
                                <NewCustomCoinSelector
                                    coin={pairOne}
                                    isloggedIn={loggedIn}

                                    tokenIcons={tokenIcons}
                                    coins={pairOneList}
                                    plachHolder={t("selectCoin")}
                                    inputTitle={t("searchCoin")}
                                    handleChange={handleCoinChange}
                                    name="coin1"
                                />
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8, alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography style={{ textAlign: 'left', color: '#AFBDD0', fontFamily: 'Avenir', fontWeight: '500', fontSize: isMobile ? 12 : 14 }}>{t("availableCapital")} </Typography>
                                    <Typography style={{ textAlign: 'left', marginLeft: 4, color: '#271CFF', fontFamily: 'Avenir', fontWeight: '500', fontSize: isMobile ? 12 : 14 }}>
                                        {coinFree1 ?
                                            roundingDown(coinFree1.free, 4)
                                            :
                                            "--"}
                                    </Typography>
                                </div>
                            </div>


                        </div>

                    </div>




                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 24 }}>
                        <Button
                            style={{ border: '0', minHeight: 0, minWidth: 0, padding: 0, backgroundColor: '#C1E1FF', height: 36, width: 36, borderRadius: 21, borderColor: '#A6D4FF', borderWidth: 2 }}
                            onClick={handleSwitch}
                            disabled={!loggedIn || inputFocused}
                        >

                            <img alt='' src={swapCoinIcon} style={{ height: 24, width: 24, }} />
                        </Button>

                    </div>

                    {/* 2 new input component */}
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 24 }}>
                        <div style={{ width: '91%', alignItems: 'flex-start', }}>
                            <Typography style={{ textAlign: 'left', color: '#AFBDD0', fontFamily: 'Avenir', fontWeight: '600', fontSize: isMobile ? 12 : 14 }}>{t("depositAmount")}</Typography>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '91%', backgroundColor: 'white', marginTop: 8, borderRadius: 16, border: '1px solid #DCDCDC' }} >
                            <div style={{ marginLeft: isMobile ? 12 : 24, marginTop: 12, marginBottom: 12 }}>
                                <NewCustomTextField
                                    disabled={true}
                                    staticInput={true}

                                    // inputRef={inputRef}
                                    id="amount2"
                                    name="amount2"
                                    onChange={handleChange}
                                    value={amount2 === 0 ? "" : amount2}
                                    placeholder={"0.00"}

                                    customtype="number"
                                    coin={pairTwo}
                                    tokenIcons={tokenIcons}
                                    coins={pairTwoList}
                                    handleChange={handleCoinChange}
                                >

                                </NewCustomTextField>
                                <Typography style={{ marginLeft: 4, fontFamily: 'Avenir', fontWeight: '500', color: amount1Warning === "" ? "#AFBDD0" : '#FF4646', fontSize: 14 }}>
                                    {coinFree2 ?
                                        t("swap-toAmount")
                                        :
                                        t("swap-toAmount") + " " + pairTwo}
                                </Typography>
                            </div>



                            <div style={{ display: 'flex', flexDirection: 'column', marginRight: isMobile ? 12 : 24 }}>
                                <NewCustomCoinSelector
                                    coin={pairTwo}
                                    isloggedIn={loggedIn}

                                    tokenIcons={tokenIcons}
                                    coins={pairTwoList}
                                    plachHolder={t("selectCoin")}
                                    inputTitle={t("searchCoin")}
                                    handleChange={handleCoinChange}
                                    name="coin2"
                                />
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8, alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography style={{ textAlign: 'left', color: '#AFBDD0', fontFamily: 'Avenir', fontWeight: '500', fontSize: isMobile ? 12 : 14 }}>{t("availableCapital")} </Typography>
                                    <Typography style={{ textAlign: 'left', marginLeft: 4, color: '#271CFF', fontFamily: 'Avenir', fontWeight: '500', fontSize: isMobile ? 12 : 14 }}>
                                        {coinFree2 ?
                                            roundingDown(coinFree2.free, 4)
                                            :
                                            "--"}
                                    </Typography>
                                </div>
                            </div>


                        </div>

                    </div>



                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: 32, justifyContent: 'center' }}>
                        <div style={{ width: '91%', display: 'flex', flexDirection: 'column', borderRadius: 16, border: '1px solid #DCDCDC', alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '91%', alignItems: 'center', marginTop: 24, marginBottom: 12 }}>
                                <Typography className={classes.defaultFont} style={{ color: '#8B96A7', fontSize: 16 }}> {t('price')}</Typography>
                                <Typography className={classes.defaultFont} style={{ fontSize: 16, color: '#8B96A7' }}>
                                    1 {pairOne} ≈ {swapPrice} {pairTwo}
                                </Typography>





                            </div>
                            <div style={{ height: 1, width: '91%', backgroundColor: '#DCDCDC' }}></div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '91%', alignItems: 'center', marginTop: 12, marginBottom: 24 }}>

                                <Typography className={classes.defaultFont} style={{ color: '#8B96A7', fontSize: 16 }}>{t('fee')}</Typography>
                                <Typography style={{ fontSize: 16, color: '#251CFF' }}> {swapQuote ? roundingDown(swapQuote.swapFee, 4) : 0} {pairOne}</Typography>
                            </div>
                        </div>

                    </div>
                    <div style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '7%' }}>
                        {
                            address.length < 42 || !isValidAddress(address) ?
                                <CustomButton buttonstyle="connectStyle" style={{ width: '91%' }} onClick={!window.ethereum ? () => onClickInstall(sendBackButton1, sendBackButton1Disabled) : onClickConnect}>
                                    {button1}
                                </CustomButton>
                                :

                                address.length === 42 && isValidAddress(address) && loggedIn ?


                                    <CustomButton style={{ opacity: !loggedIn || submitDisable ? 0.2 : 1, width: '91%' }} onClick={handleSubmit} variant="outlined" color="primary" disabled={!loggedIn || submitDisable}>
                                        {t('swap-swap')}
                                    </CustomButton>


                                    :
                                    <CustomButton buttonstyle="unlockStyle" style={{ width: '91%' }} onClick={(!registered || !loggedIn) && !loading ? () => unlock(address, chainId, network, '', Web3, registered, dispatch) : null} >
                                        {t('unlock')}
                                    </CustomButton>
                        }

                    </div>
                </div>


                {/* {
                    address.length < 42 || !isValidAddress(address) ?
                        <Button style={{ borderRadius: 12, height: 70, width: '91%', backgroundColor: '#4477F1', textTransform: 'none' }} onClick={!window.ethereum ? () => onClickInstall(sendBackButton1, sendBackButton1Disabled) : onClickConnect}>
                            <Typography style={{ fontSize: 16, fontWeight: 'bold', color: '#010846' }}>
                                {button1}
                            </Typography>
                        </Button>
                        :
                        null
                }
                {
                    address.length === 42 && isValidAddress(address) && !loggedIn ?
                        <Button style={{ borderRadius: 12, height: 70, width: '91%', backgroundColor: '#75E2F9', textTransform: 'none' }}
                            className={button2Disabled ? classes.btn_disabled : classes.btn} onClick={(!registered || !loggedIn) && !loading ? () => unlock(address, chainId, network, code, Web3, registered, dispatch) : null} variant="outlined" color="primary" disabled={button2Disabled}>
                            <Typography style={{ fontSize: 16, fontWeight: 'bold', color: '#010846' }}>
                                {button2}
                            </Typography>
                        </Button> : null
                }
                {
                    address.length === 42 && isValidAddress(address) && loggedIn ?
                        <Button
                            variant="outlined"
                            color="primary"
                            disabled={!loggedIn || submitDisable}
                            onClick={handleSubmit}
                            style={{ borderRadius: 12, height: 70, width: '91%', backgroundColor: '#1DF0A9', opacity: !loggedIn || submitDisable ? 0.5 : 1, textTransform: 'none' }}
                        >
                            <Typography style={{ fontSize: 16, fontWeight: 'bold', color: '#010846' }}>
                                {t('swap-swap')}
                            </Typography>
                        </Button>
                        :
                        null
                } */}

            </div >
        </div >
    )
    const phoneContainer = (
        <div style={{ width: "100%", display: 'flex', flexDirection: "column", marginTop: "0" }}>

            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: 'space-between',
                width: "100%",
            }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginTop: 24 }}>
                    <Typography style={{ fontSize: 24, fontFamily: 'Syncopate', color: '#251CFF', marginLeft: 24 }}>{t('swap-title')}</Typography>

                    {loggedIn ?
                        <div>
                            <Link to='/swap/records' style={{ textDecoration: 'none', marginRight: 12 }}>
                                <Button style={{}}>

                                    <Typography style={{ fontSize: 14, fontWeight: 'bold', color: '#251CFF' }}>
                                        {t("swap-record")}
                                    </Typography>
                                </Button>
                            </Link>
                        </div>
                        :
                        null
                    }
                </div>
                {address.length === 42 && isValidAddress(address) && loggedIn ?
                    <Typography className={classes.defaultFont} style={{ fontSize: 14, fontWeight: 'bold', color: '#8FB9E1', textAlign: 'left', marginLeft: 24, marginRight: 24 }}>
                        {t('swap-announce')}
                    </Typography>
                    :
                    <Typography className={classes.defaultFont} style={{ fontSize: width < 960 ? 12 : 14, fontWeight: 'bold', color: '#8FB9E1', textAlign: 'left', marginLeft: 24, marginRight: 24 }}>
                        {t('swap-announce')}
                    </Typography>
                }



            </div>
            <div style={{ width: "100%" }}>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>


                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                        {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <Typography className={classes.defaultFont} style={{ fontSize: width < 960 ? 12 : 14 }}>
                                {t('price')}:1 {pairOne} ≈ {swapPrice} {pairTwo}
                            </Typography>


                            <Typography className={classes.defaultFont} style={{ fontSize: width < 960 ? 12 : 14, color: '' }}>



                                {coinFree1 ?
                                    pairOne + " " + t("swap-Balance") + " " + roundingDown(coinFree1.free, 4)
                                    :
                                    t("swap-noCapital")}
                            </Typography>

                            <Typography className={classes.defaultFont} style={{ fontSize: width < 960 ? 12 : 14, color: '' }}>



                                {coinFree2 ?
                                    pairTwo + " " + t("swap-Balance") + " " + roundingDown(coinFree2.free, 4) + " " + pairTwo
                                    :
                                    t("swap-noCapital")}
                            </Typography>



                        </div> */}


                    </div>

                    {/*1 new input component */}
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 24 }}>
                        <div style={{ width: '91%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
                            <Typography style={{ textAlign: 'left', color: '#AFBDD0', fontFamily: 'Avenir', fontWeight: '600', fontSize: isMobile ? 12 : 14 }}>From</Typography>


                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '91%', backgroundColor: 'white', marginTop: 8, borderRadius: 16, border: '1px solid #DCDCDC' }} >
                            <div style={{ marginLeft: isMobile ? 12 : 24, marginTop: 12, marginBottom: 12 }}>
                                <NewCustomTextField
                                    disabled={!loggedIn}
                                    // inputRef={inputRef}
                                    id="amount1"
                                    name="amount1"
                                    onChange={handleChange}
                                    value={amount1}
                                    placeholder={"0.00"}
                                    rightbuttonlabel={t('all')}
                                    onrightbuttonclick={() => {
                                        amount1All.current = true
                                        if (coinFree1) {
                                            if (roundingDown(coinFree1.free, 8) !== amount1) {
                                                setAmount1(roundingDown(coinFree1.free, 8))
                                            } else {
                                                amount1All.current = false
                                            }
                                        } else {
                                            setAmount1("0")
                                        }
                                    }}
                                    customtype="number"
                                    coin={pairOne}
                                    tokenIcons={tokenIcons}
                                    coins={pairOneList}
                                    handleChange={handleCoinChange}
                                    onFocus={() => {
                                        setInputFocused(true)
                                    }}

                                    onBlur={() => {
                                        setInputFocused(false)
                                        dispatch(swapActions.swapQuote({
                                            tokenFrom: pairOne,
                                            tokenTo: pairTwo,
                                            amount: amount1,
                                        }, token))
                                    }}
                                >

                                </NewCustomTextField>
                                <Typography style={{ marginLeft: 4, fontFamily: 'Avenir', fontWeight: '500', color: amount1Warning === "" ? "#AFBDD0" : '#FF4646', fontSize: 14 }}> {amount1Warning === "" ? t("enterAmount") : amount1Warning}</Typography>
                            </div>



                            <div style={{ display: 'flex', flexDirection: 'column', marginRight: isMobile ? 12 : 24 }}>
                                <NewCustomCoinSelector
                                    coin={pairOne}
                                    isloggedIn={loggedIn}

                                    tokenIcons={tokenIcons}
                                    coins={pairOneList}
                                    plachHolder={t("selectCoin")}
                                    inputTitle={t("searchCoin")}
                                    handleChange={handleCoinChange}
                                    name="coin1"
                                />
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8, alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography style={{ textAlign: 'left', color: '#AFBDD0', fontFamily: 'Avenir', fontWeight: '500', fontSize: isMobile ? 12 : 14 }}>{t("availableCapital")} </Typography>
                                    <Typography style={{ textAlign: 'left', marginLeft: 4, color: '#271CFF', fontFamily: 'Avenir', fontWeight: '500', fontSize: isMobile ? 12 : 14 }}>
                                        {coinFree1 ?
                                            roundingDown(coinFree1.free, 4)
                                            :
                                            "--"}
                                    </Typography>
                                </div>
                            </div>


                        </div>

                    </div>




                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 24 }}>
                        <Button
                            style={{ border: '0', minHeight: 0, minWidth: 0, padding: 0, backgroundColor: '#C1E1FF', height: 36, width: 36, borderRadius: 21, borderColor: '#A6D4FF', borderWidth: 2 }}
                            onClick={handleSwitch}
                            disabled={!loggedIn || inputFocused}
                        >

                            <img alt='' src={swapCoinIcon} style={{ height: 24, width: 24, }} />
                        </Button>

                    </div>

                    {/* 2 new input component */}
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 24 }}>
                        <div style={{ width: '91%', alignItems: 'flex-start', }}>
                            <Typography style={{ textAlign: 'left', color: '#AFBDD0', fontFamily: 'Avenir', fontWeight: '600', fontSize: isMobile ? 12 : 14 }}>Receiving</Typography>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '91%', backgroundColor: 'white', marginTop: 8, borderRadius: 16, border: '1px solid #DCDCDC' }} >
                            <div style={{ marginLeft: isMobile ? 12 : 24, marginTop: 12, marginBottom: 12 }}>
                                <NewCustomTextField
                                    disabled={true}
                                    // inputRef={inputRef}
                                    staticInput={true}
                                    id="amount2"
                                    name="amount2"
                                    onChange={handleChange}
                                    value={amount2 === 0 ? "" : amount2}
                                    placeholder={"0.00"}
                                    // rightbuttonlabel={t('all')}
                                    // onrightbuttonclick={() => {
                                    //     amount1All.current = true
                                    //     if (coinFree1) {
                                    //         if (roundingDown(coinFree1.free, 8) !== amount1) {
                                    //             setAmount1(roundingDown(coinFree1.free, 8))
                                    //         } else {
                                    //             amount1All.current = false
                                    //         }
                                    //     } else {
                                    //         setAmount1("0")
                                    //     }
                                    // }}
                                    customtype="number"
                                    coin={pairTwo}
                                    tokenIcons={tokenIcons}
                                    coins={pairTwoList}
                                    handleChange={handleCoinChange}
                                >

                                </NewCustomTextField>
                                <Typography style={{ marginLeft: 4, fontFamily: 'Avenir', fontWeight: '500', color: amount1Warning === "" ? "#AFBDD0" : '#FF4646', fontSize: 14 }}>
                                    {coinFree2 ?
                                        t("swap-toAmount")
                                        :
                                        t("swap-toAmount") + " " + pairTwo}
                                </Typography>
                            </div>



                            <div style={{ display: 'flex', flexDirection: 'column', marginRight: isMobile ? 12 : 24 }}>
                                <NewCustomCoinSelector
                                    coin={pairTwo}
                                    isloggedIn={loggedIn}

                                    tokenIcons={tokenIcons}
                                    coins={pairTwoList}
                                    plachHolder={t("selectCoin")}
                                    inputTitle={t("searchCoin")}
                                    handleChange={handleCoinChange}
                                    name="coin2"
                                />
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8, alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography style={{ textAlign: 'left', color: '#AFBDD0', fontFamily: 'Avenir', fontWeight: '500', fontSize: isMobile ? 12 : 14 }}>{t("availableCapital")} </Typography>
                                    <Typography style={{ textAlign: 'left', marginLeft: 4, color: '#271CFF', fontFamily: 'Avenir', fontWeight: '500', fontSize: isMobile ? 12 : 14 }}>
                                        {coinFree2 ?
                                            roundingDown(coinFree2.free, 4)
                                            :
                                            "--"}
                                    </Typography>
                                </div>
                            </div>


                        </div>

                    </div>





                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: "10vh", justifyContent: 'center' }}>
                        <div style={{ width: '91%', display: 'flex', flexDirection: 'column', borderRadius: 12, border: '1px solid #f5f5f5', alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '91%', alignItems: 'center', marginTop: 12, marginBottom: 6 }}>
                                <Typography className={classes.defaultFont} style={{ color: '#8B96A7', fontSize: 14 }}>{t('price')}</Typography>
                                <Typography className={classes.defaultFont} style={{ fontSize: 14, color: '#8B96A7' }}>1 {pairOne} ≈ {swapPrice} {pairTwo}</Typography>
                            </div>
                            <div style={{ height: 1, width: '91%', backgroundColor: '#f5f5f5' }}></div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '91%', alignItems: 'center', marginTop: 6, marginBottom: 12 }}>

                                <Typography className={classes.defaultFont} style={{ color: '#8B96A7', fontSize: 14 }}>{t('fee')}</Typography>
                                <Typography style={{ fontSize: 14, color: '#251CFF' }}>{swapQuote ? roundingDown(swapQuote.swapFee, 4) : 0} {pairOne}</Typography>
                            </div>

                        </div>

                    </div>

                    <div style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '7%' }}>
                        {
                            address.length < 42 || !isValidAddress(address) ?
                                <CustomButton buttonstyle="connectStyle" style={{ width: '91%' }} onClick={!window.ethereum ? () => onClickInstall(sendBackButton1, sendBackButton1Disabled) : onClickConnect}>
                                    {button1}
                                </CustomButton> :
                                address.length === 42 && isValidAddress(address) && loggedIn ?


                                    <CustomButton style={{ opacity: !loggedIn || submitDisable ? 0.2 : 1, width: '91%' }} onClick={handleSubmit} variant="outlined" color="primary" disabled={!loggedIn || submitDisable}>
                                        {t('swap-swap')}
                                    </CustomButton>


                                    :
                                    <CustomButton buttonstyle="unlockStyle" style={{ width: '91%' }} onClick={(!registered || !loggedIn) && !loading ? () => unlock(address, chainId, network, '', Web3, registered, dispatch) : null} >
                                        {t('unlock')}
                                    </CustomButton>
                        }

                    </div>
                </div>




            </div >
        </div >
    )
    return (
        <div style={{ display: 'flex', minHeight: 900, justifyContent: 'center' }}>
            <Helmet>
                <title>DumboEX Layer 2 Swap</title>
                <meta name="description" content="Swap | DumboEX Layer 2, a fully decentralized trading protocol" />
            </Helmet>


            {
                width < 960 ?
                    phoneContainer
                    :
                    deskContainer
            }


            {/*确认页*/}
            <Modal
                open={confirmViewVisible}
                onClose={() => {
                    if (!swapSigning) {
                        setConfirmViewVisible(false)
                    }
                }}
                style={{ alignItems: "center", display: 'flex', justifyContent: 'center' }}
            >
                {confirmViewBody}
            </Modal>
            {/*兑换结果提示框*/}
            <Snackbar
                open={snackBarVisible}
                onClose={handleSnackClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={5000}
                message={snackBarMessage}
                key={"resultPopup"}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </div >
    )
}

export default withTranslation()(Swap);
