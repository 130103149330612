import * as Url from '../../config/Url'
import { authHeader } from '../../utils/AuthHeader';
import { handleResponse } from '../../utils/HandleResponse'

export const poolService = {
    addLiquidity,
    removeLiquidity,
    getAllLiquidityPool,
    getMyLiquidityPool,
    getPoolBanner,
    getLiquidityTransactions,
    getLiquidityCollectTransactions,
    getAddLiquidityQuote,
    getRemoveLiquidityQuote,
    harvestLiquidityReward,
    getMyPoolApy,
    getCurrentTime,
    getMyLocker,
    lockLp,
    unlockLp,
    getUnlockable,
    unlockAllLp
};

async function addLiquidity(payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(payload.token),
        body: JSON.stringify(payload)
    };
    return fetch(Url.ADD_LIQUIDITY, requestOptions).then(handleResponse);
}

async function removeLiquidity(payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(payload.token),
        body: JSON.stringify(payload)
    };
    return fetch(Url.REMOVE_LIQUIDITY, requestOptions).then(handleResponse);
}

async function getAllLiquidityPool(payload) {
    const requestOptions = {
        method: 'GET',
        // headers: authHeader(payload.token)
    };
    return fetch(Url.GET_ALL_LIQUIDITY_POOL, requestOptions).then(handleResponse);
}

async function getMyLiquidityPool(payload) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(payload)
    };
    return fetch(Url.GET_MY_LIQUIDITY_POOL, requestOptions).then(handleResponse);
}

async function getPoolBanner(payload) {
    const requestOptions = {
        method: 'GET',
    };
    return fetch(Url.GET_POOL_BANNER+payload, requestOptions).then(handleResponse);
}

async function getLiquidityTransactions(payload) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(payload)
    };
    return fetch(Url.GET_LIQUIDITY_TRANSACTIONS, requestOptions).then(handleResponse);
}

async function getLiquidityCollectTransactions(payload) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(payload)
    };
    return fetch(Url.GET_LIQUIDITY_COLLECT_TRANSACTIONS, requestOptions).then(handleResponse);
}

async function getAddLiquidityQuote(payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(payload.accessToken),
        body: JSON.stringify(payload)
    };
    return fetch(Url.GET_ADD_LIQUIDITY_QUOTE, requestOptions).then(handleResponse);
}

async function getRemoveLiquidityQuote(payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(payload.accessToken),
        body: JSON.stringify(payload)
    };
    return fetch(Url.GET_REMOVE_LIQUIDITY_QUOTE, requestOptions).then(handleResponse);
}

async function harvestLiquidityReward(payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(payload.accessToken),
        body: JSON.stringify(payload)
    };
    return fetch(Url.HARVEST_LIQUIDITY_REWARD, requestOptions).then(handleResponse);
}

async function getMyPoolApy(payload) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(payload)
    };
    return fetch(Url.GET_LIQUIDITY_POOL_APY, requestOptions).then(handleResponse);
}

async function getCurrentTime(payload) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(payload)
    };
    return fetch(Url.GET_CURRENT_TIME, requestOptions).then(handleResponse);
}

async function getMyLocker(payload) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(payload)
    };
    return fetch(Url.GET_MY_LOCKER, requestOptions).then(handleResponse);
}

async function lockLp(payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(payload.token),
        body: JSON.stringify(payload)
    };
    return fetch(Url.LOCK_LP, requestOptions).then(handleResponse);
}

async function unlockLp(payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(payload.token),
        body: JSON.stringify(payload)
    };
    return fetch(Url.UNLOCK_LP, requestOptions).then(handleResponse);
}

async function getUnlockable(payload) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(payload)
    };
    return fetch(Url.GET_UNLOCKABLE, requestOptions).then(handleResponse);
}

async function unlockAllLp(payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(payload.token),
        body: JSON.stringify(payload)
    };
    return fetch(Url.UNLOCK_ALL_LP, requestOptions).then(handleResponse);
}