import {
    GET_ALL_EARN_POOL,
    GET_ALL_EARN_POOL_SUCCEED,
    GET_ALL_EARN_POOL_FAILED,
    EARN_SIGNING,
    EARN_SIGNING_CANCELLED,
    EARN_STAKE,
    EARN_STAKE_SUCCEED,
    EARN_STAKE_FAILED,
    CLEAR_EARN_STAKE_STATE,
    GET_MY_EARN_POOL,
    GET_MY_EARN_POOL_SUCCEED,
    GET_MY_EARN_POOL_FAILED,
    GET_EARN_TRANSACTIONS,
    GET_EARN_TRANSACTIONS_FAILED,
    GET_EARN_TRANSACTIONS_SUCCEED,
    HARVEST_EARN_REWARD,
    HARVEST_EARN_REWARD_SUCCEED,
    HARVEST_EARN_REWARD_FAILED,
    CLEAR_HARVEST_EARN_STATE,
    EARN_UNSTAKE,
    EARN_UNSTAKE_SUCCEED,
    EARN_UNSTAKE_FAILED,
    CLEAR_EARN_UNSTAKE_STATE,
    GET_EARN_RATE_QUOTE,
    GET_EARN_RATE_QUOTE_SUCCEED,
    GET_EARN_RATE_QUOTE_FAILED,
    CLEAR_EARN_RATE_QUOTE,
    GET_ETH_PRICE,
    GET_ETH_PRICE_SUCCEED,
    GET_ETH_PRICE_FAILED,
} from "../constants";

export function earn (state = {
    earnPools:[],
    myEarnPools:[],
    myEarnTransactions:[],
    earnPoolSigning:false,
    earnRateQuote: {
        "token": "",
        "amount": 0,
        "priceImpact": 0,
        "swapFee": 0,
        "pools": []
    },
    ethPrice: {
        "symbol": "ETH",
        "currency": "USDT",
        "price": 0
    },
}, action){
    switch (action.type) {
        case GET_ALL_EARN_POOL:
            return{
                ...state,
                loading: true
            }
        case GET_ALL_EARN_POOL_SUCCEED:
            return{
                ...state,
                earnPools: action.res.data,
                loading: false
            }
        case GET_ALL_EARN_POOL_FAILED:
            return{
                ...state,
                message: action.error,
                loading: false
            }


        case GET_MY_EARN_POOL:
            return{
                ...state,
                loading: true
            }
        case GET_MY_EARN_POOL_SUCCEED:
            return{
                ...state,
                myEarnPools: action.res.data,
                loading: false
            }
        case GET_MY_EARN_POOL_FAILED:
            return{
                ...state,
                message: action.error,
                loading: false
            }


        case GET_EARN_TRANSACTIONS:
            return{
                ...state,
                loading: true
            }
        case GET_EARN_TRANSACTIONS_FAILED:
            return{
                ...state,
                message: action.error,
                loading: false
            }
        case GET_EARN_TRANSACTIONS_SUCCEED:
            return{
                ...state,
                myEarnTransactions: action.res,
                loading: false
            }


        case HARVEST_EARN_REWARD:
            return{
                ...state,
                harvestLoading: true,
                harvestSucceed: false,
                harvestFailed: false,
                earnPoolSigning:false,
            }
        case HARVEST_EARN_REWARD_SUCCEED:
            return{
                ...state,
                harvestMessage: action.msg.message,
                harvestLoading: false,
                harvestSucceed:true,
            }
        case HARVEST_EARN_REWARD_FAILED:
            return{
                ...state,
                harvestMessage: action.error,
                harvestLoading: false,
                harvestFailed:true,
            }
        case CLEAR_HARVEST_EARN_STATE:
            return{
                ...state,
                harvestSucceed: false,
                harvestFailed: false,
            }



        case EARN_STAKE:
            return{
                ...state,
                earnStakeLoading: true,
                earnStakeSucceed: false,
                earnStakeFailed: false,
                earnPoolSigning: false,
            }
        case EARN_STAKE_SUCCEED:
            return{
                ...state,
                earnStakeMessage: action.msg,
                earnStakeLoading: false,
                earnStakeSucceed: true,
            }
        case EARN_STAKE_FAILED:
            return{
                ...state,
                earnStakeMessage: action.error,
                earnStakeLoading: false,
                earnStakeFailed: true,
            }
        case CLEAR_EARN_STAKE_STATE:
            return{
                ...state,
                earnStakeFailed: false,
                earnStakeSucceed: false,
            }


        case EARN_UNSTAKE:
            return{
                ...state,
                earnUnStakeLoading: true,
                earnUnStakeSucceed: false,
                earnUnStakeFailed: false,
                earnPoolSigning: false,
            }
        case EARN_UNSTAKE_SUCCEED:
            return{
                ...state,
                earnUnStakeMessage: action.msg,
                earnUnStakeLoading: false,
                earnUnStakeSucceed: true,
            }
        case EARN_UNSTAKE_FAILED:
            return{
                ...state,
                earnUnStakeMessage: action.error,
                earnUnStakeLoading: false,
                earnUnStakeFailed: true,
            }
        case CLEAR_EARN_UNSTAKE_STATE:
            return{
                ...state,
                earnUnStakeFailed: false,
                earnUnStakeSucceed: false,
            }


        case GET_EARN_RATE_QUOTE:
            return{
                ...state,
                earnRateQuoteLoading: true
            }
        case GET_EARN_RATE_QUOTE_SUCCEED:
            return{
                ...state,
                earnRateQuote: action.res.data,
                earnRateQuoteLoading: false
            }
        case GET_EARN_RATE_QUOTE_FAILED:
            return{
                ...state,
                earnRateQuote: {
                    "token": "",
                    "amount": 0,
                    "priceImpact": 0,
                    "swapFee": 0,
                    "pools": []
                },
                message: action.error,
                earnRateQuoteLoading: false
            }
        case CLEAR_EARN_RATE_QUOTE:
            return{
                ...state,
                earnRateQuote: {
                    "token": "",
                    "amount": 0,
                    "priceImpact": 0,
                    "swapFee": 0,
                    "pools": []
                },
            }


        case GET_ETH_PRICE:
            return{
                ...state,
                loading: true
            }
        case GET_ETH_PRICE_SUCCEED:
            return{
                ...state,
                ethPrice: action.res.data,
                loading: false
            }
        case GET_ETH_PRICE_FAILED:
            return{
                ...state,
                ethPrice: {
                    "symbol": "ETH",
                    "currency": "USDT",
                    "price": 0
                },
                message: action.error,
                loading: false
            }


        case EARN_SIGNING:
            return{
                ...state,
                earnPoolSigning: true,
            }
        case EARN_SIGNING_CANCELLED:
            return{
                ...state,
                earnPoolSigning: false,
            }
        default:
            return state;
    }
    return state;
}