import React, { useEffect, useState } from 'react';
import {
    Backdrop,
    Button,
    Fade,
    makeStyles,
    withStyles,
    Modal,
    Typography,
    List,
    ListItem,
    IconButton,
    Snackbar, Tooltip
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import inviteBanner from '../../images/invite/inviteBanner.png'
import backArrow from '../../images/backIcon.png'
import CustomTextField from "../CommonElements/CustomTextField";
import { useDispatch, useSelector } from "react-redux";
import { onClickConnect, onClickInstall, unlock } from "../../utils/Sign";
import { isValidAddress } from "ethereumjs-util";
import Note from "../Note/Note";
import { walletActions } from "../../redux/actions/walletActions";
import { inviteActions } from "../../redux/actions/inviteActions";
import { convertTimeString, getIcons } from "../../utils/Common";
import './IDO.css'
import { authActions } from "../../redux/actions";
import { roundingDown } from "../../utils/RoundingDown";
import successIcon from '../../images/successIconBlue.png'
import crossIcon from '../../images/crossWhite.png'
import infoIcon from '../../images/info.png'
import infoInvert from '../../images/info-invert.png'
import crossIconBlue from '../../images/closeIcon.png'
import { buyIDO } from "../../utils/Sign";
import CloseIcon from "@material-ui/icons/Close";
import copy from 'copy-to-clipboard';
import warningicon from "../../images/warningicon.png";
import NewCustomTextField from '../CommonElements/NewCustomTextField'
import { isMobile } from 'react-device-detect';
import IDOBuyModal from "./components/IDOBuyModal";

const Web3 = require("web3");

function IDOInvite({ setOpenSliderModal, handleCloseSliderModal, setSignUpPayload, width, t, sliderInfo,  button2Disabled, registered, loggedIn, address, chainId, network, code, button2, sendBackHeight, sendBackAddr, sendBackChainId, sendBackNetworkId, button1, sendBackButton1, sendBackButton1Disabled, height, navBarHeight }) {
    const useStyles = makeStyles((theme) => ({
        root: {
            ...theme.typography.button,
            backgroundColor: "#010746",
            padding: theme.spacing(1),
            flexGrow: 1,
            height: '100%',
            textAlign: 'center',
            justifyContent: 'center',
        },
        btn: {
            backgroundColor: '#83ACF4',
            color: '#010846',
            borderColor: 'transparent',
            borderRadius: 25,
            height: 45,
            fontWeight: 600,
            minWidth: 100,
            border: 'none',
            '&:hover': {
                // backgroundColor: 'orange',
                border: 'none',
            },
        },
        btn_disabled: {
            backgroundColor: '#8BBDFF',
            color: '#010846',
            borderColor: 'transparent',
            borderRadius: 25,
            height: 45,
            fontWeight: 600,
            opacity: 0.2
        },
        registerBtn: {
            backgroundColor: '#271CFF',
            color: 'white',
            borderColor: 'transparent',
            borderRadius: 25,
            height: 45,
            fontWeight: 600,
            minWidth: 100
        },
        paper: {
            position: 'absolute',
            width: 400,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        defaultFont: {
            fontWeight: '400', color: '#363636', fontFamily: 'Avenir', textTransform: 'none'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },

    }));
    const classes = useStyles();
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: '#363636',
            boxShadow: '0 0 22px 2px rgba(0,0,0,0.09)',
            fontFamily: "PingFang SC",
            fontWeight: 'light',
            fontSize: 14,
            borderRadius: 20,
            padding: 30,
            // height: 186,
            // width: 431,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // marginRight: width * 0.06,
        },
    }))(Tooltip);

    const dispatch = useDispatch()
    const history = useHistory()

    const [referCode, setReferCode] = useState('')
    const [referCodeMsg, setReferCodeMsg] = useState('')
    const [referCodeStatus, setReferCodeStatus] = useState(0)
    const { userCapitals, walletSigning, buyIDOFinished, buyIDOSucceed, buyIDOMsg, idoInfo, idoInfoMsg, message } = useSelector(state => state.wallet)
    const { inviteInfo, inviteCode, invitees, inviteCodeMsg, registerInviterMsg, inviteInfoMsg, inviteeMsg, loading, registerInviterSucceed } = useSelector(state => state.invite)
    const { token, userInfo } = useSelector(state => state.auth)
    const [openNote, setOpenNote] = useState(false)
    const [openBuyModal, setOpenBuyModal] = useState(false)
    const [openRegisterModal, setOpenRegisterModal] = useState(false)


    const [hasInviteCode, setHasInviteCode] = useState(false)

    const [buyAmount, setBuyAmount] = useState('')
    const [buyAmountWarning, setBuyAmountWarning] = useState('')
    const [buyAmountStatus, setBuyAmountStatus] = useState(0)
    const [quota, setQuota] = useState(0)
    const [cantRegister, setCantRegister] = useState(false)
    const [buyBtnDisabled, setBuyBtnDisabled] = useState(false)
    const [buyInputFocused, setBuyInputFocused] = useState(false)
    const [usdtCapital, setUsdtCapital] = useState(0)
    const [inviterRegistered, setInviterRegistered] = useState(false)
    const [snackBarVisible, setSnackBarVisible] = useState(false)

    const [noteType, setNoteType] = useState('')
    const [noteTitle, setNoteTitle] = useState('')
    const [noteContent, setNoteContent] = useState('')
    const [showNoteBtn, setShowNoteBtn] = useState(false)

    const [snackMsg, setSnackMsg] = useState('')

    const [idoStartTime, setIdoStartTime] = useState('')

    const [btnText, setBtnText] = useState('')

    const [infoArray, setInfoArray] = useState([
        {name: 'currentLevel', value: ''},
    ])

    const [openInfoModal, setOpenInfoModal] = useState(false)
    const [infoTitle, setInfoTitle] = useState('')
    const [infoContent, setInfoContent] = useState('')

    const [hoverStatus, setHoverStatus] = useState([false, false, false, false, false, false, false, false])

    const registerInviter = () => {
        setReferCode('')
        handleCloseRegisterModal()
        dispatch(inviteActions.registerInviter(token, { code: referCode }))
    }

    const handleOpenNote = (type, title, content, time) => {
        setNoteType(type)
        setNoteTitle(t(title))
        setNoteContent(content)
        setOpenNote(true);
        if (time) {
            setTimeout(() => {
                handleCloseNote()
            }, time)
        }
    };

    const handleCloseNote = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpenNote(false);
        dispatch(inviteActions.clearModalInfo())
        dispatch(walletActions.clearInfo())
    };

    const handleOpenInfoModal = (item) => {
        setInfoTitle(t(item.name))
        setInfoContent(t(`${item.name}Note`))
        setOpenInfoModal(true)
    }

    const handleCloseInfoModal = () => {
        setOpenInfoModal(false)
    }

    useEffect(() => {
        if (!openNote) {
            setNoteContent('')
            setNoteTitle('')
            setNoteType('')
            setShowNoteBtn(false)
            setBtnText('')
        }
    }, [openNote])

    const handleReferCodeChange = (e) => {
        setReferCode(e.target.value)
    }

    useEffect(() => {
        if (referCode === '') {
            setReferCodeStatus(0)
            setReferCodeMsg('')
        } else if (referCode.length !== 6) {
            setReferCodeStatus(2)
            setReferCodeMsg(t('invalidReferCode'))
        } else {
            setReferCodeStatus(1)
            setReferCodeMsg('')
        }
    }, [referCode])

    const goToDeposit = () => {
        history.push('/deposit')
    }

    const handleOpenBuyModal = () => {
        setOpenBuyModal(true);
        dispatch(walletActions.getUserCapital(token))
    };

    const handleCloseBuyModal = () => {
        setOpenBuyModal(false);
        setBuyAmount('')
    };

    const handleOpenRegisterModal = () => {
        setOpenRegisterModal(true);
    };

    const handleCloseRegisterModal = () => {
        setOpenRegisterModal(false);
    };

    const handleBuyInputChange = (e) => {
        setBuyAmount(e.target.value.replace('.', ''))
    }

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBarVisible(false);
    };

    const checkBuyInput = () => {
        if (buyAmount === '') {
            setBuyAmountWarning('')
            setBuyAmountStatus(0)
        } else {
            if (parseFloat(buyAmount) < 100) {
                setBuyAmountWarning(t('lessThanBuyMin'))
                setBuyAmountStatus(2)
            } else if (parseFloat(buyAmount) > quota || parseFloat(buyAmount) > usdtCapital) {
                setBuyAmountStatus(2)
                if (Math.min(quota, usdtCapital) === usdtCapital) {
                    setBuyAmountWarning(t('balanceLow'))
                } else {
                    setBuyAmountWarning(t('exceedQuota'))
                }
            } else {
                setBuyAmountWarning('')
                setBuyAmountStatus(1)
            }
        }
    }

    useEffect(() => {
        checkBuyInput()
    }, [buyAmount])

    const max = () => {
        let maxString = roundingDown(Math.min(quota, usdtCapital), 0)
        if (maxString.includes('.')) {
            maxString = maxString.slice(0, -1)
        }
        setBuyAmount(maxString)
    }

    const handleInfoMouseOver = (index) => {
        let items = [false, false, false, false, false, false, false, false];
        let item = {...hoverStatus[index]};
        item = true;
        items[index] = item;
        setHoverStatus(items)
    }

    const handleInfoMouseOut = (index) => {
        let items = [false, false, false, false, false, false, false, false];
        let item = {...hoverStatus[index]};
        item = false;
        items[index] = item;
        setHoverStatus(items)
    }

    useEffect(() => {
    }, [hoverStatus])

    const registerModalBody = (
        <div className="buy-modal-body" style={{ background: 'white' }}>
            <div className="modal-header" >
                <Typography className={classes.defaultFont} style={{ color: '#251CFF', fontSize: isMobile ? 20 : 32, fontWeight: 'bold' }}>{t('registerModalTitle')}</Typography>
                <img src={crossIconBlue} className="modal-close-icon" onClick={handleCloseRegisterModal} />
            </div>

            <Typography className={classes.defaultFont} style={{ width: '85%', color: '#8786A7', marginTop: 60, marginBottom: 24, }}>{t('invite-input-title')}</Typography>

            <CustomTextField
                placeholder={t("invite-input-placeHolder")}
                label={''}
                style={{ width: '85%', textTransform: 'none' }}
                onChange={handleReferCodeChange}
                value={referCode}
                helperText={referCodeMsg}
                error={referCodeMsg !== ''}
                customtype="text"
            />

            <div className="modal-btn-wrapper">
                <button style={{ opacity: referCodeStatus !== 1 ? 0.5 : 1, background: '#271CFF', textTransform: 'none' }} className="buy-btn" onClick={registerInviter} disabled={referCodeStatus !== 1}>
                    <Typography style={{ fontSize: 20, fontWeight: 'bold', color: 'white' }}>
                        {t('registerInviter')}
                    </Typography>
                </button>
            </div>
        </div>
    );

    const infoModalBody = (
        <div className="buy-modal-body" style={{ background: 'white', height: 339, width: 354 }}>
            <div className="modal-header" >
                <Typography className={classes.defaultFont} style={{ color: '#251CFF', fontSize: isMobile ? 20 : 32, fontWeight: 'bold' }}>{infoTitle}</Typography>
                <img src={crossIconBlue} className="modal-close-icon" onClick={handleCloseInfoModal} />
            </div>
            <div className="info-modal-content">
                <Typography style={{ fontSize: 14, fontWeight: 'bold', color: '#363636', textAlign: 'center' }}>
                    {infoContent}
                </Typography>
            </div>
        </div>
    )

    useEffect(() => {
        if (loggedIn) {
            dispatch(walletActions.getUserCapital(token))
            dispatch(inviteActions.getInviteInfo(token))
            dispatch(inviteActions.getInviteCode(token))
            dispatch(inviteActions.getInvitee(token))
            dispatch(authActions.getUserInfo(token))
            dispatch(walletActions.getIDOInfo(token))
        } else {
            console.log('logging out !')
            dispatch(walletActions.clearInfo())
            dispatch(inviteActions.clearInviteInfo())
        }
    }, [loggedIn])

    useEffect(() => {
        // console.log('userCapitals: ', userCapitals)
        // console.log('token: ', token)
        // console.log('userInfo: ', userInfo.idoInvestment, typeof userInfo.idoInvestment)
        let usdt = userCapitals.find(item => item.token === 'USDT')
        if (usdt == undefined) {
            usdt = {
                free: 0
            }
        }
        setUsdtCapital(usdt.free)
        if (userInfo !== undefined) {
            setQuota(5000 - userInfo.idoInvestment)
        }
    }, [userCapitals, token, userInfo])

    useEffect(() => {
        if (inviteCode !== null && inviteCode.length === 6) {
            setHasInviteCode(true)
        } else {
            setHasInviteCode(false)
        }
        if (inviteInfo.uid === -1) {
            setInviterRegistered(false)
        } else {
            setInviterRegistered(true)
        }

        setInfoArray(
            [
                {name: 'currentLevel', value: inviteInfo.level === 0 ? '--' : `lv${inviteInfo.level.toString()}`},
                {name: 'totalInvited', value: inviteInfo.totalInvited},
                {name: 'directinvitee', value: inviteInfo.l1Invited},
                {name: 'indirectinvitee', value: inviteInfo.l2Invited},
                {name: 'thirdLevelInvitee', value: inviteInfo.l3Invited},
                {name: 'totalReward', value: inviteInfo.totalBonus},
                {name: 'rewardToRelease', value: inviteInfo.remainingBonus},
                {name: 'rewardReleased', value: inviteInfo.vestedBonus},
            ]
        )

        return () => {
        }
    }, [inviteCode, inviteInfo])

    useEffect(() => {
        // console.log('infoArray: ', infoArray)
    }, [infoArray])

    useEffect(() => {
        if (idoInfoMsg === 'succeed') {
            setIdoStartTime(idoInfo.startAt)
        }
    }, [idoInfo, idoInfoMsg])


    useEffect(() => {
        if (buyIDOFinished && buyIDOMsg !== '') {
            setShowNoteBtn(false)
            if (buyIDOSucceed) {
                handleOpenNote('succeed', t('buySucceed'), '', 2000)
            } else {
                handleOpenNote('failed', t('buyFailed'), t(buyIDOMsg))
            }
        }
        return () => {
        }
    }, [buyIDOMsg, buyIDOSucceed, buyIDOFinished])

    useEffect(() => {

        if (!loading && registerInviterMsg !== '') {
            if (registerInviterSucceed) {
                setShowNoteBtn(false)
                handleOpenNote('succeed', t('registerInviterSucceed'), '', 2000)
            } else {
                setShowNoteBtn(true)
                setBtnText(t('tryAgain'))
                handleOpenNote('failed', t('registerInviterFailed'), t(registerInviterMsg))
            }
        }
    }, [registerInviterMsg, loading, registerInviterSucceed])

    const copyReferCode = () => {
        setSnackMsg(t('copied'))
        setSnackBarVisible(true)
        copy(inviteCode.toUpperCase())
    }

    const tryAgain = () => {
        handleCloseNote()
        if (width < 960) {
            handleOpenRegisterModal()
        }
        setReferCode('')
    }

    const renderStep0 = () => {
        return (
            width <= 960 ?

                <div style={{ width: '91%', marginTop: 24 }} >
                    <div style={{ width: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <img src={warningicon} style={{ height: 28, marginRight: 8 }}></img>
                        <Typography className={classes.defaultFont} style={{ fontSize: 16, fontWeight: '500' }}>
                            {t('unlock')}
                        </Typography>
                    </div>
                    <Typography className={classes.defaultFont} style={{ marginTop: 16, color: '#8786A7', fontSize: 12 }}>
                        {t('unlockContent')}
                    </Typography>
                </div>
                :
                <div style={{ width: '91%', marginTop: 24 }} >
                    <div style={{ width: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <img src={warningicon} style={{ height: 28, marginRight: 8 }}></img>
                        <Typography className={classes.defaultFont} style={{ fontSize: 24, fontWeight: '500' }}>
                            {t('unlock')}
                        </Typography>
                    </div>
                    <Typography className={classes.defaultFont} style={{ marginTop: 16, color: '#8786A7' }}>
                        {t('unlockContent')}
                    </Typography>
                </div>
        )
    }

    const renderStep1 = () => {
        return (
            width <= 960 ?
                <div style={{ width: '91%', marginBottom: 24, marginTop: isMobile ? 0 : 0 }} >
                    <Typography className={classes.defaultFont} style={{ fontSize: 12 }}>
                        {t('inviteMobileStep1')}
                    </Typography>

                    {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 0 }}>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 24 }}>
                            <div style={{ width: '100%', alignItems: 'flex-start', }}>
                                <Typography style={{ textAlign: 'left', color: '#AFBDD0', fontFamily: 'Avenir', fontWeight: '600', fontSize: isMobile ? 12 : 14 }}>{t("invite-input-placeHolder")}</Typography>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', backgroundColor: 'white', marginTop: 8, borderRadius: 16, border: '1px solid #DCDCDC' }}>
                                <div style={{ marginLeft: isMobile ? 12 : 24, marginTop: isMobile ? 10 : 16, marginBottom: 12 }}>
                                    <NewCustomTextField
                                        placeholder={t("invite-input-placewarning")}
                                        label={''}
                                        onChange={handleReferCodeChange}
                                        value={referCode}
                                        // helperText={referCodeMsg}

                                        customtype="text"
                                    >

                                    </NewCustomTextField>
                                    <Typography style={{ marginLeft: 8, fontFamily: 'Avenir', fontWeight: '500', color: referCodeMsg == "" ? "#AFBDD0" : '#FF4646', fontSize: isMobile ? 12 : 14 }}> {referCodeMsg == "" ? t("hisRefeCode") : referCodeMsg}</Typography>
                                </div>


                            </div>
                        </div>
                    </div> */}
                </div>
                :
                <div style={{ width: '91%', marginTop: 24 }} >
                    <Typography className={classes.defaultFont}>
                        {t('inviteMobileStep1')}
                    </Typography>
                    {/* <CustomTextField
                        placeholder={t("invite-input-placeHolder")}
                        label={''}
                        style={{ width: '91%', textTransform: 'none' }}
                        onChange={handleReferCodeChange}
                        value={referCode}
                        helperText={referCodeMsg}
                        error={referCodeMsg !== ''}
                        customtype="text"
                    /> */}

                    {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '91%', backgroundColor: 'white', marginTop: 8, borderRadius: 16, border: '2px solid #DCDCDC' }}>
                        <div style={{ marginLeft: 12, marginRight: 12, marginTop: 12, marginBottom: 12, width: '100%' }}>
                            <NewCustomTextField


                                placeholder={t("invite-input-placeHolder")}
                                label={''}
                                onChange={handleReferCodeChange}
                                value={referCode}
                                helperText={referCodeMsg}
                                error={referCodeMsg !== ''}
                                customtype="text"
                            >

                            </NewCustomTextField>
                        </div>

                    </div> */}

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 12 }}>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 24 }}>
                            <div style={{ width: '100%', alignItems: 'flex-start', }}>
                                <Typography style={{ textAlign: 'left', color: '#AFBDD0', fontFamily: 'Avenir', fontWeight: '600', fontSize: isMobile ? 12 : 14 }}>{t("invite-input-placeHolder")}</Typography>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', backgroundColor: 'white', marginTop: 8, borderRadius: 16, border: '1px solid #DCDCDC' }}>
                                <div style={{ marginLeft: isMobile ? 12 : 24, marginTop: isMobile ? 10 : 16, marginBottom: 12 }}>
                                    <NewCustomTextField
                                        placeholder={t("invite-input-placewarning")}
                                        label={''}
                                        onChange={handleReferCodeChange}
                                        value={referCode}
                                        // helperText={referCodeMsg}

                                        customtype="text"
                                    >

                                    </NewCustomTextField>
                                    <Typography style={{ marginLeft: 8, fontFamily: 'Avenir', fontWeight: '500', color: referCodeMsg == "" ? "#AFBDD0" : '#FF4646', fontSize: isMobile ? 12 : 14 }}> {referCodeMsg == "" ? t("hisRefeCode") : referCodeMsg}</Typography>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
        )
    }

    const renderStep2 = () => {
        return (
            <div style={{ width: '91%', marginTop: isMobile ? 0 : 24 }} >
                <div style={{ width: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <img src={successIcon} style={{ height: isMobile ? 24 : 28, marginRight: 8 }} />
                    <Typography className={classes.defaultFont} style={{ fontSize: isMobile ? 16 : 20, fontWeight: '500' }}>
                        {t('inviteDone')}
                    </Typography>
                </div>
                <Typography className={classes.defaultFont} style={{ marginTop: 16, color: '#8786A7', fontSize: isMobile ? 12 : 16 }}>
                    {t('inviteDoneContent')}
                </Typography>
            </div>
        )
    }

    const renderStep3 = () => {
        return (
            <div style={{ width: '91%', marginTop: isMobile ? 'unset' : 24 }} >
                <div style={{ width: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <img src={successIcon} style={{ height: isMobile ? 32 : 40, marginRight: 8 }} />
                    <Typography className={classes.defaultFont} style={{ fontSize: isMobile ? 16 : 24, fontWeight: '500' }}>
                        {t('codeGenerated')}
                    </Typography>
                </div>
                <Typography className={classes.defaultFont} style={{ marginTop: 16, color: '#8786A7', fontSize: isMobile ? 14 : 16 }}>
                    {t('codeGeneratedContent')}
                </Typography>
            </div>
        )
    }

    const renderInfoElement = (item, index) => {
        let needCheck = (inviteInfo.level < 2 && item.name === 'indirectinvitee' ) || ( inviteInfo.level < 3 && item.name === 'thirdLevelInvitee')
        return (
            <LightTooltip title={t(`${item.name}Note`)}>
                <div key={item.name} className={hoverStatus[index] ? 'info-wrap info-wrap-hover' : 'info-wrap'} onMouseEnter={() => handleInfoMouseOver(index)} onMouseLeave={() => handleInfoMouseOut(index)}>
                    <img src={hoverStatus[index] ? infoInvert : infoIcon} className="info-note-btn" />
                    <div className="info-container">
                        <p className={hoverStatus[index] ? 'info-title info-txt-hover' : 'info-title'} >
                            {t(item.name)}
                        </p>
                        <p onClick={needCheck ? handleOpenBuyModal : null} className={hoverStatus[index] ? 'info-value info-txt-hover' : needCheck ? 'info-value info-txt-check' : 'info-value'} style={{ fontSize: needCheck ? 18 : item.name === 'currentLevel' ? 24 : roundingDown(item.value, 4).length > 3 ? 24 : 32 }}>
                            {
                                 needCheck ?
                                    t('levelUp') :
                                    item.name === 'currentLevel' ? t(item.value) : roundingDown(item.value, 4)
                            }
                        </p>
                    </div>
                </div>
            </LightTooltip>
        )
    }

    const renderMobileInfoElement = (item) => {
        let needCheck = (inviteInfo.level < 2 && item.name === 'indirectinvitee' ) || ( inviteInfo.level < 3 && item.name === 'thirdLevelInvitee')

        return (
            <div key={item.name} className="info-wrap" style={{ backgroundColor: '#FFFFFF' }}>
                <img src={infoIcon} className="info-note-btn" onClick={() => handleOpenInfoModal(item)} />
                <div className="info-container">
                    <Typography style={{ fontSize: 14, color: '#8B96A7', marginBottom: 7 }}>
                        {t(item.name)}
                    </Typography>
                    <Typography onClick={needCheck ? handleOpenBuyModal : null} style={{ marginTop: 7, cursor: needCheck ? 'pointer' : 'unset', fontSize: needCheck ? 16 : 20, color: needCheck ? '#251CFF' : '#363636', fontFamily: 'Syncopate' }}>
                        {
                            needCheck ?
                                t('levelUp') :
                                item.name === 'currentLevel' ? t(item.value) : roundingDown(item.value, 4)
                        }
                    </Typography>
                </div>
            </div>
        )
    }

    return (
        <div className="ido-invite-body" style={{marginTop:40}}>

            {
                width > 960 ?
                    null :
                    <div className="ido-mobile-bg-wrapper" style={{ zIndex: hasInviteCode ? 'unset' : -2 }}>
                        {
                            hasInviteCode ?
                                <div className="invite-info">
                                    <div className="info-body">
                                        {
                                            infoArray.map(item => renderMobileInfoElement(item))
                                        }
                                    </div>
                                    {/*<div className="info-wrap buy-more" onClick={handleOpenBuyModal}>*/}
                                    {/*    <Typography className="buy-more-title" >*/}
                                    {/*        {`${t('buyMore')}`}*/}
                                    {/*    </Typography>*/}
                                    {/*</div>*/}
                                </div>
                                :
                                <img className="ido-mobile-bg" src={inviteBanner} style={{ width: width }} />

                        }
                    </div>
            }
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: width <= 960 ? "100%" : '61%' }}>
                <div style={{ display: 'flex', flexDirection: width <= 960 ? "column" : 'row', justifyContent: width <= 960 ? "unset" : 'space-between', }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: width <= 960 ? 'unset' : "#f9f9f9",
                        justifyContent: 'space-between',
                        width: width <= 960 ? "100%" : '38%',
                        height: width <= 960 ? "unset" : 467,
                    }}>
                        {
                            hasInviteCode && width <= 960 ?
                                null :
                                <div style={{ marginTop: isMobile ? 40 : '3%', width: '91%', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <Typography style={{ fontSize: width <= 960 ? 24 : 32, fontFamily: 'Syncopate', color: '#251CFF' }}>{t('ido-invite')}</Typography>
                                    <Typography className={classes.defaultFont} style={{ fontSize: 14, fontWeight: 'bold', color: '#363636', textAlign: 'left' }}>
                                        {t('invite-announce')}
                                    </Typography>
                                </div>
                        }
                        {
                            width <= 960 ?
                                null
                                :
                                <div style={{ justifyContent: 'flex-end' }}>
                                    <img src={inviteBanner} style={{ width: '80%' }} />
                                </div>
                        }
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: width <= 960 ? 'unset' : "#f9f9f9",
                        width: width <= 960 ? "100%" : '60%',
                        height: width <= 960 ? 300 : 467,

                        justifyContent: 'space-around',
                        marginBottom: isMobile ? 0 : 60

                    }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: isMobile ? 'unset' : 24, width: '100%', height: isMobile ? 'unset' : '100%', justifyContent: width <= 960 ? 'center' : 'flex-start', alignItems: 'flex-start' }}>
                            <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }} >
                                {
                                    loggedIn ?
                                        inviterRegistered ?
                                            hasInviteCode ?
                                                renderStep3() :
                                                renderStep2() :
                                            renderStep1() :
                                        renderStep0()
                                }

                            </div>
                        </div>
                        <div style={{ marginTop: width <= 960 ? 'unset' : 24, height: isMobile ? 'unset' : '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {
                                address.length < 42 || !isValidAddress(address) ?
                                    <Button style={{ borderRadius: 12, height: 70, width: '91%', backgroundColor: '#4477F1' }} onClick={!window.ethereum ? () => onClickInstall(sendBackButton1, sendBackButton1Disabled) : onClickConnect}>
                                        <Typography style={{ fontSize: 20, fontWeight: 'bold', color: '#010846', textTransform: 'none' }}>
                                            {button1}
                                        </Typography>
                                    </Button>
                                    :
                                    null
                            }

                            {
                                address.length === 42 && isValidAddress(address) && !loggedIn ?
                                    <Button style={{ borderRadius: 12, height: 70, width: '91%', backgroundColor: button2Disabled ? '#8BBDFF' : '#F5573A' }}
                                        className={button2Disabled ? classes.btn_disabled : classes.btn} onClick={(!registered || !loggedIn) && !loading ? () => unlock(address, chainId, network, code, Web3, registered, dispatch, setOpenSliderModal, setSignUpPayload) : null} variant="outlined" color="primary" disabled={button2Disabled}>
                                        <Typography style={{ fontSize: 20, fontWeight: 'bold', color: 'white', textTransform: 'none' }}>
                                            {button2}
                                        </Typography>
                                    </Button> : null
                            }

                            {
                                isValidAddress(address) && loggedIn ?
                                    inviterRegistered ?
                                        hasInviteCode ?
                                            <div className="my-code-wrapper" style={{ marginTop: isMobile ? 10 : 0 }} >
                                                <div className="my-code-container" style={{ marginLeft: 16 }} >
                                                    <Typography className={classes.defaultFont} style={{ fontWeight: isMobile ? 600 : 'unset', color: 'white', fontSize: isMobile ? 14.4 : 16, opacity: isMobile ? 1 : 0.7 }} >{t('myReferCode')}</Typography>
                                                    <Typography className={classes.defaultFont} style={{ lineHeight: isMobile ? 'unset' : 1.5, fontWeight: isMobile ? 600 : 'unset', fontFamily: isMobile ? 'PingFang SC' : 'Syncopate', fontSize: isMobile ? 44 : 24, color: 'white' }}>{inviteCode.toUpperCase()}</Typography>
                                                </div>
                                                <button className="copy-btn" onClick={copyReferCode} style={{ width: '50%', alignItems: 'flex-end', display: 'flex', justifyContent: 'flex-end' }}>
                                                    <Typography className={classes.defaultFont} style={{ color: 'white', fontWeight: 'bold' }}>
                                                        {t('copy')}
                                                    </Typography>
                                                </button>
                                            </div>
                                            :
                                            <Button style={{ borderRadius: 12, height: 70, width: '91%' }}
                                                className={classes.registerBtn} onClick={handleOpenBuyModal} variant="outlined" color="primary" disabled={buyBtnDisabled}>
                                                <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>
                                                    {t('buy')}
                                                </Typography>
                                            </Button> :
                                        width <= 960 ?
                                            <Button style={{ borderRadius: 12, height: 70, width: '91%', opacity: cantRegister ? 0.5 : 1 }}
                                                className={classes.registerBtn} onClick={handleOpenRegisterModal} variant="outlined" color="primary" disabled={cantRegister}>
                                                <Typography style={{ fontSize: 20, fontWeight: 'bold', color: 'white' }}>
                                                    {t('start')}
                                                </Typography>
                                            </Button> :
                                            <Button style={{ borderRadius: 12, height: 70, width: '91%', opacity: referCodeStatus !== 1 ? 0.5 : 1 }}
                                                className={classes.registerBtn} onClick={registerInviter} variant="outlined" color="primary" disabled={referCodeStatus !== 1}>
                                                <Typography style={{ fontSize: 20, fontWeight: 'bold', color: referCodeStatus !== 1 ? 'white' : 'black' }}>
                                                    {t('registerInviter')}
                                                </Typography>
                                            </Button>
                                    : null
                            }

                        </div>
                    </div>


                </div>
                {
                    hasInviteCode && width > 960 ?
                        <div className="invite-info">
                            <div className="info-body">
                                {
                                    infoArray.map((item, index) => renderInfoElement(item, index))
                                }
                                {/*<div className="info-wrap buy-more" onClick={handleOpenBuyModal}>*/}
                                {/*    <p className="buy-more-title" >*/}
                                {/*        {`+ ${t('buyMore')}`}*/}
                                {/*    </p>*/}
                                {/*</div>*/}
                            </div>
                        </div> : null
                }
                {
                    hasInviteCode ?
                        <div className="invite-detail">
                            <div className="detail-body" >
                                <p className="detail-title">{t('detailTitle')}</p>
                                <p className="detail-content">{t('detailContent')}</p>
                                <div className="invitee-list-wrap">
                                    <div className="invitee-list-header">
                                        <p className="header-text">
                                            {t('walletAddress')}
                                        </p>
                                        <p className="header-text">
                                            {t('inviteTime')}
                                        </p>
                                    </div>
                                    <List className="invitee-list">
                                        {
                                            invitees.length > 0 ?
                                                invitees.map(item => (
                                                    <div key={item.username} className="invitee-list-item">
                                                        <p className="list-item-text">
                                                            {item.username}
                                                        </p>
                                                        <p className="list-item-text">
                                                            {convertTimeString(item.createdAt)}
                                                        </p>
                                                    </div>
                                                )) : <p className="no-invitee">{t('noInvitee')}</p>
                                        }
                                    </List>
                                </div>
                            </div>

                        </div> : null
                }
            </div>

            <Note btnAction={tryAgain} btnText={btnText} width={width} height={height} openNote={openNote} handleCloseNote={handleCloseNote} showBtn={showNoteBtn} noteType={noteType} noteTitle={noteTitle} noteContent={noteContent} closeAction={inviterRegistered ? null : walletActions.clearInfo} />
            <IDOBuyModal
                onCancel={handleCloseBuyModal}
                modalVisible={openBuyModal}
                t
                width={width}
                address={address}
                chainId={chainId}
                network={network}
                height={height}
            />
            <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                aria-labelledby="server-modal-title"
                aria-describedby="server-modal-description"
                className="buy-modal"
                open={openRegisterModal}
                onClose={handleCloseRegisterModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openRegisterModal}>
                    {registerModalBody}
                </Fade>
            </Modal>
            <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                aria-labelledby="server-modal-title"
                aria-describedby="server-modal-description"
                className="buy-modal"
                open={openInfoModal}
                onClose={handleCloseInfoModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openInfoModal}>
                    {infoModalBody}
                </Fade>
            </Modal>
            <Snackbar
                open={snackBarVisible}
                onClose={handleSnackClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={3000}
                TransitionComponent={Fade}
                message={snackMsg}
                key={"cancelSign"}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />

        </div>
    )
}

export default withTranslation()(IDOInvite);
