import {
    GET_PROPOSALS,
    GET_PROPOSALS_FAILED,
    GET_PROPOSALS_SUCCEED,
    GET_VOTE_HISTORY,
    GET_VOTE_HISTORY_FAILED,
    GET_VOTE_HISTORY_SUCCEED,
    VOTE_SIGNING,
    VOTE_SIGNING_CANCELLED,
    VOTE,
    VOTE_SUCCEED,
    VOTE_FAILED,
    CLEAR_VOTE_STATE,
    GET_L3_REWARD,
    GET_L3_REWARD_SUCCEED,
    GET_L3_REWARD_FAILED
} from '../constants';

export function vote(state = {
    proposals: [],
    voteHistory: [],
    voteSigning: false,
    l3RewardInfo: {}
}, action) {
    switch (action.type) {
        case GET_PROPOSALS:
            return {
                ...state,
                proposalsLoading: true
            }
        case GET_PROPOSALS_SUCCEED:
            return {
                ...state,
                proposals: action.res.data.sort(function (a, b) {
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return b.id - a.id;
                }),
                proposalsLoading: false
            }
        case GET_PROPOSALS_FAILED:
            return {
                ...state,
                proposals: [],
                message: action.error,
                proposalsLoading: false
            }


        case GET_VOTE_HISTORY:
            return {
                ...state,
                voteHistoryLoading: true
            }
        case GET_VOTE_HISTORY_SUCCEED:
            return {
                ...state,
                voteHistory: action.res.data.sort(function (a, b) {
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return new Date(b.createdAt) - new Date(a.createdAt);
                }),
                voteHistoryLoading: false
            }
        case GET_VOTE_HISTORY_FAILED:
            return {
                ...state,
                voteHistory: [],
                message: action.error,
                voteHistoryLoading: false
            }

        case GET_L3_REWARD:
            return {
                ...state,
                l3MessageLoding: true
            }
        case GET_L3_REWARD_SUCCEED:
            return {
                ...state,
                l3RewardInfo: action.res.data,
                l3MessageLoding: false,
                l3MessageSucceed: true,
            }
        case GET_L3_REWARD_FAILED:
            return {
                ...state,
                l3RewardInfo: {},
                l3MessageSucceed: true,
                voteHistoryLoading: false
            }


        case VOTE:
            return {
                ...state,
                voteLoading: true,
                voteSucceed: false,
                voteFailed: false,
                voteSigning: false,
            }
        case VOTE_SUCCEED:
            return {
                ...state,
                voteMessage: action.msg,
                voteLoading: false,
                voteSucceed: true,
            }
        case VOTE_FAILED:
            return {
                ...state,
                voteMessage: action.error,
                voteLoading: false,
                voteFailed: true,
            }
        case CLEAR_VOTE_STATE:
            return {
                ...state,
                voteFailed: false,
                voteSucceed: false,
            }


        case VOTE_SIGNING:
            return {
                ...state,
                voteSigning: true,
            }
        case VOTE_SIGNING_CANCELLED:
            return {
                ...state,
                voteSigning: false,
            }
        default:
            return state;
    }
    return state;
}