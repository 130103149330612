import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { history } from "../../../utils/History";
import { Avatar, Button, Grid, List, ListItem, makeStyles, Typography } from "@material-ui/core";
import { roundingDown } from "../../../utils/RoundingDown";
import { swapActions } from "../../../redux/actions/swapActions";
import useWindowDimensions from "../../../utils/WindowDimensions";
import { useDispatch, useSelector } from "react-redux";
import SwapRecordDetail from "./SwapRecordDetail";
import { convertTimeString, getIcons } from "../../../utils/Common";
import backIcon from "../../../images/backIcon.png";
import { isMobile } from 'react-device-detect';
const backArrow = 'https://cdn.swapallapp.com/l2/images/backArrow.png'

function SwapRecord({ t, navBarHeight, address, setOpenSliderModal, handleCloseSliderModal, setSignUpPayload, sliderInfo, network, chainId, code,
    sendBackButton1, sendBackButton1Disabled, button1, button1Disabled,
    sendBackButton2, sendBackButton2Disabled, button2, button2Disabled
}) {
    const { height, width } = useWindowDimensions();
    const dispatch = useDispatch();
    const { swapTransactions } = useSelector(state => state.swap)
    const { token, registered, loggedIn, loggingIn, loading } = useSelector(state => state.auth)
    const { tokenIcons } = useSelector(state => state.wallet)

    const useStyles = makeStyles((theme) => ({
        root: {
            ...theme.typography.button,
            backgroundColor: '#010846',
            padding: theme.spacing(1),
            flexGrow: 1,
            textAlign: 'center',
            height: 1000
        },
        btn: {
            backgroundColor: '#83ACF4',
            color: '#010846',
            borderColor: 'transparent',
            borderRadius: 25,
            height: 45,
            fontWeight: 600,
            minWidth: 100
        },
        btn_disabled: {
            backgroundColor: '#83ACF4',
            color: '#010846',
            borderColor: 'transparent',
            borderRadius: 25,
            height: 45,
            fontWeight: 600,
            opacity: 0.2
        },
        paper: {
            position: 'absolute',
            width: 400,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        backArrow: {
            width: 20,
            height: 20,
        },
        list: {
            width: 400,
            height: 400,
            backgroundColor: theme.palette.background.paper,
            overflow: 'auto',
        },
        defaultFont: {
            fontWeight: '500', color: '#363636', fontFamily: 'Avenir', textTransform: 'none', fontSize: isMobile ? 10 : 14
        },
    }));
    const classes = useStyles();

    const [swapHistoryVisible, setSwapHistoryVisible] = useState(false);
    const [swapHistoryValue, setSwapHistoryValue] = useState({});
    useEffect(() => {
        if (loggedIn) {
            dispatch(swapActions.getSwapTransactions(token))
        }
        return () => {
        }
    }, [])

    const renderSwapTransactions = (pool) => {
        return (
            <div style={{ marginBottom: 32 }}>
                {
                    <ListItem
                        button
                        onClick={() => {
                            // setSwapHistoryValue(pool)
                            // setSwapHistoryVisible(true)
                        }}
                        style={{ backgroundColor: 'white', borderRadius: 16, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}
                    >

                        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: 16, width: '100%', justifyContent: 'space-between' }}>

                            {/* <div style={{ flexDirection: 'row', display: 'flex', backgroundColor: '#F5F5F5', height: 48, alignItems: 'center', width: 100, justifyContent: 'space-between', borderRadius: 24 }}>

                                <Avatar variant="square" src={getIcons(pool.fromToken, tokenIcons, true)} style={{ height: 24, width: 24, marginLeft: 20 }} />
                                <Avatar variant="square" src={getIcons(pool.toToken, tokenIcons, true)} style={{ height: 24, width: 24, marginRight: 20 }} />

                            </div> */}


                            <Typography className={classes.defaultFont} style={{ fontSize: isMobile ? 20 : 24 }} >
                                {/* {pool.fromToken} {t('swap-swap')} {pool.toToken} */}
                                {t('swap-swap')}
                            </Typography>
                            {/* <Typography style={{ fontSize: 12, color: '#251CFF', marginTop: -4, fontWeight: 'bold' }}>
                                    {t('price')}:1 {pool.fromToken} = {roundingDown((Math.abs(pool.toAmount)) / (Math.abs(pool.fromAmount)), 8)} {pool.toToken}
                                </Typography> */}

                            <Typography className={classes.defaultFont} style={{ fontSize: isMobile ? 20 : 24 }}>
                                {/* {transactionType[item.type].sign + roundingDown(item.amount, 4)} {item.token} */}
                                {pool.fromAmount} {pool.fromToken}
                            </Typography>


                        </div>
                        <div style={{ height: 1, width: '100%', backgroundColor: '#EAEAEA', marginTop: 8 }}></div>


                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 8, width: '100%' }}>
                            <Typography className={classes.defaultFont} style={{ color: '#8A96A7' }}>

                                {t('swap-from')}

                            </Typography>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography className={classes.defaultFont} style={{}} >
                                    {pool.fromAmount} {pool.fromToken}
                                </Typography>
                            </div>
                        </div>
                        {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 24, width: '100%' }}>
                            <Typography style={{ fontSize: 14, color: '#363636', fontWeight: 'bold' }}>
                                {t('swap-from')}
                            </Typography>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ fontSize: 14, color: '#363636', fontWeight: 'bold', marginRight: 8 }} >
                                    {pool.fromAmount} {pool.fromToken}
                                </Typography>
                            </div>
                        </div> */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 8, width: '100%' }}>
                            <Typography className={classes.defaultFont} style={{ color: '#8A96A7' }}>

                                {t('swap-to')}

                            </Typography>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography className={classes.defaultFont} style={{ color: '#251CFF' }} >
                                    +{roundingDown(pool.toAmount, 8)} {pool.toToken}
                                </Typography>
                            </div>
                        </div>
                        {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 8, width: '100%' }}>
                            <Typography style={{ fontSize: 14, color: '#363636', fontWeight: 'bold' }}>
                                {t('swap-to')}
                            </Typography>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ fontSize: 14, color: '#363636', fontWeight: 'bold', marginRight: 8 }} >
                                    +{roundingDown(pool.toAmount, 8)} {pool.toToken}
                                </Typography>
                            </div>
                        </div> */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 8, width: '100%' }}>
                            <Typography className={classes.defaultFont} style={{ color: '#8A96A7' }}>

                                {t('swap-fee')}
                            </Typography>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography className={classes.defaultFont}  >
                                    {roundingDown(pool.fee, 8)} {pool.fromToken}

                                </Typography>
                            </div>
                        </div>
                        {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 8, width: '100%' }}>
                            <Typography style={{ fontSize: 14, color: '#363636', fontWeight: 'bold' }}>
                                {t('swap-fee')}
                            </Typography>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ fontSize: 14, color: '#363636', fontWeight: 'bold', marginRight: 8 }} >
                                    {roundingDown(pool.fee, 8)} {pool.fromToken}
                                </Typography>
                            </div>
                        </div> */}


                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 8, width: '100%', marginBottom: 12 }}>
                            <Typography className={classes.defaultFont} style={{ color: '#8A96A7' }}>

                                {t('swap-time')}
                            </Typography>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography className={classes.defaultFont}  >
                                    {convertTimeString(pool.date)} (UTC)

                                </Typography>
                            </div>
                        </div>
                        {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 8, width: '100%', marginBottom: 24 }}>
                            <Typography style={{ fontSize: 14, color: '#363636', fontWeight: 'bold' }}>
                                {t('swap-time')}
                            </Typography>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ fontSize: 14, color: '#363636', fontWeight: 'bold', marginRight: 8 }} >
                                    {convertTimeString(pool.date)} (UTC)
                                </Typography>
                            </div>
                        </div> */}
                        {/* <Typography>
                            +{roundingDown(pool.toAmount, 8) + pool.toToken}
                        </Typography>
                        <Typography>
                            {pool.fromAmount} {pool.fromToken}
                        </Typography> */}
                        {/* <Typography>
                            {pool.date}
                        </Typography> */}
                    </ListItem>
                }
            </div>
        )
    }

    return (
        <div style={{ backgroundColor: 'white', display: 'flex', minHeight: 900, flexDirection: 'column', marginBottom: 40 }}>
            <div className='records__container'>
                <div style={{
                    width: "91%",
                    display: "flex",
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',


                }}>
                    {
                        isMobile ?
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', marginTop: 16, marginBottom: 8 }}>
                                <Typography style={{ fontSize: 24, fontFamily: 'Syncopate', color: '#251CFF' }}>  {t('records')}</Typography>

                                <Button style={{ color: "white", backgroundColor: '#251CFF', fontSize: 12, textTransform: 'none', height: 32, borderRadius: 8 }} onClick={() => {
                                    history.back()
                                }} >
                                    {t("back")}
                                </Button>
                            </div>
                            :
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', marginTop: 24, marginBottom: 24 }}>

                                <Button style={{ backgroundColor: 'white' }} onClick={() => {
                                    history.back()
                                }} >
                                    <img alt='' style={{ height: 24 }} src={backIcon} />
                                </Button>
                                {/* <Typography style={{ fontSize: 24, fontFamily: 'Syncopate', color: '#251CFF' }}>  {t('records')}</Typography> */}

                            </div>
                    }
                    <div style={{ width: '100%' }}>
                        <div style={{}}>

                            {/* <div style={{ marginTop: 24, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                <Typography style={{ textTransform: 'none', fontSize: 24, fontWeight: '600', color: '#8786A7' }} gutterBottom>
                                    {t('swap-title')} {t('records')}
                                </Typography>
                            </div> */}
                            {loggedIn ?
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        {
                                            swapTransactions !== "" ?
                                                <List style={{}}>
                                                    {swapTransactions.map(pool => {
                                                        return renderSwapTransactions(pool)
                                                    })}


                                                </List>
                                                :
                                                <Typography style={{ fontSize: 13, color: '#8786A7', marginTop: 24 }}>{t('noRecords')}</Typography>

                                        }



                                    </Grid>
                                </Grid> : null
                            }
                        </div>
                        {/*记录详情*/}
                        {swapHistoryValue && Object.keys(swapHistoryValue).length ? (
                            <SwapRecordDetail
                                onCancel={() => {
                                    setSwapHistoryVisible(false);
                                    setSwapHistoryValue({});
                                }}
                                modalVisible={swapHistoryVisible}
                                values={swapHistoryValue}
                            />
                        ) : null}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default withTranslation()(SwapRecord);
