import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Avatar, Backdrop, Button, Fade, Grid, List, ListItem, Modal, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { isValidAddress } from 'ethereumjs-util'
import { walletActions } from '../../redux/actions/walletActions';
import { useDispatch, useSelector } from 'react-redux';
import useWindowDimensions from '../../utils/WindowDimensions'
import { convertTimeString, getChain, getChainInfoByName, getIcons, getScan, isTest } from "../../utils/Common";
import { roundingDown } from "../../utils/RoundingDown";
import { history } from "../../utils/History";
import CustomButton from "../CommonElements/CustomButton";
import { isMobile } from 'react-device-detect';
import { onClickConnect, onClickInstall, unlock } from "../../utils/Sign";
import backIcon from "../../images/backIcon.png";

import './Record.css';
const backArrow = 'https://cdn.swapallapp.com/l2/images/backArrow.png'

const Web3 = require("web3");

function Records({ t, navBarHeight, address, setOpenSliderModal, handleCloseSliderModal, setSignUpPayload, sliderInfo, chainId, network, code,
    sendBackButton1, sendBackButton1Disabled, button1, button1Disabled,
    sendBackButton2, sendBackButton2Disabled, button2, button2Disabled }) {
    const { height, width } = useWindowDimensions();
    const useStyles = makeStyles((theme) => ({
        root: {
            ...theme.typography.button,
            backgroundColor: '#010846',
            padding: theme.spacing(1),
            flexGrow: 1,
            height: height - navBarHeight,
            textAlign: 'center'
        },
        walletBox: {
            width: 441,
            borderRadius: 24,
            backgroundColor: '#101B66',
            justifyContent: 'center',
            display: 'inline-flex',
            marginTop: 100,
            paddingTop: 30,
            paddingBottom: 30,

        },
        walletContent: {
            width: 300,
            alignItems: 'flex-start',
        },

        result: {
            fontSize: 10,
            color: '#000000',
            width: 200
        },
        btn: {
            backgroundColor: '#83ACF4',
            color: '#010846',
            borderColor: 'transparent',
            borderRadius: 25,
            height: 45,
            fontWeight: 600,
        },
        btn_disabled: {
            backgroundColor: '#83ACF4',
            color: '#010846',
            borderColor: 'transparent',
            borderRadius: 25,
            height: 45,
            fontWeight: 600,
            opacity: 0.2
        },
        wrapper: {
            color: 'white',
            alignItems: 'flex-start',
        },
        capitalList: {
            width: '100%',
            backgroundColor: 'transparent',
            // minHeight: isMobile ? 200 : 400,
            overflow: 'auto',
        },
        backBtn: {
            float: 'left'
        },
        backArrow: {
            width: 20,
            height: 20
        },
        textMid: {
            color: '#8786A7',
            fontSize: 20,
            fontWeight: '600'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // width: width * 0.5,
            // height: height * 0.5
        },
        paper: {
            backgroundColor: 'white',
            border: 'transparent',
            // boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            borderRadius: 20,
            textAlign: 'center'
        },

        defaultFont: {
            fontWeight: '500', color: '#363636', fontFamily: 'Avenir', textTransform: 'none', fontSize: isMobile ? 10 : 14
        },
    }));
    const classes = useStyles();
    const dispatch = useDispatch()
    const location = useLocation();

    const { token, loggedIn, registered, loading } = useSelector(state => state.auth)
    const { tokenIcons, transactionRecords } = useSelector(state => state.wallet)
    const [time, setTime] = useState('')
    const [amount, setAmount] = useState(0)
    const [type, setType] = useState(0)
    const [state, setState] = useState('')
    const [hash, setHash] = useState('')
    const [openRecordDetail, setOpenRecordDetail] = useState(false)
    const [coin, setCoin] = useState('')
    const [arrangedRecords, setArrangedRecords] = useState([])
    const [recordScan, setRecordScan] = useState('')
    const [recordChain, setRecordChain] = useState('')

    const handleOpenRecordDetail = (item) => {
        setOpenRecordDetail(true);
        setTime(convertTimeString(item.createdAt))
        setAmount(item.amount)
        setType(item.type)
        setState(item.status)
        setHash(item.txId)
        setCoin(item.token)
        let test = isTest(network, chainId)
        if (item.chain !== null) {
            let recordChainInfo = getChainInfoByName(item.chain, test)
            console.log('item: ', item.chain, test, recordChainInfo)
            setRecordChain(getChain(recordChainInfo.networkId, recordChainInfo.chainId))
            setRecordScan(getScan(recordChainInfo.networkId, recordChainInfo.chainId))
        }
    };

    const handleCloseRecordDetail = () => {
        setOpenRecordDetail(false);
    };

    const transactionType = {
        1: { name: t('depositAction'), sign: '+' },
        2: { name: t('withdrawAction'), sign: '-' },
        3: { name: 'add', sign: '-' },
        4: { name: 'remove', sign: '+' },
        5: {
            name: {
                From: t('tabs.swap'),
                To: t('swap.swapReward')
            }, sign1: '+', sign2: '-'
        },
        6: { name: t('transactionHistory.collectReward'), sign: '+' },
        9: { name: t('transactionHistory-reward'), sign: '+' },
        0: { name: '', sign: '' }
    }
    const status = {
        0: t('submitted'),
        1: t('submitted'),
        2: t('processing'),
        3: t('succeed'),
        4: t('failed')
    }
    const depositStatus = {
        0: t('submitted'),
        1: t('submitted'),
        2: t('closed'),
        3: t('succeed'),
        4: t('failed')
    }
    const color = {
        0: '#ffd700',
        1: '#ffd700',
        2: '#76a26f',
        3: '#0f9d58',
        4: '#ff0040',
        5: '#3460B7',
        6: '#0f9d58',
    }

    useEffect(() => {
        if (loggedIn) {
            dispatch(walletActions.getTransactionRecords(token))
            dispatch(walletActions.getAllTokenStatus(token))
        }

        return () => {
        }
    }, [])

    useEffect(() => {
        if (loggedIn) {
            dispatch(walletActions.getTransactionRecords(token))
            dispatch(walletActions.getAllTokenStatus(token))
        }

        return () => {
        }
    }, [loggedIn])

    useEffect(() => {
        let temRecords = []
        if (transactionRecords !== undefined) {
            for (let i = 0; i < transactionRecords.length; i++) {
                if (transactionRecords[i].type === 1 || transactionRecords[i].type === 2 || transactionRecords[i].type === 8 || transactionRecords[i].type === 9) {
                    let temRecord = transactionRecords[i]
                    temRecord.chain = transactionRecords[i].chain
                    temRecords.push(temRecord)
                }
            }
            setArrangedRecords(temRecords)
        }
        return () => {
        }
    }, [transactionRecords])

    const setupStatusColor = (status) => {
        if (status === 3) {
            return "#1DF0A9"
        }
        else if (status === 4) {
            return "#FF4646"
        }
        else {
            return "#75E2F9"
        }

    };

    return (
        <div style={{ backgroundColor: 'white', display: 'flex', minHeight: 900, flexDirection: 'column', marginBottom: 40 }}>
            <div className='records__container'>
                <div style={{
                    width: "91%",
                    display: "flex",
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',

                }}>
                    {/* <Button style={{ backgroundColor: 'white' }} onClick={() => {
                        history.back()
                    }} >
                        <img style={{ height: 24 }} src={backIcon} />
                    </Button> */}
                    {
                        isMobile ?
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', marginTop: 16, marginBottom: 8 }}>
                                <Typography style={{ fontSize: 24, fontFamily: 'Syncopate', color: '#251CFF' }}>  {t('records')}</Typography>

                                <Button style={{  color: "white", backgroundColor: '#251CFF', fontSize: 12, textTransform: 'none', height: 32, borderRadius: 8 }} onClick={() => {
                                    history.back()
                                }} >
                                    {t("back")}
                                </Button>
                            </div>
                            :
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', marginTop: 24, marginBottom: 24 }}>

                                <Button style={{ backgroundColor: 'white' }} onClick={() => {
                                    history.back()
                                }} >
                                    <img alt='' style={{ height: 24 }} src={backIcon} />
                                </Button>
                                {/* <Typography style={{ fontSize: 24, fontFamily: 'Syncopate', color: '#251CFF' }}>  {t('records')}</Typography> */}

                            </div>
                    }

                    <Grid container spacing={2} style={{ margin: 0 }}>

                        <Grid item xs={12} >
                            <div className={classes.wrapper}>
                                {!loggedIn ? null : arrangedRecords === undefined || arrangedRecords.length <= 0 ?
                                    <Typography style={{ fontSize: 13, color: '#8786A7' }}>{t('noRecords')}</Typography> :
                                    <List className={classes.capitalList}>
                                        {
                                            arrangedRecords.map(item => (
                                                item.type === 8 ?

                                                    <ListItem style={{ backgroundColor: 'white', flexDirection: 'column', marginBottom: 24, display: 'flex', borderRadius: 16, width: '98%', overflow: 'hidden', alignItems: 'flex-start' }}
                                                        key={item.id} button
                                                    >
                                                        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: 16, width: '100%', justifyContent: 'space-between' }}>
                                                            <div style={{ flexDirection: 'row', display: 'flex' }}>
                                                                {/* <div style={{ flexDirection: 'row', display: 'flex', backgroundColor: '#F5F5F5', height: 40, alignItems: 'center', width: 40, justifyContent: 'center', borderRadius: 20 }}>

                                                                    <Avatar variant="square" alt="Coin Icon" style={{ width: 32, height: 32 }} src={getIcons(item.token, tokenIcons, true)} />

                                                                </div> */}

                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                                    <Typography className={classes.defaultFont} style={{ fontSize: isMobile ? 20 : 24 }} >
                                                                        {/* {transactionType[item.type].name} */}
                                                                        {t('transfer')}
                                                                    </Typography>

                                                                    {/* <Typography style={{ fontSize: 12, color: '#8786A7', marginTop: -4, fontWeight: 'bold' }}>
                                                                        {item.token}
                                                                    </Typography> */}
                                                                </div>
                                                            </div>
                                                            <div style={{

                                                            }}
                                                            >
                                                                {/* <Typography style={{ marginLeft: 12, marginRight: 12, marginTop: 4, marginBottom: 4, fontSize: 14, color: '#000847', fontWeight: 'bold' }} >
                                                                    {t('succeed')}
                                                                </Typography> */}

                                                                <Typography className={classes.defaultFont} style={{ fontSize: isMobile ? 20 : 24 }}>
                                                                    {/* {transactionType[item.type].sign + roundingDown(item.amount, 4)} {item.token} */}
                                                                    {item.amount} {item.token}
                                                                </Typography>
                                                            </div>
                                                        </div>

                                                        <div style={{ height: 1, width: '100%', backgroundColor: '#EAEAEA', marginTop: 8 }}></div>

                                                        {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 16, width: '100%' }}>
                                                            <Typography style={{ fontSize: 14, color: '#8FB9E1', fontWeight: 'bold' }}>

                                                                {t('transferAmount')}

                                                            </Typography>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <Typography style={{ fontSize: 14, color: '#8FB9E1', fontWeight: 'bold' }} >
                                                                    {item.amount} {item.token}
                                                                </Typography>
                                                            </div>
                                                        </div> */}
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 8, width: '100%' }}>
                                                            <Typography className={classes.defaultFont} style={{ color: '#8A96A7' }}>

                                                                {t("earn-poolRecord-token")}

                                                            </Typography>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <Typography className={classes.defaultFont} style={{}} >
                                                                    {item.token}
                                                                </Typography>
                                                            </div>
                                                        </div>

                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 8, width: '100%' }}>
                                                            <Typography className={classes.defaultFont} style={{ color: '#8A96A7' }}>

                                                                {t('transferFee')}

                                                            </Typography>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <Typography className={classes.defaultFont} style={{}} >
                                                                    {/* {transactionType[item.type].sign + roundingDown(item.amount, 4)} {item.token} */}
                                                                    {item.fee} {item.feeToken}
                                                                </Typography>
                                                            </div>
                                                        </div>




                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 8, width: '100%', }}>
                                                            <Typography className={classes.defaultFont} style={{ color: '#8A96A7' }}>
                                                                {t('pool-time')}
                                                            </Typography>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <Typography className={classes.defaultFont} style={{}}>
                                                                    {convertTimeString(item.createdAt)}
                                                                </Typography>
                                                            </div>
                                                        </div>

                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 8, width: '100%' ,marginBottom: 16 }}>
                                                            <Typography className={classes.defaultFont} style={{ color: '#8A96A7' }}>
                                                                {item.amount < 0 ? t('receiverAddress') : t('senderAddress')}
                                                            </Typography>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <Typography className={classes.defaultFont} style={{}} >
                                                                    {item.remark}
                                                                </Typography>
                                                            </div>
                                                        </div>


                                                    </ListItem>
                                                    : item.type === 9 ?
                                                        <ListItem style={{ backgroundColor: 'orange', flexDirection: 'column', marginBottom: 24, display: 'flex', borderRadius: 16, width: '98%', overflow: 'hidden', alignItems: 'flex-start' }}
                                                            key={item.id} button
                                                        >

                                                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: 16, width: '100%', justifyContent: 'space-between' }}>
                                                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                                                    <div style={{ flexDirection: 'row', display: 'flex', backgroundColor: '#F5F5F5', height: 40, alignItems: 'center', width: 40, justifyContent: 'center', borderRadius: 20 }}>

                                                                        <Avatar variant="square" alt="Coin Icon" style={{ width: 32, height: 32 }} src={getIcons(item.token, tokenIcons, true)} />

                                                                    </div>
                                                                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 16 }}>

                                                                        <Typography style={{ fontSize: 18, color: item.type == 1 ? '#1DF0A9' : "#FF4646", fontWeight: 'bold' }} >
                                                                            {transactionType[item.type].name}
                                                                        </Typography>

                                                                        <Typography style={{ fontSize: 12, color: '#8786A7', marginTop: -4, fontWeight: 'bold' }}>
                                                                            {item.token}
                                                                        </Typography>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 16, width: '100%' }}>
                                                                <Typography style={{ fontSize: 14, color: '#8FB9E1', fontWeight: 'bold' }}>

                                                                    {t('pool-addLiquidity-amount')}

                                                                </Typography>
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Typography style={{ fontSize: 14, color: '#8FB9E1', fontWeight: 'bold' }} >
                                                                        {transactionType[item.type].sign + roundingDown(item.amount, 4)} {item.token}
                                                                    </Typography>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 8, width: '100%', marginBottom: 16 }}>
                                                                <Typography style={{ fontSize: 14, color: '#8FB9E1', fontWeight: 'bold' }}>

                                                                    {t('pool-time')}


                                                                </Typography>
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Typography style={{ fontSize: 14, color: '#8FB9E1', fontWeight: 'bold' }} >
                                                                        {convertTimeString(item.createdAt)}
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </ListItem>

                                                        :
                                                        <ListItem style={{ backgroundColor: 'white', flexDirection: 'column', marginBottom: 24, display: 'flex', borderRadius: 16, width: '98%', overflow: 'hidden', alignItems: 'flex-start' }}
                                                            key={item.id} button
                                                        // onClick={() =>
                                                        //     handleOpenRecordDetail(item)
                                                        // }
                                                        >
                                                            {/* <ListItemText className={isMobile ? 'records__smallMobileListItem' : null} disableTypography={false} secondaryTypographyProps={{ style: { color: 'white', width: width * 0.1, minWidth: 80 } }} primaryTypographyProps={{ style: { color: 'white', width: width * 0.1, minWidth: 80 } }} primary={`$`} secondary={ } /> */}
                                                            {/*  Disabled for now  -- Richard 210408 */}
                                                            {/*<ListItemText style={{ color: 'white' }} >{`${item.chain} ${t('chain')}`}</ListItemText>*/}

                                                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: 12, width: '100%', justifyContent: 'space-between' }}>
                                                                <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>

                                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                                        <Typography className={classes.defaultFont} style={{ fontSize: isMobile ? 20 : 24 }} >
                                                                            {transactionType[item.type].name}
                                                                        </Typography>

                                                                        {/* <Typography style={{ fontSize: 12, color: '#8786A7', marginTop: -4, fontWeight: 'bold' }}>
                                                                            {item.token}
                                                                        </Typography> */}
                                                                    </div>
                                                                </div>
                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                    <Typography className={classes.defaultFont} style={{ fontSize: isMobile ? 20 : 24, color: '#363636', }} >
                                                                        {transactionType[item.type].sign + roundingDown(item.amount, 4)}  {item.token}
                                                                    </Typography>

                                                                    {/* <Avatar variant="square" alt="Coin Icon" style={{ width: 26, height: 26 }} src={getIcons(item.token, tokenIcons, true)} /> */}

                                                                </div>
                                                            </div>
                                                            <div style={{ height: 1, width: '100%', backgroundColor: '#EAEAEA', marginTop: 8 }}></div>

                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 8, width: '100%' }}>

                                                                <Typography className={classes.defaultFont} style={{ color: '#8A96A7' }}>
                                                                    {t("earn-poolRecord-token")}
                                                                </Typography>

                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Typography className={classes.defaultFont} style={{}} >
                                                                        {item.token}
                                                                    </Typography>

                                                                </div>
                                                            </div>
                                                            {
                                                                item.type === 2 ?
                                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 8, width: '100%' }}>
                                                                        <Typography className={classes.defaultFont} style={{ color: '#8A96A7' }}>
                                                                            {t('chain')}
                                                                        </Typography>
                                                                        <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#251CFF', paddingLeft: 8, paddingRight: 8, borderRadius: 6 }}>
                                                                            <Typography className={classes.defaultFont} style={{ color: 'white' }} >
                                                                                {item.chain}
                                                                            </Typography>
                                                                        </div>
                                                                    </div> : null
                                                            }
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 8, width: '100%' }}>
                                                                <Typography className={classes.defaultFont} style={{ color: '#8A96A7' }}>

                                                                    {t('status')}

                                                                </Typography>
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>


                                                                    <Typography className={classes.defaultFont} style={{}} >
                                                                        {item.status === -1 ? t('submitted') : item.type === 1 ? depositStatus[item.status] : status[item.status]}
                                                                    </Typography>
                                                                </div>
                                                            </div>



                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 8, width: '100%', marginBottom: 16 }}>
                                                                <Typography className={classes.defaultFont} style={{ color: '#8A96A7' }}>

                                                                    {t('pool-time')}


                                                                </Typography>
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Typography className={classes.defaultFont} style={{}} >
                                                                        {convertTimeString(item.createdAt)}
                                                                    </Typography>
                                                                </div>
                                                            </div>




                                                        </ListItem>
                                            ))
                                        }
                                    </List>

                                }
                            </div>
                            <Grid item xs={12} style={{ marginBottom: 24 }}>
                                {
                                    address.length < 42 || !isValidAddress(address) ?
                                        <CustomButton buttonstyle="connectStyle" style={{ width: '100%' }} onClick={!window.ethereum ? () => onClickInstall(sendBackButton1, sendBackButton1Disabled) : () => onClickConnect(network, chainId, address, dispatch)}>
                                            {button1}
                                        </CustomButton> : null
                                }
                                {
                                    address.length === 42 && isValidAddress(address) ?
                                        loggedIn ?
                                            null :
                                            <CustomButton buttonstyle="unlockStyle" style={{ width: '100%' }} onClick={(!registered || !loggedIn) && !loading ? () => unlock(address, chainId, network, code, Web3, registered, dispatch, setOpenSliderModal, setSignUpPayload) : null} disabled={button2Disabled}>
                                                {t('unlock')}
                                            </CustomButton> : null
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                aria-labelledby="server-modal-title"
                aria-describedby="server-modal-description"
                className={classes.modal}
                open={openRecordDetail}
                onClose={handleCloseRecordDetail}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openRecordDetail}>
                    <div className={classes.paper}>
                        <h2 id="server-modal-title">{t('transactionDetails')}</h2>
                        <Grid container spacing={2} >
                            <Grid item xs={12} >
                                <p id="server-modal-description">{`${t('time')}: ${time}`}</p>
                            </Grid>
                            <Grid item xs={12} >
                                <p id="server-modal-description">{`${t('action')}: ${t(transactionType[type].name)} ${amount} ${coin}`}</p>
                            </Grid>
                            <Grid item xs={12} >
                                <p id="server-modal-description">{`${t('status')}: ${type === 1 ? t(depositStatus[state]) : t(status[state])}`}</p>
                            </Grid>
                            {/*  Disabled for now  -- Richard 210408 */}
                            {
                                hash > 1 ?
                                    <Grid item xs={12} >
                                        <Button disabled={recordChain === ''} target="_blank" href={recordScan + hash} style={{ width: 180 }} className={classes.btn} >
                                            {recordChain === '' ? `${t('detailsNotAvailable')}` : `${t('checkScan')} ${recordChain} Scan`}
                                        </Button>
                                    </Grid> : null
                            }
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div >
    )
}

export default withTranslation()(Records);
