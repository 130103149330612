import {Avatar, makeStyles, Typography, Button, Fade, Modal, Backdrop} from "@material-ui/core";
import React from "react";
import {withTranslation} from "react-i18next";
import successIcon from '../../images/successIcon.png'
import failedIcon from '../../images/failedIcon.png'
import closeIcon from '../../images/closeIcon.png'

const confirmIcon = 'https://cdn.swapallapp.com/l2/images/confirmIcon.png'

function Note({ t, openNote, handleCloseNote, noteTitle, noteContent, noteType, showBtn, btnAction, btnText, height, width }) {
    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor:'rgba(52, 52, 52, 0.6)'
        },
    }));
    const classes = useStyles();

    const body = (
        <div style={{
            backgroundColor: 'white', borderRadius: 24, width: noteType === 'succeed' ?  200 : 340, height: noteType === 'confirm' ? height * 0.26 : noteType === 'succeed' ?  112 : 354, overflow: 'hidden', outline: 0, display: 'flex', alignItems: 'center', flexDirection: 'column'
        }}>
            {
                noteType === 'failed' ?
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginRight: 30}}>
                        <img src={closeIcon} style={{ width: 18, paddingTop: 30 }} onClick={handleCloseNote} />
                    </div> : null
            }
            {
                noteType === 'succeed' ?
                    <div style={{ padding: 10, paddingTop: 20 }}>
                        <img src={successIcon} style={{ width: 34 }} />
                    </div>
                     : noteType === 'failed' ?
                    <div style={{ padding: 10, paddingBottom: 20 }}>
                        <img src={failedIcon} style={{ width: 185, height: 85 }} />
                    </div> : noteType === 'confirm' ?
                        <Avatar src={confirmIcon} variant="square" style={{ height: 54 * 1.2, width: 77 * 1.2, marginTop: 24 }} /> : null
            }
            <Typography style={{ fontSize: 20, fontWeight: 'bold', color: '#363636', textAlign: 'center' }}>
                {noteTitle}
            </Typography>
            {
                noteContent === undefined ?
                    null :
                    <Typography style={{ fontSize: 14, fontWeight: 'bold', color: '#363636', textAlign: 'center', opacity: 0.59, width: 313, padding: 10 }}>
                        {noteContent}
                    </Typography>
            }
            {
                showBtn ?
                    <Button style={{ marginTop: 15, borderRadius: 12, height: 53, width: 300, backgroundColor: '#F5573A', border: 'unset' }}
                            className={classes.registerBtn} onClick={btnAction} variant="outlined" color="primary" >
                        <Typography style={{ fontSize: 16, fontWeight: 'bold', color: 'white' }}>
                            {btnText}
                        </Typography>
                    </Button> : null
            }
        </div>
    );

    return (
        <Modal
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            aria-labelledby="server-modal-title"
            aria-describedby="server-modal-description"
            className={classes.modal}
            open={openNote}
            onClose={handleCloseNote}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        >
            <Fade in={openNote}>
                {body}
            </Fade>
        </Modal>
    )
}

export default withTranslation()(Note);
