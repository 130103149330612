import {
    ADD_LIQUIDITY,
    ADD_SUCCEED,
    ADD_FAILED,
    REMOVE_LIQUIDITY,
    REMOVE_SUCCEED,
    REMOVE_FAILED,
    GET_ALL_LIQUIDITY_POOL,
    GET_ALL_LIQUIDITY_POOL_SUCCEED,
    GET_ALL_LIQUIDITY_POOL_FAILED,
    GET_MY_LIQUIDITY_POOL,
    GET_MY_LIQUIDITY_POOL_SUCCEED,
    GET_MY_LIQUIDITY_POOL_FAILED,
    GET_POOL_BANNER,
    GET_POOL_BANNER_SUCCEED,
    GET_POOL_BANNER_FAILED,
    GET_LIQUIDITY_TRANSACTIONS,
    GET_LIQUIDITY_TRANSACTIONS_FAILED,
    GET_LIQUIDITY_COLLECT_TRANSACTIONS,
    GET_LIQUIDITY_COLLECT_TRANSACTIONS_FAILED,
    GET_MY_LIQUIDITY_TRANSACTIONS_SUCCEED,
    GET_ADD_LIQUIDITY_QUOTE,
    GET_ADD_LIQUIDITY_QUOTE_SUCCEED,
    GET_ADD_LIQUIDITY_QUOTE_FAILED,
    CLEAR_ADD_LIQUIDITY_QUOTE,
    GET_REMOVE_LIQUIDITY_QUOTE,
    GET_REMOVE_LIQUIDITY_QUOTE_SUCCEED,
    GET_REMOVE_LIQUIDITY_QUOTE_FAILED,
    CLEAR_REMOVE_LIQUIDITY_QUOTE,
    HARVEST_LIQUIDITY_REWARD,
    HARVEST_LIQUIDITY_REWARD_FAILED,
    HARVEST_LIQUIDITY_REWARD_SUCCEED,
    CLEAR_ADD_STATE,
    CLEAR_REMOVE_STATE,
    CLEAR_HARVEST_STATE,
    POOL_SIGNING,
    POOL_SIGNING_CANCELLED,
    GET_MY_POOL_APY,
    GET_MY_POOL_APY_SUCCEED,
    GET_MY_POOL_APY_FAILED,
    GET_CURRENT_TIME,
    GET_CURRENT_TIME_SUCCEED,
    GET_CURRENT_TIME_FAILED,
    GET_MY_LOCKER,
    GET_MY_LOCKER_FAILED,
    GET_MY_LOCKER_SUCCEED,
    LOCK_LP,
    LOCK_LP_SUCCEED,
    LOCK_LP_FAILED,
    CLEAR_LOCK_LP_STATE,
    UNLOCK_LP,
    UNLOCK_LP_SUCCEED,
    UNLOCK_LP_FAILED,
    CLEAR_UNLOCK_LP_STATE,
    GET_UNLOCKABLE,
    GET_UNLOCKABLE_FAILED,
    GET_UNLOCKABLE_SUCCEED,
    CLEAR_UNLOCKABLE,
    UNLOCK_ALL_LP,
    UNLOCK_ALL_LP_FAILED,
    UNLOCK_ALL_LP_SUCCEED,
    CLEAR_UNLOCK_ALL_LP_STATE,

} from '../constants';

export function pool(state = {
    liquidityPools: [],
    poolBannerInfo:[],
    myLiquidityPools: [],
    myLiquidityTransactions: [],
    addLiquidityQuote: {
        "token": "",
        "amount": 0,
        "shareOfPool": 0,
        "ratioPerToken1ToToken2": 0,
        "ratioPerToken2ToToken1": 0
    },
    removeLiquidityQuote: {
        "token": "",
        "amount": 0,
        "lpAmount": 0,
        "ratioPerToken1ToToken2": 0,
        "ratioPerToken2ToToken1": 0,
        "fee1": 0,
        "fee2": 0
    },
    poolSigning: false,
    poolsApy: [],
    currentTime: '',
    myLocker: [],
    unlockables: []
}, action) {
    switch (action.type) {
        case ADD_LIQUIDITY:
            return {
                ...state,
                addLiquidityLoading: true,
                addSucceed: false,
                addFailed: false,
                poolSigning: false,
            }
        case ADD_SUCCEED:
            return {
                ...state,
                addMessage: action.msg,
                addLiquidityLoading: false,
                addSucceed: true,
            }
        case ADD_FAILED:
            return {
                ...state,
                addMessage: action.error,
                addLiquidityLoading: false,
                addFailed: true,
            }
        case CLEAR_ADD_STATE:
            return {
                ...state,
                addSucceed: false,
                addFailed: false,
            }
        case REMOVE_LIQUIDITY:
            return {
                ...state,
                removeLiquidityLoading: true,
                removeSucceed: false,
                removeFailed: false,
                poolSigning: false,
            }
        case REMOVE_SUCCEED:
            return {
                ...state,
                removeMessage: action,
                removeLiquidityLoading: false,
                removeSucceed: true,
            }
        case REMOVE_FAILED:
            return {
                ...state,
                removeMessage: action.error,
                removeLiquidityLoading: false,
                removeFailed: true,
            }
        case CLEAR_REMOVE_STATE:
            return {
                ...state,
                removeSucceed: false,
                removeFailed: false,
            }
        case GET_ALL_LIQUIDITY_POOL:
            return {
                ...state,
                loading: true
            }
        case GET_ALL_LIQUIDITY_POOL_SUCCEED:
            return {
                ...state,
                liquidityPools: action.res.data,
                loading: false
            }
        case GET_ALL_LIQUIDITY_POOL_FAILED:
            return {
                ...state,
                message: action.error,
                loading: false
            }

        case GET_MY_LIQUIDITY_POOL:
            return {
                ...state,
                loading: true
            }
        case GET_MY_LIQUIDITY_POOL_SUCCEED:
            return {
                ...state,
                myLiquidityPools: action.res.data,
                loading: false
            }
        case GET_MY_LIQUIDITY_POOL_FAILED:
            return {
                ...state,
                message: action.error,
                loading: false
            }

        case GET_POOL_BANNER:
            return {
                ...state,
                loading: true
            }
        case GET_POOL_BANNER_SUCCEED:
            return {
                ...state,
                // myLiquidityPools: action.res.data,
                poolBannerInfo:action.res.data,
                loading: false
            }
        case GET_POOL_BANNER_FAILED:
            return {
                ...state,
                message: action.error,
                loading: false
            }

        case GET_LIQUIDITY_TRANSACTIONS:
            return {
                ...state,
                loading: true
            }
        case GET_LIQUIDITY_TRANSACTIONS_FAILED:
            return {
                ...state,
                message: action.error,
                loading: false
            }
        case GET_LIQUIDITY_COLLECT_TRANSACTIONS:
            return {
                ...state,
                loading: true
            }
        case GET_LIQUIDITY_COLLECT_TRANSACTIONS_FAILED:
            return {
                ...state,
                message: action.error,
                loading: false
            }
        case GET_MY_LIQUIDITY_TRANSACTIONS_SUCCEED:
            return {
                ...state,
                myLiquidityTransactions: action.res,
                loading: false
            }
        case GET_ADD_LIQUIDITY_QUOTE:
            return {
                ...state,
                addLiquidityQuoteLoading: true
            }
        case GET_ADD_LIQUIDITY_QUOTE_SUCCEED:
            return {
                ...state,
                addLiquidityQuote: action.res.data,
                addLiquidityQuoteLoading: false
            }
        case GET_ADD_LIQUIDITY_QUOTE_FAILED:
            return {
                ...state,
                addLiquidityQuote: {
                    "token": "",
                    "amount": 0,
                    "shareOfPool": 0,
                    "ratioPerToken1ToToken2": 0,
                    "ratioPerToken2ToToken1": 0
                },
                message: action.error,
                addLiquidityQuoteLoading: false
            }
        case CLEAR_ADD_LIQUIDITY_QUOTE:
            return {
                ...state,
                addLiquidityQuote: {
                    "token": "",
                    "amount": 0,
                    "shareOfPool": 0,
                    "ratioPerToken1ToToken2": 0,
                    "ratioPerToken2ToToken1": 0
                },
            }
        case GET_REMOVE_LIQUIDITY_QUOTE:
            return {
                ...state,
                removeLiquidityQuoteLoading: true
            }
        case GET_REMOVE_LIQUIDITY_QUOTE_SUCCEED:
            return {
                ...state,
                removeLiquidityQuote: action.res.data,
                removeLiquidityQuoteLoading: false
            }
        case GET_REMOVE_LIQUIDITY_QUOTE_FAILED:
            return {
                ...state,
                removeLiquidityQuote: {
                    "token": "",
                    "amount": 0,
                    "lpAmount": 0,
                    "ratioPerToken1ToToken2": 0,
                    "ratioPerToken2ToToken1": 0,
                    "fee1": 0,
                    "fee2": 0
                },
                message: action.error,
                removeLiquidityQuoteLoading: false
            }
        case CLEAR_REMOVE_LIQUIDITY_QUOTE:
            return {
                ...state,
                removeLiquidityQuote: {
                    "token": "",
                    "amount": 0,
                    "lpAmount": 0,
                    "ratioPerToken1ToToken2": 0,
                    "ratioPerToken2ToToken1": 0,
                    "fee1": 0,
                    "fee2": 0
                },
            }
        case HARVEST_LIQUIDITY_REWARD:
            return {
                ...state,
                harvestLoading: true,
                harvestSucceed: false,
                harvestFailed: false,
                poolSigning: false,
            }
        case HARVEST_LIQUIDITY_REWARD_SUCCEED:
            return {
                ...state,
                harvestMessage: action.msg.message,
                harvestLoading: false,
                harvestSucceed: true,
            }
        case HARVEST_LIQUIDITY_REWARD_FAILED:
            return {
                ...state,
                harvestMessage: action.error,
                harvestLoading: false,
                harvestFailed: true,
            }
        case CLEAR_HARVEST_STATE:
            return {
                ...state,
                harvestSucceed: false,
                harvestFailed: false,
            }
        case POOL_SIGNING:
            return {
                ...state,
                poolSigning: true,
            }
        case POOL_SIGNING_CANCELLED:
            return {
                ...state,
                poolSigning: false,
            }


        case GET_MY_POOL_APY:
            return {
                ...state,
                loading: true
            }
        case GET_MY_POOL_APY_SUCCEED:
            return {
                ...state,
                poolsApy: action.res.data,
                loading: false
            }
        case GET_MY_POOL_APY_FAILED:
            return {
                ...state,
                message: action.error,
                loading: false
            }

        case GET_CURRENT_TIME:
            return {
                ...state,
                loading: true
            }
        case GET_CURRENT_TIME_SUCCEED:
            return {
                ...state,
                currentTime: action.res.data,
                loading: false
            }
        case GET_CURRENT_TIME_FAILED:
            return {
                ...state,
                message: action.error,
                loading: false
            }

        case GET_MY_LOCKER:
            return {
                ...state,
                loading: true
            }
        case GET_MY_LOCKER_SUCCEED:
            return {
                ...state,
                myLocker: action.res.data,
                loading: false
            }
        case GET_MY_LOCKER_FAILED:
            return {
                ...state,
                message: action.error,
                loading: false
            }

        case LOCK_LP:
            return {
                ...state,
                lockLpLoading: true,
                lockLpSucceed: false,
                lockLpFailed: false,
                poolSigning: false,
            }
        case LOCK_LP_SUCCEED:
            return {
                ...state,
                lockLpMessage: action.msg,
                lockLpLoading: false,
                lockLpSucceed: true,
            }
        case LOCK_LP_FAILED:
            return {
                ...state,
                lockLpMessage: action.error,
                lockLpLoading: false,
                lockLpFailed: true,
            }
        case CLEAR_LOCK_LP_STATE:
            return {
                ...state,
                lockLpSucceed: false,
                lockLpFailed: false,
            }

        case UNLOCK_LP:
            return {
                ...state,
                unlockLpLoading: true,
                unlockLpSucceed: false,
                unlockLpFailed: false,
                poolSigning: false,
            }
        case UNLOCK_LP_SUCCEED:
            return {
                ...state,
                unlockLpMessage: action.msg,
                unlockLpLoading: false,
                unlockLpSucceed: true,
            }
        case UNLOCK_LP_FAILED:
            return {
                ...state,
                unlockLpMessage: action.error,
                unlockLpLoading: false,
                unlockLpFailed: true,
            }
        case CLEAR_UNLOCK_LP_STATE:
            return {
                ...state,
                unlockLpSucceed: false,
                unlockLpFailed: false,
            }

        case GET_UNLOCKABLE:
            return {
                ...state,
                loading: true
            }
        case GET_UNLOCKABLE_SUCCEED:
            return {
                ...state,
                unlockables: action.res.data,
                loading: false
            }
        case GET_UNLOCKABLE_FAILED:
            return {
                ...state,
                unlockables: [],
                message: action.error,
                loading: false
            }
        case CLEAR_UNLOCKABLE:
            return {
                ...state,
                unlockables: [],
            }

        case UNLOCK_ALL_LP:
            return {
                ...state,
                unlockAllLpLoading: true,
                unlockAllLpSucceed: false,
                unlockAllLpFailed: false,
                poolSigning: false,
            }
        case UNLOCK_ALL_LP_SUCCEED:
            return {
                ...state,
                unlockAllLpMessage: action.msg,
                unlockAllLpLoading: false,
                unlockAllLpSucceed: true,
            }
        case UNLOCK_ALL_LP_FAILED:
            return {
                ...state,
                unlockAllLpMessage: action.error,
                unlockAllLpLoading: false,
                unlockAllLpFailed: true,
            }
        case CLEAR_UNLOCK_ALL_LP_STATE:
            return {
                ...state,
                unlockAllLpSucceed: false,
                unlockAllLpFailed: false,
            }
        default:
            return state;
    }

    return state;
};
