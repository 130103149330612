import {withTranslation} from "react-i18next";
import {makeStyles, Modal, Typography} from "@material-ui/core";
import React from "react";
import {roundingDown} from "../../../utils/RoundingDown";

const SwapRecordDetail = (props) =>{
    const {
        onCancel: handleClose,
        modalVisible,
        values,
    } = props;
    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            position: 'absolute',
            width: 400,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        }
    }));
    const classes = useStyles();

    const resultViewBody = (
        <div className={classes.paper}>
            <h2 id="simple-modal-title">交易详情</h2>
            <Typography>
                时间: {values.date}
            </Typography>
            <Typography>
                操作:  {roundingDown(Math.abs(values.fromAmount),8) + " " + values.fromToken} > {roundingDown(values.toAmount,8) + " " + values.toToken}
            </Typography>
            <Typography>
                价格:    1 {values.fromToken} = {roundingDown((Math.abs(values.toAmount)) / (Math.abs(values.fromAmount)), 8)} {values.toToken}
            </Typography>
            <Typography>
                手续费: {roundingDown(values.fee,8)}{values.fromToken}
            </Typography>
        </div>
    );
    return (
        <Modal
            open={modalVisible}
            onClose={handleClose}
            className={classes.modal}
        >
            {resultViewBody}
        </Modal>
    )
}
export default withTranslation()(SwapRecordDetail);
