import * as Url from '../../config/Url'
import { authHeader } from '../../utils/AuthHeader';
import { handleResponse } from '../../utils/HandleResponse'

export const swapService = {
    swapQuote,
    swap,
    getSwapTransactions,
    getMaxPriceImpact
};

async function swapQuote(payload,token) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(token),
        body: JSON.stringify(payload)
    };
    return fetch(Url.SWAP_QUOTE, requestOptions).then(handleResponse);
}

async function swap(payload,token) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(token),
        body: JSON.stringify(payload)
    };
    return fetch(Url.SWAP, requestOptions).then(handleResponse);
}

async function getSwapTransactions(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };
    return fetch(Url.GET_SWAP_TRANSACTIONS, requestOptions).then(handleResponse);
}

async function getMaxPriceImpact(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };
    return fetch(Url.MAX_PRICE_IMPACT, requestOptions).then(handleResponse);
}