import React, {useEffect} from 'react';
import {Button, makeStyles, Typography} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {isValidAddress} from "ethereumjs-util";
import {onClickConnect, onClickInstall, unlock} from "../../utils/Sign";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import nodeShort1 from '../../images/ido/nodeBgShort.png'
import nodeShort2 from '../../images/ido/nodeBgShort2.png'
import node from '../../images/ido/node.png'
import rank from '../../images/ido/rank.png'
import redirect from '../../images/ido/redirect.png'
import {inviteActions} from "../../redux/actions/inviteActions";

const Web3 = require("web3");

function IDOHome({ width, t, button2Disabled, registered, loggedIn, address, chainId, network, code, button2, sendBackHeight, sendBackAddr, sendBackChainId, sendBackNetworkId, button1, sendBackButton1, sendBackButton1Disabled, height, setOpenSliderModal, setSignUpPayload}) {
    const useStyles = makeStyles((theme) => ({
        font: {
            fontFamily: "PingFang SC",
            letterSpacing: 0
        },
        countDownFont:{
            fontFamily:"Technology",
            letterSpacing: 1.11,
            fontSize: 40,
            lineHeight: "39px",
            color: "#251CFF",
            fontWeight: "bold",
        },
        forBarColorPrimary: {
            backgroundColor:"#F5573B",
            borderRadius:5
        },
        forMigrationBarColor:{
            backgroundColor:"#271CFF",
            borderRadius:5
        },
        forReferralBarColor:{
            backgroundColor:"#FAD301",
            borderRadius:5
        },
        btn: {
            backgroundColor: '#83ACF4',
            color: '#010846',
            borderColor: 'transparent',
            borderRadius: 25,
            height: 45,
            fontWeight: 600,
            minWidth: 100
        },
        btn_disabled: {
            backgroundColor: '#83ACF4',
            color: '#010846',
            borderColor: 'transparent',
            borderRadius: 25,
            height: 45,
            fontWeight: 600,
            opacity: 0.2
        },
    }));

    const classes = useStyles();
    const dispatch = useDispatch()
    const history = useHistory()
    const { token, userInfo } = useSelector(state => state.auth)
    const { inviteInfo, rankSwitch, loading } = useSelector(state => state.invite)
    const { idoInvestmentInfo, idoMigrationInfo, buyIDOFinished, buyIDOSucceed, buyIDOMsg, idoInfo, migrateIDOMsg, migrateIDOSucceed, migrateIDOFinished } = useSelector(state => state.wallet)

    useEffect(() => {
        console.log('token: ', token)
        dispatch(inviteActions.getRankSwitch(token))
    }, [token])

    const goToNode = (path) => {
        history.push(`/dapp/${path}`)
    }

    useEffect(() => {
        console.log('rankSwitch: ', rankSwitch)
    }, [rankSwitch])

    const renderConnectMetamask = (path)=>{
        return (
            path === 'node' ?
                <Button style={{ borderRadius: width < 960 ? '0 0 20px 20px' : 'unset', height: 58, width: '100%', backgroundColor: 'rgba(0,0,0,0.44)', opacity: 0.7, position: width < 960 ? 'absolute' : 'unset', bottom: width < 960 ? 0 : 'unset' }} >
                    <Typography style={{ fontSize: 20, fontWeight: 'bold', color: 'white' }}>
                        {t('openSoon')}
                    </Typography>
                </Button> :
            <>

                {

                    loggedIn ?
                    ( path === 'rank' && !rankSwitch ) ?
                        <Button style={{ borderRadius: width < 960 ? '0 0 20px 20px' : 'unset', height: 58, width: '100%', backgroundColor: 'rgba(0,0,0,0.44)', opacity: 0.7, position: width < 960 ? 'absolute' : 'unset', bottom: width < 960 ? 0 : 'unset' }} >
                            <Typography style={{ fontSize: 20, fontWeight: 'bold', color: 'white' }}>
                                {t('openSoon')}
                            </Typography>
                        </Button> :
                        <Button style={{ borderRadius: width < 960 ? '0 0 20px 20px' : 'unset', height: 58, width: '100%', backgroundColor: 'rgba(0,0,0,0.44)', opacity: 0.7, position: width < 960 ? 'absolute' : 'unset', bottom: width < 960 ? 0 : 'unset' }} onClick={() => goToNode(path)}>
                            <Typography style={{ fontSize: 20, fontWeight: 'bold', color: 'white' }}>
                                {t('enterDApp')}
                            </Typography>
                        </Button> : null
                }
                {
                    address.length < 42 || !isValidAddress(address) ?
                        <Button style={{ borderRadius: width < 960 ? '0 0 20px 20px' : 'unset', height: 58, width: '100%', backgroundColor: 'rgba(0,0,0,0.44)', opacity: 0.7, position: width < 960 ? 'absolute' : 'unset', bottom: width < 960 ? 0 : 'unset' }} onClick={!window.ethereum ? () => onClickInstall(sendBackButton1, sendBackButton1Disabled) : onClickConnect}>
                            <Typography style={{ fontSize: 20, fontWeight: 'bold', color: 'white' }}>
                                {button1}
                            </Typography>
                        </Button>
                        :
                        null
                }

                {
                    address.length === 42 && isValidAddress(address) && !loggedIn ?
                        <Button style={{ borderRadius: width < 960 ? '0 0 20px 20px' : 'unset', height: 58, width: '100%', backgroundColor: 'rgba(0,0,0,0.44)', opacity: 0.7, position: width < 960 ? 'absolute' : 'unset', bottom: width < 960 ? 0 : 'unset' }}
                                className={button2Disabled ? classes.btn_disabled : classes.btn} onClick={(!registered || !loggedIn) && !loading ? () => unlock(address, chainId, network, code, Web3, registered, dispatch, setOpenSliderModal, setSignUpPayload) : null} variant="outlined" color="primary" disabled={button2Disabled}>
                            <Typography style={{ fontSize: 20, fontWeight: 'bold', color: 'white' }}>
                                {button2}
                            </Typography>
                        </Button> : null
                }
            </>
        )
    }

    const renderHeader = ()=>{
        return (
            <div style={{marginTop:60, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
                    <span
                        style={{
                            fontSize: 40,
                            maxWidth: width < 960 ? 218 : '100%',
                            fontWeight: 600,
                            color:"#251CFF",
                            textAlign: width < 960 ? "left" : 'center',
                            fontFamily:"Syncopate",
                            marginBottom: 20
                        }}
                    >
                        {width < 960 ? t("dappTitle") : t('dappTitlePc')}
                    </span>
                    {
                        <Typography
                            className={classes.font}
                            style={{
                                fontSize: 14,
                                color:"#363636",
                                textAlign: width < 960 ? "left" : 'center',
                                whiteSpace: 'pre-line'
                            }}
                        >
                            {t("dappContent")}
                        </Typography>
                    }
                </div>
            </div>
        )
    }

    const  renderPC = ()=>{
        return(
            <div style={{display: 'flex', width:"100%", justifyContent:"space-between", marginTop:60, marginBottom:40}}>

                <div style={{ height:350, width: 441, backgroundImage: `url(${nodeShort1})`, backgroundSize: 'cover', display: 'flex', flexDirection: 'column'}}>
                    <div style={{margin:"15px 30px", height: '100%'}}>
                        <div style={{ marginBottom: 20, backgroundColor: 'white', width: 58, height: 20, padding: '2px 0 2px 0', borderRadius: 4, display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                            <Typography style={{ color: '#FF0067', fontWeight: 600, fontSize: 14 }}>
                                {t('hot')}
                            </Typography>
                        </div>
                        <div style={{display: 'flex', justifyContent:"space-between", alignItems: "center"}}>
                            <div>
                                <Typography style={{fontFamily:"PingFang SC", fontSize:40, fontWeight:"bold", color: 'white'}}>{t('nodeTitle')}</Typography>
                            </div>
                            <img src={node} style={{width:56, height:56}}/>
                        </div>
                        <Typography className={classes.font} style={{marginTop:30, fontSize:14, fontWeight:400, color: 'white'}}>{t('nodeContent')}</Typography>
                        <div style={{marginTop:30, marginBottom: 30, display: 'flex', justifyContent:"space-between", alignItems: 'center'}}>
                            <Typography className={classes.font}  style={{fontSize: 16, lineHeight: "22px", color: "white", fontWeight:400}}>{t("project")}</Typography>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }} onClick={() => {
                                window.open('https://oasismktg.com/', '_blank')
                            }}>
                                <Typography className={classes.font}  style={{fontSize: 16, lineHeight: "22px", color: "white", fontWeight:400}}>{t('enter')}</Typography>
                                <img src={redirect} style={{ marginLeft: 4, width: 13, height: 13 }} />
                            </div>
                        </div>
                    </div>
                    <div style={{ top: 0 }}>
                        {renderConnectMetamask('node')}
                    </div>
                </div>

                <div style={{ height: 350, width: 441, backgroundImage: `url(${nodeShort2})`, backgroundSize: 'cover', display: 'flex', flexDirection: 'column'}}>
                    <div style={{margin:"15px 30px", height: '100%'}}>
                        <div style={{ marginBottom: 20, backgroundColor: 'white', width: 58, height: 20, padding: '2px 0 2px 0', borderRadius: 4, display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                            <Typography style={{ color: '#FF00FD', fontWeight: 600, fontSize: 14 }}>
                                {t('hot')}
                            </Typography>
                        </div>
                        <div style={{display: 'flex', justifyContent:"space-between", alignItems: "center"}}>
                            <div>
                                <Typography style={{fontFamily:"PingFang SC", fontSize:40, fontWeight:"bold", color: 'white'}}>{t('rankTitle')}</Typography>
                            </div>
                            <img src={rank} style={{width:50, height:56}}/>
                        </div>
                        <Typography className={classes.font} style={{marginTop:30, fontSize:14, fontWeight:400, color: 'white'}}>{t('rankContent')}</Typography>
                    </div>
                    <div style={{ top: 0 }}>
                         {renderConnectMetamask('rank')}
                    </div>
                </div>

            </div>
        )
    }

    const renderPhone = ()=>{
        return(
            <div style={{display: 'flex', width:"100%", flexDirection:"column", marginTop:60, marginBottom:40, alignItems:"center"}}>
                <div style={{ display:"flex", flexDirection:"column", maxHeight: 350, width:"90%", maxWidth: 396, backgroundColor: "rgb(5, 0, 107,0.04)",borderRadius: 20, marginBottom: width < 290 ? 140 : width < 300 ? 120 : width < 395 ? 105 : width < 421 ? 80 : 30}}>
                    <div style={{ position: 'relative', backgroundImage: `url(${nodeShort1})`, backgroundSize: 'cover', borderRadius: 20}}>
                        <div style={{margin:"23px 30px 100px"}}>
                            <div style={{ marginBottom: 20, backgroundColor: 'white', width: 58, height: 20, padding: '2px 0 2px 0', borderRadius: 4, display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                                <Typography style={{ color: '#ff0067', fontWeight: 600, fontSize: 14 }}>
                                    {t('hot')}
                                </Typography>
                            </div>
                            <div style={{display: 'flex', justifyContent:"space-between", alignItems: "center"}}>
                                <div>
                                    <span style={{fontFamily:"PingFang SC", fontSize:40, fontWeight:"bold", color: 'white'}}>{t('nodeTitle')}</span>
                                </div>
                                <img src={node} style={{width:56, height:56}}/>
                            </div>
                            <Typography className={classes.font} style={{marginTop:30, fontSize:14, fontWeight:400, color: 'white'}}>{t('nodeContent')}</Typography>
                            <div style={{marginTop:30, marginBottom: 30, display: 'flex', justifyContent:"space-between"}}>
                                <Typography className={classes.font}  style={{fontSize: 16, lineHeight: "22px", color: "white", fontWeight:400}}>{t("project")}</Typography>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }} onClick={() => {
                                    window.open('https://oasismktg.com/', '_blank')
                                }}>
                                    <Typography className={classes.font}  style={{fontSize: 16, lineHeight: "22px", color: "white", fontWeight:400}}>{t('enter')}</Typography>
                                    <img src={redirect} style={{ marginLeft: 4, width: 13, height: 13 }} />
                                </div>
                            </div>
                        </div>
                         {renderConnectMetamask('node')}
                    </div>
                </div>
                <div style={{ display:"flex", flexDirection:"column", maxHeight: 350, width:"90%", maxWidth: 396, backgroundColor: "rgb(5, 0, 107,0.04)",borderRadius: 20}}>
                    <div style={{ position: 'relative', height: '100%', backgroundImage: `url(${nodeShort2})`, backgroundSize: 'cover', borderRadius: 20}}>
                        <div style={{margin: "23px 30px 120px"}}>
                            <div style={{ marginBottom: 20, backgroundColor: 'white', width: 58, height: 20, padding: '2px 0 2px 0', borderRadius: 4, display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                                <Typography style={{ color: '#FF00FD', fontWeight: 600, fontSize: 14 }}>
                                    {t('hot')}
                                </Typography>
                            </div>
                            <div style={{display: 'flex', justifyContent:"space-between", alignItems: "center"}}>
                                <div>
                                    <span style={{fontFamily:"PingFang SC", fontSize:40, fontWeight:"bold", color: 'white'}}>{t('rankTitle')}</span>
                                </div>
                                <img src={rank} style={{width:50, height:56}}/>
                            </div>
                            <Typography className={classes.font} style={{marginTop:30, fontSize:14, fontWeight:400, color: 'white'}}>{t('rankContent')}</Typography>
                        </div>
                         {renderConnectMetamask('rank')}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div style={{ display: 'flex', flexDirection:"column", justifyContent: 'center', alignItems:"center" }}>
            <div style={{backgroundColor:"white", width:"100%", display: 'flex', justifyContent: 'center'}}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minWidth:width<960 ? null:896, width:width<960 ? "100%":"50%" }}>
                    {renderHeader()}
                    {width < 960 ? renderPhone() : renderPC()}
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(IDOHome);
